// libraries
import { ITradeInVehicle, IVehicleModel, IVehicleMake, IVehicleTrim } from '@makemydeal/dr-activities-common';

const cloneMake = (make: IVehicleMake) => ({
    id: make?.id || '',
    name: make?.name || ''
});

const cloneModel = (model: IVehicleModel) => ({
    id: model?.id || '',
    name: model?.name || ''
});

const cloneTrim = (trim: IVehicleTrim) => ({
    id: trim?.id || '',
    name: trim?.name || '',
    vehicleId: trim?.vehicleId || ''
});

export const cloneVehicle = (vehicle: ITradeInVehicle): ITradeInVehicle => ({
    ...vehicle,
    make: cloneMake(vehicle.make),
    model: cloneModel(vehicle.model),
    trim: cloneTrim(vehicle.trim)
});
