// externals
import { connect } from 'react-redux';
import isEmpty_ from 'lodash.isempty';
import isEqual_ from 'lodash.isequal';

// libraries
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';

// components
import VehicleInfoUI from './VehicleInfoUI-singleColumn';

// interfaces/types
import { IVehicleInfoUIStateProps, IVehicleInfoUIDispatchProps } from './VehicleInfoUI-singleColumn';

// selectors
import * as offerSelectors from '../../../store/mmd/offerSelectors';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as featureToggleSelectors from '../../../store/featureToggles';

// actions
import * as tradeInActionCreators from '../../../store/actionCreators';
import { goToNext } from '../../../store/actionCreators';

// consts/enums
import * as tradeInRoutes from '../../../utils/routes';
import { CALLSITE_SINGLECOLUMN_1, CALLSITE_SINGLECOLUMN_2 } from '../../../utils/constants';

// utils
import * as optimizelyUtils from '../../../common/optimizelyWrapper';
import { cloneVehicle } from '../../../utils/cloner';

const isEmpty = isEmpty_;
const isEqual = isEqual_;

const mapStateToProps = (state: any): IVehicleInfoUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponentRaw(state);
    const years = tradeInSelectors.getTradeInLookupYears(tradeIn);
    const makes = tradeInSelectors.getTradeInLookupMakes(tradeIn);
    const models = tradeInSelectors.getTradeInLookupModels(tradeIn);
    const trims = tradeInSelectors.getTradeInLookupTrims(tradeIn);
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn);

    const isMakeEnabled = tradeInSelectors.getTradeInIsMakeEnabled(tradeIn);
    const isMakeLoading = tradeInSelectors.getTradeInIsMakeLoading(tradeIn);
    const isModelEnabled = tradeInSelectors.getTradeInIsModelEnabled(tradeIn);
    const isModelLoading = tradeInSelectors.getTradeInIsModelLoading(tradeIn);
    const isTrimEnabled = tradeInSelectors.getTradeInIsTrimEnabled(tradeIn);
    const isTrimLoading = tradeInSelectors.getTradeInIsTrimLoading(tradeIn);
    const isTrimSelected = tradeInSelectors.getTradeInIsTrimSelected(tradeIn);
    const isYearEnabled = tradeInSelectors.getTradeInIsYearEnabled(tradeIn);
    const isYearLoading = tradeInSelectors.getTradeInIsYearLoading(tradeIn);
    const isOwnershipEnabled = tradeInSelectors.getTradeInIsOwnershipEnabled(tradeIn);
    const isMileageEnabled = tradeInSelectors.getTradeInIsMileageEnabledFromState(state);
    const isZipCodeEnabled = tradeInSelectors.getTradeInIsZipCodeEnabled(tradeIn);
    const areOptionalFieldsEnabled = tradeInSelectors.getTradeInAreOptionalFieldsEnabled(tradeIn);

    const zip = tradeInSelectors.getTradeInZip(tradeIn) || offerSelectors.getShopperZip(state);
    const ownership = tradeInSelectors.getTradeInOwnership(tradeIn);
    const vehicle = tradeInSelectors.getTradeInVehicle(tradeIn);
    const savedVehicle = tradeInSelectors.getTradeInSavedVehicle(tradeIn);
    const isTradeInValid = tradeInSelectors.getTradeInIsValid(tradeIn, state);
    const pageTitle = 'Which car are you trading in?';
    const headerSubtitle = 'Step 1 of 5';
    const isKbbFieldValidationEnabled = featureToggleSelectors.isKbbFieldValidationEnabled(state);
    const formFieldVariation = 'test';

    return {
        headerSubtitle,
        isMakeEnabled,
        isMakeLoading,
        isModelEnabled,
        isModelLoading,
        isTradeInValid,
        isTrimEnabled,
        isTrimLoading,
        isTrimSelected,
        isYearEnabled,
        isYearLoading,
        isOwnershipEnabled,
        isMileageEnabled,
        isZipCodeEnabled,
        areOptionalFieldsEnabled,
        makes,
        models,
        pageTitle,
        ownership,
        savedVehicle,
        trims,
        vehicle,
        vehicleOptions,
        years,
        zip,
        isKbbFieldValidationEnabled,
        formFieldVariation
    };
};

const updateVehicleProp = (vehicle: ITradeInVehicle, prop, elem, subProps) => {
    const clonedVehicle = cloneVehicle(vehicle);
    const { value, text } = elem;
    if (subProps) {
        return {
            ...clonedVehicle,
            [prop]: {
                ...clonedVehicle[prop],
                id: value,
                name: text
            }
        };
    } else {
        return {
            ...clonedVehicle,
            [prop]: value
        };
    }
};

const mapDispatchToProps = (dispatch: any): IVehicleInfoUIDispatchProps => {
    return {
        onLoad: (vehicle, years, makes, models, trims, zip) => {
            const loadCb = () => {
                dispatch(tradeInActionCreators.tradeInStarted());
                optimizelyUtils.track('TRADE_VEHICLE_INFO_IMPRESSION');
            };
            setTimeout(loadCb, 500);
            if (years.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInYears());
            }
            if (vehicle.year && makes.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInMakes(vehicle.year));
            }
            if (vehicle.year && vehicle.make.id && models.length === 0) {
                dispatch(
                    tradeInActionCreators.fetchTradeInModels(vehicle.year, vehicle.make.id, undefined, CALLSITE_SINGLECOLUMN_1)
                );
            }
            if (vehicle.year && vehicle.make.id && vehicle.model.id && trims.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInTrims(vehicle.year, vehicle.make.id, vehicle.model.id));
            }
            if (zip) {
                dispatch(tradeInActionCreators.updateZip(zip));
            }
        },
        onValueChange: (propName, domElement, vehicle, savedVehicle) => {
            const hasVehicleChanged = !isEqual(vehicle, savedVehicle);
            dispatch(tradeInActionCreators.changedTradeIn(hasVehicleChanged));

            switch (propName) {
                case 'year': {
                    const vehicleWithUpdatedYear = updateVehicleProp(vehicle, propName, domElement, false);
                    dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedYear));
                    dispatch(tradeInActionCreators.fetchTradeInMakes(vehicleWithUpdatedYear.year));
                    dispatch(tradeInActionCreators.clearYMMT({ make: true, model: true, trim: true }));
                    break;
                }

                case 'make': {
                    const vehicleWithUpdatedMake = updateVehicleProp(vehicle, propName, domElement, true);
                    dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedMake));
                    dispatch(
                        tradeInActionCreators.fetchTradeInModels(
                            vehicleWithUpdatedMake.year,
                            vehicleWithUpdatedMake.make.id,
                            undefined,
                            CALLSITE_SINGLECOLUMN_2
                        )
                    );
                    dispatch(tradeInActionCreators.clearYMMT({ model: true, trim: true }));
                    break;
                }

                case 'model': {
                    const vehicleWithUpdatedModel = updateVehicleProp(vehicle, propName, domElement, true);
                    dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedModel));
                    dispatch(
                        tradeInActionCreators.fetchTradeInTrims(
                            vehicleWithUpdatedModel.year,
                            vehicleWithUpdatedModel.make.id,
                            vehicleWithUpdatedModel.model.id
                        )
                    );
                    dispatch(tradeInActionCreators.clearYMMT({ trim: true }));
                    break;
                }

                case 'trim': {
                    const vehicleWithUpdatedTrim = updateVehicleProp(vehicle, propName, domElement, true);
                    vehicleWithUpdatedTrim.trim.vehicleId = vehicleWithUpdatedTrim.trim ? vehicleWithUpdatedTrim.trim.id : '';
                    dispatch(tradeInActionCreators.updateVehicleOptions({}));
                    dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedTrim));
                    break;
                }

                case 'ownership':
                case 'paymentPreference':
                    if (domElement) {
                        dispatch(tradeInActionCreators.updatePurchasePreference(domElement.value));
                    } else {
                        throw new Error(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (VehicleInfo-singleColumn)`
                        );
                    }
                    break;

                case 'color':
                case 'condition':
                case 'mileage':
                case 'vin': {
                    const vehicleWithUpdatedFields = updateVehicleProp(vehicle, propName, domElement, false);
                    dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedFields));
                    break;
                }

                case 'zip':
                    dispatch(tradeInActionCreators.updateZip(domElement.value));
                    break;

                default:
                    break;
            }
        },
        cancelTradeIn: () => {
            // Analytics
            dispatch(tradeInActionCreators.cancelTradeIn());
        },
        applyTradeIn: () => {
            // Analytics + Middle Ware
            dispatch(tradeInActionCreators.applyTradeInSelections());
        },
        fetchEquipmentOptions: (vehicleOptions, vehicle, zip) => {
            if (isEmpty(vehicleOptions)) {
                dispatch(tradeInActionCreators.fetchTradeInEquipmentOptions(vehicle, zip));
            }
        },
        next: () => {
            dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_CONDITION));
        },
        onSkip: () => {
            dispatch(goToNext('/tradeIn'));
            dispatch(tradeInActionCreators.tradeInSkipped());
        }
    };
};

const VehicleInfoContainer = connect(mapStateToProps, mapDispatchToProps)(VehicleInfoUI);

export default VehicleInfoContainer;
