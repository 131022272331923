// istanbul ignore file
import styled from 'styled-components';

export type StyledExpandableAreaPropTypes = {
    height: number;
    duration: number;
    expanded: boolean;
    initializing: boolean;
};

// If height is === 0, it means the resizeObserver didn't had a chance yet to ge the height on the component,
// In that case we set the position to absolute to avoid layout shifting
export const StyledExpandableArea = styled.div<StyledExpandableAreaPropTypes>`
    ${({ initializing, duration }) => (initializing ? '' : `transition: margin ${duration}ms ease;`)}
    margin-top: ${({ expanded, height }) => (expanded ? '0px' : `-${height}px`)};
    ${({ height }) => (height === 0 ? 'position: absolute;' : '')}
`;

export const ExpandableAreaContainer = styled.div`
    overflow: hidden;
    position: relative;
`;
