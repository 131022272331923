// externals
import * as React from 'react';

// libraries
import { INextStepCardDetails, IVehicleOption } from '@makemydeal/dr-activities-common';
import { IconFactory, Modal, PageUI } from '@makemydeal/dr-activities-common';

// utils
import { formatDollars } from '../../../store/mmd/formatUtils';
import { milesToStringShort } from '../../../store/mmd/uiHelpersUtil';

// config
import config from '../../../store/mmd/config';

// components
import { KBBTradeInAdvisor } from './KBBTradeInAdvisor';
import RulesUI from '../../../common/components/subaruEligibility/RulesUI';

export interface ITradeSummaryUIStateProps {
    calculatedTradeInValue: number;
    cardDetails: INextStepCardDetails;
    currentDate: Date;
    dealerPhoneNumber: string;
    expirationDate: string;
    imageFPO: string;
    isSubaruGtpEligible?: boolean;
    isLeadFormEnabled: boolean;
    isLeadFormSubmitted: boolean;
    isManualEntryFlow: boolean;
    isTradeLeadFormEnabled: boolean;
    loanBalance: number;
    make: string;
    model: string;
    showShareDeal: boolean;
    subaruGtpLogoAlt?: any; // This is purely for local dev use (storybook can't show the static assets)
    tradeInValue: number;
    tradePaymentFailed: boolean;
    userIsDealer: boolean;
    userIsDesking: boolean;
    valueSource: string;
    vehicleCondition: string;
    vehicleId: string;
    vehicleMileage: number;
    vehicleOptions: IVehicleOption;
    vin?: string;
    year: string;
    zipCode: string;
}

export interface ITradeSummaryUIDispatchProps {
    editTradeIn: () => void;
    termsDetailsClicked: () => void;
    goToRoute: (route: string) => void;
    loadLeadForm: (skiplink: boolean) => void;
    dispatchAnalytics: (type: string, description: string) => void;
    tradeInEstimateDisplayed: () => void;
    removeTrade: () => void;
}

interface ITradeSummaryUIState {
    showKbbDisclaimer: boolean;
    showPaymentError: boolean;
    showModal: boolean;
}

export interface ITradeSummaryUIProps extends ITradeSummaryUIStateProps, ITradeSummaryUIDispatchProps {}

// TODO: Rename this - the file is TradeSummaryUI
class TradeSummaryUI extends React.Component<ITradeSummaryUIProps, ITradeSummaryUIState> {
    constructor(public props) {
        super(props);
        this.state = {
            showKbbDisclaimer: false,
            showPaymentError: props.tradePaymentFailed,
            showModal: false
        };
    }

    componentDidMount() {
        const {
            loadLeadForm,
            isTradeLeadFormEnabled,
            isLeadFormSubmitted,
            userIsDealer,
            tradeInEstimateDisplayed,
            isLeadFormEnabled
        } = this.props;

        if (!isLeadFormSubmitted && isLeadFormEnabled) {
            if (!userIsDealer) {
                loadLeadForm(!isTradeLeadFormEnabled);
            } else {
                tradeInEstimateDisplayed();
            }
        } else {
            tradeInEstimateDisplayed();
        }
    }

    componentDidUpdate(prevProps: ITradeSummaryUIProps) {
        if (prevProps.isLeadFormSubmitted === false && this.props.isLeadFormSubmitted) {
            this.props.tradeInEstimateDisplayed();
        }
        if (prevProps.tradePaymentFailed !== this.props.tradePaymentFailed) {
            this.setState({ showPaymentError: this.props.tradePaymentFailed });
        }
    }

    handlePencilEditClicked = () => {
        this.props.editTradeIn();
    };

    toggleKbbDisclaimer = () => {
        this.props.termsDetailsClicked();
        this.setState({ showKbbDisclaimer: !this.state.showKbbDisclaimer });
    };

    toggleShowPaymentError = () => {
        this.setState({ showPaymentError: !this.state.showPaymentError });
    };

    renderError = () => {
        const errorMessage = this.props.userIsDealer
            ? // eslint-disable-next-line max-len
              'A payment could not be calculated based on the added trade-in value. Try removing the trade-in to receive a payment. '
            : // eslint-disable-next-line max-len
              `Oops, We couldn't calculate a monthly payment based on your trade-in value. Try removing your trade to receive a payment or contact us at ${this.props.dealerPhoneNumber}. `;
        return this.state.showPaymentError ? (
            <div className="trade-in-payment-failed">
                <i className="fa fa-info-circle"></i>
                <p>
                    {errorMessage}
                    <a href="#" onClick={() => this.props.removeTrade()}>
                        Remove Trade-In
                    </a>
                </p>
            </div>
        ) : null;
    };

    renderTradeConfirmationHeader() {
        const { staticImages } = config;
        const {
            year,
            make,
            model,
            loanBalance,
            calculatedTradeInValue,
            isSubaruGtpEligible,
            tradeInValue,
            isManualEntryFlow
        } = this.props;

        const className = isSubaruGtpEligible ? 'estimated-value__subtext hidden-xs' : 'estimated-value__subtext';

        return (
            <div className="trade-in-estimate">
                <IconFactory icon="checkmark" className="icon checkmark" staticImages={staticImages} />
                <div className="estimated-value">
                    <div className="estimated-value__teaser common-header hidden-xs">
                        Your {year} {make} {model} is worth
                    </div>
                    <div className="estimated-value__content common-header">
                        <div className="estimated-value__content--label">
                            {isSubaruGtpEligible ? 'Subaru Guaranteed Trade Value:' : 'Estimated Trade-in Value:'}
                        </div>
                        {calculatedTradeInValue > 0 ? (
                            <div className="estimated-value__content--value positive">{formatDollars(calculatedTradeInValue)}</div>
                        ) : null}
                        {calculatedTradeInValue < 0 ? (
                            <div className="estimated-value__content--value negative">{formatDollars(calculatedTradeInValue)}</div>
                        ) : null}
                    </div>
                    <div className={className}>
                        Your trade-in equity is the net of your Trade-In Value and any Outstanding Loan amount
                    </div>
                </div>
                {loanBalance > 0 && (
                    <div className="math-stack-container">
                        <div className="math-stack">
                            <div>
                                <div className="math-stack-label">{!isManualEntryFlow ? 'KBB Value' : 'Entered Value'}:</div>
                                <div className="math-stack-value">{formatDollars(tradeInValue)}</div>
                            </div>
                            <div>
                                <div className="math-stack-label">Loan Balance:</div>
                                <div className="math-stack-value">{formatDollars(loanBalance)}</div>
                            </div>
                            <div>
                                <div className="math-stack-label">Trade-In Value:</div>
                                {calculatedTradeInValue > 0 ? (
                                    <div className="math-stack-value positive">{formatDollars(calculatedTradeInValue)}</div>
                                ) : null}
                                {calculatedTradeInValue < 0 ? (
                                    <div className="math-stack-value negative">{formatDollars(calculatedTradeInValue)}</div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { staticImages, kbbTradeInApiKey, renderMenuProducts } = config;
        const {
            cardDetails,
            currentDate,
            dispatchAnalytics,
            expirationDate,
            goToRoute,
            isSubaruGtpEligible,
            isManualEntryFlow,
            make,
            model,
            showShareDeal,
            subaruGtpLogoAlt,
            userIsDealer,
            userIsDesking,
            valueSource,
            vehicleCondition,
            vehicleId,
            vehicleMileage,
            vehicleOptions,
            vin,
            year,
            zipCode
        } = this.props;

        const { showKbbDisclaimer } = this.state;
        const pageTitle = isSubaruGtpEligible ? `Your ${year} ${make} ${model} is Eligible` : "We've Added Your Trade In!";
        const pageSubTitle = isSubaruGtpEligible ? '' : "We've determined your trade-in value and applied it to your deal.";
        const columnSize = isSubaruGtpEligible ? 'col-sm-12' : 'col-sm-5';
        const subaruDesktop = isSubaruGtpEligible ? 'subaru-desktop-view' : null;
        const subaruPencilEditIcon = isSubaruGtpEligible ? 'subaru-pencil-edit-icon' : null;
        const subaruYmm = isSubaruGtpEligible ? 'vehicle-info__subaru-ymm' : null;
        const subaruHeader = isSubaruGtpEligible ? 'subaru-header' : null;
        const subaruGtpLogo = subaruGtpLogoAlt || (
            <IconFactory icon="subaruGtpLogo" className="subaru-gtp-logo" staticImages={staticImages} />
        );
        const pencilIcon = <IconFactory icon="pencilIcon" className="icon primary-link-icon" staticImages={staticImages} />;
        const editText = <span className="primary-link">Edit</span>;
        return (
            <>
                <PageUI
                    pageClass="trade-in-summary-page"
                    pageTitle={pageTitle}
                    pageSubTitle={pageSubTitle}
                    showShareDeal={showShareDeal}
                    isDealerUser={userIsDealer}
                    isDeskingUser={userIsDesking}
                    staticAssetsConfig={staticImages}
                    cardDetails={cardDetails}
                    goToRoute={goToRoute}
                    dispatchAnalytics={dispatchAnalytics}
                    renderError={this.renderError}
                >
                    <div className="trade-in-summary-content">
                        {this.renderTradeConfirmationHeader()}
                        <div className="trade-in-vehicle container-fluid">
                            <div className="row">
                                <div className={`${columnSize} container vehicle-info-details`}>
                                    <div className={`${subaruHeader} title common-header`}>
                                        Your Trade-In
                                        <div
                                            className={`primary-link-container ${subaruPencilEditIcon} subaru-mobile-view`}
                                            onClick={this.handlePencilEditClicked}
                                        >
                                            {isSubaruGtpEligible && pencilIcon}
                                            {isSubaruGtpEligible && editText}
                                        </div>
                                    </div>
                                    <div className="vehicle-info common-header">
                                        <div className={`${subaruYmm} vehicle-info__ymm`}>
                                            {year} {make} {model}
                                            <div
                                                className={`primary-link-container pencil-edit-icon ${subaruDesktop}`}
                                                onClick={this.handlePencilEditClicked}
                                            >
                                                {pencilIcon}
                                                {editText}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="vehicle-info__content">
                                        <div className="vehicle-info__contentLeft">
                                            {isManualEntryFlow && (
                                                <div>
                                                    Value Provided by: <span>{valueSource}</span>
                                                </div>
                                            )}
                                            {isSubaruGtpEligible ? (
                                                <div>
                                                    VIN: <span>{vin}</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    Condition: <span>{vehicleCondition}</span>
                                                </div>
                                            )}
                                            <div>
                                                Mileage: <span>{milesToStringShort(vehicleMileage)}</span>
                                            </div>
                                            {isSubaruGtpEligible ? (
                                                <div>
                                                    Valid Until: <span>{new Date(expirationDate).toLocaleDateString()}</span>
                                                </div>
                                            ) : (
                                                <div>
                                                    Zip Code: <span>{zipCode}</span>
                                                </div>
                                            )}
                                        </div>
                                        {isSubaruGtpEligible && (
                                            <div className="vehicle-info__contentRight">
                                                <div className="dr-ui-subaru-gtp-logo">{subaruGtpLogo}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                {!isManualEntryFlow && !isSubaruGtpEligible && (
                                    <div className="col-sm-7 vehicle-info-kbb-value">
                                        <div className="kbb-trade-in-advisor-container">
                                            <KBBTradeInAdvisor
                                                apiKey={kbbTradeInApiKey}
                                                vehicleId={vehicleId}
                                                vehicleOptions={vehicleOptions}
                                                zipCode={zipCode}
                                                vehicleMileage={vehicleMileage}
                                                vehicleCondition={vehicleCondition}
                                                showExactValue={false}
                                            />
                                        </div>
                                    </div>
                                )}
                                {isSubaruGtpEligible && (
                                    <>
                                        <div className="col-xs-12 trade-in-vehicle__gtprules">
                                            Subaru Guaranteed Trade Program value is subject to verification. &nbsp;
                                            <a
                                                className="btn-link link"
                                                id="dr_trade_subaru_rules_link"
                                                onClick={() => this.setState({ showModal: true })}
                                            >
                                                See Program Rules and Eligibility
                                            </a>
                                        </div>
                                    </>
                                )}
                            </div>
                            {!isManualEntryFlow && !isSubaruGtpEligible && (
                                <div className="row">
                                    <div className="col-xs-12 trade-in-vehicle__tc">
                                        Actual price of this vehicle may vary. Estimate valid through&nbsp;
                                        {new Date(expirationDate).toLocaleDateString()}. &nbsp;
                                        <a className="primary-link" onClick={this.toggleKbbDisclaimer}>
                                            {showKbbDisclaimer ? 'Hide Terms and Conditions' : 'View Terms and Conditions'}
                                        </a>
                                        {showKbbDisclaimer && (
                                            <p className="kbb-trade-in-advisor-disclaimer">
                                                © {currentDate.getFullYear()} Kelley Blue Book Co., Inc. All rights reserved.{' '}
                                                {new Date(expirationDate).toLocaleDateString()} Edition for {zipCode}. Values and
                                                pricing are the opinions of Kelley Blue Book, and the actual price of a vehicle may
                                                vary. The value and pricing information displayed for a particular vehicle is based
                                                upon the specification, mileage and/or condition information provided by the person
                                                generating this report. Kelley Blue Book assumes no responsibility for errors or
                                                omissions.
                                            </p>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    {renderMenuProducts()}
                </PageUI>
                <Modal
                    show={this.state.showModal}
                    elementToAttachModalTo=".dr-sp-checkout-main"
                    elementToBlur="#checkout-main"
                    cls={'dr_trade_subaru_rules_modal'}
                >
                    <PageUI
                        pageClass="dr_trade_subaru_rules_page"
                        pageTitle="Rules and Eligibility"
                        footerProps={{
                            onActionButtonClick: () => this.setState({ showModal: false }),
                            showBackButton: false,
                            buttonText: 'Close',
                            disableActionButton: false
                        }}
                        dispatchAnalytics={dispatchAnalytics}
                    >
                        <RulesUI />
                    </PageUI>
                </Modal>
            </>
        );
    }
}

export default TradeSummaryUI;
