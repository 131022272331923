import * as React from 'react';

export const GTPFlowPageSubtitleUI = ({ GTPErrorPageSubTitle, pageSubtitle, suffix }) => {
    return (
        <>
            {GTPErrorPageSubTitle ? (
                <p data-testid="gtp-flow-error-subtitle" className="subheader-text">
                    {GTPErrorPageSubTitle} {suffix}
                </p>
            ) : (
                pageSubtitle
            )}
        </>
    );
};
