// libraries
import { Grid } from '@interstate/components/Grid';

import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { compositeSelectors, offerReduxSelectors } from '@makemydeal/dr-dash-store';

// consts/enums
import { CAP_COST_REDUCTION, CASH, CASH_FROM_CUSTOMER, REBATES, TRADE_IN } from '../../constants';

// components
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { useSelector } from 'react-redux';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';
import { QuickCompareOffer } from '../../offerCompareColumn/types/quickCompareOffer';
import DealSummaryItem from '../common/DealSummaryItem';
import { DealSummaryItemWrapper } from '../common/DealSummaryItemWrapper';
import LineItemWithChildren from '../common/LineItemWithChildren';
import { labelStyles, valueStyles } from '../utils/styles';

export interface ICapCostReductionProps {
    offer?: QuickCompareOffer;
    useWrapper?: boolean;
}

const CapCostReduction = ({ offer, useWrapper = true }: ICapCostReductionProps) => {
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);
    const useDealRootSelector = createDealRootSelector(offer ? { offer } : undefined);

    const netCashDown = useDealRootSelector(offerReduxSelectors.getNetCashDown);
    const reductionRebate = useDealRootSelector(offerReduxSelectors.getCapReductionRebate);
    const reductionTradeIn = useDealRootSelector(offerReduxSelectors.getCapReductionTradeIn);
    const selectedCapCostReduction = useDealRootSelector(compositeSelectors.getCapCostReduction);

    return (
        <div>
            {isEnhancedDealDetailsEnabled ? (
                <DealSummaryItem
                    valueStyles={valueStyles}
                    labelStyles={labelStyles}
                    label={CAP_COST_REDUCTION}
                    value={formatDollarsAndCents(selectedCapCostReduction)}
                >
                    <LineItemWithChildren label={CASH_FROM_CUSTOMER} value={formatDollarsAndCents(netCashDown)} />
                    <LineItemWithChildren label={REBATES} value={formatDollarsAndCents(reductionRebate)} />
                    <LineItemWithChildren label={TRADE_IN} value={formatDollarsAndCents(reductionTradeIn)} />
                </DealSummaryItem>
            ) : (
                <DealSummaryItemWrapper
                    label={CAP_COST_REDUCTION}
                    value={formatDollarsAndCents(selectedCapCostReduction)}
                    useWrapper={useWrapper}
                >
                    <Grid>
                        <LineItemWithChildren label={CASH} value={formatDollarsAndCents(netCashDown)} />
                        <LineItemWithChildren label={REBATES} value={formatDollarsAndCents(reductionRebate)} />
                        <LineItemWithChildren label={TRADE_IN} value={formatDollarsAndCents(reductionTradeIn)} />
                    </Grid>
                </DealSummaryItemWrapper>
            )}
        </div>
    );
};

export default CapCostReduction;
