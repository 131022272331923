// externals
import React from 'react';
import assignIn_ from 'lodash.assignin';
import forOwn_ from 'lodash.forown';
import uniqueId_ from 'lodash.uniqueid';

// components
import Header from '../header/Header';
import RadioButtonUI from '../radioButton/RadioButtonUI';
import CheckboxUI from './equipmentOptionsCheckbox/CheckboxUI';
import WarningIconUI from '../warningIcon/WarningIconUI';

// libraries
import { ActionFooterBar, ITradeInVehicle } from '@makemydeal/dr-activities-common';

const assignIn = assignIn_;
const forOwn = forOwn_;
const uniqueId = uniqueId_;

export interface IEquipmentOptionsUIStateProps {
    conflict?: any;
    vehicle: ITradeInVehicle;
    vehicleOptions: any;
    zip: string;
    pageTip: string;
    pageTitle: string;
    headerSubTitle: string;
    conditionMileageZipSubtitle: string;
}

export interface IEquipmentOptionsUIDispatchProps {
    handleBack?: { () };
    handleCancel?: { () };
    handleNext?: { () };
    resetStandardOptions?: { (vehicle: ITradeInVehicle, zip: string) };
    onValueChange: { (categoryName: string, id: number, checked: boolean) };
    next: { () };
    previous: { () };
}

export interface IEquipmentOptionsUIProps extends IEquipmentOptionsUIStateProps, IEquipmentOptionsUIDispatchProps {}

export interface IEquipmentOptionsUIState {}

let totalOptionsItemHeight = 0;
const calculateHeight = (vehicleOptions) => {
    totalOptionsItemHeight = 0;
    const optionsByCategory = {};
    if (vehicleOptions) {
        forOwn(vehicleOptions, (category, categoryName) => {
            const blockHeight = 2 + category.options.length;
            optionsByCategory[categoryName] = assignIn({}, category, { blockHeight });
            totalOptionsItemHeight += blockHeight;
        });
    }

    return optionsByCategory;
};

export class EquipmentOptionsUI extends React.Component<IEquipmentOptionsUIProps, IEquipmentOptionsUIState> {
    constructor(props) {
        super(props);
    }

    onClickNext = () => {
        this.props.handleNext();
        this.props.next();
    };

    render() {
        const {
            conflict,
            vehicle,
            vehicleOptions,
            zip,
            onValueChange,
            resetStandardOptions,
            pageTip,
            pageTitle,
            headerSubTitle,
            conditionMileageZipSubtitle
        } = this.props;
        const equipmentOptionsInputsCol1 = [];
        const equipmentOptionsInputsCol2 = [];
        const optionsByCategory = calculateHeight(vehicleOptions);
        let heightCounter = 0;
        const firstColumnLimit = totalOptionsItemHeight / 2;

        const reset = () => {
            resetStandardOptions(vehicle, zip);
        };

        forOwn(optionsByCategory, (category: any, categoryName) => {
            let componentToRender = null;
            const inputType = category.allowMultiSelect ? 'checkbox' : 'radio';
            const categoryOptionsInputs = category.options.map((option, index) => {
                const changeHandler = (event) => {
                    onValueChange(option.categoryName || categoryName, option.id, event.target.checked);
                };

                switch (inputType) {
                    case 'radio':
                        componentToRender = (
                            <RadioButtonUI
                                defaultChecked={option.isSelected === true}
                                id={option.id}
                                name={option.categoryName}
                                label={option.displayName}
                                onChange={changeHandler}
                                value={option.isSelected}
                            />
                        );
                        break;
                    default: {
                        let conflictWarning = null;
                        const hasLegacyConflict =
                            categoryName === conflict?.categoryInConflict && index === conflict?.optionInConflictIndex;
                        if (hasLegacyConflict || option.conflict) {
                            const warningMsg = option?.conflict?.message || 'This option was removed based on a previous selection';
                            conflictWarning = <WarningIconUI title={warningMsg} />;
                        }

                        componentToRender = (
                            <CheckboxUI
                                defaultChecked={option.isSelected === true}
                                id={option.id}
                                name={option.categoryName}
                                label={option.displayName}
                                onChange={changeHandler}
                                value={option.isSelected}
                            >
                                {conflictWarning}
                            </CheckboxUI>
                        );
                    }
                }
                return <li key={uniqueId()}>{componentToRender}</li>;
            });

            const equipmentCategoryItem = (
                <div className="equipment-category" key={uniqueId()}>
                    <div className="field-label">{category.name}</div>
                    <ul>{categoryOptionsInputs}</ul>
                </div>
            );

            if (heightCounter < firstColumnLimit) {
                equipmentOptionsInputsCol1.push(equipmentCategoryItem);
            } else {
                equipmentOptionsInputsCol2.push(equipmentCategoryItem);
            }
            heightCounter += category.blockHeight;
        });

        const barProps = {
            onActionButtonClick: this.onClickNext,
            onBackButtonClick: this.props.previous,
            showBackButton: true,
            ignoreRouterHistory: true,
            buttonText: 'Continue'
        };

        return (
            <div className="options-step-container">
                <Header
                    pageTitle={pageTitle}
                    pageTip={pageTip}
                    headerSubTitle={headerSubTitle}
                    pageSubTitle={conditionMileageZipSubtitle}
                />

                <div className="equipment-options-body">
                    <div className="equipment-options-form">
                        <div className="equipment-options-col col1">{equipmentOptionsInputsCol1}</div>
                        <div className="equipment-options-col col2">{equipmentOptionsInputsCol2}</div>
                    </div>
                </div>

                <div className="reset-button">
                    <button
                        className="btn btn-secondary"
                        data-analytics="standard-options-reset"
                        id="reset-options-btn"
                        onClick={reset}
                    >
                        <i className="fa fa-undo"></i>&nbsp; Reset to Standard Options
                    </button>
                </div>

                <ActionFooterBar {...barProps} />
            </div>
        );
    }
}

export default EquipmentOptionsUI;
