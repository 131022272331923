// libraries
import { IConfigObj, urlBuilder, urlSlicer } from '@makemydeal/dr-common-utils';

// consts/enums
import {
    ASSETS_BASE,
    BFF_ENV,
    BFF_ENV_LIST,
    DEV_SCHEME,
    LOCAL_ASSET_PORT,
    LOCAL_SCHEME,
    THEME_ENV
} from '../consts/apiConfigConsts';
import { HostEnvironment } from '../hostUrlHelper/hostUrlHelperEnums';
import { LocalDevDefaultOverrideMode } from '../hostUrlHelper/apiConfigHelperEnums';

// interfaces/types
import type { ApiConfigResult, BffApiUrlResult } from '../hostUrlHelper/apiConfigHelperTypes';

// utils
import {
    getEnvOverrideFromSessionStorage,
    getHostFromEnvironment,
    getHostFromEnvironmentApp,
    getHostUrlEnvironment,
    isAppDomain,
    mapAppToHostPrefix,
    mapAppToLocalPortNumber
} from '../hostUrlHelper/hostUrlHelper';
import { getEnvironmentName } from '../environmentNameUtils/environmentNameUtils';
import { hostUrlHelper } from '../hostUrlHelper';

/**
 * By default when running locally Dash will use the dev environment for BFF because it is assumed, as with Shopper Platform, that
 * most development will require only client app changes.  For the developers who need to run the BFF locally they can make use
 * of a URL like this: http://localhost:4310?bffEnv=local to force the app to use the local BFF instance.
 *
 * @param detectedEnvironment The environment determined by using the browser's href.
 * @returns environment enum value based on the rules defined in the description above.
 */
export const applyLocalDevDefaultOverride = (detectedEnvironment: HostEnvironment): HostEnvironment => {
    return detectedEnvironment === HostEnvironment.Local ? HostEnvironment.Dev : detectedEnvironment;
};

export const buildBffApiUrl = (appPrefix: string, docLocationHref: string, relativePath: string): BffApiUrlResult => {
    const { configObj, environmentName, defaultEnvironmentName, overriddenEnvironmentName } = buildBffApiConfig(
        appPrefix,
        docLocationHref
    );
    const url = urlBuilder.buildFromConfig(configObj, relativePath);
    return {
        url,
        environmentName,
        defaultEnvironmentName,
        overriddenEnvironmentName
    };
};

/**
 * Uses the browser URL to determine the BFF API configuration data.
 * @param docLocationHref browser URL typically retrieved using document.location.href
 * @returns
 *    environmentName: ultimate environment that will be used after defaults and overrides applied;
 *    defaultEnvironmentName: default chosen for that environment (in future local will return dev for this);
 *    overriddenEnvironmentName: environment specified by query param override;
 *    environmentOverriden: true if an override was applied and this was different from the default
 */
export const buildBffApiConfig = (appPrefix: string, docLocationHref: string): ApiConfigResult => {
    return buildApiConfig(
        appPrefix,
        docLocationHref,
        BFF_ENV,
        mapAppToLocalPortNumber(appPrefix),
        LocalDevDefaultOverrideMode.ApplyOverride
    );
};

export const buildBffConfigObj = (
    appPrefix: string,
    environment: HostEnvironment,
    localPort: number,
    docLocationHref: string
): IConfigObj => {
    const environmentIsLocal = environment === HostEnvironment.Local;

    const hostPrefix = mapAppToHostPrefix(appPrefix);

    return {
        base: 'api',
        host: isAppDomain(appPrefix, docLocationHref)
            ? getHostFromEnvironmentApp(appPrefix, environment)
            : getHostFromEnvironment(hostPrefix, environment),
        protocol: environmentIsLocal ? LOCAL_SCHEME : DEV_SCHEME,
        port: environmentIsLocal ? localPort : undefined
    };
};

export const isInLocalDevMode = (appPrefix: string, docLocationHref: string) => {
    const environmentFromHref = getHostUrlEnvironment(appPrefix, docLocationHref);
    return environmentFromHref === HostEnvironment.Local;
};

export const buildApiConfig = (
    appPrefix: string,
    docLocationHref: string,
    envQueryParamName: string,
    localPort: number,
    localDevDefaultOverrideMode: LocalDevDefaultOverrideMode
): ApiConfigResult => {
    const environmentFromHref = getHostUrlEnvironment(appPrefix, docLocationHref);

    const defaultEnvironment =
        localDevDefaultOverrideMode === LocalDevDefaultOverrideMode.ApplyOverride
            ? applyLocalDevDefaultOverride(environmentFromHref)
            : environmentFromHref;

    const overriddenEnvironment = getEnvOverrideFromSessionStorage(envQueryParamName);
    const environment = overriddenEnvironment !== HostEnvironment.None ? overriddenEnvironment : defaultEnvironment;
    let configObj: IConfigObj;
    if (environmentFromHref === HostEnvironment.Local) {
        if (!BFF_ENV_LIST.includes(overriddenEnvironment) && overriddenEnvironment !== HostEnvironment.None) {
            const displayList = BFF_ENV_LIST.map((env: HostEnvironment) => `"${env}"`).join(', ');
            const msg = `Environment Override (${envQueryParamName}) is only supported for the following environments: ${displayList}.`;
            throw new Error(msg);
        }
        configObj = buildBffConfigObj(appPrefix, environment, localPort, docLocationHref);
    } else {
        const schemeHostAndDomain = urlSlicer.extractSchemeAndDomain(docLocationHref);
        const protocol = urlSlicer.extractScheme(docLocationHref);
        const protocolPrefix = `${protocol}://`;
        const hostAndDomain = schemeHostAndDomain.substr(protocolPrefix.length);
        const hostAndDomainParts = hostAndDomain.split(':');
        const host = hostAndDomainParts[0];
        const port = hostAndDomainParts.length > 1 ? parseInt(hostAndDomainParts[1], 10) : undefined;
        configObj = {
            base: 'api',
            host,
            protocol,
            port
        };
    }
    return {
        configObj,
        environmentName: getEnvironmentName(environment),
        defaultEnvironmentName: getEnvironmentName(defaultEnvironment),
        overriddenEnvironmentName: getEnvironmentName(overriddenEnvironment)
    };
};

export const buildThemeAssetBasePath = (appPrefix: string, docLocationHref: string): string => {
    const { configObj } = buildApiConfig(
        appPrefix,
        docLocationHref,
        THEME_ENV,
        LOCAL_ASSET_PORT,
        LocalDevDefaultOverrideMode.RetainOriginal
    );
    const assetsConfigObj = { ...configObj, base: ASSETS_BASE };
    return urlBuilder.buildFromConfig(assetsConfigObj);
};

export const bffApiUrlBuilderFromEnvironment = (environment: HostEnvironment, appPrefix: string, path: string): BffApiUrlResult => {
    const isLocalEnvironment = environment === HostEnvironment.Local;
    const schema = isLocalEnvironment ? LOCAL_SCHEME : DEV_SCHEME;
    const host = hostUrlHelper.getHostFromEnvironmentApp(appPrefix, environment);
    const portForLocal = isLocalEnvironment ? `:${hostUrlHelper.mapAppToLocalPortNumber(appPrefix)}` : '';
    const openDealUrlFormat = `${schema}://${host}${portForLocal}/api${path}`;
    const environmentName = getEnvironmentName(environment);
    return {
        url: openDealUrlFormat,
        environmentName: environmentName,
        defaultEnvironmentName: environmentName,
        overriddenEnvironmentName: 'None'
    };
};

export const apiUrlBuilderFromEnvironment = (environment: HostEnvironment, appPrefix: string) => {
    const apiUrlBuilder = {
        buildBffApiUrl(path: string) {
            return bffApiUrlBuilderFromEnvironment(environment, appPrefix, path);
        }
    };
    return apiUrlBuilder;
};
