// externals
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

// libraries
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { formatDollarsAndCents, kebabCase } from '@makemydeal/dr-common-utils';
import { compositeSelectors, offerReduxSelectors, offerSelectors, paymentInputActionCreators } from '@makemydeal/dr-dash-store';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
// utils
import { usePaymentCall } from '../../utils/usePaymentCall';

// styles
import { NumericInput } from '@interstate/components/NumericInput';
import IsTargetOutOfPocket from './IsTargetOutOfPocket';

// consts/enums
import { offerActionTypes } from '@makemydeal/dr-dash-store';
import { CASH_DOWN, GENERIC_LAST_FIELD_CHANGED_ERROR } from '../../constants';
import { generateCashDownLabel } from './utils';

export const CashDownField = () => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const deductDueAtSigning = useSelector(dealerSelectors.getIsDueAtSigninAmountDeduct);
    const isTargetOutOfPocket = useSelector(offerReduxSelectors.getIsTargetOutOfPocket);
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);
    const isTargetOutOfPocketLeaseEnabled = useSelector(featureToggleSelectors.isTargetOutOfPocketLeaseEnabled);

    const failed = useSelector(compositeSelectors.getLastFailureActionIfError(offerActionTypes.UPDATED_DOWN_PAYMENT_IMMEDIATE));
    const failedMessage = useMemo(() => (failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined), [failed]);

    const {
        value: downPayment,
        handleChange,
        handleBlur
    } = usePaymentCall(offerSelectors.getDownPayment, paymentInputActionCreators.updateDownPayment, {
        formatValueToString: formatDollarsAndCents
    });

    const label = generateCashDownLabel(
        offerType,
        deductDueAtSigning,
        isTargetOutOfPocket,
        isEnhancedDealDetailsEnabled,
        isTargetOutOfPocketLeaseEnabled
    );

    const [inputError, setInputError] = useState('');

    const handleChangeWrapper = (e: InterstateOnChangeEvent<string>) => {
        if (e.target.value === '') {
            setInputError('Please enter a valid amount');
            return;
        }
        setInputError('');
        handleChange(e);
    };

    const errorState = inputError || failedMessage;
    const showError = Boolean(inputError || failed);

    return (
        <div style={{ display: 'flex', alignItems: 'flex-end', gap: '12px' }}>
            <NumericInput
                placeholder="e.g. 2000.00"
                name={kebabCase(CASH_DOWN)}
                value={inputError ? '' : downPayment}
                onChange={handleChangeWrapper}
                onBlur={handleBlur}
                hasError={showError}
                errorMessage={errorState}
                label={label}
                data-testid={kebabCase(CASH_DOWN)}
                allowDecimal={true}
                decimalMaxLength={2}
                decimalMinLength={2}
                inputPrefix={'$'}
                autoInsertCommas={true}
            />
            {isTargetOutOfPocketLeaseEnabled && offerType === 'lease' && <IsTargetOutOfPocket />}
        </div>
    );
};

export default CashDownField;
