import * as React from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { formatUtils } from '@makemydeal/dr-common-utils';

import * as tradeInSelectors from '../../store/mmd/tradeIn';
import { getGlobalSelector } from '../../store/mmd/mmd';
import { TRADEIN_SOURCE_TYPES, VEHICLE_DETAILS_LABELS } from './constants';

export interface ITradeTermsDetailsUIStateProps {
    ymm: string;
    vehicleMileage: number;
    equity: string;
    isTradeInImprovedWorkflowEnabled?: boolean;
    year?: string;
    make?: string;
    model?: string;
    condition?: string;
    amountOwed?: number;
    rawValue?: number;
    value?: string;
    source?: string;
    hasACV?: boolean;
    formatedEquity?: string;
}

export class TradeTermsDetailsUI extends React.Component<ITradeTermsDetailsUIStateProps, any> {
    getFormattedMileage() {
        const { vehicleMileage } = this.props;
        if (vehicleMileage) {
            const formattedMileage = Number(vehicleMileage).toLocaleString('en-US');
            return formattedMileage;
        }
        return vehicleMileage;
    }

    getRedesignDetails() {
        return (
            <>
                <h3>Trade-In Details</h3>
                <ul className="card-drawer-list">
                    <li>
                        YMM: <span className="card-drawer-list-values">{this.props.ymm}</span>
                    </li>
                    <li>
                        Vehicle Mileage: <span className="card-drawer-list-values">{this.getFormattedMileage()}</span>
                    </li>
                    <li>
                        Trade-In Equity: <span className="card-drawer-list-values">{this.props.equity}</span>
                    </li>
                </ul>
            </>
        );
    }

    getImprovedVehicleDetails(vehicle) {
        return (
            <>
                {vehicle
                    .filter((detail) => {
                        if (detail.label === VEHICLE_DETAILS_LABELS.condition) {
                            return detail.value !== '';
                        }
                        return true;
                    })
                    .map(({ label, value }) => (
                        <li key={label} className="card-drawer-closed-gaps">
                            {label}: <span className="card-drawer-list-values">{value}</span>
                        </li>
                    ))}
            </>
        );
    }

    getManualEntryDetails(details) {
        const { vehicle, equity, amountOwed, value, rawValue, hasACV } = details;
        return (
            <>
                <h3>Trade-In Details</h3>
                <ul className="card-drawer-list">
                    {this.getImprovedVehicleDetails(vehicle)}
                    <br />
                    <li className="card-drawer-flex-item card-drawer-closed-gaps">
                        {hasACV ? 'Trade-In Value ' : 'Est. Value* '}
                        <span className="card-drawer-list-values">{rawValue > 0 ? value : 'Pending Appraisal'}</span>
                    </li>
                    {amountOwed && (
                        <li className="card-drawer-flex-item card-drawer-closed-gaps">
                            Amount You Owe <span className="card-drawer-list-values">-{amountOwed}</span>
                        </li>
                    )}
                    <hr />
                    <li className="card-drawer-flex-item card-drawer-closed-gaps">
                        {hasACV ? 'Trade-In Value Applied ' : 'Est. Trade-In Value '}
                        <span className="card-drawer-list-values">{rawValue > 0 ? equity : 'Pending Appraisal'}</span>
                    </li>
                </ul>
            </>
        );
    }

    getIcoDetails(details) {
        const { vehicle, formatedEquity, amountOwed, value, hasACV, rawValue } = details;
        return (
            <>
                <h3>Trade-In Details</h3>
                <ul className="card-drawer-list">
                    {this.getImprovedVehicleDetails(vehicle)}
                    <br />
                    <li className="card-drawer-flex-item card-drawer-closed-gaps">
                        <span>Kelley Blue Book Instant Cash Offer Value{hasACV ? '' : '*'} </span>
                        <span className="card-drawer-list-values">{rawValue === 0 ? 'See Dealer' : value}</span>
                    </li>
                    {amountOwed && (
                        <li className="card-drawer-flex-item card-drawer-closed-gaps">
                            Amount You Owe <span className="card-drawer-list-values">-{amountOwed}</span>
                        </li>
                    )}
                    <hr />
                    <li className="card-drawer-flex-item card-drawer-closed-gaps">
                        Trade-In Value{' '}
                        <span className="card-drawer-list-values">{rawValue === 0 ? 'See Dealer' : formatedEquity}</span>
                    </li>
                </ul>
            </>
        );
    }

    getKbbDetails(details) {
        const { vehicle, amountOwed, value, hasACV, rawValue, formatedEquity } = details;
        return (
            <>
                <h3>Trade-In Details</h3>
                <ul className="card-drawer-list">
                    {this.getImprovedVehicleDetails(vehicle)}
                    <br />
                    <li className="card-drawer-flex-item card-drawer-closed-gaps">
                        <span>
                            {hasACV ? '' : 'Est. '}Kelley Blue Book Value{hasACV ? '' : '*'}{' '}
                        </span>
                        <span className="card-drawer-list-values">{rawValue === 0 ? 'See Dealer' : value}</span>
                    </li>
                    {amountOwed && (
                        <li className="card-drawer-flex-item card-drawer-closed-gaps">
                            Amount You Owe <span className="card-drawer-list-values">-{amountOwed}</span>
                        </li>
                    )}
                    <hr />
                    <li className="card-drawer-flex-item card-drawer-closed-gaps">
                        Trade-In Value{' '}
                        <span className="card-drawer-list-values">{rawValue === 0 ? 'See Dealer' : formatedEquity}</span>
                    </li>
                </ul>
            </>
        );
    }

    getSubaruGtpDetails(details) {
        const { vehicle, amountOwed, value, hasACV, rawValue, formatedEquity } = details;
        return (
            <>
                <h3>Trade-In Details</h3>
                <ul className="card-drawer-list">
                    {this.getImprovedVehicleDetails(vehicle)}
                    <br />
                    <li className="card-drawer-flex-item card-drawer-closed-gaps">
                        <span>
                            {hasACV ? '' : 'Est. '}Subaru Guaranteed Trade-in Value{hasACV ? '' : '*'}{' '}
                        </span>
                        <span className="card-drawer-list-values">{rawValue === 0 ? 'See Dealer' : value}</span>
                    </li>
                    {amountOwed && (
                        <li className="card-drawer-flex-item card-drawer-closed-gaps">
                            Amount You Owe <span className="card-drawer-list-values">-{amountOwed}</span>
                        </li>
                    )}
                    <hr />
                    <li className="card-drawer-flex-item card-drawer-closed-gaps">
                        Trade-In Value{' '}
                        <span className="card-drawer-list-values">{rawValue === 0 ? 'See Dealer' : formatedEquity}</span>
                    </li>
                </ul>
            </>
        );
    }

    render() {
        const { isTradeInImprovedWorkflowEnabled } = this.props;
        if (isTradeInImprovedWorkflowEnabled) {
            const { equity, value, year, make, model, condition, amountOwed, source, hasACV, rawValue, formatedEquity } =
                this.props;
            const detailsObj = {
                vehicle: [
                    { label: 'Year', value: year },
                    { label: 'Make', value: make },
                    { label: 'Model', value: model },
                    { label: 'Condition', value: condition },
                    { label: 'Mileage', value: this.getFormattedMileage() }
                ],
                equity,
                amountOwed,
                value,
                rawValue,
                hasACV,
                source,
                formatedEquity
            };
            switch (source) {
                case TRADEIN_SOURCE_TYPES.kbb:
                    return this.getKbbDetails(detailsObj);
                case TRADEIN_SOURCE_TYPES.ico:
                    return this.getIcoDetails(detailsObj);
                case TRADEIN_SOURCE_TYPES.manual:
                    return this.getManualEntryDetails(detailsObj);
                case TRADEIN_SOURCE_TYPES.subarugtp:
                    return this.getSubaruGtpDetails(detailsObj);
                default:
                    return this.getRedesignDetails();
            }
        }
        return this.getRedesignDetails();
    }
}

export const mapStateToProps = (state): ITradeTermsDetailsUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state);
    const { getTradeInVehicleYear, getTradeInVehicleMakeName, getTradeInVehicleModelName } = tradeInSelectors;
    const year = getTradeInVehicleYear(tradeIn);
    const make = getTradeInVehicleMakeName(tradeIn);
    const model = getTradeInVehicleModelName(tradeIn);
    const ymm = `${year} ${make} ${model}`;
    const shouldIgnoreValues = tradeInSelectors.getShouldIncludeTradeIn(tradeIn) === false;
    const vehicleMileage = tradeInSelectors.getTradeInVehicleMileage(tradeIn);
    const rawEquity = shouldIgnoreValues ? 0 : tradeInSelectors.getTradeInEquity(tradeIn);
    const formatedEquity = formatUtils.formatDollarsAndCents(rawEquity);
    const equity = rawEquity ? formatedEquity : 'See Dealer';

    const isTradeInImprovedWorkflowEnabled =
        getGlobalSelector().additionalSelectors.configSelectors.isTradeInImprovedWorkflowEnabled(state);
    if (isTradeInImprovedWorkflowEnabled) {
        const { getTradeInValue, getTradeInAmountOwed, getTradeInVehicleCondition } = tradeInSelectors;
        const condition = getTradeInVehicleCondition(tradeIn);
        const rawAmountOwed = shouldIgnoreValues ? 0 : getTradeInAmountOwed(tradeIn) || null;
        const amountOwed = rawAmountOwed ? formatUtils.formatDollarsAndCents(rawAmountOwed) : null;
        const rawValue = shouldIgnoreValues ? 0 : getTradeInValue(tradeIn);
        const value = formatUtils.formatDollarsAndCents(rawValue);
        const source = getGlobalSelector().additionalSelectors.tradeInSelectors.getTradeInSource(state);
        const hasACV = tradeInSelectors.getTradeInHasACV(state);

        return {
            amountOwed,
            condition,
            equity,
            hasACV,
            isTradeInImprovedWorkflowEnabled,
            make,
            model,
            rawValue,
            source,
            value,
            vehicleMileage,
            year,
            ymm,
            formatedEquity
        };
    }

    return {
        equity,
        isTradeInImprovedWorkflowEnabled,
        vehicleMileage,
        ymm
    };
};

const TradeTermsDetails = withRouter(connect(mapStateToProps)(TradeTermsDetailsUI) as any);

export default TradeTermsDetails;
