// externals
import * as React from 'react';

// libraries
import { CardSelectButtonsUI, ITradeInVehicle } from '@makemydeal/dr-activities-common';

// components
import TradePage from '../../../common/components/TradePage/TradePage';

// config
import config from '../../../store/mmd/config';

// consts/enums
import { MANUAL_STEPPER_ROUTES, STEPPER_ROUTES, TRADE_IN_VEHICLE } from '../../../utils/constants';
import VehicleConditionDescriptionModalUI from './VehicleConditionDescriptionModalUI';
import { Grid } from './VehicleConditionComponents';

export interface IVehicleConditionUIStateProps {
    zip?: string;
    vehicleName: string;
    vehicle: ITradeInVehicle;
    selectedCondition: string;
    mileage: string;
    pageTitle: string;
    conditionMileageZipSubtitle: string;
    pageSubTitle: string;
    isManualEntryFlow?: boolean;
    renderDisclaimer?: { () };
}

export interface IVehicleConditionUIDispatchProps {
    onValueChange: { (vehicle: any) };
    next: { () };
    goToRoute: { (route: string): void };
    dispatchAnalytics?: { (type: string, description: string) };
}

export interface IVehicleConditionUIProps extends IVehicleConditionUIStateProps, IVehicleConditionUIDispatchProps {
    zip?: string;
    vehicleName: string;
    vehicle: ITradeInVehicle;
    isExcellentHiddenWebsite: boolean;
    isExcellentHiddenListing: boolean;
}

export interface IVehicleConditionUIState {
    selectedConditionIndex: number;
    selectedCondition: string;
    vehicleConditionModalIsOpen: boolean;
}

class VehicleConditionUI extends React.Component<IVehicleConditionUIProps, IVehicleConditionUIState> {
    constructor(props) {
        super(props);
        const { selectedCondition } = this.props;
        const selectedConditionItem = TRADE_IN_VEHICLE.find((item: any) => item.label === selectedCondition);
        const selectedConditionIndex = selectedConditionItem ? selectedConditionItem.index : -1;
        this.state = { selectedConditionIndex, selectedCondition, vehicleConditionModalIsOpen: false };
    }

    onClickConditionPanel = (event): void => {
        const conditionId = event.currentTarget.getAttribute('data-id');
        const id = conditionId.split('_')[1];
        const condition = TRADE_IN_VEHICLE[id].label;

        if (this.state.selectedConditionIndex.toString() !== id) {
            this.props.onValueChange(condition);
        }

        this.setState({
            ...this.state,
            selectedConditionIndex: id,
            selectedCondition: condition
        });
    };

    composeConditionPanels = (item): JSX.Element => {
        const { selectedConditionIndex } = this.state;
        const activeConditionId = `condition_${selectedConditionIndex}`;
        const { id, label, statistic, description } = item;
        const checked = id === activeConditionId;
        const panelId = `panel_${id}`;

        return (
            <CardSelectButtonsUI
                id={id}
                panelId={panelId}
                headingText={label}
                secondaryText={statistic}
                bodyText={description}
                onClick={this.onClickConditionPanel}
                selected={checked}
                key={statistic}
            ></CardSelectButtonsUI>
        );
    };

    renderTitle = (): JSX.Element => {
        return (
            <>
                {this.props.pageTitle}
                <span
                    onClick={() => this.setState({ ...this.state, vehicleConditionModalIsOpen: true })}
                    data-testid="vehicle-info"
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="#0d65bf"
                        strokeWidth="3"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        style={{
                            width: '1rem',
                            display: 'inline-block',
                            height: '1rem',
                            marginLeft: '.5rem',
                            cursor: 'pointer'
                        }}
                    >
                        <circle cx="12" cy="12" r="10" />
                        <line x1="12" y1="16" x2="12" y2="12" />
                        <line x1="12" y1="8" x2="12.01" y2="8" />
                    </svg>
                </span>
            </>
        );
    };

    closeVehicleConditionDetailsModal(): void {
        this.setState({ ...this.state, vehicleConditionModalIsOpen: false });
    }

    render(): JSX.Element {
        const { pageSubTitle, dispatchAnalytics, goToRoute, isManualEntryFlow } = this.props;
        const isHidden = this.props.isExcellentHiddenWebsite || this.props.isExcellentHiddenListing ? true : false;
        const cards = isHidden ? TRADE_IN_VEHICLE.filter((card) => card.label !== 'Excellent') : TRADE_IN_VEHICLE;
        const index = this.state.selectedConditionIndex;

        return (
            <TradePage
                pageClass="vehicle-condition-page"
                pageTitle={this.renderTitle() as unknown as string | JSX.Element}
                pageSubTitle={pageSubTitle}
                stepperRoutes={isManualEntryFlow ? MANUAL_STEPPER_ROUTES : STEPPER_ROUTES}
                goToRoute={goToRoute}
                dispatchAnalytics={dispatchAnalytics}
                footerProps={{
                    onActionButtonClick: this.props.next,
                    showBackButton: false,
                    buttonText: isManualEntryFlow ? 'Submit' : 'Next',
                    disableActionButton: index === -1
                }}
                renderDisclaimer={this.props.renderDisclaimer}
                showTradeErrorMessage={false}
                staticAssetsConfig={config.staticImages}
            >
                <Grid id="dr-p202-vc-grid" data-hidden={isHidden}>
                    {cards.map(this.composeConditionPanels)}
                </Grid>
                <VehicleConditionDescriptionModalUI
                    isOpen={this.state.vehicleConditionModalIsOpen}
                    handleClose={this.closeVehicleConditionDetailsModal.bind(this)}
                    isExcellentHiddenWebsite={this.props.isExcellentHiddenWebsite}
                    isExcellentHiddenListing={this.props.isExcellentHiddenListing}
                />
            </TradePage>
        );
    }
}

export default VehicleConditionUI;
