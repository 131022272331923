// externals
import * as React from 'react';

// libraries
import { INextStepCardDetails, IVehicleOption } from '@makemydeal/dr-activities-common';
import { IconFactory } from '@makemydeal/dr-activities-common';

// utils
import { formatDollarsAndCents } from '../../../../store/mmd/formatUtils';
import { milesToStringShort } from '../../../../store/mmd/uiHelpersUtil';

// config
import config from '../../../../store/mmd/config';
import * as tradeInRoutes from '../../../../utils/routes';

// components
import TradePage from '../../../../common/components/TradePage/TradePage';
import { errorMessageUserIsDealer, getErrorMessageUserIsNotDealer } from './constants';
import GTPFlowPageSubtitle from '../../GTPFlowPageSubtitle/GTPFlowPageSubtitle';

// types
import { ImprovedStepperRoute } from '../../../../types';

export interface IManualSummaryUIStateProps {
    calculatedTradeInValue: number;
    cardDetails: INextStepCardDetails;
    currentDate: Date;
    dealerPhoneNumber: string;
    expirationDate: string;
    hasAcv: boolean;
    imageFPO: string;
    isSubaruGtpEligible?: boolean;
    isIcoTradeInFlow: boolean;
    isLeadFormEnabled: boolean;
    isLeadFormSubmitted: boolean;
    isManualEntryFlow: boolean;
    isTradeLeadFormEnabled: boolean;
    loanBalance: number;
    make: string;
    model: string;
    showShareDeal: boolean;
    stepperRoutes?: ImprovedStepperRoute[];
    subaruGtpLogoAlt?: any; // This is purely for local dev use (storybook can't show the static assets)
    tradeInValue: number;
    tradePaymentFailed: boolean;
    userIsDealer: boolean;
    userIsDesking: boolean;
    valueSource: string;
    vehicleCondition: string;
    vehicleId: string;
    vehicleMileage: number;
    vehicleOptions: IVehicleOption;
    vin?: string;
    year: string;
    zipCode: string;
    ghostVinDealerConfig: boolean;
    isGhostVinEnabled: boolean;
    isSDPRedesignEnabled: boolean;
    actionButtonText: string;
    showActionButton: boolean;
}

export interface IManualSummaryUIDispatchProps {
    dispatchAnalytics: (type: string, description: string) => void;
    editTradeIn: () => void;
    goToRoute: (route: string) => void;
    loadLeadForm: (skiplink: boolean) => void;
    removeTrade: (isSDPRedesignEnabled: boolean) => void;
    termsDetailsClicked: () => void;
    tradeInEstimateDisplayed: () => void;
    goToNextStep: () => void;
}

interface IManualSummaryUIState {
    showPaymentError: boolean;
    showModal: boolean;
}

export interface IManualSummaryUIProps extends IManualSummaryUIStateProps, IManualSummaryUIDispatchProps {}

class ManualSummaryUI extends React.Component<IManualSummaryUIProps, IManualSummaryUIState> {
    constructor(public props) {
        super(props);
        this.state = {
            showPaymentError: props.tradePaymentFailed,
            showModal: false
        };
    }

    componentDidMount() {
        const {
            loadLeadForm,
            isTradeLeadFormEnabled,
            isLeadFormSubmitted,
            userIsDealer,
            tradeInEstimateDisplayed,
            isLeadFormEnabled
        } = this.props;

        if (!isLeadFormSubmitted && isLeadFormEnabled) {
            if (!userIsDealer) {
                loadLeadForm(!isTradeLeadFormEnabled);
            } else {
                tradeInEstimateDisplayed();
            }
        } else {
            tradeInEstimateDisplayed();
        }
    }

    componentDidUpdate(prevProps: IManualSummaryUIProps) {
        if (prevProps.isLeadFormSubmitted === false && this.props.isLeadFormSubmitted) {
            this.props.tradeInEstimateDisplayed();
        }
        if (prevProps.tradePaymentFailed !== this.props.tradePaymentFailed) {
            this.setState({ showPaymentError: this.props.tradePaymentFailed });
        }
    }

    handlePencilEditClicked = () => {
        this.props.editTradeIn();
    };

    renderError = () => {
        const errorMessage = this.props.userIsDealer
            ? errorMessageUserIsDealer
            : getErrorMessageUserIsNotDealer(this.props.dealerPhoneNumber);
        return this.state.showPaymentError ? (
            <div className="trade-in-payment-failed">
                <i className="fa fa-info-circle"></i>
                <p>
                    {errorMessage}
                    <a href="#" onClick={() => this.props.removeTrade()}>
                        Remove Trade-In
                    </a>
                </p>
            </div>
        ) : null;
    };

    renderTradeConfirmationHeader() {
        const { staticImages } = config;
        const { year, make, model, calculatedTradeInValue, tradeInValue } = this.props;

        return (
            <div className="trade-in-iw-estimate">
                {tradeInValue ? (
                    <IconFactory icon="checkmark" className="icon checkmark" staticImages={staticImages} />
                ) : (
                    <IconFactory icon="clock" className="icon clock" staticImages={staticImages} />
                )}
                <div className="estimated-value">
                    <div className="estimated-value__teaser common-header">
                        Your {year} {make} {model} has an
                    </div>
                    <div className={`estimated-value__content${tradeInValue === 0 || !tradeInValue ? '__pa' : ''} common-header`}>
                        <div className="estimated-value__content--label">Estimated Trade-in Value*:&nbsp;</div>
                        {tradeInValue && calculatedTradeInValue > 0 ? (
                            <div className="estimated-value__content--value positive">
                                {formatDollarsAndCents(calculatedTradeInValue)}
                            </div>
                        ) : null}
                        {tradeInValue && calculatedTradeInValue < 0 ? (
                            <div className="estimated-value__content--value negative">
                                {formatDollarsAndCents(calculatedTradeInValue)}
                            </div>
                        ) : null}
                        {tradeInValue === 0 || !tradeInValue ? (
                            <div className="estimated-value__content--value pending-appraisal">Pending Appraisal</div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    pageSubTitle = () => (
        <GTPFlowPageSubtitle
            pageSubtitle="You shared your estimated trade-in value and we applied it to your deal."
            suffix="Your deal has been updated with your vehicle's details."
        />
    );

    render() {
        const { staticImages /* renderMenuProducts */ } = config;
        const {
            calculatedTradeInValue,
            cardDetails,
            dispatchAnalytics,
            expirationDate,
            goToRoute,
            isSubaruGtpEligible,
            hasAcv,
            make,
            model,
            showShareDeal,
            stepperRoutes,
            tradeInValue,
            userIsDealer,
            userIsDesking,
            valueSource,
            vehicleCondition,
            vehicleMileage,
            year,
            loanBalance,
            ghostVinDealerConfig,
            isGhostVinEnabled,
            isSDPRedesignEnabled,
            actionButtonText,
            showActionButton,
            goToNextStep,
            removeTrade
        } = this.props;

        const pageTitle = isSubaruGtpEligible ? `Your ${year} ${make} ${model} is Eligible` : "Nice! We've Added Your Trade-In.";
        const pencilIcon = <IconFactory icon="pencilIcon" className="icon primary-link-icon" staticImages={staticImages} />;
        const editText = <span className="primary-link">Edit</span>;
        const shouldDisplayConditionLabel = vehicleCondition !== '';

        let dealerDealDescriptionText = '';
        let dealerDealLinkText = '';

        if (isGhostVinEnabled) {
            dealerDealDescriptionText = ghostVinDealerConfig ? 'Submit the completed deal to the CRM for review' : '';
            dealerDealLinkText = ghostVinDealerConfig ? 'Send to CRM' : 'Submit for Review';
        }

        const handleSkipButtonClick = () => {
            removeTrade(isSDPRedesignEnabled);
        };

        const handleBackButtonClick = () => {
            goToRoute(tradeInRoutes.TRADE_MANUAL_ENTRY);
        };

        const footerProps = isSDPRedesignEnabled
            ? {
                  onActionButtonClick: showActionButton ? goToNextStep : undefined,
                  buttonText: actionButtonText,
                  showBackButton: true,
                  showSkipButton: true,
                  skipButtonText: 'Remove Trade-In',
                  fullWidthLayout: true,
                  onSkipButtonClick: handleSkipButtonClick,
                  onBackButtonClick: handleBackButtonClick
              }
            : null;

        return (
            <>
                <TradePage
                    stepperRoutes={stepperRoutes}
                    isStepperClickable={false}
                    stepLabelVisible={true}
                    pageTitle={pageTitle}
                    pageSubTitle={this.pageSubTitle()}
                    headerOnTop={isSDPRedesignEnabled}
                    showCustomTitle={isSDPRedesignEnabled}
                    pageClass="trade-in-summary-page"
                    showShareDeal={isSDPRedesignEnabled ? false : showShareDeal}
                    isDealerUser={userIsDealer}
                    isDeskingUser={userIsDesking}
                    staticAssetsConfig={staticImages}
                    cardDetails={isSDPRedesignEnabled ? null : cardDetails}
                    goToRoute={goToRoute}
                    dispatchAnalytics={dispatchAnalytics}
                    renderError={this.renderError}
                    dealerDealDescription={dealerDealDescriptionText}
                    dealerDealLinkText={dealerDealLinkText}
                    footerProps={footerProps}
                >
                    <div className="trade-in-summary-content">
                        {this.renderTradeConfirmationHeader()}
                        <div className="trade-in-iw-vehicle container-fluid">
                            <div className="vehicle">
                                <div className="row">
                                    <div className="vehicle-info-details">
                                        <div className="estimate-header">How'd we come up with the value?</div>
                                        <div className="vehicle-info__content">
                                            <div className="vehicle-info__contentLeft">
                                                <div className="math-stack-iw-container">
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">Est. Value*</div>
                                                        <div className="math-stack-iw-value">
                                                            {!!tradeInValue && formatDollarsAndCents(tradeInValue)}
                                                            {!tradeInValue && (
                                                                <div>
                                                                    Pending <br className="hide-lg" />
                                                                    Appraisal
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>{' '}
                                                    {loanBalance > 0 && (
                                                        <div className="math-stack-iw">
                                                            <div className="math-stack-iw-label">Amount You Owe</div>
                                                            <div className="math-stack-iw-value">
                                                                {loanBalance ? '-' : ''}
                                                                {formatDollarsAndCents(loanBalance)}
                                                            </div>
                                                        </div>
                                                    )}
                                                    <hr className="bar" />
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">Trade-In Value</div>
                                                        {tradeInValue && calculatedTradeInValue > 0 ? (
                                                            <div className="math-stack-iw-value positive">
                                                                {formatDollarsAndCents(calculatedTradeInValue)}
                                                            </div>
                                                        ) : null}
                                                        {tradeInValue && calculatedTradeInValue < 0 ? (
                                                            <div className="math-stack-iw-value negative">
                                                                {formatDollarsAndCents(calculatedTradeInValue)}
                                                            </div>
                                                        ) : null}
                                                        {!tradeInValue && (
                                                            <div className="math-stack-iw-value">
                                                                Pending <br className="hide-lg" />
                                                                Appraisal
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="vehicle-info common-header">
                                        <div className="estimate-header flex">
                                            <div>Trade-In Details</div>
                                            {!hasAcv && (
                                                <div
                                                    className={'primary-link-container pencil-edit-icon'}
                                                    onClick={this.handlePencilEditClicked}
                                                >
                                                    {pencilIcon}
                                                    {editText}
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="vehicle-info__content">
                                        <div className="vehicle-info__contentLeft">
                                            <div className="math-stack-iw-container">
                                                <div className="math-stack-iw">
                                                    <div className="math-stack-iw-label">Year</div>
                                                    <div className="math-stack-iw-value">{year}</div>
                                                </div>
                                                <div className="math-stack-iw">
                                                    <div className="math-stack-iw-label">Make</div>
                                                    <div className="math-stack-iw-value">{make}</div>
                                                </div>
                                                <div className="math-stack-iw">
                                                    <div className="math-stack-iw-label">Model</div>
                                                    <div className="math-stack-iw-value">{model}</div>
                                                </div>
                                                {shouldDisplayConditionLabel && (
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">Condition</div>
                                                        <div className="math-stack-iw-value">{vehicleCondition}</div>
                                                    </div>
                                                )}
                                                <div className="math-stack-iw">
                                                    <div className="math-stack-iw-label">Mileage</div>
                                                    <div className="math-stack-iw-value">{milesToStringShort(vehicleMileage)}</div>
                                                </div>
                                                {valueSource && (
                                                    <div className="math-stack-iw">
                                                        <div className="math-stack-iw-label">
                                                            Value <br className="hide-md" />
                                                            Provided by
                                                        </div>
                                                        <div className="math-stack-iw-value trade-in-source-value">
                                                            {valueSource}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="trade-in-legal">
                                <p>
                                    Your trade-in equity is the net of your trade-in value and any outstanding loan amount. Final
                                    value of trade-in may vary depending on dealer appraisal. Estimate valid through&nbsp;
                                    {new Date(expirationDate).toLocaleDateString()}.
                                </p>
                            </div>
                        </div>
                    </div>
                </TradePage>
            </>
        );
    }
}

export default ManualSummaryUI;
