import type { DealHistory, StateTree } from '@makemydeal/dr-dash-types';
import DealSummaryItem from '../common/DealSummaryItem';
import { useSelector } from 'react-redux';

import * as constants from '../../constants';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

const InsufficientFunds = () => {
    const offsetPrice = useSelector((state: StateTree) => offerReduxSelectors.getCashOffsetAmountAddedToPrice(state));
    const cashOffsetAddedToPrice = formatDollarsAndCents(offsetPrice);
    return <DealSummaryItem label={constants.INSUFFICIENT_FUNDS} value={cashOffsetAddedToPrice} />;
};

export default InsufficientFunds;
