// externals
import { connect } from 'react-redux';

// libraries
import { formatUtils } from '@makemydeal/dr-common-utils';
import { optimizelyUtils } from '@makemydeal/dr-browser-utils';
import { ITradeInState } from '@makemydeal/dr-activities-common';
import { dateUtils } from '@makemydeal/dr-common-utils';
import { validator } from '@makemydeal/dr-platform-shared';

// selectors
import * as selectors from '../../../store/mmd/offerSelectors';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as offerInfoSelectors from '../../../store/mmd/offerSelectors';
import * as leadFormInfoSelectors from '../../../store/mmd/leadFormInfo';
import { getSponsor } from '../../../store/mmd/widgetSelectors';
import {
    isDealerTradeInLeadFormForWebsiteEnabled,
    testDriveAtHomeOnTradeIn,
    isDealerTradeInLeadFormForListingSiteEnabled
} from '../../../store/mmd/dealerSelectors';
import * as optimizelySelectors from '../../../store/mmd/optimizelySelectors';
import { hideNextActivityFooter } from '../../../store/mmd/activitiesStatusSelectors';

// components
import EquityUI from './EquityUI';
import { IEquityUIStateProps, IEquityUIDispatchProps } from './EquityUI';

// utils
import { hasOptionsData } from '../../../utils/tradeUtils';
import { isTradeInLeadFormSDPEnabled } from '../../../store/featureToggles';

// actions
import * as tradeInActionCreators from '../../../store/actionCreators';
import { goToNext, goToSummaryLeadForm, goToDealSummary, goToTestDrive } from '../../../store/actionCreators';

// routing
import * as tradeInRoutes from '../../../utils/routes';

// consts/enums
import * as RouteValues from '../../../utils/routes';

const mapStateToProps = (state: any): IEquityUIStateProps => {
    const { isVariationActive, getOptimizelyTests } = optimizelySelectors;
    const tradeIn = state.tradeInComponent;
    const isTradeValuationReset = tradeInSelectors.isTradeValuationReset(state);
    const amountOwed = tradeInSelectors.getTradeInAmountOwed(tradeIn);
    const value = tradeInSelectors.getTradeInValue(tradeIn);
    const condition = tradeInSelectors.getTradeInVehicleCondition(tradeIn);
    const { vehicle, zip } = tradeIn;
    const mileage = formatUtils.formatNumber(tradeInSelectors.getTradeInVehicleMileage(tradeIn));
    const conditionMileageZipSubtitle = `${vehicle.condition} Condition | ${mileage} Miles | ${tradeIn.zip}`;
    const vehicleName = `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`;
    const fairEstimate = formatUtils.formatDollars(parseInt(tradeInSelectors.getTradeInValueFair(tradeIn), 10));
    const excellentEstimate = formatUtils.formatDollars(parseInt(tradeInSelectors.getTradeInValueExcellent(tradeIn), 10));
    const calculatedDifference = value - amountOwed;
    const difference = formatUtils.formatDollars(calculatedDifference);
    const nowDate = new Date();
    const now = dateUtils.formatAsMMDDYYYY(nowDate);
    const toDate = dateUtils.formatAsMMDDYYYY(dateUtils.addDaysToDate(nowDate, 6));
    const expirationRange = `${now} - ${toDate}. `;
    const pageTitle = isTradeValuationReset
        ? `${vehicleName}`
        : `Your ${vehicleName} has an estimated trade-in equity of ${difference}`;
    const headerSubTitle = 'Step 5 of 5';
    const pageTip =
        amountOwed > 0
            ? `${formatUtils.formatDollars(value)} Kelley Blue Book Trade-In Value -
        ${formatUtils.formatDollars(amountOwed)} Loan Balance`
            : '';
    const offerType = selectors.getCurrentOfferType(state);
    const showTradeInApplyOptions = calculatedDifference < 0 && offerType !== 'cash';
    const isTradeInNegative = calculatedDifference < 0;
    const shouldIncludeTradeIn = tradeInSelectors.getAmountAppliedToFinancing(tradeIn);
    const payment = selectors.getMonthlyPaymentRoundedByOfferType(state, offerType);
    const isPaymentInvalid = selectors.isPaymentInvalid(payment, offerType);
    const tradeSaveFailure = tradeInSelectors.isTradeSaveFailed(state);
    const tradeSavePending = tradeInSelectors.isTradeSavePending(state);
    const isTradeInCompleted = tradeInSelectors.isTradeInCompleted(state);
    const isIcoTradeInFlow = tradeInSelectors.isIcoTradeInFlow(state);
    const isIcoTradeInNotSiteDriverFlow = tradeInSelectors.isIcoTradeInNotSiteDriverFlow(state);
    const hasOfferBeenSent = offerInfoSelectors.getHasBeenSent(state);
    const skipUpdateEstimate =
        tradeInSelectors.icoTradeFlow(state) &&
        tradeInSelectors.isTradeInCompleted(state) &&
        tradeInSelectors.isTradeInIcoEnabled(state);
    const offerSubmitFailed = leadFormInfoSelectors.isOfferSubmitFailed(state);
    const isProcessing = offerInfoSelectors.getIsOfferBeingSubmitted(state);
    const isTradeInSent = tradeInSelectors.isTradeInSent(state);
    const enableNextSteps = isVariationActive(
        state,
        getOptimizelyTests().tradeInNextStepsTest,
        getOptimizelyTests().tradeInNextStepsTest.showNextSteps
    );
    let buttonCTAMessage: string;
    if (isPaymentInvalid || isTradeValuationReset || (isTradeInCompleted && isIcoTradeInFlow)) {
        buttonCTAMessage = 'Continue';
    } else if (!isTradeInSent && hasOfferBeenSent) {
        buttonCTAMessage = 'Apply and Continue';
    } else if (enableNextSteps) {
        buttonCTAMessage = 'View My Offer Summary';
    } else {
        buttonCTAMessage = 'Continue';
    }
    const testDriveAtHomeOnTradeInToggle = testDriveAtHomeOnTradeIn(state);
    const hideRequestTestDrive = hideNextActivityFooter(state);
    return {
        buttonCTAMessage,
        condition,
        conditionMileageZipSubtitle,
        difference,
        enableNextSteps,
        excellentEstimate,
        expirationRange,
        fairEstimate,
        headerSubTitle,
        hideRequestTestDrive,
        shouldIncludeTradeIn,
        isIcoTradeInFlow,
        isIcoTradeInNotSiteDriverFlow,
        isPaymentInvalid,
        isProcessing,
        isTradeInCompleted,
        isTradeInNegative,
        mileage,
        offerSubmitFailed,
        pageTip,
        pageTitle,
        showTradeInApplyOptions,
        skipUpdateEstimate,
        testDriveAtHomeOnTradeInToggle,
        tradeIn,
        tradeSaveFailure,
        tradeSavePending,
        vehicleName,
        zip
    };
};

const mapDispatchToProps = (dispatch: any): IEquityUIDispatchProps => {
    const { isVariationActive, getOptimizelyTests } = optimizelySelectors;
    return {
        onTradeInUpdated: (applyToAmountFinanced: boolean) => {
            dispatch((_dispatch, getState) => {
                const state = getState();
                const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
                const hasAmountOwedChanged = !(applyToAmountFinanced === tradeIn.saved.shouldIncludeTradeIn);
                dispatch(tradeInActionCreators.changedTradeIn(hasAmountOwedChanged));
            });
        },
        goToNextAvailableRoute: () => {
            dispatch(goToNext(RouteValues.TRADE_IN));
        },
        applyTradeIn: (shouldIncludeTradeIn, appliedFromViewOfferSummaryLink, requestedTestDrive) => {
            optimizelyUtils.track('tradeInCompleted');
            dispatch((_dispatch, getState) => {
                const state: any = getState();
                const sponsor = getSponsor(state).toString();
                const isListingDealer = validator.isListingSite(sponsor);
                const isWebSiteDealer = !isListingDealer;
                const isTradeInLeadFormFeatureEnabled = isTradeInLeadFormSDPEnabled(state);
                const isLeadFormEnabled =
                    isTradeInLeadFormFeatureEnabled &&
                    ((isWebSiteDealer && isDealerTradeInLeadFormForWebsiteEnabled(state)) ||
                        (isListingDealer && isDealerTradeInLeadFormForListingSiteEnabled(state)));
                const isTradeInSent = tradeInSelectors.isTradeInSent(state);
                const hasTradeInChanged = tradeInSelectors.hasTradeInChanged(state);
                const enableNextSteps = isVariationActive(
                    state,
                    getOptimizelyTests().tradeInNextStepsTest,
                    getOptimizelyTests().tradeInNextStepsTest.showNextSteps
                );

                if (isLeadFormEnabled) {
                    if (!isTradeInSent || hasTradeInChanged) {
                        requestedTestDrive && dispatch(tradeInActionCreators.testDriveRequestedFromTradeIn());
                        dispatch(tradeInActionCreators.applyToAmountFinanced(shouldIncludeTradeIn));
                        dispatch(tradeInActionCreators.updateTradeInValuation());
                    } else {
                        requestedTestDrive ? dispatch(goToTestDrive()) : dispatch(goToNext(RouteValues.TRADE_IN));
                    }
                } else {
                    requestedTestDrive && dispatch(tradeInActionCreators.testDriveRequestedFromTradeIn());
                    dispatch(tradeInActionCreators.applyToAmountFinanced(shouldIncludeTradeIn));
                    dispatch(tradeInActionCreators.updateTradeInValuation());
                }
                if (enableNextSteps) {
                    if (!appliedFromViewOfferSummaryLink) optimizelyUtils.track('tradeInNextStepsViewMyOfferSummaryClicked');
                    const hasOfferBeenSent = offerInfoSelectors.getHasBeenSent(state);
                    if (hasOfferBeenSent) {
                        dispatch(goToDealSummary());
                    } else {
                        dispatch(goToSummaryLeadForm());
                    }
                }
            });
        },
        tradeInEstimateReceived: () => {
            dispatch(tradeInActionCreators.tradeInEstimateOnLoad());
        },
        removeTrade: () => {
            dispatch(tradeInActionCreators.removeTradeIn());
        },
        previous: () => {
            dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VALUATION));
            dispatch(tradeInActionCreators.updateResetValuationFlag(false));
        },
        perfomTradeInValuation: (tradeInComponent) => {
            if (hasOptionsData(tradeInComponent)) {
                dispatch(tradeInActionCreators.performTradeInValuation(tradeInComponent));
            }
        },
        startNewTradeIn: () => {
            dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN_ICO));
        }
    };
};

const EquityContainer = connect(mapStateToProps, mapDispatchToProps)(EquityUI);

export default EquityContainer;
