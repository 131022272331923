// externals
import * as React from 'react';
import find_ from 'lodash.find';

// libraries
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';

// components
import ConditionPanel from './ConditionPanel';
import Header from '../header/Header';
import { ActionFooterBar } from '@makemydeal/dr-activities-common';

const find = find_;

export interface IVehicleConditionUIStateProps {
    zip?: string;
    vehicleName: string;
    vehicle: ITradeInVehicle;
    selectedCondition: string;
    mileage: string;
    pageTitle: string;
    headerSubtitle: string;
    pageTip: string;
    conditionMileageZipSubtitle: string;
}

export interface IVehicleConditionUIDispatchProps {
    onValueChange: { (vehicle: any) };
    next: { () };
    previous: { () };
}

export interface IVehicleConditionUIProps extends IVehicleConditionUIStateProps, IVehicleConditionUIDispatchProps {
    zip?: string;
    vehicleName: string;
    vehicle: ITradeInVehicle;
}

export interface IVehicleConditionUIState {
    selectedConditionIndex: number;
    selectedCondition: string;
}

// TODO : create a const file with breakpoints
// $screen-tablet-landscape-width
const isMobile = screen.width <= 767;

// using id `condition_n` so it's easier to hook into the buttons for automation
const conditionArray = [
    {
        index: 0,
        id: 'condition_0',
        label: 'Excellent',
        statistic: '3% of cars',
        selected: false,
        description: isMobile
            ? 'Looks new and is in excellent mechanical condition'
            : `This vehicle looks new and is in excellent mechanical condition.
            It has never had paint or bodywork and has an interior and body free of wear
            and visible defects.`
    },
    {
        index: 1,
        id: 'condition_1',
        label: 'Very Good',
        statistic: '23% of cars',
        selected: false,
        description: isMobile
            ? 'Has minor cosmetic defects and is in excellent mechanical condition'
            : `This vehicle has minor wear or visible defects on the body and interior
            but is in excellent mechanical condition, requiring only minimal reconditioning.`
    },
    {
        index: 2,
        id: 'condition_2',
        label: 'Good',
        statistic: '56% of cars',
        selected: false,
        description: isMobile
            ? 'Has some repairable cosmetic defects and is free of major mechanical problems'
            : 'This vehicle is free of major mechanical problems but may need some reconditioning.'
    },
    {
        index: 3,
        id: 'condition_3',
        label: 'Fair',
        statistic: '18% of cars',
        selected: false,
        description: isMobile
            ? 'Has some cosmetic defects that require repairing and/or replacing'
            : `This vehicle has some mechanical or cosmetic defects and needs servicing,
            but is still in safe running condition and has a clean title history.`
    }
];

class VehicleConditionUI extends React.Component<IVehicleConditionUIProps, IVehicleConditionUIState> {
    constructor(props) {
        super(props);
        const { selectedCondition } = this.props;
        const selectedConditionIndex = find(conditionArray, (item: any) => {
            return item.label === selectedCondition;
        }).index;

        this.state = { selectedConditionIndex, selectedCondition };
    }

    onClickConditionPanel = (conditionId) => {
        const id = conditionId.split('_')[1];
        const currentlySelectedId = this.state.selectedConditionIndex.toString();
        const index = id === currentlySelectedId ? -1 : id;
        const condition = index === -1 ? null : conditionArray[index].label;

        this.props.onValueChange(condition);
        this.setState({
            selectedConditionIndex: index,
            selectedCondition: condition
        });
    };

    composeConditionPanels = (item) => {
        const { selectedConditionIndex } = this.state;
        const activeConditionId = `condition_${selectedConditionIndex}`;
        return (
            <ConditionPanel
                id={item.id}
                checked={item.id === activeConditionId}
                label={item.label}
                statistic={item.statistic}
                description={item.description}
                onClickConditionPanel={this.onClickConditionPanel}
            />
        );
    };

    render() {
        const { pageTitle, headerSubtitle, pageTip, conditionMileageZipSubtitle } = this.props;
        const conditionPanels = conditionArray.map(this.composeConditionPanels);
        const index = this.state.selectedConditionIndex;

        const barProps = {
            onActionButtonClick: this.props.next,
            onBackButtonClick: this.props.previous,
            showBackButton: true,
            ignoreRouterHistory: true,
            buttonText: 'Continue',
            disableActionButton: index === -1
        };

        return (
            <div className="dr-ui-vehicle-condition">
                <Header
                    pageTitle={pageTitle}
                    headerSubTitle={headerSubtitle}
                    pageTip={pageTip}
                    pageSubTitle={conditionMileageZipSubtitle}
                    showTradeErrorMessage={false}
                />

                <div className="vehicle-condition-grid">
                    <div className="vehicle-condition-row">
                        {conditionPanels[0]}
                        {conditionPanels[1]}
                    </div>
                    <div className="vehicle-condition-row">
                        {conditionPanels[2]}
                        {conditionPanels[3]}
                    </div>
                </div>
                <ActionFooterBar {...barProps} />
            </div>
        );
    }
}

export default VehicleConditionUI;
