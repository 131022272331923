import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ConditionPanelUI from './ConditionPanelUI';
import { IConditionPanelUIStateProps, IConditionPanelUIDispatchProps } from './ConditionPanelUI';

const mapStateToProps = (_state: any): IConditionPanelUIStateProps => {
    return {};
};

const mapDispatchToProps = (dispatch: any): IConditionPanelUIDispatchProps => {
    return bindActionCreators({}, dispatch);
};

const ConditionPanelContainer = connect(mapStateToProps, mapDispatchToProps)(ConditionPanelUI);

export default ConditionPanelContainer;
