import * as React from 'react';
import { attachExtTradeInListener, detachExtTradeInListener } from './externalTradeInListener';
import { PageUI } from '@makemydeal/dr-activities-common';
import config from '../../../store/mmd/config';
import NoTradeInButton from '../../../common/components/NoTradeInButton/NoTradeInButton';

// TODO: import/create listeners
export interface IIcoValuationUIStateProps {
    instantCashOfferUrlProvider: string;
    icoAction?: string;
}

export interface IIcoValuationUIDispatchProps {
    onCompletedIcoTradeIn: { () };
    onIcoConfirmMessage: { () };
    dispatchCallback: { () };
    dispatchAnalytics?: { (type: string, description: string) };
    dispatchImpressionTag: { () };
}

export interface IIcoValuationUIProps extends IIcoValuationUIStateProps, IIcoValuationUIDispatchProps {}

export interface IIcoValuationUIState {}

class IcoValuationUI extends React.Component<IIcoValuationUIProps, IIcoValuationUIState> {
    static defaultProps = {
        icoAction: ''
    };

    iframeEl: HTMLElement;

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.body.classList.add('dr-page-iframe');
        attachExtTradeInListener(this.props.dispatchCallback, this.props.instantCashOfferUrlProvider);
        this.iframeEl.addEventListener('load', this.props.dispatchImpressionTag);
    }

    componentDidUpdate() {
        const { icoAction } = this.props;
        if (icoAction) {
            this.props.onIcoConfirmMessage();
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('dr-page-iframe');
        const { onCompletedIcoTradeIn } = this.props;
        onCompletedIcoTradeIn();
        detachExtTradeInListener();
        this.iframeEl.removeEventListener('load', this.props.dispatchImpressionTag);
    }

    setRef = (el: HTMLElement) => (this.iframeEl = el);

    render() {
        const { instantCashOfferUrlProvider, dispatchAnalytics } = this.props;
        return (
            <PageUI
                pageClass="dr-trade-ico-valuation"
                pageTitle="Value Your Vehicle"
                pageSubTitle={
                    <>
                        Complete the Kelley Blue Book<sup>®</sup> Instant Cash Offer below.
                    </>
                }
                staticAssetsConfig={config.staticImages}
                dispatchAnalytics={dispatchAnalytics}
            >
                <div id="dr-trade-ico">
                    <iframe
                        id="dr-trade-ico-iframe"
                        ref={this.setRef}
                        width="100%"
                        height="600px"
                        src={instantCashOfferUrlProvider}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
                <NoTradeInButton showOnICO />
            </PageUI>
        );
    }
}

export default IcoValuationUI;
