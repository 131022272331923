// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Alert } from '@interstate/components/Alert';
import { Action } from '@interstate/components/Action';
import { FINANCE } from '@makemydeal/dr-platform-types';
import type { StateTree } from '@makemydeal/dr-dash-types';
import {
    creditDecisionSelectors,
    dealXgDetailsSelectors,
    navigationActionCreators,
    offerReduxSelectors,
    paymentSelectors
} from '@makemydeal/dr-dash-store';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { featureToggleSelectors, dealerSelectors } from '@makemydeal/dr-shared-store';

// components
import CashDownField from './common/CashDownField.interstate';
import FinanceTermField from './common/FinanceTermField.interstate';
import RetailPriceField from './common/RetailPriceField.interstate';
import SellingPriceField from './common/SellingPriceField.interstate';
import SellRateField from './common/SellRateField.interstate';
import BuyRateField from './common/BuyRateField.interstate';
import PaymentFrequencyField from './common/PaymentFrequencyField.interstate';
import CreditDecisionStatusField from './common/CreditDecisionStatusField.interstate';
import CreditDecisionEnabled from './common/CreditDecisionEnabled.interstate';
import CreditDecisionLenderField from './common/CreditDecisionLenderNameField.interstate';
import DaysToFirstPaymentField from './common/DaysToFirstPaymentField';
import FirstPaymentDayField from './common/FirstPaymentDayField';
import AlertError from './gridTable/components/AlertError';

// styles
import { TermsTab } from './common/Terms.style';
import { FinanceHeader } from './Finance.styles';
import PaymentGridToggle from './common/PaymentGridToggle';
import { ContainerGrid } from '../common/ContainerGrid.style';
import { ContainerGridItem } from '../common/ContainerGridItem';
import { Box } from '@interstate/components/Box';

// utils/constants
import { GENERIC_LAST_FIELD_CHANGED_ERROR, NO_VALID_PAYMENT_EXIST } from '../constants';
import { IPaymentGridErrorState } from '@makemydeal/dr-dash-types';
import { usePaymentGridValidation } from '../utils/usePaymentGridValidation';

export type FinanceProps = {
    enableCreditDecisionAlert?: boolean;
};

const Finance = ({ enableCreditDecisionAlert = false }: FinanceProps) => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const lenderDecisions = useSelector((state: StateTree) => {
        return creditDecisionSelectors.getLenderCreditDecisionsByOfferType(state, currentOfferType);
    });
    const enableCreditBureauAndDecisionPBCs = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCs);
    const enableCreditBureauAndDecisionPBCRouteOne = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCRouteOne);
    const isCreditProviderRouteOne: boolean = useSelector(dealerSelectors.isCreditProviderRouteOne);
    const usePaymentGridMV = useSelector(featureToggleSelectors.usePaymentGridMV);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const isPaymentError = useSelector(paymentSelectors.getIsError);
    const isCreditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);

    const dispatch = useDispatch();
    const creditDecisionAvailable = lenderDecisions.length > 0;
    const navigateToCreditBureau = () => dispatch(navigationActionCreators.navigateToCreditBureau());
    const navigateToCreditDecision = () => dispatch(navigationActionCreators.navigateToCreditDecision());

    const enableCreditDecisionForR1 =
        enableCreditBureauAndDecisionPBCs && enableCreditBureauAndDecisionPBCRouteOne && isCreditProviderRouteOne;
    const enableCreditDecisionForDealerTrack = enableCreditBureauAndDecisionPBCs && !isCreditProviderRouteOne;
    const shouldNavigateToCreditDecision = enableCreditDecisionForDealerTrack || enableCreditDecisionForR1;

    const paymentGridEnabledToggle = useSelector((state: StateTree) =>
        offerReduxSelectors.getPaymentGridToggle(state, currentOfferType)
    );
    const isPaymentGridEnabled = usePaymentGridMV && paymentGridEnabledToggle;

    const isEcommerceDeal = useSelector(dealXgDetailsSelectors.getIsEcommerceDeal);
    const orderDetailsLink = useSelector(dealXgDetailsSelectors.getOrderDetailsLink);
    const isUserProgramQuotes = useSelector(offerReduxSelectors.getIsUserProgramQuotes);
    const paymentGridErrorState: IPaymentGridErrorState = useSelector(offerReduxSelectors.getPaymentGridErrorState);
    const { shouldDisablePaymentGridToggle } = usePaymentGridValidation(isPaymentError, paymentGridErrorState);

    const handleViewLenderDecisionClick = () => {
        if (isEcommerceDeal && currentOfferType === FINANCE) {
            window.open(`${orderDetailsLink}?show=ildp`, '_blank');
        } else if (shouldNavigateToCreditDecision) {
            navigateToCreditDecision();
        } else {
            navigateToCreditBureau();
        }
    };

    if (currentOfferType !== FINANCE) return null;

    const financeFields = [
        { component: <CashDownField />, key: 'CashDownField' },
        { component: <CreditDecisionLenderField />, key: 'CreditDecisionLenderField' },
        { component: <CreditDecisionStatusField />, key: 'CreditDecisionStatusField' },
        { component: <FinanceTermField />, key: 'FinanceTermField' },
        { component: <PaymentFrequencyField />, key: 'PaymentFrequencyField' },
        { component: <SellRateField />, key: 'SellRateField' },
        { component: <BuyRateField />, key: 'BuyRateField' },
        { component: <DaysToFirstPaymentField />, key: 'DaysToFirstPaymentField' },
        { component: <FirstPaymentDayField />, key: 'FirstPaymentDayField' }
    ];

    return (
        <TermsTab data-testid="finance-terms">
            <FinanceHeader>
                <Typography data-testid="finance-title" variant="h5">
                    Payment And Lender Information
                </Typography>
                <Action size="sm" data-testid="finance-terms-edit-link" onClick={handleViewLenderDecisionClick}>
                    View Lender Decisions
                </Action>
            </FinanceHeader>
            <Grid xs={12}>
                {enableCreditDecisionAlert && creditDecisionAvailable && (
                    <Alert id="infoAlert" role="infoAlert" type="info" data-testid="terms-lender-decision-alert">
                        Lender decisions are ready for this offer.
                    </Alert>
                )}
            </Grid>
            {!isABPencilEnabled && (
                <Grid xs={12}>
                    <CreditDecisionEnabled />
                </Grid>
            )}
            {usePaymentGridMV && (
                <Box>
                    {(isPaymentGridEnabled || isUserProgramQuotes) && !isCreditDecisionEnabled && (
                        <Grid xs={12} padding="0" marginBottom="1rem">
                            <Alert role="banner" type="info" data-testid="upq-alert-warning">
                                <Typography variant="label-md">
                                    Payments may not be honoring a lender program. Go to Programs and Incentives to select.
                                </Typography>
                            </Alert>
                        </Grid>
                    )}
                    {paymentGridErrorState.hasError && isPaymentGridEnabled && (
                        <AlertError message={GENERIC_LAST_FIELD_CHANGED_ERROR} />
                    )}
                    {shouldDisablePaymentGridToggle && <AlertError message={NO_VALID_PAYMENT_EXIST} testId="no-valid-payment" />}
                    <Grid xs={12} paddingLeft="0">
                        <PaymentGridToggle
                            currentOfferType={currentOfferType}
                            disabled={shouldDisablePaymentGridToggle || (!paymentGridEnabledToggle && isPaymentError)}
                        />
                    </Grid>
                </Box>
            )}
            <ContainerGrid data-testid="main-finance-content">
                <ContainerGridItem xs={6} md={isPaymentGridEnabled ? 6 : 4}>
                    <RetailPriceField />
                </ContainerGridItem>
                <ContainerGridItem xs={6} md={isPaymentGridEnabled ? 6 : 4}>
                    <SellingPriceField />
                </ContainerGridItem>
                {(usePaymentGridMV ? !paymentGridEnabledToggle : true) && (
                    <>
                        {financeFields.map(({ key, component }) => (
                            <ContainerGridItem key={key} xs={6} md={4}>
                                {component}
                            </ContainerGridItem>
                        ))}
                    </>
                )}
            </ContainerGrid>
        </TermsTab>
    );
};

export default Finance;
