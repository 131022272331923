import * as React from 'react';

export interface ITradeInHeaderUIStateProps {
    kbbLogo?: string;
    pageTitle?: string;
    pageTip?: string;
    headerSubTitle?: string;
    pageSubTitle?: string;
    tradeSaveFailure?: boolean;
    showTradeErrorMessage?: boolean;
    hideHeaderTitle?: boolean;
    dealerPhoneNumber?: string;
    isPaymentInvalid?: boolean;
    tradeValuationReset?: boolean;
    isIcoTradeIn?: boolean;
    tradePaymentFailed?: boolean;
    hasOfferBeenSent?: boolean;
}

export interface ITradeInHeaderUIDispatchProps {
    applyTradeIn: { (): void };
    removeTrade: { (hasOfferBeenSent: boolean): void };
}

export interface ITradeInHeaderUIProps extends ITradeInHeaderUIStateProps, ITradeInHeaderUIDispatchProps {}

export interface ITradeInHeaderUIState {}

class TradeInHeaderUI extends React.Component<ITradeInHeaderUIProps, ITradeInHeaderUIState> {
    constructor(props) {
        super(props);
    }

    renderTradeInHeaderTitle = () => {
        const { headerSubTitle, kbbLogo, isIcoTradeIn, hideHeaderTitle } = this.props;
        const className = headerSubTitle
            ? 'dr-ui-trade-in-header__title  dr-ui-trade-in-header__title--hasSubTitle'
            : 'dr-ui-trade-in-header__title';

        return hideHeaderTitle ? null : (
            <h1 className={className}>
                <figure className="dr-ui-trade-in-header__title--brand">
                    <img src={kbbLogo} />
                </figure>
                {/* tslint:disable-next-line */}
                <span className="dr-ui-trade-in-header__title--text">
                    Kelley Blue Book &reg; <span>{isIcoTradeIn ? 'Instant Cash Offer' : 'Value'}</span>
                </span>
            </h1>
        );
    };

    renderTradeInHeaderSubTitle = () => {
        const { headerSubTitle } = this.props;
        return headerSubTitle ? <p className="dr-ui-trade-in-header__subtitle">{headerSubTitle}</p> : null;
    };

    renderTradeInPageTitle = () => {
        const { pageTitle } = this.props;
        return pageTitle ? <h2 className="dr-ui-trade-in-header__page-title">{pageTitle}</h2> : null;
    };

    renderTradeInPageSubTitle = () => {
        const { pageSubTitle } = this.props;
        return pageSubTitle ? <h6 className="dr-ui-trade-in-header__page-subtitle">{pageSubTitle}</h6> : null;
    };

    renderPaymentErrorMessage = () => {
        const { hasOfferBeenSent, dealerPhoneNumber } = this.props;
        return (
            <>
                <p>
                    Oops, We couldn't calculate a monthly payment based on your trade-in value. Try removing your trade to receive a
                    payment or contact us at {dealerPhoneNumber}.{' '}
                    <a href="#" onClick={() => this.props.removeTrade(hasOfferBeenSent)}>
                        Remove link
                    </a>
                </p>
            </>
        );
    };

    renderTradeInPageTip = () => {
        const { pageTip } = this.props;
        return pageTip ? <h5 className="dr-ui-trade-in-header__page-tip">{pageTip}</h5> : null;
    };

    renderTradeInErrorMessage = () => {
        const { tradePaymentFailed, tradeValuationReset } = this.props;

        return tradePaymentFailed || tradeValuationReset ? (
            <div className="form-group text-center">
                <span className="message-error">{this.renderPaymentErrorMessage()}</span>
            </div>
        ) : null;
    };

    retryApplyTrade() {
        this.props.applyTradeIn();
    }

    render() {
        return (
            <div className="dr-ui-trade-in-header">
                {this.renderTradeInHeaderTitle()}
                {this.renderTradeInHeaderSubTitle()}
                {/* Error Messaging */}
                {this.renderTradeInErrorMessage()}
                {/* End Error Messaging */}
                {this.renderTradeInPageTitle()}
                {this.renderTradeInPageSubTitle()}
                {this.renderTradeInPageTip()}
            </div>
        );
    }
}

export default TradeInHeaderUI;
