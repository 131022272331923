// externals
import { connect } from 'react-redux';
import isEmpty_ from 'lodash.isempty';
import isEqual_ from 'lodash.isequal';

import { push } from 'connected-react-router';

// libraries
import { validator } from '@makemydeal/dr-common-utils';
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';

// utils
import { cloneVehicle } from '../../../utils/cloner';

// components
import VehicleInfoUI, { IChangeHandlerMetaData } from './VehicleInfoUI';
import { IVehicleInfoUIStateProps, IVehicleInfoUIDispatchProps } from './VehicleInfoUI';

// selectors
import * as offerSelectors from '../../../store/mmd/offerSelectors';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as featureToggleSelectors from '../../../store/featureToggles';
import { microSiteEnabled } from '../../../store/mmd/dealerSelectors';

// actions
import * as tradeInActionCreators from '../../../store/actionCreators';
import { goToNext } from '../../../store/actionCreators';

// routing
import * as tradeInRoutes from '../../../utils/routes';
import { getMenuRoutes } from '../../../store/mmd/menu';

// utils
import * as optimizelyUtils from '../../../common/optimizelyWrapper';

// consts/enums
import { CALLSITE_VEHICLEINFO_1, CALLSITE_VEHICLEINFO_2 } from '../../../utils/constants';

const isEmpty = isEmpty_;
const isEqual = isEqual_;

const mapStateToProps = (state: any): IVehicleInfoUIStateProps => {
    const tradeIn = state.tradeInComponent;
    const years = tradeInSelectors.getTradeInLookupYears(tradeIn);
    const makes = tradeInSelectors.getTradeInLookupMakes(tradeIn);
    const models = tradeInSelectors.getTradeInLookupModels(tradeIn);
    const trims = tradeInSelectors.getTradeInLookupTrims(tradeIn);
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn);

    const isMakeEnabled = tradeInSelectors.getTradeInIsMakeEnabled(tradeIn);
    const isMakeLoading = tradeInSelectors.getTradeInIsMakeLoading(tradeIn);
    const isModelEnabled = tradeInSelectors.getTradeInIsModelEnabled(tradeIn);
    const isModelLoading = tradeInSelectors.getTradeInIsModelLoading(tradeIn);
    const isTrimEnabled = tradeInSelectors.getTradeInIsTrimEnabled(tradeIn);
    const isTrimLoading = tradeInSelectors.getTradeInIsTrimLoading(tradeIn);
    const isTrimSelected = tradeInSelectors.getTradeInIsTrimSelected(tradeIn);
    const isYearEnabled = tradeInSelectors.getTradeInIsYearEnabled(tradeIn);
    const isYearLoading = tradeInSelectors.getTradeInIsYearLoading(tradeIn);

    const zip = tradeInSelectors.getTradeInZip(tradeIn) || offerSelectors.getShopperZip(state);
    const ownership = tradeInSelectors.getTradeInOwnership(tradeIn);
    const vehicle = tradeInSelectors.getTradeInVehicle(tradeIn);
    const savedVehicle = tradeInSelectors.getTradeInSavedVehicle(state.tradeInComponent);
    const isTradeInValid = tradeInSelectors.getTradeInIsValid(tradeIn, state) && validator.validateZipCode(zip, 'US');
    const pageTitle = 'Which car are you trading in?';
    const headerSubtitle = 'Step 1 of 5';
    const isKbbFieldValidationEnabled = featureToggleSelectors.isKbbFieldValidationEnabled(state);

    return {
        headerSubtitle,
        isMakeEnabled,
        isMakeLoading,
        isModelEnabled,
        isModelLoading,
        isTradeInValid,
        isTrimEnabled,
        isTrimLoading,
        isTrimSelected,
        isYearEnabled,
        isYearLoading,
        makes,
        models,
        pageTitle,
        ownership,
        savedVehicle,
        trims,
        vehicle,
        vehicleOptions,
        years,
        zip,
        isKbbFieldValidationEnabled
    };
};

const updateVehicleProp = (vehicle: ITradeInVehicle, prop, elem, subProps = true): ITradeInVehicle => {
    const clonedVehicle = cloneVehicle(vehicle);
    const { value, text } = elem;
    if (subProps) {
        clonedVehicle[prop] = {
            id: value,
            name: text
        };
    } else {
        clonedVehicle[prop] = value;
    }
    return clonedVehicle;
};

const mapDispatchToProps = (dispatch: any): IVehicleInfoUIDispatchProps => {
    return {
        onLoad: (vehicle, years, makes, models, trims, zip) => {
            setTimeout(() => {
                dispatch(tradeInActionCreators.tradeInStarted());
                optimizelyUtils.track('TRADE_VEHICLE_INFO_IMPRESSION');
            }, 500);
            if (years.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInYears(undefined));
            }
            if (vehicle.year && makes.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInMakes(vehicle.year, undefined));
            }
            if (vehicle.year && vehicle.make.id && models.length === 0) {
                dispatch(
                    tradeInActionCreators.fetchTradeInModels(vehicle.year, vehicle.make.id, undefined, CALLSITE_VEHICLEINFO_1)
                );
            }
            if (vehicle.year && vehicle.make.id && vehicle.model.id && trims.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInTrims(vehicle.year, vehicle.make.id, vehicle.model.id, undefined));
            }
            if (zip) {
                dispatch(tradeInActionCreators.updateZip(zip));
            }
        },
        onValueChange: (propName, domElement, vehicle, savedVehicle, meta: IChangeHandlerMetaData) => {
            const hasVehicleChanged = !isEqual(vehicle, savedVehicle);
            dispatch(tradeInActionCreators.changedTradeIn(hasVehicleChanged));

            switch (propName) {
                case 'year': {
                    const newVehicle = updateVehicleProp(vehicle, propName, domElement, false);
                    dispatch(tradeInActionCreators.updateVehicle(newVehicle));
                    dispatch(tradeInActionCreators.fetchTradeInMakes(newVehicle.year, undefined));
                    dispatch(tradeInActionCreators.clearYMMT({ make: true, model: true, trim: true }));
                    break;
                }
                case 'make': {
                    const newVehicle = updateVehicleProp(vehicle, propName, domElement);
                    dispatch(tradeInActionCreators.updateVehicle(newVehicle));
                    dispatch(
                        tradeInActionCreators.fetchTradeInModels(
                            newVehicle.year,
                            newVehicle.make.id,
                            undefined,
                            CALLSITE_VEHICLEINFO_2
                        )
                    );
                    dispatch(tradeInActionCreators.clearYMMT({ model: true, trim: true }));
                    break;
                }
                case 'model': {
                    const newVehicle = updateVehicleProp(vehicle, propName, domElement);
                    dispatch(tradeInActionCreators.updateVehicle(newVehicle));
                    dispatch(
                        tradeInActionCreators.fetchTradeInTrims(newVehicle.year, newVehicle.make.id, newVehicle.model.id, undefined)
                    );
                    dispatch(tradeInActionCreators.updateVehicle(newVehicle));
                    dispatch(tradeInActionCreators.clearYMMT({ trim: true }));
                    break;
                }
                case 'trim': {
                    const newVehicle = updateVehicleProp(vehicle, propName, domElement);
                    dispatch(tradeInActionCreators.updateVehicle(newVehicle));
                    // NOTE: This is while i figure out how to properly provide the vehicleId: (this comment copied from old code)
                    newVehicle.trim.vehicleId = newVehicle.trim.id;
                    dispatch(tradeInActionCreators.updateVehicleOptions({}));
                    dispatch(tradeInActionCreators.updateVehicle(newVehicle));
                    break;
                }
                case 'ownership':
                case 'paymentPreference': {
                    if (domElement) {
                        dispatch(tradeInActionCreators.updatePurchasePreference(domElement.value));
                    } else {
                        throw new Error(
                            `Unexpected condition: index "${meta?.eventTargetSelectedIndex}", ` +
                                `options "${meta?.eventTargetOptionsCount}" and domElement is undefined (legacy/VehicleInfo)`
                        );
                    }
                    break;
                }
                case 'color':
                case 'condition':
                case 'mileage':
                case 'vin': {
                    const newVehicle = updateVehicleProp(vehicle, propName, domElement, false);
                    dispatch(tradeInActionCreators.updateVehicle(newVehicle));
                    break;
                }
                case 'zip': {
                    dispatch(tradeInActionCreators.updateZip(domElement.value));
                    break;
                }
                default:
                    break;
            }
        },
        cancelTradeIn: () => {
            // Analytics
            dispatch(tradeInActionCreators.cancelTradeIn());
        },
        applyTradeIn: () => {
            // Analytics + Middle Ware
            dispatch(tradeInActionCreators.applyTradeInSelections());
        },
        fetchEquipmentOptions: (vehicleOptions, zip, vehicle) => {
            if (isEmpty(vehicleOptions)) {
                dispatch(tradeInActionCreators.fetchTradeInEquipmentOptions(vehicle, zip));
            }
        },
        next: () => {
            dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_CONDITION));
        },
        onSkip: () => {
            dispatch((dispatch, getState) => {
                const isMicroSiteEnabled = microSiteEnabled(getState());
                if (isMicroSiteEnabled) {
                    dispatch(push(getMenuRoutes().MENU_PRODUCTS));
                } else {
                    dispatch(goToNext('/tradeIn'));
                    dispatch(tradeInActionCreators.tradeInSkipped());
                }
            });
        }
    };
};

const VehicleInfoContainer = connect(mapStateToProps, mapDispatchToProps)(VehicleInfoUI);

export default VehicleInfoContainer;
