// libraries
import { ITradeInState, ITradeInVehicle, IVehicleOption, NavigationItemStatus } from '@makemydeal/dr-activities-common';
import { validator } from '@makemydeal/dr-platform-shared';
// utils
import { getGlobalSelector } from './mmd';
import { getSponsor } from './widgetSelectors';

export const getDealerSelector = () => getGlobalSelector().additionalSelectors.dealerSelectors;

export const getIsPaymentCalculating = (state) =>
    getGlobalSelector().additionalSelectors.paymentSelectors.isCalculatingPayment(state);

/**
 * Like getTradeInComponent but won't return an empty object if the trade is undefined (see getTradeInComponent for safer selector).
 */
export const getTradeInComponentRaw = (state: any): ITradeInState => getGlobalSelector().getTradeInComponentRaw(state);

/**
 * Returns the trade object or an empty object if the trade is undefined (see getTradeInComponentRaw for simpler selector).
 */
export const getTradeInComponent = (state: any): ITradeInState => getGlobalSelector().getTradeInComponent(state);

export const icoTradeFlow = (state: any) => getGlobalSelector().icoTradeFlow(state);

export const getInstantCashOfferProviderUrl = (state: any): string => getGlobalSelector().getInstantCashOfferProviderUrl(state);

export const getTradeInValueIcoStatus = (state: any) => getGlobalSelector().getTradeInValueIcoStatus(state);

export const isTradeUpdateRequired = (state: any) => getGlobalSelector().isTradeUpdateRequired(state);

export const isAmsiDealer = (state: any): boolean => getGlobalSelector().isAmsiDealer(state);

export const isTradeInIcoEnabled = (state: any): boolean => getGlobalSelector().isTradeInIcoEnabled(state);

export const isICO = (state: any) => getGlobalSelector().isICO(state);

export const isIcoTradeInFlow = (state: any) => getGlobalSelector().isIcoTradeInFlow(state);

export const isIcoTradeInNotSiteDriverFlow = (state: any) => getGlobalSelector().isIcoTradeInNotSiteDriverFlow(state);

export const getTradeInIsOwnershipEnabled = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsOwnershipEnabled(tradeIn);

export const getTradeInIsMileageEnabled = (tradeIn: ITradeInState, state: any) =>
    getGlobalSelector().getTradeInIsMileageEnabled(tradeIn, state);

export const getTradeInIsMileageEnabledFromState = (state: any) => getGlobalSelector().getTradeInIsMileageEnabledFromState(state);

export const getTradeInIsZipCodeEnabled = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsZipCodeEnabled(tradeIn);

export const getTradeInAreOptionalFieldsEnabled = (tradeIn: ITradeInState) =>
    getGlobalSelector().getTradeInAreOptionalFieldsEnabled(tradeIn);

export const getTradeInLookupTrims = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInLookupTrims(tradeIn);

export const getTradeInIsTrimEnabled = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsTrimEnabled(tradeIn);

export const getTradeInIsTrimLoading = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsTrimLoading(tradeIn);

export const getTradeInIsTrimSelected = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsTrimSelected(tradeIn);

export const getTradeInVehicleOptions = (tradeIn: ITradeInState): IVehicleOption =>
    getGlobalSelector().getTradeInVehicleOptions(tradeIn);

export const getTradeInAmountOwed = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInAmountOwed(tradeIn);

export const getTradeInValueSource = (tradeIn) => getGlobalSelector().getTradeInValueSource(tradeIn);

export const getTradeInEstimate = (tradeIn) => getGlobalSelector().getTradeInEstimate(tradeIn);

export const getTradeInEstimateSelectedValue = (tradeIn: ITradeInState) =>
    getGlobalSelector().getTradeInEstimateSelectedValue(tradeIn);

export const getTradeInValue = (tradeIn: ITradeInState, isImprovedWorkflowEnabled?: boolean) =>
    getGlobalSelector().getTradeInValue(tradeIn, isImprovedWorkflowEnabled);

export const getTradeInEquity = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInEquity(tradeIn);

export const getAmountAppliedToFinancing = (tradeIn: ITradeInState) => getGlobalSelector().getAmountAppliedToFinancing(tradeIn);

export const getTradeInZip = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInZip(tradeIn);

export const getTradeInVehicleObj = (tradeIn: ITradeInState): Partial<ITradeInVehicle> =>
    getGlobalSelector().getTradeInVehicleObj(tradeIn);

export const getTradeInVehicleCondition = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleCondition(tradeIn);

export const getTradeInVehicleVin = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleVin(tradeIn);

export const getTradeInVehicleYear = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleYear(tradeIn);

export const getTradeInVehicleMake = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleMake(tradeIn);

export const getTradeInVehicleMakeName = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleMakeName(tradeIn);

export const getTradeInVehicleMakeId = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleMakeId(tradeIn);

export const getTradeInVehicleModel = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleModel(tradeIn);

export const getTradeInVehicleModelName = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleModelName(tradeIn);

export const getTradeInVehicleModelId = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleModelId(tradeIn);

export const getTradeInVehicleTrim = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleTrim(tradeIn);

export const getTradeInVehicleTrimName = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleTrimName(tradeIn);

export const getTradeInVehicleTrimId = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleTrimId(tradeIn);

export const getTradeInTrimVehicleId = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInTrimVehicleId(tradeIn);

export const getTradeInVehicleColor = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleColor(tradeIn);

export const getTradeInVehicleInteriorColor = (tradeIn: ITradeInState) =>
    getGlobalSelector().getTradeInVehicleInteriorColor(tradeIn);

export const getTradeInVehicleMileage = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleMileage(tradeIn);

export const getTradeInEstimateFairValue = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInEstimateFairValue(tradeIn);

export const getTradeInEstimateExcellentValue = (tradeIn: ITradeInState) =>
    getGlobalSelector().getTradeInEstimateExcellentValue(tradeIn);

export const getTradeInValueFair = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInValueFair(tradeIn);

export const getTradeInValueExcellent = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInValueExcellent(tradeIn);

export const getTradeInLookupYears = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInLookupYears(tradeIn);

export const getTradeInIsYearEnabled = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsYearEnabled(tradeIn);

export const getTradeInIsYearLoading = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsYearLoading(tradeIn);

export const getTradeInLookupMakes = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInLookupMakes(tradeIn);

export const getTradeInIsMakeEnabled = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsMakeEnabled(tradeIn);

export const getTradeInIsMakeLoading = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsMakeLoading(tradeIn);

export const getTradeInLookupModels = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInLookupModels(tradeIn);

export const getTradeInIsModelEnabled = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsModelEnabled(tradeIn);

export const getTradeInIsModelLoading = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInIsModelLoading(tradeIn);

export const getTradeInOwnership = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInOwnership(tradeIn);

export const getTradeInVehicle = (tradeIn: ITradeInState): ITradeInVehicle => getGlobalSelector().getTradeInVehicle(tradeIn);

export const getTradeInSavedVehicleOpts = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInSavedVehicleOpts(tradeIn);

export const getTradeInSavedVehicle = (tradeIn: ITradeInState): ITradeInVehicle =>
    getGlobalSelector().getTradeInSavedVehicle(tradeIn);

export const getTradeInIsValid = (tradeIn: ITradeInState, state: any) => getGlobalSelector().getTradeInIsValid(tradeIn, state);

export const getTradeVehicleNeedsFetch = (tradeIn: ITradeInState, state: any) =>
    getGlobalSelector().getTradeVehicleNeedsFetch(tradeIn, state);

export const getTradeInHasACV = (state: any) => getGlobalSelector().getTradeInHasACV(state);

export const getOemTradeInIsValid = (tradeIn: ITradeInState) => getGlobalSelector().getOemTradeInIsValid(tradeIn);

export const isOemInfoRequestFailed = (tradeIn: ITradeInState) => getGlobalSelector().isOemInfoRequestFailed(tradeIn);
export const isOemInfoRequestInProgress = (tradeIn: ITradeInState) => getGlobalSelector().isOemInfoRequestInProgress(tradeIn);

export const getTradeInVehicleId = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInVehicleId(tradeIn);

export const getTradeInOptionInConflict = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInOptionInConflict(tradeIn);

export const isEquipOptionsEstimate1InProgress = (tradeIn: ITradeInState) =>
    getGlobalSelector().isEquipOptionsEstimate1InProgress(tradeIn);

export const getTradeInCurrentLocationRoute = (state: any) => getGlobalSelector().getTradeInCurrentLocationRoute(state);

export const getTradeInCurrentLocationRouteP202 = (state: any) => getGlobalSelector().getTradeInCurrentLocationRouteP202(state);

export const getTradeInCurrentLocationRouteForIco = (state: any) => getGlobalSelector().getTradeInCurrentLocationRouteForIco(state);

export const checkIsSubaruGtpEligible = (tradeIn: ITradeInState): boolean => getGlobalSelector().checkIsSubaruGtpEligible(tradeIn);

export const isSubaruGTPImprovedWorkflowEnabled = (tradeIn: ITradeInState): boolean =>
    getGlobalSelector().isSubaruGTPImprovedWorkflowEnabled();

export const isSubaruGtpEligible = (tradeIn: ITradeInState): boolean => getGlobalSelector().isSubaruGtpEligible(tradeIn);

export const inTradeInSubaruGtpFlow = (state: any): boolean => getGlobalSelector().inTradeInSubaruGtpFlow(state);

export const getTradeInSubaruGtpFlowError = (state: any) => getGlobalSelector().getTradeInSubaruGtpFlowError(state);

export const isTradeInCompleted = (state: any): boolean => getGlobalSelector().isTradeInCompleted(state);

export const isICOTradeAlreadySubmitted = (state: any): boolean => getGlobalSelector().isICOTradeAlreadySubmitted(state);

export const isExternalIcoCompleted = (state: any): boolean => getGlobalSelector().isExternalIcoCompleted(state);

export const isTradeInSkipped = (state: any): boolean => getGlobalSelector().isTradeInSkipped(state);

export const isTradeValuationReset = (state: any): boolean => getGlobalSelector().isTradeValuationReset(state);

export const showTradeCard = (state: any): boolean => getGlobalSelector().showTradeCard(state);

export const isTradeInAppCardCompleted = (state: any): boolean => getGlobalSelector().isTradeInAppCardCompleted(state);

export const isTradeSaveFailed = (state: any): boolean => getGlobalSelector().isTradeSaveFailed(state);

export const isTradeSavePending = (state: any): boolean => getGlobalSelector().isTradeSavePending(state);

export const hasTradeInChanged = (state: any): boolean => getGlobalSelector().hasTradeInChanged(state);

export const tradeInStatus = (state: any): NavigationItemStatus => getGlobalSelector().tradeInStatus(state);

export const isTradeInSent = (state: any) => getGlobalSelector().isTradeInSent(state);

export const isTradeInDisabled = (state: any): boolean => getGlobalSelector().isTradeInDisabled(state);

export const getTradeInForOffer = (tradeIn: ITradeInState) => getGlobalSelector().getTradeInForOffer(tradeIn);

export const isSearchingForVin = (state: any) => getGlobalSelector().isSearchingForVin(state);

export const isSearchingForYMMT = (state: any) => getGlobalSelector().isSearchingForYMMT(state);

export const getSearchResultsForVin = (state: any) => getGlobalSelector().getSearchResultsForVin(state);

export const getSearchResultsForYMMT = (state: any) => getGlobalSelector().getSearchResultsForYMMT(state);

export const isTradeInStatusSent = (state: any): boolean => getGlobalSelector().isTradeInStatusSent(state);

export const getTradeInCompletionDate = (state: any): Date | undefined => getGlobalSelector().getTradeInCompletionDate(state);

export const getTradeInStatusText = (state: any): string => getGlobalSelector().getTradeInStatusText(state);

export const getTradeInStatus = (state: any): any => getGlobalSelector().getTradeInStatus(state);

export const hasTradeIn = (state: any): boolean => getGlobalSelector().hasTradeIn(state);

export const getTradePaymentFailed = (state: any) => getGlobalSelector().getTradePaymentFailed(state);

export const getExpirationDate = (tradeIn: any) => getGlobalSelector().getExpirationDate(tradeIn);

export const getTradeInSavedObj = (tradeIn: any) => getGlobalSelector().getTradeInSavedObj(tradeIn);

export const getTradeInSavedMileage = (tradeIn: any) => getGlobalSelector().getTradeInSavedMileage(tradeIn);

export const getTradeInSavedZip = (tradeIn: any) => getGlobalSelector().getTradeInSavedZip(tradeIn);

export const getTradeInSavedAmountOwed = (tradeIn: any) => getGlobalSelector().getTradeInSavedAmountOwed(tradeIn);

export const getTradeInErrorCount = (state: any) => getGlobalSelector().getTradeInSavedAmountOwed(state);

export const getTradeDecisionPage = (state: any) => getGlobalSelector().getTradeDecisionPage(state);

export const getManualTradeDecision = (state: any) => getGlobalSelector().getManualTradeDecision(state);

export const isTradeInUnavailable = (state: any) => getGlobalSelector().isTradeInUnavailable(state);

export const shouldShowLeadForm = (state: any) => {
    const sponsor = getSponsor(state).toString();
    const isListingDealer = validator.isListingSite(sponsor);
    const isDealerLeadFormForWebsiteEnabled = getDealerSelector().isDealerTradeInLeadFormForWebsiteEnabled(state);
    const isDealerLeadFormForListingSiteEnabled = getDealerSelector().isDealerTradeInLeadFormForListingSiteEnabled(state);

    return (!isListingDealer && isDealerLeadFormForWebsiteEnabled) || (isListingDealer && isDealerLeadFormForListingSiteEnabled);
};

export const getValueSource = (tradeIn: any) => getGlobalSelector().getValueSource(tradeIn);

export const getValueComments = (state: any) => getGlobalSelector().getValueComments(state);

export const isManualEntryFlow = (state: any) => getGlobalSelector().isShopperEnteredTrade(state);

export const hideKBBDisclaimer = (state: any) => getGlobalSelector().hideKBBDisclaimer(state);

export const hasDealChangeMessageBoxBeenClosed = (state: any) => getGlobalSelector().hasDealChangeMessageBoxBeenClosed(state);

export const getTradeInEntryPoint = (state: any) => getGlobalSelector().getTradeInEntryPoint(state);

export const tradeInValuationProviderDefault = (state: any) => getGlobalSelector().tradeInValuationProviderDefault(state);

export const isTradeInValuationProviderKBB = (state: any) => getGlobalSelector().isTradeInValuationProviderKBB(state);

export const isTradeInValuationProviderKBBICO = (state: any) => getGlobalSelector().isTradeInValuationProviderKBBICO(state);

export const isTradeInValuationProviderKBBICOSalesView = (state: any) =>
    getGlobalSelector().isTradeInValuationProviderKBBICOSalesView(state);

export const isTradeInValuationProviderKBBSalesView = (state: any) =>
    getGlobalSelector().isTradeInValuationProviderKBBSalesView(state);

export const isTradeInValuationProviderManual = (state: any) => getGlobalSelector().isTradeInValuationProviderManual(state);

export const isTradeInValuationProviderManualSalesView = (state: any) =>
    getGlobalSelector().isTradeInValuationProviderManualSalesView(state);

export const isShowAppraisalValueScreenSalesView = (state: any) => getGlobalSelector().isShowAppraisalValueScreenSalesView(state);

export const tradeInValuationProviderDefaultSalesView = (state: any) =>
    getGlobalSelector().tradeInValuationProviderDefaultSalesView(state);

export const getShouldIncludeTradeIn = (tradeIn: ITradeInState) => getGlobalSelector().getShouldIncludeTradeIn(tradeIn);
