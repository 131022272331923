const POST_REQUEST_METHOD = 'POST';
const PUT_REQUEST_METHOD = 'PUT';
const REQUEST_HEADERS = { 'Content-Type': 'application/json' };

import { addUserIdHeader, IHeaders } from './paramHelper';

export const commonApiLogic = (url: string, body: any, submitType: string, fakeFailure: boolean, done: any) => {
    if (fakeFailure) {
        setTimeout(() => {
            done('Fake error for testing');
        }, 2000);
        return;
    }

    const opts = {
        method: submitType === 'create' ? POST_REQUEST_METHOD : PUT_REQUEST_METHOD,
        body,
        headers: REQUEST_HEADERS as IHeaders
    };
    opts.headers = addUserIdHeader(opts.headers);

    fetch(url, opts)
        .then((response: any) => {
            return response.json();
        })
        .then((result: any) => {
            done(null, result);
        })
        .catch((err: any) => {
            done(err);
        });
};
