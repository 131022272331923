import * as React from 'react';

import { Select } from '@makemydeal/ui-bricks/dist/cox';
import { Modal } from '@makemydeal/dr-activities-common';
import { IconFactory, SpinnerUI } from '@makemydeal/dr-activities-common';
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';
import config from '../../../store/mmd/config';


export interface IVehicleInfoUIStateProps {
    vehicle: ITradeInVehicle;
    years: any[];
    makes: any[];
    models: any[];
    trims: any[];
    isYearEnabled: boolean;
    isYearLoading: boolean;
    isMakeEnabled: boolean;
    isMakeLoading: boolean;
    isModelEnabled: boolean;
    isModelLoading: boolean;
    isOemTradeInValid: boolean;
    isTrimEnabled: boolean;
    isTrimLoading: boolean;
    isTrimSelected: boolean;
}

export interface IVehicleInfoUIDispatchProps {
    onLoad: { (vehicle, years, makes, models, trims): void };
    onValueChange: {
        (propName, domElement, vehicle: ITradeInVehicle, savedVehicle?: ITradeInVehicle): void;
    };
}

export interface IVehicleInfoUIProps extends IVehicleInfoUIStateProps, IVehicleInfoUIDispatchProps {}

export interface IModalUIProps extends IVehicleInfoUIProps {
    cls?: string;
    description: string;
    elementToAttachModalTo: string;
    elementToBlur: string;
    errorMessage: string;
    hideModal: { () };
    isRequestInProgress: boolean;
    isVisible: boolean;
    onClickDone: { () };
    onClickOutsideModal?: { () };
    onModalHidden?: { () };
    onModalShown?: { () };
    outsideModalExcludeClasses?: string[];
    show: boolean;
    title: string;
}

export interface IVehicleInfoUIState {
    doneWasClicked?: boolean;
    validationErrorMessaqe?: string;
}

interface IModalUIState extends IVehicleInfoUIState {}

/**
 * This modal is used for the user to provide trade-in vehicle year, make, model, and trim when a DealXG deal is created by OEM,
 * and only totalAllowance and totalPayoff are provided for a trade-in.
 */
export default class TradeInVehicleInfoModalUI extends React.Component<IModalUIProps, IModalUIState> {
    setTextInputRef: (elem, name) => void;

    constructor(props) {
        super(props);

        this.state = {
            validationErrorMessaqe: '',
            doneWasClicked: false
        };

        this.setTextInputRef = (element, name) => {
            this[name] = element;
        };
    }

    componentDidMount() {
        const { vehicle, years, makes, models, trims } = this.props;

        this.props.onLoad(vehicle, years, makes, models, trims);

        let focusFieldName = 'year';
        if (vehicle.year) focusFieldName = 'make';
        if (vehicle.make?.id) focusFieldName = 'model';
        if (vehicle.model?.id) focusFieldName = 'trim';

        this.focusElementByRefName(focusFieldName);
    }

    focusElementByRefName(refName: string): void {
        const element = this[refName];
        if (element && !element.disabled) {
            element.focus();
        } else if (element && element.disabled) {
            setTimeout(() => this.focusElementByRefName(refName), 100);
        }
    }

    validateRequiredFields = (fieldValue) => {
        return (fieldValue === null || fieldValue === '') && this.state.doneWasClicked ? false : true;
    };

    handleSelectOnChange: React.ChangeEventHandler<HTMLSelectElement> = (event): void => {
        const { currentTarget } = event;
        this.onChangeHandler(currentTarget.getAttribute('name'), currentTarget.options[currentTarget.selectedIndex]);
    };

    getSelectField(name, options, optionsId, label, selectedValue, enabled = false, additionalClass = '', placeholder = 'Select') {
        const optionsItems = options.map((option, index) => {
            const optionId = optionsId !== '' ? option[optionsId] : option;
            const optionName = option.hasOwnProperty('name') ? option.name : option;
            return (
                <option key={optionId} value={optionId}>
                    {optionName}
                </option>
            );
        });

        const isValid = this.validateRequiredFields(selectedValue);
        const showError = isValid ? 'hide' : '';
        const showRedOutline = isValid ? '' : 'field-not-filled-in';
        return (
            <>
                <Select
                    selectId={name}
                    name={name}
                    ref={(element) => this.setTextInputRef(element, name)}
                    disabled={!enabled}
                    value={selectedValue || ' '}
                    label={label}
                    placeholder={placeholder}
                    defaultValue={' '}
                    className={`select-field ui-bricks ${showRedOutline}`}
                    onChangeFunction={this.handleSelectOnChange}
                >
                    <option value=" " disabled>
                        {placeholder}
                    </option>
                    {optionsItems}
                </Select>
                <div className={`error-div ${showError}`}>
                    <IconFactory icon="errorIcon" className="error-icon" staticImages={config.staticImages} />
                    <span className="error-message">This is a required field</span>
                </div>
            </>
        );
    }

    renderErrorMessage() {
        const { errorMessage } = this.props;

        if (errorMessage) {
            return <span className="error-message">{errorMessage}</span>;
        } else {
            return null;
        }
    }

    onChangeHandler(propName, domElement) {
        this.props.onValueChange(propName, domElement, { ...this.props.vehicle }, undefined);

        switch (propName) {
            case 'year': {
                this.focusElementByRefName('make');
                break;
            }
            case 'make': {
                this.focusElementByRefName('model');
                break;
            }
            case 'model': {
                this.focusElementByRefName('trim');
                break;
            }
            case 'trim': {
                this.focusElementByRefName('ownership');
                break;
            }
        }
    }

    done = () => {
        if (!this.props.isOemTradeInValid) {
            this.setState({ doneWasClicked: true });
            return;
        }

        this.props.onClickDone();
    };

    renderModalContents() {
        const { description, isRequestInProgress, title } = this.props;
        const {
            years,
            isYearEnabled,
            isYearLoading,
            makes,
            isMakeEnabled,
            isMakeLoading,
            models,
            isModelEnabled,
            isModelLoading,
            trims,
            isTrimEnabled,
            isTrimLoading,
            isTrimSelected,
            vehicle
        } = this.props;

        const disabledDoneBtn = (isRequestInProgress || !isTrimSelected) ? 'disabled' : '';

        return (
            <>
                <div className="top">
                    <div className="title common-header">
                        {title}
                    </div>
                    <div className="description">
                        {description}
                    </div>
                </div>

                <div className="middle-container">
                    <div className="fields-container">
                        {isRequestInProgress && <SpinnerUI size="30" />}
                        <div className="vehicle-info-container">
                            <div className="row custom-row">
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <div className="form-row form-group">
                                        <div>
                                            {this.getSelectField('year', years, '', 'Year', vehicle.year, isYearEnabled)}
                                            {isYearLoading && <SpinnerUI size="30" />}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <div className="form-row form-group">
                                        <div>
                                            {this.getSelectField('make', makes, 'id', 'Make', vehicle.make.id, isMakeEnabled)}
                                            {isMakeLoading && <SpinnerUI size="30" />}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row custom-row">
                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <div className="form-row form-group">
                                        <div>
                                            {this.getSelectField('model', models, 'id', 'Model', vehicle.model.id, isModelEnabled)}
                                            {isModelLoading && <SpinnerUI size="30" />}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-xs-12 col-md-6 col-lg-4">
                                    <div className="form-row form-group">
                                        <div>
                                            {this.getSelectField('trim', trims, 'id', 'Trim', vehicle.trim.id, isTrimEnabled)}
                                            {isTrimLoading && <SpinnerUI size="30" />}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="error-message">{this.renderErrorMessage()}</div>
                        <div className="button-container">
                            <button
                                disabled={!!disabledDoneBtn}
                                type="button"
                                className={`btn btn-primary ${disabledDoneBtn}`}
                                onClick={this.done}
                            >
                                Done
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    render() {
        const { isVisible, elementToAttachModalTo, elementToBlur, hideModal, cls } = this.props;

        return (
            <Modal
                show={isVisible}
                elementToAttachModalTo={elementToAttachModalTo}
                elementToBlur={elementToBlur}
                onClickOutsideModal={hideModal}
                cls={cls}
            >
                {isVisible ? this.renderModalContents() : null}
            </Modal>
        );
    }
}
