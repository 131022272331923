/**
 * PURPOSE: Ensures deal history is refreshed when it is necessary to update the app state with new information.
 */

// libraries
import { AnyFSA } from '@makemydeal/dr-platform-shared';

// actions
import { dealHistoryActionCreators } from '../actions';

// consts/enums
import { OFFER_SAVE_NOCHANGE_SUCCESS, OFFER_SAVE_SUCCESS } from '../actionTypes/offerActionTypes';
import { SOCKET_EVENT_RECEIVED } from '../actionTypes/socketActionTypes';

// selectors
import { offerInfoSelectors } from '../selectors';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';
import { bootstrapActionTypes } from '@makemydeal/dr-shared-store';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();
    switch (action.type) {
        case bootstrapActionTypes.BOOTSTRAP_SUCCESS:
        case OFFER_SAVE_NOCHANGE_SUCCESS:
        case OFFER_SAVE_SUCCESS: {
            // fetch snapshots
            // TODO: DASH - what should happen if this fails?
            store.dispatch(dealHistoryActionCreators.fetchDealHistory(state));
            break;
        }
        case SOCKET_EVENT_RECEIVED: {
            const isUpdatedMilestonesEmpty = offerInfoSelectors.getSocketEventUpdatedMilestones(state).length === 0;
            const isOfferUpdate = offerInfoSelectors.getSocketEventUpdatedMilestones(state).includes('offer');
            const isPaymentUpdate = offerInfoSelectors.getSocketEventIsPaymentUpdate(state);
            const hasMilestonesChanged = isPaymentUpdate || isUpdatedMilestonesEmpty || isOfferUpdate;
            if (hasMilestonesChanged) {
                store.dispatch(dealHistoryActionCreators.fetchDealHistory(state));
            }

            break;
        }
    }
};
