// externals
import * as React from 'react';

// libraries
import { IconFactory, ITradeInVehicle } from '@makemydeal/dr-activities-common';
import { Spinner } from '@makemydeal/ui-bricks/dist/cox';

// config
import config from '../../../store/mmd/config';
import * as tradeInRoutes from '../../../utils/routes';

// components
import TradePage from '../../../common/components/TradePage/TradePage';

// types
import { ImprovedStepperRoute } from '../../../types';

export type EquipmentOptionsUIStateProps = {
    conflict?: any;
    hasLeadFormSubmitted: boolean;
    shouldIncludeTradeIn?: boolean;
    isDealerUser?: boolean;
    isIE: boolean;
    isSubmitting?: boolean;
    paymentCalculating?: boolean;
    paymentFailure?: boolean;
    renderDisclaimer?: { () };
    stepperRoutes: ImprovedStepperRoute[];
    tradeSaveFailure?: boolean;
    tradeSavePending?: boolean;
    vehicle: ITradeInVehicle;
    vehicleOptionsArray: any;
    waitingForEstimateSuccess: boolean;
    isSDPRedesignEnabled: boolean;
};

export type EquipmentOptionsUIDispatchProps = {
    onValueChange: { (categoryName: string, id: number, checked: boolean) };
    next: { () };
    goToRoute: { (route: string): void };
    dispatchAnalytics?: { (type: string, description: string) };
    showVehicleEquipment?: { (showAll: boolean) };
    removeTradeIn?: { (isSDPRedesignEnabled?: boolean) };
};

export interface IEquipmentOptionsUIProps extends EquipmentOptionsUIStateProps, EquipmentOptionsUIDispatchProps {}

export interface IEquipmentOptionsUIState {
    expandSections: object;
    showAll: boolean;
}

export class EquipmentOptionsUI extends React.Component<IEquipmentOptionsUIProps, IEquipmentOptionsUIState> {
    constructor(props) {
        super(props);
        this.state = {
            expandSections: {},
            showAll: false
        };
    }

    handleChange = (event: React.MouseEvent | React.KeyboardEvent, vehicleOptionName: string) => {
        const { vehicleOptionsArray, onValueChange } = this.props;

        const optionId = parseInt(event.currentTarget.getAttribute('data-option-id'), 10);
        const arrayOfArrayOfOptions = vehicleOptionsArray.map((section) => section.value.options);
        const flattenedArrayOfOptions = [].concat(...arrayOfArrayOfOptions);
        const option = flattenedArrayOfOptions.find((option) => option.id === optionId);

        if (!option) return;
        onValueChange(option.categoryName || vehicleOptionName, option.id, !option.isSelected);
    };

    toggleExpandSection = (event: React.MouseEvent | React.KeyboardEvent) => {
        const optionName = event.currentTarget.getAttribute('data-option-name');

        const expandSections = {
            ...this.state.expandSections,
            [optionName]: !this.state.expandSections[optionName]
        };

        this.setState({ expandSections });
    };

    handleEnterAndSpace = (event: React.KeyboardEvent, callback: any, ...args: any): void => {
        if (event.key === 'Enter' || event.key === ' ') {
            event.preventDefault();
            event.stopPropagation();
            callback(event, ...args);
        }
    };

    toggleShowAll = (showAll: boolean) => {
        const namesOfVehicleOptions = this.props.vehicleOptionsArray.map((option) => option.key);
        const expandSections = {};
        namesOfVehicleOptions.forEach((name) => (expandSections[name] = showAll));
        this.props.showVehicleEquipment(showAll);

        this.setState({
            showAll,
            expandSections
        });
    };

    showAll = () => this.toggleShowAll(true);

    hideAll = () => this.toggleShowAll(false);

    renderIndividualVehicleOptions = (option, vehicleOptionName: string) => {
        const { isSelected, categoryName, originalIndex } = option;
        const { categoryInConflict, optionInConflictIndex } = this.props.conflict;
        const showCheckIcon = isSelected ? '' : 'hide';
        const hasLegacyConflict = categoryInConflict === categoryName && optionInConflictIndex === originalIndex;
        const hasVrs2Conflict = !!option.conflict;
        const showConflictIcon = hasLegacyConflict || hasVrs2Conflict ? '' : 'hide';
        const conflictTitle = hasVrs2Conflict ? option.conflict.message : 'This option was removed based on a previous selection';

        const vehicleOptionButton = (
            <div
                className={`selectable-vehicle-option ${isSelected ? 'selected' : ''}`}
                data-option-id={option.id}
                onClick={(event: React.MouseEvent) => this.handleChange(event, vehicleOptionName)}
                onKeyDown={(ev: React.KeyboardEvent) => this.handleEnterAndSpace(ev, this.handleChange, vehicleOptionName)}
                key={option.id}
                tabIndex={0}
                role="checkbox"
                aria-selected={isSelected}
            >
                <i className={`fa fa-check-circle vehicle-option-icon ${showCheckIcon}`} />
                <span>{option.displayName}</span>
                <i className={`fa fa-exclamation-triangle conflict-warning-icon ${showConflictIcon}`} title={conflictTitle} />
            </div>
        );

        if (this.props.isIE) {
            return (
                <div key={option.id} className="col-xs-6 column-props">
                    {vehicleOptionButton}
                </div>
            );
        }
        return vehicleOptionButton;
    };

    renderVehicleOptionGroups = (vehicleOption) => {
        const sectionIsExpanded = this.state.expandSections[vehicleOption.key];
        const outerDivClass = this.props.isIE ? 'row' : 'selectable-vehicle-options';

        const selectedVehicleOptions = vehicleOption.value.options
            .filter((option) => option.isSelected)
            .map((option) => option.displayName);

        return (
            <div key={vehicleOption.key} className="vehicle-option-group">
                <div
                    className="vehicle-option-flexbox"
                    data-option-name={vehicleOption.key}
                    onClick={this.toggleExpandSection}
                    onKeyDown={(ev: React.KeyboardEvent) => this.handleEnterAndSpace(ev, this.toggleExpandSection)}
                    aria-label={vehicleOption.key}
                    aria-expanded={sectionIsExpanded}
                    role="button"
                    tabIndex={0}
                >
                    <div className="vehicle-option-header common-header">{vehicleOption.key}</div>
                    <IconFactory
                        icon="plus"
                        className={`toggle-expand ${sectionIsExpanded ? 'hide' : ''}`}
                        staticImages={config.staticImages}
                    />
                    <IconFactory
                        icon="minus"
                        className={`toggle-expand ${sectionIsExpanded ? '' : 'hide'}`}
                        staticImages={config.staticImages}
                    />
                </div>

                <div className={`selected-container ${sectionIsExpanded ? 'hide' : ''}`}>
                    <span className="selected-label">Selected: </span>
                    {selectedVehicleOptions.length > 0 ? selectedVehicleOptions.join(', ') : 'None'}
                </div>

                <div className={`${outerDivClass} ${sectionIsExpanded ? '' : 'hide'}`}>
                    {vehicleOption.value.options.map((option) =>
                        this.renderIndividualVehicleOptions(option, vehicleOption.value.name)
                    )}
                </div>
            </div>
        );
    };

    render() {
        const {
            isDealerUser,
            vehicle,
            vehicleOptionsArray,
            hasLeadFormSubmitted,
            tradeSaveFailure,
            tradeSavePending,
            dispatchAnalytics,
            goToRoute,
            paymentFailure,
            paymentCalculating,
            removeTradeIn,
            stepperRoutes,
            next,
            isSDPRedesignEnabled
        } = this.props;

        const { showAll } = this.state;
        const { year, make, model } = vehicle;

        // tslint:disable-next-line:max-line-length
        // eslint-disable-next-line max-len
        const pageSubTitle = `To save you time we've pre-selected the standard options for your ${year} ${make.name} ${model.name}.`;
        const buttonText = isDealerUser || !hasLeadFormSubmitted ? 'Next' : 'Submit';
        const spinnerSize = 40;
        const shouldDisplaySpinner = isDealerUser && (paymentCalculating || (tradeSavePending && !paymentFailure));
        const displaySpinner = shouldDisplaySpinner && (
            <div className="trade-pending-container">
                <Spinner size={spinnerSize} />
            </div>
        );
        const paymentOrTradeFailureMessage = 'Could not update an existing trade. Please remove the trade to continue. ';

        const shouldDisplayFailureMessage = isDealerUser && (paymentFailure || tradeSaveFailure);
        const displayFailureMessage = shouldDisplayFailureMessage && (
            <div className="trade-failure-container">
                <div className="trade-failure-message">
                    {paymentOrTradeFailureMessage}
                    <a className="btn-link link link-trade-removal" onClick={() => removeTradeIn(isSDPRedesignEnabled)}>
                        Remove Trade-In
                    </a>
                </div>
            </div>
        );

        const handleSkipButtonClick = () => {
            removeTradeIn(isSDPRedesignEnabled);
        };

        const handleBackButtonClick = () => {
            goToRoute(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS_STEP_ONE);
        };

        return (
            <TradePage
                pageClass="equipment-options-page"
                pageTitle="What Options Are On Your Car?"
                pageSubTitle={pageSubTitle}
                stepperRoutes={stepperRoutes}
                goToRoute={goToRoute}
                dispatchAnalytics={dispatchAnalytics}
                footerProps={{
                    onActionButtonClick: next,
                    showBackButton: isSDPRedesignEnabled,
                    buttonText: isSDPRedesignEnabled ? 'Continue To Summary' : buttonText,
                    subText: hasLeadFormSubmitted ? '*Your latest updates will be sent to us.' : '',
                    isPending: tradeSavePending,
                    hasSpinner: true,
                    disableActionButton: this.props.waitingForEstimateSuccess,
                    fullWidthLayout: isSDPRedesignEnabled,
                    showSkipButton: isSDPRedesignEnabled,
                    skipButtonText: 'Cancel Trade-In',
                    onSkipButtonClick: handleSkipButtonClick,
                    onBackButtonClick: handleBackButtonClick
                }}
                renderDisclaimer={this.props.renderDisclaimer}
                stepLabelVisible={true}
                showTradeErrorMessage={false}
                staticAssetsConfig={config.staticImages}
            >
                <div className="options-step-container">
                    <div className="show-all-widget-container">
                        <div className="show-all-flex-container">
                            <div
                                className={`show-all-toggle toggle-show ${showAll ? 'active' : ''}`}
                                tabIndex={0}
                                onClick={this.showAll}
                                role="button"
                                onKeyDown={(ev: React.KeyboardEvent) => this.handleEnterAndSpace(ev, this.showAll)}
                                aria-selected={showAll}
                            >
                                Show All
                            </div>
                            <div
                                className={`show-all-toggle toggle-hide ${showAll ? '' : 'active'}`}
                                tabIndex={0}
                                role="button"
                                onClick={this.hideAll}
                                onKeyDown={(ev: React.KeyboardEvent) => this.handleEnterAndSpace(ev, this.hideAll)}
                                aria-selected={!showAll}
                            >
                                Hide All
                            </div>
                        </div>
                    </div>

                    <div className="vehicle-option-groups">{vehicleOptionsArray.map(this.renderVehicleOptionGroups)}</div>
                    {displaySpinner}
                    {displayFailureMessage}
                </div>
            </TradePage>
        );
    }
}

export default EquipmentOptionsUI;
