import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { CreditBureau } from './creditBureau/CreditBureau';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { LOAD_CB_VERSION1, LOAD_CB_VERSION2 } from '../constants';

const CreditBureauActivity = () => {
    const enableCreditBureauAndDecisionPBCs = useSelector(featureToggleSelectors.enableCreditBureauAndDecisionPBCs);

    useEffect(() => {
        const scriptAlpha = document.createElement('script');
        const version = enableCreditBureauAndDecisionPBCs ? LOAD_CB_VERSION2 : LOAD_CB_VERSION1;
        scriptAlpha.src = `https://fni-static.dealertrack.com/apps/fni-credit-bureau-widget/versions/${version}/fni-credit-bureau.esm.js`;
        scriptAlpha.type = 'module';
        scriptAlpha.id = 'fni-cb-module';
        document.body.appendChild(scriptAlpha);

        const scriptBeta = document.createElement('script');
        scriptBeta.src = `https://fni-static.dealertrack.com/apps/fni-credit-bureau-widget/versions/${version}/fni-credit-bureau.js`;
        scriptBeta.noModule = true;
        scriptBeta.id = 'fni-cb-js';
        document.body.appendChild(scriptBeta);
        return () => {
            const cbScripts = document.querySelectorAll('script[id*="fni-cb-"]');
            cbScripts.forEach((elem) => elem.remove());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <CreditBureau />;
};

export default CreditBureauActivity;
