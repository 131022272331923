import * as React from 'react';
import { connect } from 'react-redux';

import VehicleInfoSingleColumn from '../VehicleInfo/VehicleInfo-singleColumn';
import VehicleInfo from '../VehicleInfo/VehicleInfo';
import { getOptimizelyTests, isVariationActive, activateTests } from '../../../store/mmd/optimizelySelectors';

interface IAdaptVehicleInfo {
    isOptimizelySingleCol: boolean;
}

const VehicleInfoAdapterUI: React.FC<IAdaptVehicleInfo> = (props) => {
    React.useEffect(() => {
        activateTests([getOptimizelyTests().tradeInSingleColumn]);
    }, []);
    return props.isOptimizelySingleCol ? <VehicleInfoSingleColumn /> : <VehicleInfo />;
};

const mapStateToProps = (state: any): IAdaptVehicleInfo => {
    const isOptimizelySingleCol = isVariationActive(
        state,
        getOptimizelyTests().tradeInSingleColumn,
        getOptimizelyTests().tradeInSingleColumn.singleColumn
    );
    return { isOptimizelySingleCol };
};

export default connect(mapStateToProps)(VehicleInfoAdapterUI);
