import { useContext } from 'react';
import { ExpandableAreaContext, ExpandableAreaContextType } from './Context';

const ERROR_MESSAGE = 'useExpandableArea must be within a ExpandableAreaProvider.';

/**
 * Returns collapsible area utility functions.
 */
export const useExpandableArea = (): ExpandableAreaContextType => {
    const context = useContext(ExpandableAreaContext);
    if (context == null) {
        throw new Error(ERROR_MESSAGE);
    }
    return context;
};

export const useCheckExpandableAreaProvider = (): boolean => {
    const context = useContext(ExpandableAreaContext);
    return context != null;
};
