import styled from 'styled-components';

export const StyledVehicleInfoCardContainer = styled.div``;

export const StyledVehicleInfoCard = styled.div`
    display: flex;
    align-items: start;
    gap: ${({ theme }) => theme.tokens.BaseSpace40};
`;

export const StyledVehicleInfoCardImage = styled.div`
    flex-shrink: 0;

    img {
        width: 9.5rem;
        max-height: 5.25rem;
        object-fit: cover;
        border-radius: 0.3rem;
        overflow-clip-margin: unset;
    }
`;

export const StyledVehicleInfoCardContent = styled.div`
    width: 100%;

    button {
        span {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            text-transform: capitalize;
        }
    }
`;

export const StyledVehicleInfoCardStockVin = styled.div`
    margin-top: ${({ theme }) => theme.tokens.BaseSpace25};
    display: flex;
    flex-wrap: wrap;
`;

export const VehicleInfoCardSeparator = styled.span<{ isVisible: boolean }>`
    margin-right: ${({ theme }) => theme.tokens.BaseSpace25};
    margin-left: ${({ theme }) => theme.tokens.BaseSpace25};
    border-left: ${({ theme }) => `1px solid ${theme.tokens.BaseColorGray300}`};
    visibility: ${({ isVisible }) => (isVisible ? 'initial' : 'hidden')};
`;

export const StyledVehicleInfoCardActions = styled.div`
    margin-top: ${({ theme }) => theme.tokens.BaseSpace40};
    display: flex;
    align-items: center;
    padding: 0;
    gap: ${({ theme }) => theme.tokens.BaseSpace40};
    position: relative;
`;
