import React from 'react';
import { PageUI } from '@makemydeal/dr-activities-common';
import NoTradeInButton from '../NoTradeInButton/NoTradeInButton';

export const TradePageUI = (props) => {
    return (
        <PageUI {...props}>
            {props.children}
            {props.isSDPRedesignEnabled ? null : <NoTradeInButton />}
        </PageUI>
    );
};
