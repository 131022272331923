import * as React from 'react';
import { Component } from 'react';
import { Input } from '@makemydeal/ui-bricks/dist/cox';
import { SpinnerUI } from '@makemydeal/dr-activities-common';
import { ITradeInSearchItem } from '../../../store';

export interface ISearchInputProps {
    fetchCallback: { (value: string) };
    normalizeCallback?: { (value: string) };
    formatResult: { (item: any, value: string): string };
    selectCallback: { (item: ITradeInSearchItem, currentValue?: string) };
    inputId: string;
    name: string;
    label?: string;
    placeholder?: string;
}

export interface ISearchInputState {
    currentValue: string;
    searchResults: any[];
    focused: boolean;
    activeIndex: number;
    isSearchRunning: boolean;
}

let uniqueId = 0;

class SearchInput extends Component<ISearchInputProps, ISearchInputState> {
    queueSearch: string;
    searchTimeout: any;
    inputEl: HTMLElement;
    blurTimeout: any;

    constructor(props) {
        super(props);
        this.state = {
            isSearchRunning: false,
            currentValue: '',
            searchResults: [],
            focused: false,
            activeIndex: -1
        };
    }

    componentWillUnmount() {
        if (this.blurTimeout) clearTimeout(this.blurTimeout);
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
    }

    componentDidMount() {
        this.inputEl.addEventListener('focus', () => {
            if (this.blurTimeout) clearTimeout(this.blurTimeout);
            this.setState({ focused: true });
        });
        this.inputEl.addEventListener('blur', () => {
            // We need to delay the hide of the results so that clicks can be captured before it goes away
            const blurCb = () => this.setState({ focused: false });
            this.blurTimeout = setTimeout(blurCb, 1000);
        });
        this.inputEl.addEventListener('keydown', this.handleKeyUp);
    }

    handleKeyUp = (event: KeyboardEvent) => {
        const { searchResults } = this.state;
        let { activeIndex } = this.state;
        if (searchResults.length === 0) return;

        switch (event.keyCode) {
            // Up key pressed
            case 38:
                activeIndex = activeIndex - 1;
                if (activeIndex < 0) activeIndex = searchResults.length - 1;
                break;

            // Down key pressed
            case 40:
                activeIndex = activeIndex + 1;
                if (activeIndex >= searchResults.length) activeIndex = 0;
                break;

            // Enter pressed
            case 13:
                if (activeIndex >= 0 && activeIndex < searchResults.length) {
                    this.props.selectCallback(searchResults[activeIndex]);
                }
                return;

            default:
                return;
        }

        event.preventDefault();
        event.stopPropagation();
        this.setState({
            activeIndex
        });
    };

    async runQuery(value: string) {
        if (this.state.isSearchRunning) {
            this.queueSearch = value;
            return;
        }

        this.queueSearch = undefined;
        this.setState({
            isSearchRunning: true
        });

        const response = await this.props.fetchCallback(value);
        let searchResults = [];
        if (response.ok) searchResults = await response.json();
        searchResults.forEach((item) => (item._id = uniqueId++));

        const newState = {
            searchResults,
            activeIndex: -1,
            isSearchRunning: false
        };
        this.setState(newState, () => this.queueSearch && this.fetchDebounced());
    }

    fetchDebounced() {
        const timeoutInMs = 200;
        const fetchCallback = () => this.runQuery(this.state.currentValue);
        if (this.searchTimeout) clearTimeout(this.searchTimeout);
        this.searchTimeout = setTimeout(fetchCallback, timeoutInMs);
    }

    handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { normalizeCallback } = this.props;
        const currentValue = normalizeCallback ? normalizeCallback(event.currentTarget.value) : event.currentTarget.value;
        this.setState({ currentValue }, this.fetchDebounced);
    };

    handleSelectItem = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        const { searchResults } = this.state;
        const selectedId = parseInt(event.currentTarget.getAttribute('data-id'), 10);
        const searchResult = searchResults.find((item) => item._id === selectedId);
        if (!searchResult) return;
        this.props.selectCallback(searchResult, this.props.name === 'vin' && this.state.currentValue);
    };

    renderResults(): JSX.Element {
        const { currentValue, searchResults, focused, activeIndex, isSearchRunning } = this.state;

        const hidden = !focused || !currentValue;
        return (
            <div className={`search-results list-group ${hidden ? 'hidden' : ''}`}>
                {!isSearchRunning ? null : (
                    <div className="list-group-item">
                        <SpinnerUI size="16" />
                    </div>
                )}
                {searchResults.length > 0 || isSearchRunning ? null : <div className="list-group-item">No results found</div>}
                {searchResults.map((item, index) => {
                    return (
                        <button
                            type="button"
                            className={`list-group-item ${activeIndex === index ? 'active' : ''}`}
                            key={item._id}
                            data-id={item._id}
                            onClick={this.handleSelectItem}
                            dangerouslySetInnerHTML={{ __html: this.props.formatResult(item, this.state.currentValue) }}
                        ></button>
                    );
                })}
            </div>
        );
    }

    setRef = (el: HTMLElement) => (this.inputEl = el);

    render(): JSX.Element {
        const { inputId, name, label, placeholder } = this.props;

        return (
            <div className="search-input">
                <Input
                    inputId={inputId}
                    name={name}
                    label={label}
                    ref={this.setRef}
                    className="ui-bricks"
                    placeholder={placeholder}
                    onChangeFunction={this.handleChange}
                />
                <i className="fa fa-search" />
                {this.renderResults()}
            </div>
        );
    }
}

export default SearchInput;
