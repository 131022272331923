import * as React from 'react';
import { Component } from 'react';

import Header from '../header/Header';
import { ActionFooterBar } from '@makemydeal/dr-activities-common';
import RadioButtonUI from '../radioButton/RadioButtonUI';

import drUiToolkit from '@makemydeal/dr-ui-toolkit';
const CurrencyInputUI = drUiToolkit.CurrencyInputUI;

export interface IValuationUIStateProps {
    zip?: string;
    mileage: string;
    condition: string;
    amountOwed: string | number;
    headerSubtitle: string;
    pageTitle: string;
    conditionMileageZipSubtitle: string;
    isIcoTradeIn?: boolean;
}

export interface IValuationUIDispatchProps {
    updateBalanceOwed: { (amountOwed: string | number) };
    next: { () };
    previous: { () };
}

export interface IValuationUIProps extends IValuationUIStateProps, IValuationUIDispatchProps {}

export interface IValuationUIState {
    titleInHand: boolean;
    amountOwed: number | string;
}

class ValuationUI extends Component<IValuationUIProps, IValuationUIState> {
    constructor(props) {
        super(props);
        const { amountOwed } = this.props;
        const titleInHand = !amountOwed;

        this.state = {
            titleInHand,
            amountOwed
        };
    }

    onChangeRadio = () => {
        const titleInHand = !this.state.titleInHand;
        const amountOwed = titleInHand ? 0 : this.state.amountOwed;
        this.props.updateBalanceOwed(amountOwed);
        this.setState({ titleInHand, amountOwed });
    };

    onChangeAmountOwed = (value) => {
        const amountOwed = parseInt(value, 10);
        this.props.updateBalanceOwed(amountOwed);
        this.setState({
            titleInHand: this.state.titleInHand,
            amountOwed
        });
    };

    render() {
        const { headerSubtitle, pageTitle, conditionMileageZipSubtitle, next, previous, amountOwed, isIcoTradeIn } = this.props;
        const { titleInHand } = this.state;
        const showBackButton = isIcoTradeIn ? false : true;

        const barProps = {
            onActionButtonClick: next,
            onBackButtonClick: previous,
            showBackButton,
            ignoreRouterHistory: true,
            buttonText: 'Continue'
        };

        // tslint:disable-next-line: no-boolean-literal-compare
        const titleInHandTrue = titleInHand === true;
        // tslint:disable-next-line: no-boolean-literal-compare
        const titleInHandFalse = titleInHand === false;
        return (
            <div className="dr-ui-vehicle-valuation">
                <Header
                    pageTitle={pageTitle}
                    headerSubTitle={headerSubtitle}
                    pageSubTitle={conditionMileageZipSubtitle}
                    showTradeErrorMessage={false}
                />

                <div className="vehicle-valuation-body">
                    <div className="vehicle-valuation-option-container">
                        <div className="form-row form-group">
                            <div className="radio-button-container">
                                <RadioButtonUI
                                    defaultChecked={titleInHandTrue}
                                    id="titleInHandRadioButton"
                                    name="titleInHand"
                                    label="No, I have the title in hand"
                                    onChange={() => {
                                        this.onChangeRadio();
                                    }}
                                    value={titleInHand}
                                />
                            </div>
                            <div className="radio-button-container">
                                <RadioButtonUI
                                    defaultChecked={titleInHandFalse}
                                    id="amountOwedRadioButton"
                                    name="titleInHand"
                                    label="Yes, I still owe money on this vehicle"
                                    onChange={() => {
                                        this.onChangeRadio();
                                    }}
                                    value={titleInHand}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="vehicle-valuation-option-container">
                        <div className="form-row form-group">
                            {titleInHand ? (
                                ''
                            ) : (
                                <CurrencyInputUI
                                    label={'What is your current loan balance?'}
                                    id="tradeInAmountOwed"
                                    currencyValue={amountOwed}
                                    placeHolder="$0"
                                    onUpdateValue={(owed) => {
                                        this.onChangeAmountOwed(owed);
                                    }}
                                    onBlurValidation={() => null}
                                    isReadOnly={titleInHand}
                                    disableOnReadOnly={true}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <ActionFooterBar {...barProps} />
            </div>
        );
    }
}

export default ValuationUI;
