// NOTE: implementation is borrowed from widget-app
export const validateEmail = (email) => {
    const regex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
    return regex.test(email);
};

// from: http://redux-form.com/6.0.0-rc.3/examples/normalizing/
export const normalizePhone = (value, previousValue) => {
    if (!value) {
        return value;
    }
    const onlyNums = value.replace(/[^\d]/g, '');
    if (!previousValue || value.length > previousValue.length) {
        // typing forward
        if (onlyNums.length === 3) {
            return `${onlyNums}-`;
        }
        if (onlyNums.length === 6) {
            return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}-`;
        }
    }
    if (onlyNums.length <= 3) {
        return onlyNums;
    }
    if (onlyNums.length <= 6) {
        return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    }
    return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export const validateZip = (zip) => (/^[0-9]{5}$/.test(zip) && parseInt(zip, 10) > 0) || zip === '';

export const normalizeZip = (zip) => {
    const newZip = (zip.match(/[0-9]/g) || []).join('');
    return newZip.length <= 5 ? newZip : newZip.substr(0, 5);
};

export const normalizeVin = (vin) => {
    const newVin = vin.replace(/[^a-zA-Z0-9]/g, '');
    return newVin.length <= 17 ? newVin : newVin.substr(0, 17);
};

export const validateContactPreferences = (shopper) => {
    if (shopper && shopper.contactPreferences) {
        const {
            contactPreferences: { email, phone, sms }
        } = shopper;
        return !!email || !!phone || !!sms;
    }
    return false;
};

export const leadMessageNormalizer = (value) => {
    return value.length > 500 ? value.slice(0, 500) : value;
};

export const validateForMileageValues = (mileage: number | null) => {
    if (mileage === null || mileage === undefined) {
        return false;
    } else if (/^[0-9]{1,6}$/.test(mileage.toString()) && mileage > 0) {
        return true;
    } else {
        return false;
    }
};

export const normalizeMileageValues = (mileage) => {
    return (mileage.toString().match(/[0-9]/g) || []).join('');
};
/*
 * The First and last name patterns are from:
 * https://webservices.uat1.dealertrack.com/financedriver/v1.2/lead/schemas/DealerTrack.DataContracts.Lead.xsd
 */
export const validateFirstName = (name) =>
    /^[a-zA-Z]+(([\s,'-.][a-zA-Z ])?[a-zA-Z]*)*$/.test(name) && name.length > 1 && name.length < 16;

export const validateLastName = (name) =>
    /^[a-zA-Z]+(([\s,'-.][a-zA-Z ])?[a-zA-Z]*)*$/.test(name) && name.length > 1 && name.length < 26;
