import type { StateTree } from '@makemydeal/dr-dash-types';
import DealSummaryItem from '../common/DealSummaryItem';
import { useSelector } from 'react-redux';

import * as constants from '../../constants';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { LEASE } from '@makemydeal/dr-platform-types';

const AdjustedSellingPrice = () => {
    const salePrice = useSelector((state: StateTree) => offerReduxSelectors.getVehicleSalePrice(state, LEASE));
    const vehicleSalePrice = formatDollarsAndCents(salePrice);
    return <DealSummaryItem label={constants.ADJUSTED_SELLING_PRICE} value={vehicleSalePrice} />;
};

export default AdjustedSellingPrice;
