import { getGlobalSelector } from './mmd';

export const getRoot = () => getGlobalSelector().additionalSelectors.dealerSelectors;

export const isTradeValuationKbb = (state: any) => getRoot().isTradeValuationKbb(state);
export const microSiteEnabled = (state: any) => getRoot().microSiteEnabled(state);
export const getDealerTradeInValuationProvider = (state: any) => getRoot().getDealerTradeInValuationProvider(state);
export const getDealerState = (state: any) => getRoot().getDealerState(state);
export const isDealerTradeInLeadFormForWebsiteEnabled = (state: any) => getRoot().isDealerTradeInLeadFormForWebsiteEnabled(state);
export const isDealerTradeInLeadFormForListingSiteEnabled = (state: any) =>
    getRoot().isDealerTradeInLeadFormForListingSiteEnabled(state);
export const testDriveAtHomeOnTradeIn = (state: any) => getRoot().testDriveAtHomeOnTradeIn(state);
export const isVppEnabled = (state: any) => getRoot().isVppEnabled(state);
export const getCreditProvider = (state: any) => getRoot().getCreditProvider(state);
export const getDealerPhone = (state: any) => getRoot().getDealerPhone(state);
export const getDealerName = (state: any) => getRoot().getDealerName(state);
export const hasNoTradeOwnership = (state: any) => getRoot().hasNoTradeOwnership(state);
export const isSubaruGtpEnabled = (state: any) => getRoot().isSubaruGtpEnabled(state);
export const getTradeInHideExcellentConditionWebsite = (state: any) => getRoot().getTradeInHideExcellentConditionWebsite(state);
export const getTradeInHideExcellentConditionListingSite = (state: any) =>
    getRoot().getTradeInHideExcellentConditionListingSite(state);
export const isGhostVinDealerEnabled = (state: any) => getRoot().isGhostVinDealerEnabled(state);
