// externals
import { connect } from 'react-redux';

// libraries
import { ITradeInState } from '@makemydeal/dr-activities-common';

// components
import TradeSummaryUI from './SummaryUI';

// interfaces/types
import { ITradeSummaryUIStateProps } from './SummaryUI';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';

const mapStateToProps = (state: any): ITradeSummaryUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
    const source = tradeIn?.source;

    return {
        source
    };
};

const TradeSummary = connect(mapStateToProps)(TradeSummaryUI);

export default TradeSummary;
