import { log } from './loggerUtil';

export function removeEvent(elt, eventType, eventHandler) {
    if (elt.removeEventListener) {
        elt.removeEventListener(eventType, eventHandler, false);
    } else {
        elt.detachEvent(`on${eventType}`, eventHandler);
    }
}

export function addEvent(elt, eventType, eventHandler) {
    if (elt.addEventListener) {
        elt.addEventListener(eventType, eventHandler, false);
    } else {
        elt.attachEvent(`on${eventType}`, eventHandler);
    }
}

// Detects if localStorage or sessionStorage is both supported and available.
// https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
export const storageAvailable = (type) => {
    let storage;
    try {
        storage = window[type];
        const x = `__storage_test__${Date.now()}`;
        storage.setItem(x, x);
        storage.removeItem(x);
        return true;
    } catch (e) {
        return (
            e instanceof DOMException &&
            // everything except Firefox
            (e.code === 22 ||
                // Firefox
                e.code === 1014 ||
                // test name field too, because code might not be present
                // everything except Firefox
                e.name === 'QuotaExceededError' ||
                // Firefox
                e.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
            // acknowledge QuotaExceededError only if there's something already stored
            storage.length !== 0
        );
    }
};

export const checkOfferStorage = (offerStorage) => {
    const checked = offerStorage && offerStorage.vin && offerStorage.offerId && offerStorage.connectionId;

    return !!checked;
};

export const setOfferStorage = (vin, offerId, connectionId, dealerId) => {
    // Set a localStorage item to remember the vin, offerId, connectionId and after
    // an offer is sent.
    const isValidData = vin && connectionId && dealerId;
    let offerStorage;
    try {
        if (!isValidData) {
            throw Error('Missing data to save in local storage');
        }
        offerStorage = JSON.stringify({ vin, offerId, connectionId });
        window.localStorage.setItem(`drs:${dealerId}:${vin}`, offerStorage);
    } catch (error) {
        log(['dealstarter', 'client', 'error', 'localstorage'], null, 'error', 'Error setting local storage value', error);
    }
};

const validateValue = (value) => {
    return !!(value && value !== '');
};

export const preferenceStorage = (vin, connectionId, tradeInValuationProvider) => {
    // Set a localStorage item to remember the vin, connectionId, tradeInValuationProvider and expirationDate after
    // an offer is sent.
    const validData = validateValue(vin) && validateValue(connectionId) && validateValue(tradeInValuationProvider);
    try {
        if (!validData) {
            throw Error('Missing data to save preferences in local storage');
        }

        const drsPreferences = {
            connectionId,
            vin,
            tradeInSource: tradeInValuationProvider,
            tradeInExpiration: undefined
        } as any;

        if (drsPreferences.tradeInSource !== 'Kelley Blue Book') {
            const msInADay = 86400000;
            const newDate = new Date().getTime() + 3 * msInADay; // 3 days from offer
            const expirationDate = new Date(newDate);
            drsPreferences.tradeInExpiration = expirationDate.toUTCString();
        }

        window.localStorage.setItem('drs:preferences', JSON.stringify(drsPreferences));
    } catch (error) {
        log(['shopperPlatform', 'client', 'error', 'preferences'], null, 'error', 'Error setting local storage value', error);
    }
};

export const readPreferenceStorage = () => {
    let preferenceStorage;
    try {
        preferenceStorage = window.localStorage.getItem('drs:preferences');
        if (!preferenceStorage) throw new Error('Failed reading preferences');
        preferenceStorage = JSON.parse(preferenceStorage);
    } catch (error) {
        log(['shopperPlatform', 'client', 'error', 'preferences'], null, 'error', 'Error reading local storage value', error);
    }
    return preferenceStorage;
};

export const setSessionItem = (keyName: any, keyValue: any) => {
    return sessionStorage.setItem(keyName, keyValue);
};

export const getSessionItem = (keyName) => {
    return sessionStorage.getItem(keyName);
};

export const removeSessionItem = (keyName) => {
    return sessionStorage.removeItem(keyName);
};

export const clearStorage = () => {
    return sessionStorage.clear();
};
