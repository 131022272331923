import { PaymentGridRow } from '@makemydeal/dr-platform-types/typedefs/shared/types/paymentGrid';

export const roundValue = (value: string) => {
    if (value === '') return null;
    const number = parseFloat(value);
    if (number === 0) return 0;
    return number < 1 ? 1 : Math.round(number);
};

export const hasTermAndDownpayment = (str: string): boolean => str.includes('term') && str.includes('downpayment');

export const notValidPaymentGridRows = (data: PaymentGridRow[]): boolean => {
    if (!data || data.length === 0) return false;

    const allSubItems = data.flatMap((row) => row.items);
    return allSubItems.every((item) => !item.isLoading && item.isEmpty && item.id && hasTermAndDownpayment(item.id));
};

export const disabledStyle = {
    pointerEvents: 'none',
    opacity: 0.5,
    cursor: 'not-allowed'
};
