import { useSelector, useDispatch } from 'react-redux';

import { Switch } from '@interstate/components/Switch';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';

import { kebabCase } from '@makemydeal/dr-common-utils';
import { CREDIT_DECISION_TOGGLE } from '../../constants';
import { Badge as InterstateBadge } from '@interstate/components/Badge';

const CreditDecisionEnabled = () => {
    const dispatch = useDispatch();
    const selectorValue = useSelector(offerReduxSelectors.getCreditDecisionEnabled);

    const id = kebabCase('CreditDecisionToggle');
    return (
        <div style={{ display: 'flex', alignItems: 'flex-start' }}>
            <Switch
                name={id}
                checked={selectorValue}
                label={CREDIT_DECISION_TOGGLE}
                labelSx={{
                    marginBottom: '16px',
                    width: 'unset'
                }}
                layout="vertical"
                onClick={(e: any) => {
                    e.target.checked = e.target.value === 'checked';
                    dispatch(offerReduxActionCreators.updateCreditDecisionEnabled(e.target.checked)); // is this still used anywhere?
                }}
                data-testid={id}
            />
            <InterstateBadge variant="info" id="beta-badge">
                Beta
            </InterstateBadge>
        </div>
    );
};

export default CreditDecisionEnabled;
