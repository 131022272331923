import * as React from 'react';

export interface IRadionButtonUIState {}

export interface IRadioButtonUIProps {
    dataAnalytics?: string;
    defaultChecked: boolean;
    id: number | string;
    label: string;
    sublabel?: string;
    name: string;
    onChange: any;
    value: any;
}

class RadioButtonUI extends React.Component<IRadioButtonUIProps, IRadionButtonUIState> {
    render() {
        const id = this.props.id ? this.props.id.toString() : '';

        const label = this.props.label ? (
            <label className={this.props.sublabel ? 'radio hasSublabel' : 'radio'} htmlFor={id}>
                {this.props.label}
            </label>
        ) : null;
        const sublabel = this.props.sublabel ? <span className="radio-sublabel">{this.props.sublabel}</span> : null;
        // tslint:disable-next-line: no-boolean-literal-compare
        const defaultChecked = this.props.defaultChecked === true;
        return (
            <div className="radio-ui-container">
                <input
                    type="radio"
                    name={this.props.name}
                    aria-label={this.props.label || this.props.name}
                    onChange={this.props.onChange}
                    id={id}
                    defaultChecked={defaultChecked}
                    value={this.props.value}
                    data-analytics={this.props.dataAnalytics}
                />
                <div className="check"></div>
                <div className="radio-content">
                    {label}
                    {sublabel}
                </div>
            </div>
        );
    }
}

export default RadioButtonUI;
