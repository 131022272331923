import { connect } from 'react-redux';
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { Resources } from '@makemydeal/dr-activities-common';
import config from '../../../store/mmd/config';
import HeaderUI from './HeaderUI';
import { ITradeInHeaderUIStateProps, ITradeInHeaderUIDispatchProps } from './HeaderUI';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as dealerSelectors from '../../../store/mmd/dealerSelectors';
import * as tradeInActionCreators from '../../../store/actionCreators';
import * as offerSelectors from '../../../store/mmd/offerSelectors';
import { isTradeValuationReset } from '../../../store/mmd/tradeIn';
import * as offerInfoSelectors from '../../../store/mmd/offerSelectors';
import { goToSummaryLeadForm, goToDealSummary } from '../../../store/actionCreators';

const mapStateToProps = (state: any, PassedProps: ITradeInHeaderUIStateProps): ITradeInHeaderUIStateProps => {
    const kbbLogo = urlBuilder.buildFromConfig(config.staticImages, Resources.KBB_LOGO_URL);
    const pageTitle = PassedProps.pageTitle || null;
    const pageTip = PassedProps.pageTip || null;
    const headerSubTitle = PassedProps.headerSubTitle || null;
    const showTradeErrorMessage = PassedProps.showTradeErrorMessage || null;
    const tradeSaveFailure = tradeInSelectors.isTradeSaveFailed(state);
    const tradeValuationReset = isTradeValuationReset(state);
    const dealerPhoneNumber = dealerSelectors.getDealerPhone(state);
    const tradePaymentFailed = tradeInSelectors.getTradePaymentFailed(state);

    const offerType = offerSelectors.getCurrentOfferType(state);
    const payment = offerSelectors.getMonthlyPaymentRoundedByOfferType(state, offerType);
    const isPaymentInvalid = offerSelectors.isPaymentInvalid(payment, offerType);
    const isIcoTradeIn = tradeInSelectors.isIcoTradeInNotSiteDriverFlow(state);
    const hasOfferBeenSent = offerInfoSelectors.getHasBeenSent(state);

    return {
        kbbLogo,
        pageTitle,
        pageTip,
        headerSubTitle,
        tradeSaveFailure,
        dealerPhoneNumber,
        showTradeErrorMessage,
        isPaymentInvalid,
        tradeValuationReset,
        tradePaymentFailed,
        isIcoTradeIn,
        hasOfferBeenSent
    };
};

const mapDispatchToProps = (dispatch: any): ITradeInHeaderUIDispatchProps => {
    return {
        applyTradeIn: () => {
            dispatch(tradeInActionCreators.updateTradeInValuation());
        },
        removeTrade: (hasOfferBeenSent) => {
            dispatch(tradeInActionCreators.removeTradeIn());
            if (hasOfferBeenSent) {
                dispatch(goToDealSummary());
            } else {
                dispatch(goToSummaryLeadForm());
            }
        }
    };
};

const HeaderContainer = connect(mapStateToProps, mapDispatchToProps)(HeaderUI);

export default HeaderContainer;
