import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@interstate/components/Grid';
import GridRow from './GridRow';
import TermsMonth from './TermsMonth';
import SellRates from './SellRates';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { PaymentGridRow, TermMonthItem } from '@makemydeal/dr-platform-types';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { ContainerGrid } from './styled.components';
import IsTargetOutOfPocket from '../common/IsTargetOutOfPocket';
import { getKeyWithLoadingItem } from '../common/utils';

const GridTable: FC = () => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const paymentGridItems: PaymentGridRow[] = useSelector(offerReduxSelectors.getPaymentGridItems);
    const paymentGridTermList: TermMonthItem[] = useSelector(offerReduxSelectors.getPaymentGridTermsList);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);
    const isTargetOutOfPocketLeaseEnabled = useSelector(featureToggleSelectors.isTargetOutOfPocketLeaseEnabled);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(getKeyWithLoadingItem(paymentGridItems));
    }, [paymentGridItems]);

    return (
        <ContainerGrid>
            <Grid
                data-testid="payment-grid"
                container
                justifyContent="center"
                alignItems="center"
                height="auto"
                marginTop={isABPencilEnabled ? 0 : '15px'}
                position={'relative'}
                padding={isABPencilEnabled ? '0 20px' : '0 8px'}
                sx={{
                    '& > div:first-of-type': {
                        borderTopLeftRadius: '0.2rem'
                    },
                    '& > div:nth-of-type(4)': {
                        borderTopRightRadius: '0.2rem'
                    }
                }}
            >
                {isTargetOutOfPocketLeaseEnabled && offerType === 'lease' && (
                    <Grid xs={12} sx={{ paddingBottom: '10px' }}>
                        <IsTargetOutOfPocket disabled={true} />
                    </Grid>
                )}
                <TermsMonth paymentGridTermList={paymentGridTermList} isLoading={isLoading} />
                {paymentGridItems.map((item) => (
                    <Grid
                        key={item.key}
                        display="contents"
                        sx={{
                            '& > div:first-of-type': {
                                background: '#f4f4f4'
                            }
                        }}
                    >
                        <GridRow data={item} isLoading={isLoading} />
                    </Grid>
                ))}
                <SellRates isLoading={isLoading} />
            </Grid>
        </ContainerGrid>
    );
};

export default GridTable;
