import * as qs from 'querystring';

export const getUserId = () => {
    const locHref = (document && document.location && document.location.href) || '';
    const locHrefQS = locHref.split('?')[1];
    const obj = qs.parse(locHrefQS);
    const userId = obj && obj.userId;
    return userId;
};

export interface IHeaders {
    [key: string]: string;
}

export const addUserIdHeader = (headers: IHeaders): any => {
    const userId = getUserId();
    if (userId) {
        return {
            ...headers,
            ...{ 'x-dr-user-id': userId }
        };
    }
    return headers;
};
