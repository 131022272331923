// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import type { RSAACallAction, RSAACallRequest } from '@makemydeal/dr-react-18-wrappers';
import type { StateTree } from '@makemydeal/dr-dash-types';

// consts/enums
import {
    CONSUMER_INSIGHTS_FETCH_REQUEST_V2,
    CONSUMER_INSIGHTS_FETCH_SUCCESS_V2,
    CONSUMER_INSIGHTS_FETCH_FAILURE_V2
} from '../actionTypes/consumerInsightsActionTypes';

// utils
import { apiConfigHelper, BffApiUrlResult, locHrefUtil, MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { getOfferGlobalCustomerId, getOfferCommonConsumerId } from '../selectors/offerSelectors';

export const fetchConsumerInsights = (state: StateTree): RSAACallAction => {
    const locHref = locHrefUtil.getLocHref();
    const bffApiUrlResult: BffApiUrlResult = apiConfigHelper.buildBffApiUrl(MANAGER_VIEW_APP_PREFIX, locHref, '');
    const globalCustomerId = getOfferGlobalCustomerId(state);
    const commonConsumerId = getOfferCommonConsumerId(state);
    const endpoint = `${bffApiUrlResult.url}/v2/insights`;

    const payload = {
        globalCustomerId,
        commonConsumerId
    };

    const request: RSAACallRequest = {
        endpoint,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
        types: [CONSUMER_INSIGHTS_FETCH_REQUEST_V2, CONSUMER_INSIGHTS_FETCH_SUCCESS_V2, CONSUMER_INSIGHTS_FETCH_FAILURE_V2]
    };

    return { [RSAA]: request };
};
