import React, { useEffect } from 'react';
import { TRADE_IN_DESCRIPTION_VEHICLE } from '../../../utils';
import {
    CloseBtn,
    Container,
    Header,
    Modal,
    ModalBG,
    ModalBody,
    ModalFooter,
    Row,
    RowContainer
} from './VehicleConditionComponents';

interface VehicleConditionDescriptionModalUIProps {
    isOpen: boolean;
    isExcellentHiddenWebsite: boolean;
    isExcellentHiddenListing: boolean;
    handleClose: () => void;
}

const VehicleConditionDescriptionModalUI: React.FC<VehicleConditionDescriptionModalUIProps> = ({
    isExcellentHiddenWebsite,
    isExcellentHiddenListing,
    isOpen,
    handleClose
}) => {
    useEffect(() => {
        if (document.querySelector('.dr-sp-checkout-main')) {
            if (isOpen) {
                (document.querySelector('.dr-sp-checkout-main') as HTMLElement).style.position = 'unset';
            } else {
                (document.querySelector('.dr-sp-checkout-main') as HTMLElement).style.position = 'relative';
            }
        }
    }, [isOpen]);
    const isHidden = isExcellentHiddenWebsite || isExcellentHiddenListing ? true : false;

    return (
        <Modal id="vc-description-modal" data-open={isOpen} data-testid="vehicle-modal-info">
            <ModalBG />
            <Container>
                <Header>
                    <p>Condition Description</p>
                    <CloseBtn onClick={handleClose} data-testid="vehicle-info-close">
                        <svg
                            viewBox="0 0 24 24"
                            width="24"
                            height="24"
                            stroke="#434447"
                            strokeWidth="2"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="vehicle-condition-modal-close"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </CloseBtn>
                </Header>
                <ModalBody id="modal-body">
                    {TRADE_IN_DESCRIPTION_VEHICLE.filter((category) => (isHidden ? category.title !== 'Excellent' : category)).map(
                        (category) => (
                            <Row className="vcd-modal-body-row" data-hidden={isHidden} key={category.title}>
                                <p className="title">{category.title}</p>
                                <p className="percentage">{category.percentage}</p>
                                <RowContainer>
                                    {category.bullets.map((segment) => (
                                        <div className="row-container-segment" data-hidden={isHidden} key={segment.join('-')}>
                                            {segment.map((bullet) => (
                                                <ul key={bullet}>
                                                    <li>{bullet}</li>
                                                </ul>
                                            ))}
                                        </div>
                                    ))}
                                </RowContainer>
                            </Row>
                        )
                    )}
                </ModalBody>
                <ModalFooter className="vc-description-modal-container-footer">
                    <p>Kelley Blue Book® does not provide values for cars in poor condition</p>
                </ModalFooter>
            </Container>
        </Modal>
    );
};

export default VehicleConditionDescriptionModalUI;
