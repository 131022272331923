// externals
import * as React from 'react';

// libraries
import { IconFactory, Modal, PageUI } from '@makemydeal/dr-activities-common';

// config
import config from '../../../store/mmd/config';

// components
import TradePage from '../TradePage/TradePage';
import RulesUI from './RulesUI';

// consts/enums
import VehicleInfoUI from '../VehicleInfo/VehicleInfoUI';
import { formatUtils } from '@makemydeal/dr-common-utils';
import { analyticsActions } from '../../../store/actions';

// style
import { SubaruEligibilityDiv } from './styles';

export interface ISubaruEligibilityFields {
    amountOwed?: number;
    mileage: number;
    shouldIncludeTradeIn: boolean;
    vin: string;
}

export interface ISubaruEligibilityUIStateProps {
    amountOwed?: number;
    mileage: number;
    model: string;
    vin?: string;
    year: string;
}

export interface ISubaruEligibilityUIDispatchProps {
    onBackButtonClick: { (eligibilityFields: ISubaruEligibilityFields) };
    onNextButtonClick: { (eligibilityFields: ISubaruEligibilityFields) };
    goToRoute: { (route: string): void };
    dispatchAnalytics?: { (type: string, description: string) };
}

export interface ISubaruEligibilityUIProps extends ISubaruEligibilityUIStateProps, ISubaruEligibilityUIDispatchProps {}

export interface ISubaruEligibilityUIState {}

// TODO: In future this should be addressed- Raul extended from VehicleInfoUI because it was a quick solution, but this
//       should be moved to a composition based implementation in future to avoid this type of hack.
class SubaruEligibilityUI extends VehicleInfoUI {
    constructor(props) {
        super(props);
        this.state = {
            continueWasClicked: false,
            validationErrorMessaqe: '',
            showModal: false,
            fieldsValues: {
                mileage: props.mileage || 0,
                vin: props.vin || '',
                amountowed: props.amountOwed || 0,
                hasActiveLoan: props.amountOwed ? true : false
            },
            fieldsChanged: {
                mileage: false,
                vin: false,
                amountowed: false,
                hasActiveLoan: false
            }
        } as any;

        this.setTextInputRef = (element, name) => {
            this[name] = element;
        };
    }
    // override
    componentDidMount() {
        this.props.dispatchAnalytics(analyticsActions.DR_GTRADE_ELIGIBILITY_SCREEN_OPENED, 'Subaru Eligibility Screen entered.');
        return;
    }

    handleVinValidation(vin: string) {
        if (vin?.length && vin?.length === 17) {
            return true;
        }

        return false;
    }

    handleFieldChangeAnalytics(fieldName: string, value: string | boolean = null) {
        switch (fieldName) {
            case 'vin': {
                this.props.dispatchAnalytics(analyticsActions.DR_GTRADE_TRADEIN_VIN_CHANGED, 'Subaru TradeIn VIN changed');
                break;
            }
            case 'mileage': {
                this.props.dispatchAnalytics(
                    analyticsActions.DR_GTRADE_TRADEIN_VEHICLE_MILEAGE_CHANGED,
                    'Subaru TradeIn vehicle MILEAGE changed'
                );
                break;
            }
            case 'hasactiveloan': {
                value
                    ? this.props.dispatchAnalytics(
                          analyticsActions.DR_GTRADE_TRADEIN_FINANCE_TYPE_CHANGED,
                          'Subaru Balance owed updated to YES'
                      )
                    : this.props.dispatchAnalytics(
                          analyticsActions.DR_GTRADE_TRADEIN_FINANCE_TYPE_CHANGED,
                          'Subaru Balance owed updated to NO'
                      );
                break;
            }
            case 'amountowed': {
                this.props.dispatchAnalytics(
                    analyticsActions.DR_GTRADE_TRADEIN_FINANCE_BALANCE_CHANGED,
                    'Subaru TradeIn vehicle BALANCE changed'
                );
                break;
            }
        }
    }

    handleInputOnChange: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
        const { currentTarget } = event;
        const fieldName = currentTarget.getAttribute('name').toLowerCase();
        let value = currentTarget.value;
        const isDollarString = typeof value === 'string' && value.indexOf('$') !== -1;

        if (isDollarString) {
            value = value.split('$')[1].split(',').join('');
        }

        this.updateFieldChanged(fieldName, true);
        this.updateFieldValue(fieldName, value);
    };

    updateFieldValue(fieldName: string, value: string | boolean) {
        const fieldsValues = {
            ...(this.state as any).fieldsValues
        };
        fieldsValues[fieldName] = value;
        this.handleFieldChangeAnalytics(fieldName, value);
        this.setState({ fieldsValues } as any);
    }

    onChangeHandler(propName, domElement) {
        const value = domElement.value === 'Yes' ? true : false;
        this.updateFieldValue(propName, value);
    }

    get isTradeInValid() {
        const { mileage, vin, amountowed, hasActiveLoan } = (this.state as any).fieldsValues;
        const isFormFilled = mileage && this.handleVinValidation(vin) && (hasActiveLoan ? amountowed > 0 : true);

        return isFormFilled;
    }

    getEligibilityFields() {
        // NOTE: See TODO comment above class definition
        const { mileage, vin, amountowed, hasActiveLoan } = (this.state as any).fieldsValues;
        const mileageAsNumber = parseInt(mileage, 10);
        const eligibilityFields = {
            amountOwed: amountowed,
            mileage: mileageAsNumber,
            shouldIncludeTradeIn: hasActiveLoan,
            vin
        };
        return eligibilityFields;
    }

    next = () => {
        if (!this.isTradeInValid) {
            this.setState({ continueWasClicked: true });
            return;
        }

        const { applyToAmountFinanced } = this.state;
        this.props.applyTradeIn && this.props.applyTradeIn(applyToAmountFinanced);
        const eligibilityFields = this.getEligibilityFields();

        // NOTE: See TODO comment above class definition
        (this.props as unknown as ISubaruEligibilityUIDispatchProps).onNextButtonClick(eligibilityFields);
    };

    render() {
        const { year, model, goToRoute, dispatchAnalytics } = this.props as unknown as ISubaruEligibilityUIProps;
        const { mileage, vin, amountowed, hasActiveLoan } = (this.state as any).fieldsValues;

        return (
            <>
                <SubaruEligibilityDiv>
                    <TradePage
                        pageClass="dr-ui-trade-in subaru-eligibility-page"
                        pageTitle={`Your ${year} Subaru ${model} May Be Eligible`}
                        goToRoute={goToRoute}
                        dispatchAnalytics={dispatchAnalytics}
                        footerProps={{
                            onActionButtonClick: () => this.next(),
                            // NOTE: See TODO comment above class definition
                            onBackButtonClick: () =>
                                (this.props as unknown as ISubaruEligibilityUIDispatchProps).onBackButtonClick(
                                    this.getEligibilityFields()
                                ),
                            showBackButton: true,
                            hideBackIcon: true,
                            backButtonBelowActionButton: true,
                            buttonText: 'Next',
                            backButtonText: "I don't have my VIN"
                        }}
                        staticAssetsConfig={config.staticImages}
                        renderDisclaimer={null}
                    >
                        <div className="container-fluid">
                            <div className="row eligibility-text">
                                <p>
                                    The Subaru Guaranteed Trade-In Program (GTP) is tailored specifically so that owners are always
                                    provided with the highest possible trade-in value for their vehicle from Subaru.
                                </p>
                                <p>
                                    {'Submit your vin below to see if your vehicle is eligible for this incredible program. '}
                                    <a
                                        className="btn-link link"
                                        id="dr_trade_subaru_rules_link"
                                        onClick={() => this.setState({ showModal: true } as any)}
                                    >
                                        Click Here For Rules and Eligibility
                                    </a>
                                </p>
                            </div>
                            <div className="row justify-content-center gtp-logo">
                                <IconFactory staticImages={config.staticImages} icon="subaruGtpLogo" className="subaru-gtp-logo" />
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-md-4 col-md-offset-4 col-lg-6 col-lg-offset-3">
                                    <div className="form-row form-group">
                                        {this.getInputField(
                                            'vin',
                                            'Vin',
                                            vin,
                                            true,
                                            'vin-field',
                                            {
                                                type: 'text',
                                                maxlength: 17,
                                                minlength: 17,
                                                inputType: 'vin',
                                                inputmode: 'text'
                                            },
                                            true,
                                            this.handleVinValidation,
                                            'e.g. RTG567908THG',
                                            this.handleInputOnBlur
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-md-4 col-md-offset-4 col-lg-6 col-lg-offset-3">
                                    <div className="form-row form-group">
                                        {this.getInputField(
                                            'mileage',
                                            'Mileage',
                                            mileage,
                                            true,
                                            'mileage-field',
                                            {
                                                type: 'number',
                                                maxlength: 6,
                                                inputType: 'mileage',
                                                pattern: '[0-9]*',
                                                inputmode: 'numeric'
                                            },
                                            true,
                                            this.handleMileageValidation,
                                            'e.g. 105,000',
                                            this.handleInputOnBlur
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="row justify-content-center">
                                <div className="col-xs-12 col-md-4 col-md-offset-4 col-lg-6 col-lg-offset-3">
                                    <div className="form-row form-group">
                                        {this.getSelectField(
                                            'hasActiveLoan',
                                            ['Yes', 'No'],
                                            '',
                                            'Active Loan',
                                            hasActiveLoan ? 'Yes' : 'No',
                                            true
                                        )}
                                    </div>
                                </div>
                                {hasActiveLoan && (
                                    <div className="col-xs-12 col-md-4 col-md-offset-4 col-lg-6 col-lg-offset-3">
                                        <div className="form-row form-group amtOwedInputWrap">
                                            <div>
                                                {this.getInputField(
                                                    'amountOwed',
                                                    'How Much Do You Owe?',
                                                    amountowed,
                                                    true,
                                                    'amount-owed-field',
                                                    {
                                                        type: 'number',
                                                        maxlength: 12,
                                                        inputType: 'amountOwed',
                                                        pattern: '[0-9]*',
                                                        inputmode: 'numeric'
                                                    },
                                                    true,
                                                    (value: number) => value > 0 && formatUtils.isValidNumber(value),
                                                    'e.g. $1000',
                                                    this.handleInputOnBlur
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </TradePage>
                </SubaruEligibilityDiv>
                <Modal
                    show={(this.state as any).showModal}
                    elementToAttachModalTo=".dr-sp-checkout-main"
                    elementToBlur="#checkout-main"
                    cls={'dr_trade_subaru_rules_modal'}
                >
                    <PageUI
                        pageClass="dr_trade_subaru_rules_page"
                        pageTitle="Rules and Eligibility"
                        footerProps={{
                            onActionButtonClick: () => this.setState({ showModal: false } as any),
                            showBackButton: false,
                            buttonText: 'Close',
                            disableActionButton: false
                        }}
                        dispatchAnalytics={dispatchAnalytics}
                    >
                        <RulesUI />
                    </PageUI>
                </Modal>
            </>
        );
    }
}

export default SubaruEligibilityUI;
