// externals
import { connect } from 'react-redux';

// components
import vehicleInfoUI from './VehicleInfoUI';

// libraries
import { navigateTo } from '@makemydeal/dr-activities-common';

// interfaces
import { vehicleInfoUIDispatchProps, vehicleInfoUIStateProps } from './VehicleInfoInterfaces';
import { IRootProps } from '../../../components/RootProps';
import { DECISION_TYPES } from '../../../store/actionCreators';
import { TradeInSubaruGtpFlowErrorType } from '../../../types';

// selectors
import * as tradeInComponentActionCreators from '../../../store/actionCreators';
import * as featureToggleSelectors from '../../../store/featureToggles';
import {
    getSearchResultsForVin,
    getSearchResultsForYMMT,
    getTradeInIsValid,
    getTradeInVehicle,
    getAmountAppliedToFinancing,
    getTradeVehicleNeedsFetch,
    getTradeInIsTrimLoading,
    checkIsSubaruGtpEligible,
    getTradeInAmountOwed,
    getTradeInVehicleMileage,
    getTradeInOwnership
} from '../../../store/mmd/tradeIn';
import * as globalConfigFeatureToggleSelectors from '../../../store/globalConfigFeatureToggles';
import { isSubaruGtpEnabled } from '../../../store/mmd/dealerSelectors';
import { getNextStepInfo, getPrevStepInfo } from '../../../store/mmd/navigationCard';

// utils
import { isShowroomExperience } from '../../../utils/configUtils';
import {
    getExternalTradeInConditions,
    getImprovedTradeInSelectors,
    getUniqueEstimateOption,
    handleNavigationToManualEntry,
    processStepperRoutes,
    tradeInMethod
} from '../../../utils/tradeInDecisionLogic';
import {
    IMPROVED_FLOW_STEPPER_ROUTES,
    OWNERSHIP_OPTIONS,
    TRADE_IN_DECISION,
    TRADE_IN_ICO,
    TRADE_MANUAL_ENTRY,
    TRADE_VEHICLE_CONDITION
} from '../../../utils';
import * as tradeInActionCreators from '../../../store/actionCreators';

const mapStateToProps = (state: any, ownProps: IRootProps): vehicleInfoUIStateProps => {
    const isDevHelperEnabled = featureToggleSelectors.isDevHelperEnabled(state);

    const tradeIn = state.tradeInComponent;
    const isTradeInValid = getTradeInIsValid(tradeIn, state);
    const shouldIncludeTradeIn = getAmountAppliedToFinancing(tradeIn);
    const vehicle = getTradeInVehicle(tradeIn);
    const vin = vehicle.vin;
    const isSearchingForVin = isTradeInValid ? vehicle?.vin : true;
    const isSearchingForYMMT = isTradeInValid ? !vehicle?.vin : false;
    const isTrimLoading = getTradeInIsTrimLoading(tradeIn);
    const isVinFetchRequired = getTradeVehicleNeedsFetch(tradeIn, state);
    const isSalesView = isShowroomExperience(ownProps.config);
    const tradeInAssertionSelectors = getImprovedTradeInSelectors(state, isSalesView);
    const { isShowAppraisalValueScreenSalesView } = tradeInAssertionSelectors;
    const uniqueEstimateOption = getUniqueEstimateOption(tradeInAssertionSelectors, isSalesView);
    const stepperRoutes = processStepperRoutes(state, IMPROVED_FLOW_STEPPER_ROUTES.vehicleInfo, isSalesView);
    const isGtpEligible = checkIsSubaruGtpEligible(state);
    const isSubaruGtpOn = isSubaruGtpEnabled(state);
    const isSubaruGtpImprovedEnabled = globalConfigFeatureToggleSelectors.isSubaruGTPImprovedWorkflowEnabled();
    const amountOwed = getTradeInAmountOwed(tradeIn);
    const mileage = getTradeInVehicleMileage(tradeIn);
    const isLease = getTradeInOwnership(tradeIn) === 'Lease';
    const isSDPRedesignEnabled = ownProps.selector.additionalSelectors.configSelectors.isSDPRedesignEnabled(state);

    const nextStepInfo = getNextStepInfo(state);
    const prevStepInfo = getPrevStepInfo(state);
    const nextStepRoute = nextStepInfo ? nextStepInfo.route : null;
    const prevStepRoute = prevStepInfo ? prevStepInfo.route : null;
    const showBackButton = isSDPRedesignEnabled && prevStepInfo ? true : false;
    const showSkipButton = isSDPRedesignEnabled && nextStepInfo ? true : false;

    const externalTradeInConditions = getExternalTradeInConditions(state);
    return {
        amountOwed,
        externalTradeInConditions,
        isSearchingForVin: !!isSearchingForVin,
        isSearchingForYMMT: !!isSearchingForYMMT,
        getSearchResultsForVin: getSearchResultsForVin(state),
        getSearchResultsForYMMT: getSearchResultsForYMMT(state),
        isTradeInValid,
        shouldIncludeTradeIn,
        isDevHelperEnabled,
        uniqueEstimateOption,
        isShowAppraisalValueScreenSalesView,
        stepperRoutes,
        isTrimLoading,
        isVinFetchRequired,
        isGtpEligible,
        isSubaruGtpOn,
        isSubaruGtpImprovedEnabled,
        vin,
        eligibilityFields: { vin, amountOwed, shouldIncludeTradeIn, mileage },
        isLease,
        isSDPRedesignEnabled,
        nextStepRoute,
        prevStepRoute,
        showBackButton,
        showSkipButton
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: IRootProps): vehicleInfoUIDispatchProps => {
    const isSalesView = isShowroomExperience(ownProps.config);
    return {
        tradeInStarted: () => {
            const loadCb = () => {
                dispatch(tradeInComponentActionCreators.tradeInStarted());
            };
            setTimeout(loadCb, 500);
        },
        next: (route: string, uniqueEstimateOption, isShowAppraisalValueScreenSalesView = false) => {
            if (route !== '') {
                dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(route));
            } else if (uniqueEstimateOption.hasUniqueOption) {
                switch (uniqueEstimateOption.uniqueOptionValue) {
                    case tradeInMethod.ICO:
                        dispatch(tradeInComponentActionCreators.updateTradeInSource(DECISION_TYPES.ICO));
                        dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(TRADE_IN_ICO));
                        dispatch(tradeInComponentActionCreators.sendTradeDecisionClickedAnalytics(DECISION_TYPES.ICO));
                        break;
                    case tradeInMethod.MANUAL_ENTRY:
                        dispatch(tradeInComponentActionCreators.updateTradeInSource(DECISION_TYPES.ENTER_OWN_VALUE));
                        handleNavigationToManualEntry(isSalesView, isShowAppraisalValueScreenSalesView, dispatch);
                        break;
                    case tradeInMethod.STANDARD:
                    default:
                        dispatch(tradeInComponentActionCreators.updateTradeInSource(DECISION_TYPES.TRADE_IN_VALUE));
                        dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(TRADE_VEHICLE_CONDITION));
                        dispatch(tradeInComponentActionCreators.sendTradeDecisionClickedAnalytics(DECISION_TYPES.TRADE_IN_VALUE));
                        break;
                }
            } else {
                dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(TRADE_IN_DECISION));
            }
        },
        tabSelected: (searchBy: string) => {
            dispatch(tradeInComponentActionCreators.trackSearchTypeAnalytics(searchBy));
        },
        setVehicle: (item: any) => {
            dispatch(tradeInComponentActionCreators.handleTradeInSearchSetVehicle(item));
        },
        goToRoute: (route: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(route));
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
        },
        initializeOwnership: () => {
            dispatch(tradeInComponentActionCreators.updatePurchasePreference(OWNERSHIP_OPTIONS.PURCHASE));
        },
        initializeSource: () => {
            dispatch(tradeInComponentActionCreators.updateTradeInSource(DECISION_TYPES.ENTER_OWN_VALUE));
        },
        subaruEligibilityImprovedWorkflowClickMultiEstimateOptions: (eligibilityFields) => {
            const eligibilityFieldsImprovedWorkflow = {
                ...eligibilityFields,
                isUniqueEstimateOption: false,
                fallbackAction: undefined
            };

            eligibilityFieldsImprovedWorkflow.fallbackAction =
                tradeInComponentActionCreators.subaruEligibilityImprovedWorkflowFallback(
                    DECISION_TYPES.ENTER_OWN_VALUE,
                    TRADE_IN_DECISION
                );

            dispatch(tradeInComponentActionCreators.subaruEligibilityImprovedWorkflowClick(eligibilityFieldsImprovedWorkflow));
        },
        subaruEligibilityImprovedWorkflowClickUniqueEstimateOption: (
            eligibilityFields,
            uniqueEstimateOption,
            isShowAppraisalValueScreenSalesView
        ) => {
            const eligibilityFieldsImprovedWorkflow = {
                ...eligibilityFields,
                fallbackAction: undefined,
                isUniqueEstimateOption: true
            };
            switch (uniqueEstimateOption.uniqueOptionValue) {
                case tradeInMethod.ICO:
                    eligibilityFieldsImprovedWorkflow.fallbackAction =
                        tradeInComponentActionCreators.subaruEligibilityImprovedWorkflowFallback(DECISION_TYPES.ICO, TRADE_IN_ICO);
                    break;
                case tradeInMethod.MANUAL_ENTRY:
                    if ((isSalesView && isShowAppraisalValueScreenSalesView) || !isSalesView) {
                        eligibilityFieldsImprovedWorkflow.fallbackAction =
                            tradeInComponentActionCreators.subaruEligibilityImprovedWorkflowFallback(
                                DECISION_TYPES.ENTER_OWN_VALUE,
                                TRADE_MANUAL_ENTRY
                            );
                    }
                    if (isSalesView && !isShowAppraisalValueScreenSalesView) {
                        eligibilityFieldsImprovedWorkflow.fallbackAction =
                            tradeInComponentActionCreators.subaruEligibilityImprovedWorkflowSalesViewNoAppraisalFallback(
                                DECISION_TYPES.ENTER_OWN_VALUE
                            );
                    }
                    break;
                case tradeInMethod.STANDARD:
                default:
                    eligibilityFieldsImprovedWorkflow.fallbackAction =
                        tradeInComponentActionCreators.subaruEligibilityImprovedWorkflowFallback(
                            DECISION_TYPES.TRADE_IN_VALUE,
                            TRADE_VEHICLE_CONDITION
                        );
                    break;
            }
            dispatch(tradeInComponentActionCreators.subaruEligibilityImprovedWorkflowClick(eligibilityFieldsImprovedWorkflow));
        },
        setTradeInIsSubaruGtpEligible: (isEligible) => {
            dispatch(tradeInComponentActionCreators.setTradeInIsSubaruGtpEligible(isEligible));
        },
        setTradeInSubaruGtpFlowLeaseError: () => {
            dispatch(
                tradeInComponentActionCreators.setTradeInSubaruGtpFlowError({
                    errorType: TradeInSubaruGtpFlowErrorType.LEASE_ERROR
                })
            );
        },
        resetTradeInSubaruGtpFlowError: () => {
            dispatch(tradeInComponentActionCreators.resetTradeInSubaruGtpFlowError());
        },
        onNavigationButtonClick: (route: string) => {
            dispatch(navigateTo(route));
        },
        onSkip: (route: string) => {
            dispatch(navigateTo(route));
            dispatch(tradeInActionCreators.tradeInSkipped());
        },
        showZipCodeModalIfApplicable: () => {
            if (ownProps.shouldShowZipModal) dispatch(tradeInComponentActionCreators.showZipCodeModal());
        }
    };
};

const vehicleInfo = connect(mapStateToProps, mapDispatchToProps)(vehicleInfoUI);

export default vehicleInfo;
