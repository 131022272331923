// externals
import { connect } from 'react-redux';

// libraries
import { formatUtils } from '@makemydeal/dr-common-utils';
import { ITradeInState } from '@makemydeal/dr-activities-common';

// components
import ValuationUI from './ValuationUI';

// interfaces/types
import { IValuationUIDispatchProps, IValuationUIStateProps } from './ValuationUI';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as offerInfoSelectors from '../../../store/mmd/offerSelectors';
import {
    isDealerTradeInLeadFormForWebsiteEnabled,
    isDealerTradeInLeadFormForListingSiteEnabled
} from '../../../store/mmd/dealerSelectors';
import { getSponsor } from '../../../store/mmd/widgetSelectors';

// actions
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

// consts/enums
import * as tradeInRoutes from '../../../utils/routes';

// utils
import { isTradeInLeadFormSDPEnabled } from '../../../store/featureToggles';

const mapStateToProps = (state: any): IValuationUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
    const condition = tradeInSelectors.getTradeInVehicleCondition(tradeIn);
    const { vehicle, zip } = tradeIn;
    const vehicleName = `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`;
    const mileage = formatUtils.formatNumber(tradeInSelectors.getTradeInVehicleMileage(tradeIn));
    const conditionMileageZipSubtitle = `${vehicle.condition} Condition | ${mileage} Miles | ${tradeIn.zip}`;
    const amountOwed = tradeInSelectors.getTradeInAmountOwed(tradeIn);
    const pageTitle = `Do you have an outstanding loan on your ${vehicleName}?`;
    const headerSubtitle = 'Step 4 of 5';
    const isIcoTradeIn = tradeInSelectors.isIcoTradeInFlow(state);

    return {
        zip,
        mileage,
        condition,
        amountOwed,
        pageTitle,
        headerSubtitle,
        conditionMileageZipSubtitle,
        isIcoTradeIn
    };
};

const mapDispatchToProps = (dispatch: any): IValuationUIDispatchProps => {
    return {
        updateBalanceOwed: (amountOwed) => {
            dispatch((_dispatch, getState) => {
                const state = getState();
                const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
                let hasAmountOwedChanged = false;

                if (amountOwed !== undefined || tradeIn.saved.amountOwed !== undefined) {
                    hasAmountOwedChanged = amountOwed.toString() !== tradeIn.saved.amountOwed.toString();
                }

                dispatch(tradeInComponentActionCreators.changedTradeIn(hasAmountOwedChanged));
                dispatch(tradeInComponentActionCreators.updateTradeInBalanceOwed(amountOwed));
                dispatch(tradeInComponentActionCreators.financeTypeChanged());
            });
        },
        next: () => {
            dispatch((_dispatch: any, getState: () => any) => {
                dispatch(tradeInComponentActionCreators.tradeInLoanStatusApplied());
                const state = getState();
                const offerHasBeenSent = offerInfoSelectors.getHasBeenSent(state);
                const sponsor = getSponsor(state).toString();
                const isListingDealer = ['atc', 'kbb'].indexOf(sponsor.toLowerCase()) > -1;
                const isWebSiteDealer = !isListingDealer;

                if (isTradeInLeadFormSDPEnabled(state)) {
                    if (
                        !offerHasBeenSent &&
                        ((isWebSiteDealer && isDealerTradeInLeadFormForWebsiteEnabled(state)) ||
                            (isListingDealer && isDealerTradeInLeadFormForListingSiteEnabled(state)))
                    ) {
                        dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN_LEADFORM));
                    } else {
                        dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_EQUITY));
                    }
                } else {
                    dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_EQUITY));
                }
            });
        },
        previous: () => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS));
        }
    };
};

const Valuation = connect(mapStateToProps, mapDispatchToProps)(ValuationUI);

export default Valuation;
