import { connect } from 'react-redux';

// libraries
import isEqual_ from 'lodash.isequal';
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';

// consts/enums
import { CALLSITE_VEHICLEINFO_V4_1, CALLSITE_VEHICLEINFO_V4_2 } from '../../../utils/constants';
import { Actions as actionTypes } from '../../../store/actions';

// components
import TradeInVehicleInfoModalUI from './TradeInVehicleInfoModalUI';

// selectors
import { isTradeInVehicleInfoModalVisible } from '../../../store/mmd/modalSelectors';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';

// actions
import * as tradeInActionCreators from '../../../store/actionCreators';

const isEqual = isEqual_;

const mapStateToProps = (state: any) => {
    const isVisible = isTradeInVehicleInfoModalVisible(state);

    const title = 'Tell Us About Your Trade-in Vehicle';
    const description = 'Enter the details of your vehicle below';

    const isRequestInProgress = tradeInSelectors.isOemInfoRequestInProgress(state);
    const isRequestFailed = tradeInSelectors.isOemInfoRequestFailed(state);
    const errorMessage = isRequestFailed ? 'Looks like we are having trouble saving trade-in.' : '';

    const tradeIn = state.tradeInComponent;
    const years = tradeInSelectors.getTradeInLookupYears(tradeIn);
    const makes = tradeInSelectors.getTradeInLookupMakes(tradeIn);
    const models = tradeInSelectors.getTradeInLookupModels(tradeIn);
    const trims = tradeInSelectors.getTradeInLookupTrims(tradeIn);

    const isOemTradeInValid = tradeInSelectors.getOemTradeInIsValid(tradeIn);

    const isMakeEnabled = tradeInSelectors.getTradeInIsMakeEnabled(tradeIn);
    const isMakeLoading = tradeInSelectors.getTradeInIsMakeLoading(tradeIn);

    const isModelEnabled = tradeInSelectors.getTradeInIsModelEnabled(tradeIn);
    const isModelLoading = tradeInSelectors.getTradeInIsModelLoading(tradeIn);

    const isTrimEnabled = tradeInSelectors.getTradeInIsTrimEnabled(tradeIn);
    const isTrimLoading = tradeInSelectors.getTradeInIsTrimLoading(tradeIn);
    const isTrimSelected = tradeInSelectors.getTradeInIsTrimSelected(tradeIn);

    const isYearEnabled = tradeInSelectors.getTradeInIsYearEnabled(tradeIn);
    const isYearLoading = tradeInSelectors.getTradeInIsYearLoading(tradeIn);

    const vehicle = tradeInSelectors.getTradeInVehicle(tradeIn);

    return {
        description,
        errorMessage,
        isRequestInProgress,
        isVisible,
        title,

        isMakeEnabled,
        isMakeLoading,
        isModelEnabled,
        isModelLoading,
        isOemTradeInValid,
        isTrimEnabled,
        isTrimLoading,
        isTrimSelected,
        isYearEnabled,
        isYearLoading,

        years,
        makes,
        models,
        trims,
        vehicle
    };
};

const updateVehicleProp = (vehicle: ITradeInVehicle, prop, elem, subProps): ITradeInVehicle => {
    const { value, text } = elem;
    switch (prop) {
        case 'make':
            vehicle.model = {
                id: '',
                name: ''
            };
            vehicle.trim = {
                id: '',
                name: '',
                vehicleId: ''
            };
            break;
        case 'model':
            vehicle.trim = {
                id: '',
                name: '',
                vehicleId: ''
            };
            break;

        default:
            break;
    }
    if (subProps) {
        return {
            ...vehicle,
            [prop]: {
                ...vehicle[prop],
                id: value,
                name: text
            }
        };
    } else {
        return {
            ...vehicle,
            [prop]: value
        };
    }
};

const mapDispatchToProps = (dispatch) => {
    return {
        hideModal: () => {
            // This function is now called when mouse click outside of the modal and on the dashboard.
            // Do nothing for now as we don't want the modal to disappear for this action.
        },
        onClickDone: () => {
            dispatch({ type: actionTypes.OEM_TRADE_IN_UPDATE });
        },
        onLoad: (vehicle, years, makes, models, trims) => {
            if (years.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInYears(undefined));
            }
            if (vehicle.year && makes.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInMakes(vehicle.year, undefined));
            }
            if (vehicle.year && vehicle.make.id && models.length === 0) {
                dispatch(
                    tradeInActionCreators.fetchTradeInModels(vehicle.year, vehicle.make.id, undefined, CALLSITE_VEHICLEINFO_V4_1)
                );
            }
            if (vehicle.year && vehicle.make.id && vehicle.model.id && trims.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInTrims(vehicle.year, vehicle.make.id, vehicle.model.id, undefined));
            }
        },

        onValueChange: (propName, domElement, vehicle, savedVehicle) => {
            const hasVehicleChanged = !isEqual(vehicle, savedVehicle);
            dispatch(tradeInActionCreators.changedTradeIn(hasVehicleChanged));

            switch (propName) {
                case 'year': {
                    if (domElement) {
                        const vehicleWithUpdatedYear = updateVehicleProp(vehicle, propName, domElement, false);
                        dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedYear));
                        dispatch(tradeInActionCreators.fetchTradeInMakes(vehicleWithUpdatedYear.year, undefined));
                        dispatch(tradeInActionCreators.clearYMMT({ make: true, model: true, trim: true }));
                    } else {
                        console.warn(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;
                }

                case 'make': {
                    if (domElement) {
                        const vehicleWithUpdatedMake = updateVehicleProp(vehicle, propName, domElement, true);
                        dispatch(
                            tradeInActionCreators.fetchTradeInModels(
                                vehicleWithUpdatedMake.year,
                                vehicleWithUpdatedMake.make.id,
                                undefined,
                                CALLSITE_VEHICLEINFO_V4_2
                            )
                        );
                        dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedMake));
                        dispatch(tradeInActionCreators.clearYMMT({ model: true, trim: true }));
                    } else {
                        console.warn(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }

                    break;
                }

                case 'model': {
                    if (domElement) {
                        const vehicleWithUpdatedModel = updateVehicleProp(vehicle, propName, domElement, true);
                        dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedModel));
                        dispatch(
                            tradeInActionCreators.fetchTradeInTrims(
                                vehicleWithUpdatedModel.year,
                                vehicleWithUpdatedModel.make.id,
                                vehicleWithUpdatedModel.model.id,
                                undefined
                            )
                        );
                        dispatch(tradeInActionCreators.clearYMMT({ trim: true }));
                    } else {
                        console.warn(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;
                }

                case 'trim':
                    if (domElement) {
                        const safeElement = { value: domElement?.value, text: domElement?.text };
                        dispatch((_dispatch, getState) => {
                            const vehicleUpdatedTrim = updateVehicleProp(vehicle, propName, safeElement, true);
                            vehicleUpdatedTrim.trim.vehicleId = vehicleUpdatedTrim.trim ? vehicleUpdatedTrim.trim.id : '';
                            dispatch(tradeInActionCreators.updateVehicle(vehicleUpdatedTrim));
                        });
                    } else {
                        throw new Error(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;

                default:
                    break;
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TradeInVehicleInfoModalUI);
