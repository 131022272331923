export const formatYMMT = (item: any, value: string) => {
    let highlight = item.displayName;
    const tokens = value
        .split(' ')
        .map((item) => item.trim())
        .filter((item) => item.length > 0);

    const escapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    const mapArray = [];
    const buildStringMap = (str) => {
        mapArray.push(str);
        return `{${mapArray.length - 1}}`;
    };
    tokens.forEach((token) => (highlight = highlight.replace(new RegExp(token, 'gi'), buildStringMap)));
    mapArray.forEach((token, index) => {
        highlight = highlight.replace(new RegExp(escapeRegExp(`{${index}}`), 'g'), `<span>${token}</span>`);
    });
    return highlight;
};