// externals
import * as React from 'react';
import { CardSelectButtonsUI, Modal, PageUI } from '@makemydeal/dr-activities-common';

// config
import config from '../../../store/mmd/config';

import TradePage from '../../../common/components/TradePage/TradePage';
import DevHelperTradeAutofill from '../../../common/components/devHelperTradeAutofill/DevHelperTradeAutofill';
import { DECISION_TYPES } from '../../../store/actionCreators';
import { IMPROVED_FLOW_STEPPER_ROUTES } from '../../../utils/constants';
import { ISubaruEligibilityFields } from '../../../common/components/subaruEligibility/SubaruEligibilityUI';
import RulesUI from '../../../common/components/subaruEligibility/RulesUI';
import { ITradeInSubaruGtpFlowError } from '../../../types';

export interface ITradeInDecisionUIStateProps {
    pageTitle: string;
    pageSubTitle: string;
    pageSubtitleLink: string;
    renderDisclaimer?: { () };
    headerImageKBB?: string;
    headerImageGTP?: string;
    isDevHelperEnabled: boolean;
    isShopperEnteredTradeEnabled: boolean;
    optionsToShow?: number[];
    selectedOption?: string;
    selectedOptionIndex?: number;
    dealerName?: string;
    isSalesView?: boolean;
    isShowAppraisalValueScreenSalesView?: boolean;
    eligibilityFields: ISubaruEligibilityFields;
    isSDPRedesignEnabled: boolean;
    isSubaruGtpEligible: boolean;
    tradeInSubaruGtpFlowError: ITradeInSubaruGtpFlowError;
}

export interface ITradeInDecisionUIDispatchProps {
    goToICOFlow: { (option: string) };
    goToKBBEstimate: { (option: string) };
    goToTradeManualEntryFlow: { (option: string, isSalesView?: boolean, isShowAppraisalValueScreenSalesView?: boolean) };
    trackOption: { (option: string, isShopperEnteredTrade: boolean) };
    dispatchImpressionTag: { () };
    updateSource: { (source: string) };
    goToRoute: { (route: string): void };
    goToGTPFlow: {
        (option: string, eligibilityFields: ISubaruEligibilityFields): void;
    };
    subaruGoBackAndRetry: { () };
    dispatchAnalytics: { (type: string, description: string): void };
    removeTrade: { () };
    goBack: { () };
}

export interface ITradeInDecisionUIProps extends ITradeInDecisionUIStateProps, ITradeInDecisionUIDispatchProps {}

export interface ITradeInDecisionUIState {
    selectedOptionIndex: number;
    selectedOption: string;
    showModal: boolean;
}

export const upperCase = (sentence) => sentence.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

const ICOOptionCard = (props) => {
    const id = 'option_0';
    const checked = props.selectedOption;
    const visibleLabel: any = (
        <div className="card-title-container">
            <img className="kbb-logo" src={props.headerImageKBB} />
            <span>{props.label}</span>
        </div>
    );

    const description =
        // eslint-disable-next-line max-len
        "Get an accurate offer for your vehicle based on it's condition and equipment. Offer is valid for 7 days, pending inspection.";

    return (
        <CardSelectButtonsUI
            id={id}
            data-testid={`${props.label}-option`}
            panelId={`panel_${id}`}
            headingText={visibleLabel}
            bodyText={description}
            onClick={props.onClick}
            selected={checked}
        ></CardSelectButtonsUI>
    );
};
const KBBOptionCard = (props) => {
    const id = 'option_1';
    const checked = props.selectedOption;

    const visibleLabel: any = (
        <>
            <img className="kbb-logo" src={props.headerImageKBB} />
            <span>Estimate</span>
        </>
    );

    return (
        <CardSelectButtonsUI
            id={id}
            data-testid={`${props.label}-option`}
            panelId={`panel_${id}`}
            headingText={visibleLabel}
            bodyText={"Get a general estimate for your vehicle based on it's condition and equipment."}
            onClick={props.onClick}
            selected={checked}
        ></CardSelectButtonsUI>
    );
};
const AppraisalOptionCard = (props) => {
    const id = 'option_2';
    const checked = props.selectedOption;

    const description = `Tell us a few things about your trade-in and we'll help you get a quote from ${props.dealerName}.`;

    return (
        <CardSelectButtonsUI
            id={id}
            data-testid={`${props.label}-option`}
            panelId={`panel_${id}`}
            headingText={'Submit for Appraisal'}
            bodyText={description}
            onClick={props.onClick}
            selected={checked}
        ></CardSelectButtonsUI>
    );
};
const GTPOptionCard = (props) => {
    const id = 'option_3';
    const checked = props.selectedOption;

    const visibleLabel: any = (
        <>
            <img className="gtp-logo" src={props.headerImageGTP} />
            <span>Subaru GTP</span>
        </>
    );

    const component = (
        <>
            The Subaru Guaranteed Trade-In Program (GTP) is tailored specifically so that owners are always provided with the
            highest possible trade-in value for their vehicle from Subaru.{' '}
            <a data-testid="gtp-rules-link" id="gtp-rules-link" className="primary-link" onClick={props.onRulesClick}>
                Rules and Eligibility
            </a>
        </>
    );

    return (
        <CardSelectButtonsUI
            id={id}
            data-testid={`${props.label}-option`}
            panelId={`panel_${id}`}
            headingText={visibleLabel}
            bodyText={''}
            onClick={props.onClick}
            selected={checked}
        >
            {component}
        </CardSelectButtonsUI>
    );
};
export const decisionOptions = [
    {
        index: 0,
        label: upperCase(DECISION_TYPES.ICO),
        component: ICOOptionCard
    },
    {
        index: 1,
        label: upperCase(DECISION_TYPES.TRADE_IN_VALUE),
        component: KBBOptionCard
    },
    {
        index: 2,
        label: upperCase(DECISION_TYPES.ENTER_OWN_VALUE),
        component: AppraisalOptionCard
    },
    {
        index: 3,
        label: upperCase(DECISION_TYPES.SUBARU_GTP),
        component: GTPOptionCard
    }
];

class TradeInDecisionUI extends React.Component<ITradeInDecisionUIProps, ITradeInDecisionUIState> {
    constructor(props) {
        super(props);
        const { selectedOption, selectedOptionIndex } = this.props;
        this.state = { selectedOptionIndex, selectedOption, showModal: false };
    }

    componentDidMount() {
        this.props.dispatchImpressionTag();
    }

    onClickTradeInOption = (event) => {
        const { trackOption, isShopperEnteredTradeEnabled } = this.props;
        const optionId = event.currentTarget.getAttribute('data-id');
        const id = Number(optionId.split('_')[1]);
        const option = decisionOptions[id].label;
        this.setState(
            {
                selectedOptionIndex: id,
                selectedOption: option
            },
            trackOption(option.toLowerCase(), isShopperEnteredTradeEnabled)
        );
    };

    onShowModalClick() {
        return (() => {
            this.setState({
                showModal: true
            });
        }).bind(this);
    }

    next = () => {
        const selectedOption = this.state.selectedOption.toLowerCase();
        const { isSalesView, isShowAppraisalValueScreenSalesView } = this.props;
        this.props.updateSource(selectedOption);

        switch (selectedOption) {
            case DECISION_TYPES.ENTER_OWN_VALUE:
                this.props.goToTradeManualEntryFlow(
                    DECISION_TYPES.ENTER_OWN_VALUE,
                    isSalesView,
                    isShowAppraisalValueScreenSalesView
                );
                break;
            case DECISION_TYPES.ICO:
                this.props.goToICOFlow(DECISION_TYPES.ICO);
                break;
            case DECISION_TYPES.SUBARU_GTP:
                this.props.goToGTPFlow(DECISION_TYPES.SUBARU_GTP, this.props.eligibilityFields);
                break;
            case DECISION_TYPES.TRADE_IN_VALUE:
            default:
                this.props.goToKBBEstimate(DECISION_TYPES.TRADE_IN_VALUE);
                break;
        }
    };

    getPageContainer = () => {
        return (
            <div className="decision-page-container">
                {this.props.pageSubTitle ? (
                    <p className="subheader-text">
                        {this.props.pageSubTitle}
                        {this.props.pageSubtitleLink && (
                            <a data-testid="dr-gtp-retry" className="primary-link" onClick={this.props.subaruGoBackAndRetry}>
                                {this.props.pageSubtitleLink}
                            </a>
                        )}
                    </p>
                ) : (
                    <p className="decision-header-text">Next, choose a method to find the value of your vehicle.</p>
                )}
            </div>
        );
    };

    buildOptionCard = (option) => {
        const isSelected = this.state.selectedOptionIndex === option;
        const component = React.createElement(decisionOptions[option].component, {
            selectedOption: isSelected,
            headerImageKBB: this.props.headerImageKBB,
            onClick: this.onClickTradeInOption,
            dealerName: this.props.dealerName,
            headerImageGTP: this.props.headerImageGTP,
            onRulesClick: this.onShowModalClick(),
            label: decisionOptions[option].label
        });
        return component;
    };

    render() {
        const {
            pageTitle,
            isDevHelperEnabled,
            dispatchAnalytics,
            isSDPRedesignEnabled,
            goBack,
            removeTrade,
            isSubaruGtpEligible,
            tradeInSubaruGtpFlowError
        } = this.props;
        const index = this.state.selectedOptionIndex;

        const elementToAttachModalTo = isSDPRedesignEnabled ? '.checkout-wizard-layout__wrapper' : '.dr-sp-checkout-main';
        const elementToModalBlur = isSDPRedesignEnabled ? '.checkout-wizard-layout__container' : '#checkout-main';

        const getNextButtonText = (): string => {
            if (!isSDPRedesignEnabled) {
                return 'Next';
            }
            const selectedOption = this.state.selectedOption.toLowerCase();
            if (selectedOption === DECISION_TYPES.SUBARU_GTP) {
                return 'Continue to Summary';
            } else if (selectedOption === DECISION_TYPES.TRADE_IN_VALUE) {
                return 'Continue to Condition';
            } else {
                return 'Continue to Details';
            }
        };

        return (
            <>
                <TradePage
                    pageClass="improved-tradein-workflow-decision-page"
                    pageTitle={pageTitle}
                    headerOnTop={isSDPRedesignEnabled}
                    showCustomTitle={isSDPRedesignEnabled}
                    isSubaruGtpEligible={isSubaruGtpEligible}
                    tradeInSubaruGtpFlowError={tradeInSubaruGtpFlowError}
                    pageSubTitle=""
                    footerProps={{
                        onActionButtonClick: this.next,
                        showBackButton: isSDPRedesignEnabled,
                        buttonText: getNextButtonText(),
                        disableActionButton: index === -1,
                        fullWidthLayout: isSDPRedesignEnabled,
                        showSkipButton: isSDPRedesignEnabled,
                        skipButtonText: 'Cancel Trade-In',
                        onSkipButtonClick: removeTrade,
                        onBackButtonClick: goBack
                    }}
                    renderDisclaimer={this.props.renderDisclaimer}
                    stepperRoutes={IMPROVED_FLOW_STEPPER_ROUTES.estimateMethod}
                    showTradeErrorMessage={false}
                    staticAssetsConfig={config.staticImages}
                    hideDisclaimerImg={true}
                    stepLabelVisible={true}
                    isStepperClickable={true}
                    goToRoute={this.props.goToRoute}
                >
                    {this.getPageContainer()}

                    <div className="card-select-grid">
                        <div className={`decision-card-select-row ${this.props.optionsToShow.length % 2 ? 'odd' : 'even'}`}>
                            {this.props.optionsToShow.map((option) => this.buildOptionCard(option))}
                        </div>
                        {isDevHelperEnabled && <DevHelperTradeAutofill />}
                    </div>
                </TradePage>
                <Modal
                    show={this.state.showModal}
                    elementToAttachModalTo={elementToAttachModalTo}
                    elementToBlur={elementToModalBlur}
                    cls="dr_trade_subaru_rules_modal"
                >
                    <PageUI
                        pageClass="dr_trade_subaru_rules_page"
                        pageTitle="Rules and Eligibility"
                        footerProps={{
                            onActionButtonClick: () => this.setState({ showModal: false }),
                            showBackButton: false,
                            buttonText: 'Close',
                            disableActionButton: false
                        }}
                        dispatchAnalytics={dispatchAnalytics}
                    >
                        <RulesUI />
                    </PageUI>
                </Modal>
            </>
        );
    }
}

export default TradeInDecisionUI;
