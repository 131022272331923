// externals
import * as React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';

// libraries
import { IconFactory } from '@makemydeal/dr-activities-common';

// config
import config from '../../../store/mmd/config';

// interfaces/constants
import { Rules, IRule } from './constants';

export const SubaruEligibilityRules = styled.div`
    .selectable-vehicle-options {
        display: block;

        .dr_trade_subaru_rules--medium-margin {
            margin-left: ${rem(25)};
        }
    }
    .vehicle-option-groups {
        margin-bottom: ${rem(40)};
    }
`;

const RulesUI: React.FC = () => {
    const [expandSections, setExpandSections] = React.useState({});

    const windowAsAny = window as any;
    const isIE = windowAsAny.detectIE && windowAsAny.detectIE();

    const toggleExpandSection = (event: React.MouseEvent) => {
        const optionName = event.currentTarget.getAttribute('data-option-name');

        setExpandSections({
            ...expandSections,
            [optionName]: !expandSections[optionName]
        });
    };

    const renderRules = (rule: IRule) => {
        const key = rule.name;
        const sectionIsExpanded = expandSections[key];
        const outerDivClass = isIE ? 'row' : 'selectable-vehicle-options';

        return (
            <div key={key} className="vehicle-option-group">
                <div className="vehicle-option-flexbox" data-option-name={key} onClick={toggleExpandSection}>
                    <div className="vehicle-option-header common-header">{key}</div>
                    <IconFactory
                        icon="plus"
                        className={`toggle-expand ${sectionIsExpanded ? 'hide' : ''}`}
                        staticImages={config.staticImages}
                    />
                    <IconFactory
                        icon="minus"
                        className={`toggle-expand ${sectionIsExpanded ? '' : 'hide'}`}
                        staticImages={config.staticImages}
                    />
                </div>

                <div data-testid={key} className={`${outerDivClass} ${sectionIsExpanded ? '' : 'hide'}`}>
                    <div dangerouslySetInnerHTML={{ __html: rule.rule }} />
                </div>
            </div>
        );
    };

    return (
        <SubaruEligibilityRules>
            <p>
                The Subaru Guaranteed Trade-In Program ("GTP") Value is an exclusive offer — only for Subaru owners — that
                guarantees the trade-in amount you will receive for your qualified Subaru vehicle when you purchase a new
                later-model-year Subaru vehicle. The GTP Value is available beginning after the first 12 months of ownership, for a
                total period of 8 years from the vehicle's original warranty start date. "Qualified vehicle" is defined as
                conforming to the mileage and condition criteria listed below. "Trade-In value" is what you can expect to receive
                from a local Subaru retailer for a trade-in vehicle with standard manufacturer's equipment and a good condition
                rating. The new Subaru being purchased must be at least one model year newer than the trade-in vehicle.
            </p>
            <p>
                Current GTP Values are available for eligible Subaru model years and will be updated on a monthly basis. You will
                have the ability to access this website, subaru.com/gtp, to get your vehicle's current trade-in value.
            </p>
            <div className="vehicle-option-groups">{Rules.map(renderRules)}</div>
            <p>For questions or comments, please contact your Subaru Retailer.</p>
        </SubaruEligibilityRules>
    );
};

export default RulesUI;
