import * as React from 'react';

export interface ICheckboxUIState {}

export interface ICheckboxUIProps {
    children?: any;
    dataAnalytics?: string;
    defaultChecked: boolean;
    id: any;
    label: string;
    name: string;
    onChange: any;
    value: any;
}

class CheckboxUI extends React.Component<ICheckboxUIProps, ICheckboxUIState> {
    render() {
        // tslint:disable-next-line: no-boolean-literal-compare
        const defaultChecked = this.props.defaultChecked === true;
        const id = this.props.id.toString();
        return (
            <div className="equipment-checkbox-ui-container">
                <input
                    type="checkbox"
                    name={this.props.name}
                    onChange={this.props.onChange}
                    id={id}
                    defaultChecked={defaultChecked}
                    value={this.props.value}
                    data-analytics={this.props.dataAnalytics}
                />
                <div className="check"></div>
                <label className="checkbox" htmlFor={id}>
                    {this.props.label}
                </label>
                {this.props.children}
            </div>
        );
    }
}

export default CheckboxUI;
