import styled from 'styled-components';

export const MobileIncentiveItems = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`;

export const MobileIncentiveItem = styled.div`
    display: flex;
    justify-content: space-between;

    & > div:last-of-type {
        text-align: right;
    }
`;
