// libraries
import { TIV_SOURCE } from '@makemydeal/dr-activities-common';

// consts/enums
import { Actions as types } from '../../../store/actions';

const DEFAULT_DR_ACTION = {
    tradeInAction: types.ICO_TRADE_IN_NO_VALUE,
    next: types.ROUTE_EXIT_SHELL
};

export default (source) => ({
    CLEARED: {
        tradeInAction: types.ICO_TRADE_IN_SUCCESS,
        next: source === TIV_SOURCE.SITEDRIVER ? types.ROUTE_TRADE_IN_VALUATION : types.ROUTE_TRADE_IN_ICO_VALUATION
    },
    FR: {
        tradeInAction: types.ICO_TRADE_IN_PENDING,
        next: types.ROUTE_EXIT_SHELL
    },
    INELIGIBLE: DEFAULT_DR_ACTION,
    EXPIRED: DEFAULT_DR_ACTION,
    INSPECTION: DEFAULT_DR_ACTION,
    AUCTION: DEFAULT_DR_ACTION,
    CONFIRMED: DEFAULT_DR_ACTION,
    GROUNDED: DEFAULT_DR_ACTION,
    PURCHASED: DEFAULT_DR_ACTION,
    RECEIVED: DEFAULT_DR_ACTION,
    SOLD: DEFAULT_DR_ACTION,
    MODIFIED: DEFAULT_DR_ACTION,
    ACTIVE: DEFAULT_DR_ACTION,
    CHECK: DEFAULT_DR_ACTION
});
