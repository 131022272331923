// externals
import * as React from 'react';
import { Component } from 'react';

// libraries
import { INextStepCardDetails, ITradeInState, IconFactory, PageUI, Modal } from '@makemydeal/dr-activities-common';
import { Select, Option, Input } from '@makemydeal/ui-bricks/dist/cox';
import { formatDollars } from '@makemydeal/dr-common-utils';

// config
import config from '../../../store/mmd/config';

// utils
import { milesToStringShort } from '../../../store/mmd/uiHelpersUtil';

export interface ITradeSummaryUIState {
    hasActiveLoan: string;
    amountOwed: number;
    showModal: boolean;
}

export interface ITradeSummaryUIStateProps {
    tradeIn: ITradeInState;
    imageUrl: string;
    hasIcoValue?: boolean;
    cardDetails: INextStepCardDetails;
    showShareDeal: boolean;
}

export interface ITradeSummaryUIDispatchProps {
    goToRoute: { (route: string) };
    editTradeIn: { () };
    updateBalanceOwed: { (amountOwed: number) };
    dispatchAnalytics: { (type: string, description: string) };
    saveTradeIn: { (option: string) };
    dispatchImpressionTag: { () };
    trackActiveLoanChanging: { (option: string) };
    onBlurAmountOwed: { () };
}

export interface ITradeSummaryUIProps extends ITradeSummaryUIStateProps, ITradeSummaryUIDispatchProps {}

enum LOAN_OPTIONS {
    NO = 'No',
    YES = 'Yes'
}

class ICOSuccessUI extends Component<ITradeSummaryUIProps, ITradeSummaryUIState> {
    constructor(props) {
        super(props);
        this.state = {
            hasActiveLoan: LOAN_OPTIONS.NO,
            amountOwed: 0,
            showModal: this.props.hasIcoValue
        };
    }

    handlePencilEditClicked = () => {
        this.props.editTradeIn();
    };

    handleHasActiveLoanChange = (event) => {
        const {
            target: { value }
        } = event || {};
        this.setState({ hasActiveLoan: event && value }, this.props.trackActiveLoanChanging(value));
    };

    onChangeAmountOwed = (event) => {
        const value = event.currentTarget.value;
        if (isNaN(value)) return;
        this.props.updateBalanceOwed(Number(value));
        this.setState({ amountOwed: value });
    };

    onSubmitCTA = () => {
        this.setState({ showModal: false });
        this.props.saveTradeIn(this.state.hasActiveLoan);
    };

    render() {
        const { staticImages } = config;
        const { imageUrl, tradeIn, goToRoute, cardDetails, dispatchAnalytics, showShareDeal } = this.props;
        const { hasActiveLoan, amountOwed } = this.state;
        const {
            vehicle: { year, make, model, condition, mileage },
            value,
            zip
        } = tradeIn || {};

        const newAmount = value - amountOwed;

        const equityClassName =
            Math.sign(newAmount) === -1 ? 'estimated-value__content--negative-value' : 'estimated-value__content--value';

        return (
            <div className="mountDiv">
                <PageUI
                    pageClass="ico-success-page"
                    pageTitle={
                        <>
                            Your Kelley Blue Book<sup>®</sup> Instant Cash Offer has been added!
                        </>
                    }
                    pageSubTitle="We've applied your Instant Cash Offer amount to your deal"
                    showShareDeal={showShareDeal}
                    staticAssetsConfig={staticImages}
                    cardDetails={cardDetails}
                    goToRoute={goToRoute}
                    dispatchAnalytics={dispatchAnalytics}
                >
                    <div className="trade-in-summary-content">
                        <div className="trade-in-estimate">
                            <IconFactory icon="checkmark" className="icon checkmark" staticImages={staticImages} />
                            <div className="estimated-value">
                                <div className="estimated-value__teaser common-header hidden-xs">
                                    Your {year} {make.name} {model.name} is worth
                                </div>
                                <div className="estimated-value__content common-header">
                                    <div className="estimated-value__content--label">Instant Cash Offer Amount:</div>
                                    <div className={equityClassName}>{formatDollars(newAmount)}</div>
                                    {hasActiveLoan === LOAN_OPTIONS.YES && (
                                        <div className="estimated-value__content--amount">
                                            ({formatDollars(value)} - {formatDollars(amountOwed)})
                                        </div>
                                    )}
                                </div>
                            </div>
                            <IconFactory icon="kbb" className="icon kbb" staticImages={staticImages} />
                        </div>
                        <div className="trade-in-vehicle container-fluid">
                            <div className="row">
                                <div className="col-xs-6 title">Your Vehicle</div>
                                <div className="col-xs-6 pencil-edit-icon" onClick={this.handlePencilEditClicked}>
                                    <IconFactory
                                        icon="pencilEdit"
                                        className="icon hidden-md hidden-lg primary-link-icon"
                                        staticImages={staticImages}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-6 vehicle-info">
                                    <div className="vehicle-info__ymm">
                                        {year} {make.name} {model.name}
                                        <div className="pencil-edit-icon" onClick={this.handlePencilEditClicked}>
                                            <IconFactory
                                                icon="pencilEdit"
                                                className="icon hidden-xs hidden-sm  primary-link-icon"
                                                staticImages={staticImages}
                                            />
                                        </div>
                                    </div>
                                    <div className="vehicle-info__content">
                                        <div>
                                            Condition: <span>{condition}</span>
                                        </div>
                                        <div>
                                            Mileage: <span>{milesToStringShort(mileage)}</span>
                                        </div>
                                        <div>
                                            Zip Code: <span>{zip}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-6 vehicle-info-image">
                                    <img src={imageUrl} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 trade-in-vehicle__tc">
                                    This offer is good for 3 days (not counting Sundays) and can only be redeemed at a Participating
                                    Dealer. Kelley Blue Book doesn't redeem offers, purchase vehicles or own/operate Participating
                                    Dealers.
                                </div>
                            </div>
                        </div>
                    </div>
                </PageUI>
                <Modal
                    show={this.state.showModal}
                    elementToAttachModalTo=".mountDiv"
                    elementToBlur=".ico-success-page"
                    onModalShown={this.props.dispatchImpressionTag}
                >
                    <PageUI
                        pageClass="ico-success-no"
                        pageTitle="Do you owe on this vehicle"
                        pageSubTitle="To get your penny perfect payment, please include any active loans on the vehicle."
                        staticAssetsConfig={staticImages}
                        footerProps={{
                            onActionButtonClick: this.onSubmitCTA,
                            showBackButton: false,
                            buttonText: 'Update Payment',
                            disableActionButton: false
                        }}
                        dispatchAnalytics={dispatchAnalytics}
                    >
                        <Select
                            label="Is There An Active Loan On This Vehicle?"
                            defaultValue=""
                            id="active-loan"
                            value={hasActiveLoan}
                            name="active-loan-amount"
                            placeholder="Select"
                            className="select-field ui-bricks"
                            onChangeFunction={this.handleHasActiveLoanChange}
                        >
                            <Option value={LOAN_OPTIONS.NO}>{LOAN_OPTIONS.NO}</Option>
                            <Option value={LOAN_OPTIONS.YES}>{LOAN_OPTIONS.YES}</Option>
                        </Select>
                        <br />

                        {this.state.hasActiveLoan === LOAN_OPTIONS.YES && (
                            <Input
                                id="balance-owed"
                                className="input-balance-owed"
                                name="input-balance"
                                maxLength={8}
                                label="How Much Do You Owe"
                                placeholder="How Much Do You Owe?"
                                value={this.state.amountOwed.toString()}
                                onChangeFunction={this.onChangeAmountOwed}
                                onBlurFunction={this.props.onBlurAmountOwed}
                                type="number"
                            />
                        )}
                    </PageUI>
                </Modal>
            </div>
        );
    }
}

export default ICOSuccessUI;
