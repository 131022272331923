// externals
import React, { useState } from 'react';
import { Select, Input, Textarea } from '@makemydeal/ui-bricks/dist/cox';

// config
import config from '../../../store/mmd/config';
import { MANUAL_STEPPER_ROUTES, TRADE_VALUE_SOURCES } from '../../../utils/constants';

import TradePage from '../../../common/components/TradePage/TradePage';
import { formatUtils } from '@makemydeal/dr-common-utils';
import { IGetInputFieldInput } from '../../../common/components/VehicleInfo/VehicleInfoUI';

const MANUAL_ENTRY = {
    VALUE: 'value',
    SOURCE: 'source',
    COMMENTS: 'comments'
};
export interface IManualEntryProps {
    value: number;
    source: string;
    comments: string;
}

export interface IManualEntryUIStateProps extends IManualEntryProps {}

export interface IManualEntryUIDispatchProps {
    next: { (payload: IManualEntryProps) };
}

export interface IManualEntryUIProps extends IManualEntryUIStateProps, IManualEntryUIDispatchProps {}

export const ManualEntryUI: React.FC<IManualEntryUIProps> = ({ value, source, comments, next }) => {
    const [formData, setFormData] = useState({
        value: value || 0,
        comments: comments || '',
        source: source || ' '
    });
    const [isValidValue, setIsValidValue] = useState(true);
    const [isValidSource, setIsValidSource] = useState(true);

    const validateVehicleValue = (value) => {
        const tradeInValue = typeof value === 'string' ? value.replace(/[^0-9]+/g, '') : value;
        return (parseFloat(tradeInValue) || 0) > 0;
    };

    const validateSource = (source) => source !== ' ';

    const updateValidationStates = (formData: IManualEntryProps) => {
        setIsValidSource(validateSource(formData.source));
        setIsValidValue(validateVehicleValue(formData.value));
    };

    const isValid = (formData: IManualEntryProps) => {
        const { value, source } = formData;
        updateValidationStates(formData);
        return validateSource(source) && validateVehicleValue(value);
    };

    const tradeInValueSourceOptions: Function = (): JSX.Element[] => {
        return TRADE_VALUE_SOURCES.map((src) => (
            <option key={src} value={src}>
                {src}
            </option>
        ));
    };
    const handleChange = (e) => {
        const { currentTarget } = e;
        const key = currentTarget.name;
        let value = currentTarget.value;
        if (key === MANUAL_ENTRY.VALUE) {
            const tradeInValue = value.replace(/[^0-9]+/g, '');
            value = parseFloat(tradeInValue) || 0;
        }
        setFormData({ ...formData, [key]: value });
    };

    const handleSubmit = () => {
        if (isValid(formData)) {
            next(formData);
        }
    };

    const pageTitle = value ? 'Your Trade-In Value' : 'Enter Your Trade-In Value';
    const input: IGetInputFieldInput = {
        inputType: 'tradeInValue',
        maxlength: 7,
        pattern: '[0-9]*',
        inputmode: 'numeric',
        type: 'text'
    };
    const normalizer = {
        tradeInValue: formatUtils.formatCurrencyAmount
    };
    return (
        <TradePage
            pageClass="manual-entry-page"
            pageTitle={pageTitle}
            pageSubTitle="Please add any additional comments about how you received your evaluation."
            stepperRoutes={MANUAL_STEPPER_ROUTES}
            footerProps={{
                onActionButtonClick: handleSubmit,
                showBackButton: false,
                buttonText: 'Next',
                disableActionButton: false
            }}
            showTradeErrorMessage={false}
            staticAssetsConfig={config.staticImages}
            hideDisclaimerImg={true}
        >
            <div className="container-fluid">
                <div className="row">
                    <div className="col-xs-12 col-md-6 col-lg-6">
                        <div className="form-row form-group">
                            <Input
                                {...input}
                                type="text"
                                name="value"
                                inputId="vehicle-value"
                                required={true}
                                label="Trade-in Value"
                                placeholder="$0"
                                value={formData.value.toString()}
                                onChangeFunction={handleChange}
                                validationFunction={validateVehicleValue}
                                hasError={!isValidValue}
                                errorMessage="Please enter a valid vehicle value more than $0."
                                data-testid="manualentryform-value"
                                formatValueFunction={normalizer[input.inputType]}
                            />
                        </div>
                    </div>

                    <div className="col-xs-12 col-md-6 col-lg-6">
                        <div className="form-row form-group">
                            <Select
                                name="source"
                                id="source"
                                data-testid="manualEntryForm-source"
                                required={true}
                                className="select-field ui-bricks"
                                label="How did you get the Vehicle Value?"
                                placeholder="Select"
                                defaultValue={' '}
                                value={formData.source}
                                onChangeFunction={handleChange}
                                hasError={!isValidSource}
                                validationFunction={validateSource}
                                errorMessage="Please select an option."
                            >
                                <option value=" " disabled>
                                    Select
                                </option>
                                {tradeInValueSourceOptions()}
                            </Select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12">
                        <Textarea
                            name="comments"
                            inputId="comments"
                            className="ui-bricks"
                            data-testid="manualEntryForm-comments"
                            rows={5}
                            label="Comments"
                            value={formData.comments}
                            onChange={handleChange}
                        />
                    </div>
                </div>
            </div>
        </TradePage>
    );
};
