// externals
import { connect } from 'react-redux';
import isEqual_ from 'lodash.isequal';

// components
import SubaruEligibilityUI, {
    ISubaruEligibilityFields,
    ISubaruEligibilityUIDispatchProps,
    ISubaruEligibilityUIStateProps
} from './SubaruEligibilityUI';

// actions
import { analyticsActions } from '../../../store/actions';
import * as tradeInActionCreators from '../../../store/actionCreators';

// consts
import { TRADE_VEHICLE_INFO } from '../../../utils/routes';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';

const isEqual = isEqual_;

const mapStateToProps = (state: any): ISubaruEligibilityUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state);
    const {
        year,
        model: { name },
        vin,
        mileage
    } = tradeInSelectors.getTradeInVehicle(tradeIn);
    const amountOwed = tradeInSelectors.getTradeInAmountOwed(tradeIn);

    return {
        amountOwed,
        mileage,
        model: name,
        vin,
        year
    } as any; // see how SubaruEligibilityUI has been implemented- any is necessary because of inheritence
};

const mapDispatchToProps = (dispatch: any): ISubaruEligibilityUIDispatchProps => ({
    onBackButtonClick: (eligibilityFields: ISubaruEligibilityFields) => {
        dispatch(tradeInActionCreators.subaruEligibilityNoVinClick(eligibilityFields));
        dispatch(tradeInActionCreators.updateTradeInSource(tradeInActionCreators.DECISION_TYPES.TRADE_IN_VALUE));
        dispatch(tradeInActionCreators.setTradeInIsSubaruGtpEligible(false));
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(TRADE_VEHICLE_INFO));
        dispatch(
            tradeInActionCreators.dispatchAnalytics(analyticsActions.DR_GTRADE_TRADEIN_VIN_UNAVAILABLE, 'Subaru VIN unavailable')
        );
    },
    onNextButtonClick: (eligibilityFields: ISubaruEligibilityFields) => {
        // TODO: This should rather be in middleware instead of a thunk - the business logic should be in one central location not dispersed throughout the code
        dispatch((_dispatch, getState) => {
            const state = getState();
            const vehicleOptions = tradeInSelectors.getTradeInSavedVehicleOpts(state.tradeInComponent);
            const tradeinOptions = tradeInSelectors.getTradeInVehicleOptions(state.tradeInComponent);
            const haveVehicleOptionsChanged = !isEqual(tradeinOptions, vehicleOptions);
            if (haveVehicleOptionsChanged) {
                // TODO: This uses the dispatch from the outer context... shouldn't it use the dispatch that it gets from the thunk?
                dispatch(tradeInActionCreators.changedTradeIn(haveVehicleOptionsChanged));
            }
        });
        dispatch(tradeInActionCreators.subaruEligibilityNextClick(eligibilityFields));
    },
    goToRoute: (route: string) => {
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(route));
    },
    dispatchAnalytics: (type, description) => {
        dispatch(tradeInActionCreators.dispatchAnalytics(type, description));
    }
});

const Eligibility = connect(mapStateToProps, mapDispatchToProps)(SubaruEligibilityUI);

export default Eligibility;
