import { connect } from 'react-redux';
import TradeInLeadFormUI from './LeadFormUI';
import { submitOffer } from '../../../store/actionCreators';
import * as tradeInComponentActionCreators from '../../../store/actionCreators';
import * as tradeInRoutes from '../../../utils/routes';
import * as optimizelyUtils from '../../../common/optimizelyWrapper';
import { validateEmail, validateFirstName, validateLastName } from '../../../utils/validation';
import * as offerInfoSelectors from '../../../store/mmd/offerSelectors';
import * as selectors from '../../../store/mmd/offerSelectors';
import globalConfig from '../../../store/mmd/config';
import { reset } from 'redux-form';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import { formatUtils } from '@makemydeal/dr-common-utils';
import * as tradeInActionCreators from '../../../store/actionCreators';
import { isTradeinLeadformValuationEnabled } from '../../../store/featureToggles';
import { dateUtils, validatePhone } from '@makemydeal/dr-common-utils';
import { onFormSubmit } from '../../../store/mmd/leadForm';
import * as optimizelySelectors from '../../../store/mmd/optimizelySelectors';

const mapStateToProps = (state: any) => {
    const offerHasBeenSent = offerInfoSelectors.getHasBeenSent(state);
    const isOfferPending = offerInfoSelectors.getIsOfferBeingSubmitted(state);

    const shopperInfo: any = ((state.form.leadForm || {}).values || {}).shopper || {};
    const disableActionButton = !(
        validateFirstName(shopperInfo.firstName || '') &&
        validateLastName(shopperInfo.lastName || '') &&
        validateEmail(shopperInfo.email || '') &&
        (shopperInfo.phone === '' || validatePhone(shopperInfo.phone))
    );

    const tradeIn = state.tradeInComponent;
    const isTradeValuationReset = tradeInSelectors.isTradeValuationReset(state);

    const amountOwed = tradeInSelectors.getTradeInAmountOwed(tradeIn);
    const value = tradeInSelectors.getTradeInValue(tradeIn);
    const offerType = selectors.getCurrentOfferType(state);

    const payment = selectors.getMonthlyPaymentRoundedByOfferType(state, offerType);
    const isPaymentInvalid = offerInfoSelectors.isPaymentInvalid(payment, offerType);
    const tradeSaveFailure = tradeInSelectors.isTradeSaveFailed(state);
    const tradeSavePending = tradeInSelectors.isTradeSavePending(state);

    const calculatedDifference = value - amountOwed;
    const showTradeInApplyOptions = calculatedDifference < 0 && offerType !== 'cash';

    const vehicleValuation = formatUtils.formatDollars(calculatedDifference);
    const shouldIncludeTradeIn = tradeInSelectors.getAmountAppliedToFinancing(tradeIn);

    const isTradeInNegative = calculatedDifference < 0;
    const isIcoTradeInFlow = tradeInSelectors.isIcoTradeInFlow(state);
    const fairEstimate = formatUtils.formatDollars(parseInt(tradeInSelectors.getTradeInValueFair(tradeIn), 10));
    const excellentEstimate = formatUtils.formatDollars(parseInt(tradeInSelectors.getTradeInValueExcellent(tradeIn), 10));
    const { vehicle } = tradeIn;
    const mileage = formatUtils.formatNumber(tradeInSelectors.getTradeInVehicleMileage(tradeIn));
    const conditionMileageZipSubtitle = `${vehicle.condition} Condition | ${mileage} Miles | ${tradeIn.zip}`;
    const vehicleName = `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`;
    const pageTitle = isTradeValuationReset
        ? `${vehicleName}`
        : `Your ${vehicleName} has an estimated trade-in equity of ${vehicleValuation}`;
    const headerSubTitle = 'Step 5 of 5';

    const tradeInValue = isTradeValuationReset ? vehicleName : '';

    const isTradeLeadformValEnabled = isTradeinLeadformValuationEnabled(state);
    const nowDate = new Date();
    const now = dateUtils.formatAsMMDDYYYY(nowDate);
    const toDate = dateUtils.formatAsMMDDYYYY(dateUtils.addDaysToDate(nowDate, 6));
    const expirationRange = `${now} - ${toDate}. `;

    const pageTip =
        amountOwed > 0
            ? `${formatUtils.formatDollars(value)} Kelley Blue Book Trade-In Value -
        ${formatUtils.formatDollars(amountOwed)} Loan Balance`
            : '';
    const isIcoTradeInNotSiteDriverFlow = tradeInSelectors.isIcoTradeInNotSiteDriverFlow(state);
    const { isVariationActive, getOptimizelyTests } = optimizelySelectors;
    const { tradeInLeadFormSingleColumn } = getOptimizelyTests();
    const enableSingleColumnLeadForm = isVariationActive(
        state,
        tradeInLeadFormSingleColumn,
        tradeInLeadFormSingleColumn.singleColumn
    );

    return {
        disableActionButton,
        offerHasBeenSent,
        isOfferPending,
        shopperInfo: selectors.getShopperInfo(state),
        vehicleValuation,
        shouldIncludeTradeIn,
        isPaymentInvalid,
        tradeSaveFailure,
        tradeSavePending,
        showTradeInApplyOptions,
        isTradeInNegative,
        isIcoTradeInFlow,
        fairEstimate,
        excellentEstimate,
        pageTitle,
        headerSubTitle,
        pageTip,
        conditionMileageZipSubtitle,
        isIcoTradeInNotSiteDriverFlow,
        expirationRange,
        isTradeLeadformValEnabled,
        tradeInValue,
        enableSingleColumnLeadForm
    };
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        next: (isTradeLeadformValEnabled: boolean, applyToAmountFinanced: boolean) => {
            dispatch(onFormSubmit());
            optimizelyUtils.track('tradeIn_leadform_submitted');

            if (isTradeLeadformValEnabled) {
                dispatch(tradeInActionCreators.applyToAmountFinanced(applyToAmountFinanced));
                dispatch(tradeInActionCreators.updateTradeInValuation());
            } else {
                dispatch(submitOffer(true));
                dispatch(tradeInComponentActionCreators.leadFormSubmit());
            }
        },
        trackLeadFormInView: () => {
            optimizelyUtils.track('viewedLeadForm', true);
        },
        removeTrade: () => {
            dispatch(tradeInActionCreators.removeTradeIn());
        },
        previous: () => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VALUATION));
        },
        updateShopperInfo: (shopperInfo: any) => {
            dispatch(
                globalConfig.offerActionCreators.updatedShopper({
                    shopper: shopperInfo,
                    message: ''
                })
            );
            dispatch(reset('leadForm'));
        }
    };
};

const TradeInLeadForm = connect(mapStateToProps, mapDispatchToProps)(TradeInLeadFormUI);

export default TradeInLeadForm;
