// externals
import isEqual_ from 'lodash.isequal';

// libraries
import { ITradeInState, IVehicleOption } from '@makemydeal/dr-activities-common';

// selectors
import * as connectionIdSelectors from '../store/mmd/connectionSelectors';
import * as selectors from '../store/mmd/offerSelectors';
import * as tradeInSelectors from '../store/mmd/tradeIn';

const isEqual = isEqual_;

// may not even need this, given that trade is much simpler than offer
export const createTradePayload = (state: any) => {
    const tradeIn = tradeInSelectors.getTradeInForOffer(state.tradeInComponent);
    const connectionId = connectionIdSelectors.getConnectionId(state);
    const vehicle = selectors.getVehicleDetails(state);

    return {
        trade: tradeIn,
        vin: vehicle.vin,
        connectionId
    };
};

export const hasOptionsData = (tradeIn) => {
    const options = tradeInSelectors.getTradeInVehicleOptions(tradeIn);
    return !!options;
};

export const hasVehicleOptChanged = (tradeInState) => {
    const vehicleOptions = tradeInSelectors.getTradeInSavedVehicleOpts(tradeInState);
    const tradeinOptions = tradeInSelectors.getTradeInVehicleOptions(tradeInState);
    return !isEqual(tradeinOptions, vehicleOptions);
};

export const getTradeInValuation = (state) => {
    const tradeInComponent = state.tradeInComponent;
    const amountOwed = tradeInSelectors.getTradeInAmountOwed(tradeInComponent);
    const value = tradeInSelectors.getTradeInValue(tradeInComponent);
    const shouldIncludeTradeIn = tradeInSelectors.getAmountAppliedToFinancing(tradeInComponent);

    const tradeInValuation = {
        amountOwed,
        shouldIncludeTradeIn,
        value
    };

    return tradeInValuation;
};

export const getFormattedTradeInVehicleOptions = (tradeIn: ITradeInState): IVehicleOption => {
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn) || {};
    const formattedVehicleOptions = {};
    for (const [key, value] of Object.entries(vehicleOptions)) {
        if (value.options?.length) {
            formattedVehicleOptions[key] = {
                ...value,
                options: value.options.map((option) => {
                    return option?.displayName
                        ? {
                              ...option,
                              // handling string containing a double-quote, for example: "Premium Wheels 19\"+"
                              displayName: option.displayName.replace(/(\\){,1}"/g, '\\"')
                          }
                        : option;
                })
            };
        }
    }
    return formattedVehicleOptions;
};
