// libraries
import { toast } from '@cx/ui/Toast';

// interfaces/types
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';
import { type AnyFSA } from '@makemydeal/dr-platform-shared';

// consts/enums
import { actions } from '@makemydeal/dr-offer-redux';
import { OFFER_SAVE_FAILURE, OFFER_SAVE_NOCHANGE_SUCCESS, OFFER_SAVE_SUCCESS } from '../actionTypes/offerActionTypes';

// utils
import { saveOffer, updateManualIncentives } from '../actions/offerActionCreators';
import * as navigationActionCreators from '../actions/navigationActionCreators';
import { addManualAccessories, checkAccessoriesCatalogExists } from '../actions/accessoriesActionCreators';
import { unselectAllVppProducts, updateVppSurchargesAttributes } from '../actions/vehicleProtectionActionCreators';
import * as rollbackSelectors from '../selectors/rollbackSelectors';
import { dealerSelectors, featureToggleSelectors, rollbackActionCreators } from '@makemydeal/dr-shared-store';
import {
    changeVehicleCreators,
    changeVehicleSelectors,
    CHANGE_VEHICLE_CLICKED,
    VEHICLE_CHANGE_FINISH,
    VEHICLE_CHANGE_SUCCESS
} from '@makemydeal/dr-activities-change-vehicle';
import { getSelectedManualAccessories } from '../selectors/accessoriesSelectors';
import { getVppSurchargesOverrides } from '../selectors/vehicleProtectionSelectors';
import { scopedStateSelectors } from '@makemydeal/dr-shared-store';

import { actions as offerReduxActions } from '@makemydeal/dr-offer-redux';
import { offerReduxActionCreators } from '../actions';
import { cloneDeep } from 'lodash';
import { dealerUtils } from '../utils';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    switch (action.type) {
        case actions.PAYMENT_RECEIVED: {
            if (action.meta?.originalAction.type === actions.SWITCH_VEHICLE) {
                const isABPencilStateMVEnabled = featureToggleSelectors.isABPencilStateMVEnabled(store.getState());

                if (isABPencilStateMVEnabled) {
                    // If the AB Pencil feature is enabled, we dont want to create a new version of dealXg on vehicle change.
                    store.dispatch(changeVehicleCreators.finishVehicleChange());
                } else {
                    store.dispatch(saveOffer());
                }
            }

            break;
        }
        case actions.PAYMENT_FAILURE: {
            if (action.meta?.originalAction.type === actions.SWITCH_VEHICLE) {
                const state = store.getState();
                const enableStateRollback = featureToggleSelectors.getFeatureToggle(state, 'enableStateRollback');
                if (enableStateRollback) {
                    const isScoped = scopedStateSelectors.getIsScopedStoreEnabled(state);
                    if (isScoped) {
                        store.dispatch(rollbackActionCreators.rollbackScopeStateForSwitchVehicle());
                    } else {
                        const originalState = rollbackSelectors.getRollbackState(state);
                        store.dispatch(rollbackActionCreators.rollbackAppStateForSwitchVehicle(originalState));
                    }
                }
                store.dispatch(changeVehicleCreators.vehicleChangeFailure());
            }
            break;
        }
        case OFFER_SAVE_NOCHANGE_SUCCESS:
        case OFFER_SAVE_SUCCESS:
        case VEHICLE_CHANGE_FINISH: {
            if (changeVehicleSelectors.isChangeProcessing(store.getState())) {
                const surchargesOverrides = getVppSurchargesOverrides(store.getState());
                store.dispatch(updateVppSurchargesAttributes(surchargesOverrides));
                store.dispatch(checkAccessoriesCatalogExists());
                store.dispatch(changeVehicleCreators.vehicleChangeSuccess());
            }
            break;
        }
        case VEHICLE_CHANGE_SUCCESS: {
            store.dispatch(navigationActionCreators.navigateToDashboard());
            break;
        }
        case CHANGE_VEHICLE_CLICKED: {
            const state = store.getState();
            const isScoped = scopedStateSelectors.getIsScopedStoreEnabled(state);
            const rollbackAction = isScoped
                ? rollbackActionCreators.prepareScopeStateCopy()
                : rollbackActionCreators.prepareStateCopy(state);
            store.dispatch(rollbackAction);
            store.dispatch(unselectAllVppProducts({ skipPayment: true }));
            const selectedManualAccessories = getSelectedManualAccessories(store.getState());
            store.dispatch(addManualAccessories(selectedManualAccessories, { skipPayment: true }));
            store.dispatch(
                updateManualIncentives({
                    manualIncentives: [],
                    dealerCashTotal: 0,
                    totalRebates: 0,
                    skipPayment: true
                })
            );

            // NOTE: update offer's credit tiers
            const dealer = cloneDeep(dealerSelectors.getDealerState(state));
            const creditTiers = dealerUtils.getCreditTiersByVehicleCondition(dealer, action.payload.selectedVehicle.condition);

            if (creditTiers) {
                dealer.creditTiers = creditTiers;

                store.dispatch(offerReduxActionCreators.initOfferCreditTiers({ dealer }));
            }

            break;
        }
        case OFFER_SAVE_FAILURE: {
            store.dispatch(changeVehicleCreators.vehicleChangeFailure());
            break;
        }
        case offerReduxActions.SWITCH_VEHICLE: {
            store.dispatch(changeVehicleCreators.processSwitchVehicle());
            break;
        }
        default:
            break;
    }
};
