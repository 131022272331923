import { IConfigTradeIn } from '../../types/IConfigTradeIn';

const globalConfig = {};

export function setGlobalConfig(config: IConfigTradeIn) {
    Object.keys(config).forEach((key) => {
        globalConfig[key] = config[key];
    });
}

export default globalConfig as IConfigTradeIn;
