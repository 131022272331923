export const CHANGE_VEHICLE_CLICKED = 'CHANGE_VEHICLE_CLICKED';
export const CHANGE_VEHICLE_CANCEL = 'CHANGE_VEHICLE_CANCEL';
export const UPDATE_CUSTOM_VEHICLE = 'UPDATE_CUSTOM_VEHICLE';
export const UPDATE_VEHICLE_CUSTOM_DATA = 'UPDATE_VEHICLE_CUSTOM_DATA';
export const FETCH_CVI_REQUEST = 'FETCH_CVI_REQUEST';
export const FETCH_CVI_SUCCESS = 'FETCH_CVI_SUCCESS';
export const FETCH_CVI_FAILURE = 'FETCH_CVI_FAILURE';
export const SEARCH_VEHICLE_REQUEST = 'SEARCH_VEHICLE_REQUEST';
export const SEARCH_VEHICLE_SUCCESS = 'SEARCH_VEHICLE_SUCCESS';
export const SEARCH_VEHICLE_FAILURE = 'SEARCH_VEHICLE_FAILURE';
export const SEARCH_STOCK_NUMBER_REQUEST = 'SEARCH_STOCK_NUMBER_REQUEST';
export const SEARCH_STOCK_NUMBER_SUCCESS = 'SEARCH_STOCK_NUMBER_SUCCESS';
export const SEARCH_STOCK_NUMBER_FAILURE = 'SEARCH_STOCK_NUMBER_FAILURE';
export const NEW_VEHICLE_SELECTED = 'NEW_VEHICLE_SELECTED';
export const PROCESS_CVI_VEHICLES = 'PROCESS_CVI_VEHICLES';
export const PROCESS_SWITCH_VEHICLE = 'PROCESS_SWITCH_VEHICLE';
export const SEARCH_VEHICLES_CLICKED = 'SEARCH_VEHICLES_CLICKED';
export const CHANGE_VEHICLE_TAB_CHANGED = 'CHANGE_VEHICLE_TAB_CHANGED';
export const VEHICLE_CHANGE_FINISH = 'VEHICLE_CHANGE_FINISH';
export const VEHICLE_CHANGE_SUCCESS = 'VEHICLE_CHANGE_SUCCESS';
export const REMOVE_VEHICLE_CHANGE_SUCCESS_SNACKBAR = 'REMOVE_VEHICLE_CHANGE_SUCCESS_SNACKBAR';
export const VEHICLE_CHANGE_FAILURE = 'VEHICLE_CHANGE_FAILURE';
export const FETCH_CATALOG_YEARS_REQUEST = 'FETCH_CATALOG_YEARS_REQUEST';
export const FETCH_CATALOG_YEARS_SUCCESS = 'FETCH_CATALOG_YEARS_SUCCESS';
export const FETCH_CATALOG_YEARS_FAILURE = 'FETCH_CATALOG_YEARS_FAILURE';
export const FETCH_CATALOG_MAKES_REQUEST = 'FETCH_CATALOG_MAKES_REQUEST';
export const FETCH_CATALOG_MAKES_SUCCESS = 'FETCH_CATALOG_MAKES_SUCCESS';
export const FETCH_CATALOG_MAKES_FAILURE = 'FETCH_CATALOG_MAKES_FAILURE';
export const FETCH_CATALOG_MODELS_REQUEST = 'FETCH_CATALOG_MODELS_REQUEST';
export const FETCH_CATALOG_MODELS_SUCCESS = 'FETCH_CATALOG_MODELS_SUCCESS';
export const FETCH_CATALOG_MODELS_FAILURE = 'FETCH_CATALOG_MODELS_FAILURE';
export const FETCH_CATALOG_TRIMS_REQUEST = 'FETCH_CATALOG_TRIMS_REQUEST';
export const FETCH_CATALOG_TRIMS_SUCCESS = 'FETCH_CATALOG_TRIMS_SUCCESS';
export const FETCH_CATALOG_TRIMS_FAILURE = 'FETCH_CATALOG_TRIMS_FAILURE';
export const FETCH_CATALOG_VEHICLES_REQUEST = 'FETCH_CATALOG_VEHICLES_REQUEST';
export const FETCH_CATALOG_VEHICLES_SUCCESS = 'FETCH_CATALOG_VEHICLES_SUCCESS';
export const FETCH_CATALOG_VEHICLES_FAILURE = 'FETCH_CATALOG_VEHICLES_FAILURE';
export const CLEAR_CATALOG_VEHICLES = 'CLEAR_CATALOG_VEHICLES';
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY';
