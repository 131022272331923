import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';

import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

import { ICreditDecisionActivity } from '../types/ICreditDecisionActivity';
import { getAllRoutes } from '../utils/routes';
import CreditDecisionActivity from './CreditDecisionActivity';
import { ActivityRouteContainer } from './CreditDecisionActivity.style';

const Root = (props: ICreditDecisionActivity) => {
    const { root } = getAllRoutes(props.baseRoute);
    const isABPencilEnabled = useSelector(featureToggleSelectors.isABPencilStateMVEnabled);

    return (
        <ActivityRouteContainer
            data-testid="activities-credit-decision-container"
            className="activities-credit-decision"
            isNewAppWidthEnabled={isABPencilEnabled}
        >
            <Route path={root} component={CreditDecisionActivity} />
        </ActivityRouteContainer>
    );
};

Root.displayName = 'CreditDecisionActivity';

export default Root;
