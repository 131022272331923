import * as routes from './routes';
import { ImprovedStepperRoute } from '../types';

export const DEAL_EXPERIENCE = 'dealStarter';
export const DDC_SPONSOR = 'ddc';
export const ICO_TRADE_IN_PROVIDER = 'kelley_blue_book_ico';

export const STEPPER_ROUTES = [
    {
        name: 'Search',
        route: routes.TRADE_IN_SEARCH,
        analyticsId: 'SEARCH'
    },
    {
        name: 'Details',
        route: routes.TRADE_VEHICLE_INFO,
        analyticsId: 'DETAILS'
    },
    {
        name: 'Conditions',
        route: routes.TRADE_VEHICLE_CONDITION,
        analyticsId: 'CONDITIONS'
    },
    {
        name: 'Vehicle',
        route: routes.TRADE_EQUIPMENT_OPTIONS_STEP_ONE,
        analyticsId: 'VEHICLE'
    },
    {
        name: 'Equipment',
        route: routes.TRADE_EQUIPMENT_OPTIONS,
        analyticsId: 'EQUIPMENT'
    }
];

export const MANUAL_STEPPER_ROUTES = [
    {
        name: 'Value',
        route: routes.TRADE_MANUAL_ENTRY,
        analyticsId: 'VALUE'
    },
    {
        name: 'Details',
        route: routes.TRADE_VEHICLE_INFO,
        analyticsId: 'DETAILS'
    },
    {
        name: 'Conditions',
        route: routes.TRADE_VEHICLE_CONDITION,
        analyticsId: 'CONDITIONS'
    }
];

export const IMPROVED_FLOW_STEPPER_ROUTES = {
    vehicleInfo: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO'
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD'
        },
        {
            name: 'Details',
            route: routes.TRADE_VEHICLE_CONDITION,
            analyticsId: 'CONDITIONS'
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    estimateMethod: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD'
        },
        {
            name: 'Details',
            route: routes.TRADE_VEHICLE_CONDITION,
            analyticsId: 'CONDITIONS'
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    detailsSubmitForAppraisal: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD',
            completed: true
        },
        {
            name: 'Details',
            route: routes.TRADE_MANUAL_ENTRY,
            analyticsId: 'MANUAL ENTRY'
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    summarySubmitForAppraisal: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD',
            completed: true
        },
        {
            name: 'Details',
            route: routes.TRADE_MANUAL_ENTRY,
            analyticsId: 'MANUAL ENTRY',
            completed: true
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    detailsICO: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD',
            completed: true
        },
        {
            name: 'Details',
            route: routes.TRADE_IN_ICO,
            analyticsId: 'ICO'
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    summaryICO: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD',
            completed: true
        },
        {
            name: 'Details',
            route: routes.TRADE_IN_ICO,
            analyticsId: 'ICO',
            completed: true
        },
        {
            name: 'Summary',
            route: routes.TRADE_IN_ICO_SUCCESS,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    detailsKBBCondition: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD',
            completed: true
        },
        {
            name: 'Details',
            route: routes.TRADE_VEHICLE_CONDITION,
            analyticsId: 'CONDITIONS'
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    detailsKbbOptions: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD',
            completed: true
        },
        {
            name: 'Details',
            route: routes.TRADE_EQUIPMENT_OPTIONS_STEP_ONE,
            analyticsId: 'VEHICLE'
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    detailsKbbEquipment: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD',
            completed: true
        },
        {
            name: 'Details',
            route: routes.TRADE_EQUIPMENT_OPTIONS,
            analyticsId: 'EQUIPMENT'
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[],
    summarySubaruGtp: [
        {
            name: 'Vehicle Info',
            route: routes.TRADE_VEHICLE_INFO,
            analyticsId: 'VEHICLE INFO',
            completed: true
        },
        {
            name: 'Estimate Method',
            route: routes.TRADE_IN_DECISION,
            analyticsId: 'ESTIMATE METHOD',
            completed: true
        },
        {
            name: 'Summary',
            route: routes.TRADE_SUMMARY,
            analyticsId: 'SUMMARY'
        }
    ] as ImprovedStepperRoute[]
};

export const CALLSITE_SINGLECOLUMN_1 = 'CALLSITE_SINGLECOLUMN_1';
export const CALLSITE_SINGLECOLUMN_2 = 'CALLSITE_SINGLECOLUMN_2';
export const CALLSITE_VEHICLEINFO_1 = 'CALLSITE_VEHICLEINFO_1';
export const CALLSITE_VEHICLEINFO_2 = 'CALLSITE_VEHICLEINFO_2';
export const CALLSITE_SEARCH_1 = 'CALLSITE_SEARCH_1';
export const CALLSITE_SEARCH_2 = 'CALLSITE_SEARCH_2';
export const CALLSITE_VEHICLEINFO_V4_1 = 'CALLSITE_VEHICLEINFO_V4_1';
export const CALLSITE_VEHICLEINFO_V4_2 = 'CALLSITE_VEHICLEINFO_V4_2';

export const MENU_CARD_ID = 'dr-sp-menu-card';
export const MESSAGING_BUTTON_CARD_ID = 'dr-sp-messaging-button';
export const PENCIL_CARD_ID = 'dr-sp-pencil-card';
export const TEST_DRIVE_CARD_ID = 'dr-sp-test-drive-card';
export const RESERVATION_CARD_ID = 'dr-sp-vehicle-reservation-card';
export const ACCEPT_OFFER_CARD_ID = 'dr-sp-accept-offer-card';
export const TRADE_IN_APP_CARD_ID = 'dr-sp-trade-in-card';
export const DASHBOARD_CARD_ID = 'dr-sp-dashboard-card';
export const TIME_SAVINGS_CALCULATOR = 'dr-sp-time-savings-card';

// Route values
export const RouteValues = {
    ACCEPT_DEAL: '/acceptDeal',
    CREDIT: '/credit',
    CREDIT_DECISION_RESULTS: '/creditDecisionResults',
    COUNTER_OFFER: '/counterOffer',
    DEAL_CERTIFICATE: '/dealCertificate',
    DEAL_CERTIFICATE_DEALER_VIEW: '/dealCertificateDealerView',
    DEAL_SUMMARY: '/dealSummary/default',
    DEALER_OFFER: '/dealerOffer',
    DEALER_OFFER_SUMMARY: '/dealerOfferSummary',
    EDIT_TERMS: '/paymentOptions/edit',
    HELP_CONTACT: '/helpContact',
    INCENTIVES: '/incentives',
    LANDING_PAGE: '/landingPage',
    LEAD_FORM: '/leadForm',
    MENU_PRODUCTS: '/menuProducts',
    MESSAGING: '/messaging',
    MY_TERMS_DEAL_SUMMARY: '/dealSummary/myTerms',
    PROTECTION_LIST: '/protectionList',
    RESERVATION: '/reservation',
    SOFT_PULL: '/softPull',
    SUMMARY_LEAD_FORM: '/summaryLeadForm',
    LANDING_INTERSTITIAL: '/landingInterstitial',
    TERMS: '/paymentOptions',
    TEST_DRIVE: '/testDrive',
    TRADE_EQUITY: '/equity',
    TRADE_IN: '/tradeIn',
    TRADE_IN_LEADFORM: '/tradeLeadForm',
    VEHICLE_INFO: '/vehicleInfo',
    TRADE_IN_DECISION: '/tradeInDecision',
    TRADE_IN_SEARCH: '/tradeInSearch',
    TRADE_IN_SUMMARY: '/tradeSummary',
    DASHBOARD: '/dashboard',
    DEAL_SUMMARY_PAGE: '/dealsummarypage',
    TRADE_IN_ICO_SUCCESS: '/tradeInIcoSuccess'
};

export const OFFER_TYPE = {
    finance: 'finance',
    lease: 'lease',
    loan: 'loan'
};
export const HERTZ_TEMPLATE_NAME = 'hertz';
export const STANDARD_TEMPLATE_NAME = 'standard';

export const TRADE_VALUE_SOURCES = ['Dealer', 'On-Line', 'Customer Entered', 'Other'];
export const TRADE_IN_DESCRIPTION_VEHICLE = [
    {
        title: 'Fair',
        percentage: '20% of all cars we value',
        bullets: [
            [
                'Requires some mechanical repairs',
                'The paint and bodywork may require refinishing and body repair',
                'The engine compartment has leaks and mat require repairs',
                'May have some repairable rust and corrosion damage',
                'The body has dents, chips and/or scratches'
            ],
            [
                'The windshield has chips and interior has substantial wear and may have small tears',
                'Wheels may be warped or bent, have major scratches, scrapes, or pitting and require replacement',
                'The tires may not match or are worn and need replacing',
                'Needs servicing, but is still in reasonable running condition with a clean title history',
                'A few service records are available'
            ]
        ]
    },
    {
        title: 'Good',
        percentage: '50% of all cars we value',
        bullets: [
            [
                'Has some repairable cosmetic defects and is free of major mechanical problems',
                'May need some servicing',
                'The paint and bodywork may require minor touch-ups',
                'The engine compartment may have minor leaks',
                'Has only minor rust and corrosion, if any',
                'The body may have minor scratches or dings'
            ],
            [
                'The windshield and interior have minor blemishes characteristic of normal wear',
                'Wheels may have minor repairable scratches or scrades',
                'All tires match and have at least 50% of tread remaining',
                'Though it may need some reconditioning, it has a clean title history and will pass a safety and smog inspection',
                'Some service records are available'
            ]
        ]
    },
    {
        title: 'Very Good',
        percentage: '28% of all cars we value',
        bullets: [
            [
                'Has minor cosmetic defects and is in excellent mechanical condition',
                'Has had minor paint touch-ups and/or bodywork',
                'Requires minimal reconditioning',
                'The engine compartment is clean and free of leaks, rust or corrosion',
                'The body, windshield, and interior have minimal signs of wear or visible defects'
            ],
            [
                'Wheels are flawless',
                'All tires match and have 75% or more of tread remaining',
                'Has a clean title history and will pass a safety and smog inspection',
                'Most service records are available'
            ]
        ]
    },
    {
        title: 'Excellent',
        percentage: '2% of all cars we value',
        bullets: [
            [
                'Looks new and is in excellent mechanical condition',
                'Has never had any paint touch-ups and/or bodywork',
                'Does not need reconditioning',
                'The engine compartment is clean and free of leaks, rust or corrosion',
                'The body has dents, chips and/or scratches'
            ],
            [
                'The body, windshield, and interior are free of wear or visible defects',
                'Wheels are flawless',
                'All tires match and are like new',
                'Has a clean title history and will pass a safety and smog inspection',
                'Has complete and verifiable service records'
            ]
        ]
    }
];

export const TRADE_IN_VEHICLE = [
    {
        index: 0,
        id: 'condition_0',
        label: 'Fair',
        statistic: '18% of cars',
        selected: false,
        description: 'Some mechanical or cosmetic defects and needs servicing'
    },
    {
        index: 1,
        id: 'condition_1',
        label: 'Good',
        statistic: '56% of cars',
        selected: false,
        description: 'Repairable cosmetic defects and free of major mechanical problems'
    },
    {
        index: 2,
        id: 'condition_2',
        label: 'Very Good',
        statistic: '23% of cars',
        selected: false,
        description: 'Minor cosmetic defects and in excellent mechanical condition'
    },
    {
        index: 3,
        id: 'condition_3',
        label: 'Excellent',
        statistic: '3% of cars',
        selected: false,
        description: 'Looks new and in excellent mechanical condition'
    }
];

export const OFFER_DDC_DEALER_SOURCE = 'ddc';

export const OWNERSHIP_OPTIONS = {
    PURCHASE: 'Purchase',
    LEASE: 'Lease'
};
