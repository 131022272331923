import { useState, useEffect } from 'react';
import isEqual from 'lodash.isequal';

// Only updates the memoized value if it is not deeply equal to the current value
export const useCustomDeepMemo = <T>(value: T): T => {
    const [memoizedValue, setMemoizedValue] = useState<T>(value);

    useEffect(() => {
        if (!isEqual(value, memoizedValue)) {
            setMemoizedValue(value);
        }
    }, [value, memoizedValue]);

    return memoizedValue;
};
