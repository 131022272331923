import * as React from 'react';
import { SecondaryButton } from '@makemydeal/ui-bricks';

export interface IFeaturedProductStateProps {
    headline: string;
    description: string;
    buttonText: string;
    bannerText?: string;
}

export interface IFeaturedProductDispatchProps {
    onButtonClick: { (): void };
}

const FeaturedProductUI: React.FC<IFeaturedProductStateProps & IFeaturedProductDispatchProps> = (props) => {
    return (
        <div className="featured-product">
            {props.bannerText && <div className="featured-product-banner">{props.bannerText}</div>}
            <h1 className="featured-product-headline">{props.headline}</h1>
            <div className="featured-product-description">{props.description}</div>
            <SecondaryButton onClick={props.onButtonClick} className="featured-product-button">
                {props.buttonText}
            </SecondaryButton>
        </div>
    );
};

export default FeaturedProductUI;
