import { connect } from 'react-redux';
import { formatUtils } from '@makemydeal/dr-common-utils';
import isEqual_ from 'lodash.isequal';
const isEqual = isEqual_;

import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as tradeInActionCreators from '../../../store/actionCreators';
import * as tradeInRoutes from '../../../utils/routes';

import { IEquipmentOptionsUIStateProps, IEquipmentOptionsUIDispatchProps } from './EquipmentOptionsUI';
import EquipmentOptionsUI from './EquipmentOptionsUI';

const mapStateToProps = (state: any): IEquipmentOptionsUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as any;
    const conflict = tradeInSelectors.getTradeInOptionInConflict(tradeIn);
    const vehicle = tradeInSelectors.getTradeInVehicle(tradeIn);
    const vehicleName = `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`;
    const mileage = formatUtils.formatNumber(tradeInSelectors.getTradeInVehicleMileage(tradeIn));
    const conditionMileageZipSubtitle = `${vehicle.condition} Condition | ${mileage} Miles | ${tradeIn.zip}`;
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn);
    const zip = tradeInSelectors.getTradeInZip(tradeIn);
    const pageTip = "Be sure to answer thoroughly, since options will affect your car's value.";
    const pageTitle = `Which options are on your ${vehicleName}?`;
    const headerSubTitle = 'Step 3 of 5';

    return {
        conflict,
        vehicleOptions,
        vehicle,
        zip,
        pageTip,
        pageTitle,
        headerSubTitle,
        conditionMileageZipSubtitle
    };
};

const mapDispatchToProps = (dispatch: any): IEquipmentOptionsUIDispatchProps => ({
    handleBack: () => {
        dispatch(tradeInActionCreators.goToSelection());
    },
    handleCancel: () => {
        dispatch(tradeInActionCreators.cancelTradeIn());
    },
    handleNext: () => {
        dispatch((_dispatch, getState) => {
            const state = getState();
            const vehicleOptions = tradeInSelectors.getTradeInSavedVehicleOpts(state.tradeInComponent);
            const tradeinOptions = tradeInSelectors.getTradeInVehicleOptions(state.tradeInComponent);
            const haveVehicleOptionsChanged = !isEqual(tradeinOptions, vehicleOptions);

            if (haveVehicleOptionsChanged) {
                dispatch(tradeInActionCreators.changedTradeIn(haveVehicleOptionsChanged));
            }
        });
        dispatch(tradeInActionCreators.applyTradeInEquipmentOptions());
    },
    resetStandardOptions: (vehicle, zip) => {
        dispatch(tradeInActionCreators.fetchTradeInEquipmentOptions(vehicle, zip));
    },
    onValueChange: (categoryName, optionValueId, isChecked) => {
        dispatch(tradeInActionCreators.changeEquipmentOption(categoryName, optionValueId, isChecked));
    },
    next: () => {
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VALUATION));
    },
    previous: () => {
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_CONDITION));
    }
});

const EquipmentOptions = connect(mapStateToProps, mapDispatchToProps)(EquipmentOptionsUI);

export default EquipmentOptions;
