import { Actions as types } from '../store/actions';
import { createTradePayload } from './tradeUtils';
import config from '../store/mmd/config';
import { commonApiLogic } from './commonApiLogic';
import { createGatewayOfferPayload, createOfferPayload } from '../store/mmd/offerSelectors';

const POST_REQUEST_METHOD = 'POST';
const PUT_REQUEST_METHOD = 'PUT';
const DEL_REQUEST_METHOD = 'DELETE';

const REQUEST_HEADERS = { 'Content-Type': 'application/json' };

export const createCdkTradeRequest = (state: any) => {
    const payload = createTradePayload(state);
    const endPoint = 'tradeCreate';
    const url = config.utils.buildApiUrlWithServiceEndpoint({ endPoint });
    const opts = {
        endpoint: url,
        method: POST_REQUEST_METHOD,
        body: JSON.stringify(payload),
        headers: REQUEST_HEADERS,
        types: [types.TRADE_IN_SEND_REQUEST, types.TRADE_IN_SEND_SUCCESS, types.TRADE_IN_SEND_FAILURE]
    };
    return opts;
};

export const createOemCdkTradeRequest = (state: any) => {
    const payload = createTradePayload(state);
    const endPoint = 'tradeCreate';
    const url = config.utils.buildApiUrlWithServiceEndpoint({ endPoint });
    const opts = {
        endpoint: url,
        method: POST_REQUEST_METHOD,
        body: JSON.stringify(payload),
        headers: REQUEST_HEADERS,
        types: [types.OEM_TRADE_IN_SEND_REQUEST, types.OEM_TRADE_IN_SEND_SUCCESS, types.OEM_TRADE_IN_SEND_FAILURE]
    };
    return opts;
};

export const createTradeRequest = (state: any, submitType: string) => {
    const payload = createTradePayload(state);
    const { connectionId, vin } = payload;
    const endPoint = submitType === 'add' ? 'gatewayTrade' : `gatewayTrade/${connectionId}/${vin}`;
    const url = config.utils.buildApiUrlWithServiceEndpoint({ endPoint });

    const body = JSON.stringify(payload);
    const opts = {
        endpoint: url,
        method: submitType === 'add' ? POST_REQUEST_METHOD : PUT_REQUEST_METHOD,
        body,
        headers: REQUEST_HEADERS,
        types: [types.TRADE_IN_SEND_REQUEST, types.TRADE_IN_SEND_SUCCESS, types.TRADE_IN_SEND_FAILURE]
    };
    return opts;
};

export const removeTradeRequest = (state: any) => {
    const payload = createTradePayload(state);
    const { connectionId, vin } = payload;

    const endPoint = `gatewayTrade/${connectionId}/${vin}`;
    const url = config.utils.buildApiUrlWithServiceEndpoint({ endPoint });

    const body = JSON.stringify(payload);
    const opts = {
        endpoint: url,
        method: DEL_REQUEST_METHOD,
        body,
        headers: REQUEST_HEADERS,
        types: [types.TRADE_IN_REMOVE_REQUEST, types.TRADE_IN_REMOVE_SUCCESS, types.TRADE_IN_REMOVE_FAILURE]
    };

    return opts;
};

const sendOfferCommon = (
    isSave: boolean,
    state: any,
    submitType: string,
    isToGateway: boolean,
    offerUpdateType: string,
    fakeFailure: boolean,
    done: any
) => {
    const gatewayEndpoint = isSave ? 'gatewayOfferSave' : 'gatewayOffer';
    const nonGatewayEndpoint = isSave ? 'offerSave' : 'offer';
    const endPoint = isToGateway ? gatewayEndpoint : nonGatewayEndpoint;
    const url = config.utils.buildApiUrlWithServiceEndpoint({ endPoint });

    const payload = isToGateway ? createGatewayOfferPayload(state, offerUpdateType) : createOfferPayload(state, offerUpdateType);
    const body = JSON.stringify(payload);
    commonApiLogic(url, body, submitType, fakeFailure, done);
};

export const sendOffer = (
    state: any,
    submitType: string,
    isToGateway: boolean,
    offerUpdateType: string,
    fakeFailure: boolean,
    done: any
) => {
    const isSave = false;
    return sendOfferCommon(isSave, state, submitType, isToGateway, offerUpdateType, fakeFailure, done);
};

export const saveOffer = (
    state: any,
    submitType: string,
    isToGateway: boolean,
    offerUpdateType: string,
    fakeFailure: boolean,
    done: any
) => {
    const isSave = true;
    return sendOfferCommon(isSave, state, submitType, isToGateway, offerUpdateType, fakeFailure, done);
};
