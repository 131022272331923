// tslint:disable:import-name
import * as React from 'react';
import { Route, Redirect } from 'react-router';

import { PropsRoute } from '@makemydeal/dr-activities-common';

import * as tradeInRoutes from '../../utils/routes';
import VehicleInfo from '../../common/components/VehicleInfo/VehicleInfo';
import VehicleCondition from './vehicleCondition/VehicleCondition';
import EquipmentOptions from './equipmentOptions/EquipmentOptions';
import EquipmentOptionsStepOne from './equipmentOptions/EquipmentOptionsStepOne';
import Search from './search/Search';
import TradeSummary from './summary/TradeSummary';
import TradeInDecision from './tradeInDecision/TradeInDecision';
import TradeInIco from './icoValuation/IcoValuation';
import ICOSuccess from './icoSuccess/IcoSuccess';
import { IRootProps } from '../../components/RootProps';
import ManualEntry from './manualEntry/ManualEntry';
import Eligibility from '../../common/components/subaruEligibility/SubaruEligibility';

const TradeInRouting: React.FC<IRootProps> = (props) => {
    const { currentRoute } = props;

    return (
        <div className="dr-p202-trade-in">
            <Route path={tradeInRoutes.TRADE_IN} render={() => <Redirect to={currentRoute} />} />
            <Route path={tradeInRoutes.TRADE_VEHICLE_INFO} component={VehicleInfo} />
            <Route path={tradeInRoutes.TRADE_VEHICLE_CONDITION} component={VehicleCondition} />
            <Route path={tradeInRoutes.TRADE_EQUIPMENT_OPTIONS} component={EquipmentOptions} />
            <Route path={tradeInRoutes.TRADE_EQUIPMENT_OPTIONS_STEP_ONE} component={EquipmentOptionsStepOne} />
            <Route path={tradeInRoutes.TRADE_IN_SEARCH} component={Search} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_SUMMARY} component={TradeSummary} />
            <Route path={tradeInRoutes.TRADE_IN_ICO} component={TradeInIco} />
            <Route path={tradeInRoutes.TRADE_IN_DECISION} component={TradeInDecision} />
            <Route path={tradeInRoutes.TRADE_IN_ICO_SUCCESS} component={ICOSuccess} />
            <Route path={tradeInRoutes.TRADE_MANUAL_ENTRY} component={ManualEntry} />
            <Route path={tradeInRoutes.TRADE_SUBARU_ELIGIBILITY} component={Eligibility} />
        </div>
    );
};

export default TradeInRouting;
