import styled from 'styled-components';

import { InformationCircleIcon } from '@interstate/components/Icons';
import { Tooltip } from '@interstate/components/Tooltip';

export const SearchBarLabelContainer = styled.div`
    color: ${({ theme }) => theme.tokens.BaseColorGray600};
    display: flex;
    gap: 8px;
    align-items: center;
`;

export const SearchBarLabel = styled.div`
    margin-top: 1px;
`;

export const SearchBarInfoIcon = styled(InformationCircleIcon)`
    cursor: help;
    color: ${({ theme }) => theme.tokens.BaseColorBlue600};
    height: 16px;
    width: 16px;
`;

export const SearchBarTooltip = styled(Tooltip)`
    display: flex;
`;

export const SearchBarErrorContainer = styled.div`
    margin-top: 10px;
`;

export const SearchBarErrorLabel = styled.h3`
    margin-bottom: 0;
`;

export const SearchBarErrorDescription = styled.p`
    margin-bottom: 0;
`;

export const SearchSpinnerContainer = styled.div`
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
