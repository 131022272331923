import { isEqual } from 'lodash';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Action } from '@interstate/components/Action';
import { CardHeader } from '@interstate/components/Card';
import { Typography } from '@interstate/components/Typography';
import { manualIncentivesSelectors, navigationActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CASH } from '@makemydeal/dr-platform-types';

import { PROGRAMS_AND_INCENTIVES } from '../../constants';
import { useScrollIntoView } from '../../utils/useScrollIntoView';
import { LenderNameContainer, ManualRebatesCardContent } from './ManualRebatesCard.interstate.styles';
import { RebatesEdit } from './RebatesEdit.interstate';
import { RebatesView } from './RebatesView.interstate';
import { StretchHeightCard } from '../deskingActivity/DeskingCard.style';

export const ManualRebatesCard = memo(function ManualRebatesCard() {
    const [mode, setMode] = useState<'view' | 'edit'>('view');
    const isManualFormOpen = useSelector(manualIncentivesSelectors.isManualFormOpen);
    const manualIncentivesRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (isManualFormOpen) setMode('edit');
        else setMode('view');
    }, [isManualFormOpen]);

    useScrollIntoView(manualIncentivesRef, isManualFormOpen);
    const dispatch = useDispatch();
    const htmlId = 'manual-rebates-card';

    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const lenderLegalName = useSelector(offerReduxSelectors.getLenderLegalName);
    const incentives = useSelector(offerReduxSelectors.getAppliedIncentives, isEqual);
    const creditDecisionEnabled = useSelector(offerReduxSelectors.getCreditDecisionEnabled);

    const actionLabel = !incentives.length ? 'Add' : 'Edit';

    const header = useMemo(
        () => (
            <CardHeader
                title={PROGRAMS_AND_INCENTIVES}
                action={
                    <>
                        {!creditDecisionEnabled && (
                            <Action
                                data-testid="programs-link"
                                sx={{ padding: '6px', border: '0' }}
                                onClick={() => dispatch(navigationActionCreators.navigateToPrograms())}
                            >
                                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                    View Programs
                                </Typography>
                            </Action>
                        )}
                        {mode !== 'edit' && (
                            <Action data-testid={`${htmlId}-edit-link`} onClick={() => setMode('edit')}>
                                <Typography variant="anchor-block-sm" color="base.color.blue.700">
                                    {actionLabel}
                                </Typography>
                            </Action>
                        )}
                    </>
                }
            />
        ),
        [creditDecisionEnabled, mode, actionLabel, dispatch]
    );

    const content = useMemo(
        () => (
            <>
                {offerType === CASH ? (
                    offerType
                ) : (
                    <LenderNameContainer>
                        <Typography variant="h6" color="base.color.black">
                            {lenderLegalName}
                        </Typography>
                    </LenderNameContainer>
                )}
                <ManualRebatesCardContent variant="body-sm" color="base.color.gray.600">
                    <>
                        {mode === 'view' && <RebatesView />}
                        {mode === 'edit' && (
                            <RebatesEdit
                                setIncentivesMode={(mode: 'view' | 'edit') => {
                                    setMode(mode);
                                }}
                            />
                        )}
                    </>
                </ManualRebatesCardContent>
            </>
        ),
        [offerType, lenderLegalName, mode]
    );

    return <StretchHeightCard ref={manualIncentivesRef} data-testid="incentives-card" header={header} content={content} />;
});
