import { getGlobalSelector } from './mmd';
import globalConfig from './config';

export const getRoot = () => getGlobalSelector().additionalSelectors.offerSelectors;

export const getHasBeenSent = (state) => getRoot().getHasBeenSent(state);
export const getIsOfferBeingSubmitted = (state) => getRoot().getIsOfferBeingSubmitted(state);
export const getMonthlyPaymentRoundedByOfferType = (state, offerType) =>
    getRoot().getMonthlyPaymentRoundedByOfferType(state, offerType);
export const getCurrentOfferType = (state) => getRoot().getCurrentOfferType(state);
export const getShopperInfo = (state) => getRoot().getShopperInfo(state);
export const getShopperZip = (state) => getRoot().getShopperZip(state);
export const isMonthlyPaymentInvalid = (state) => getRoot().isMonthlyPaymentInvalid(state);
export const getOfferCreationDateString = (state) => getRoot().getOfferCreationDateString(state);
export const getVehicleDetails = (state) => getRoot().getVehicleDetails(state);
export const getAnalyticsPayloadVehicleDetails = (state) => getRoot().getAnalyticsPayloadVehicleDetails(state);
export const isPaymentInvalid = (payment, offerType, offerPrice?) => getRoot().isPaymentInvalid(payment, offerType, offerPrice);
export const createGatewayOfferPayload = (state, offerUpdateType) => getRoot().createGatewayOfferPayload(state, offerUpdateType);
export const createOfferPayload = (state, offerUpdateType) => getRoot().createOfferPayload(state, offerUpdateType);
export const getOfferDealerSource = (state) => getRoot().getOfferDealerSource(state);

export const actionCreators = globalConfig.offerActionCreators;
