import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { PaymentGridRow } from '@makemydeal/dr-platform-types';
import { useMemo, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useCustomDeepMemo } from './useCustomDeepMemo';
import { notValidPaymentGridRows } from '../terms/gridTable/utils';

export const usePaymentGridValidation = (
    isPaymentError: boolean,
    paymentGridErrorState: {
        hasError?: boolean;
    }
) => {
    const dispatch = useDispatch();

    const paymentGridItems: PaymentGridRow[] = useSelector(offerReduxSelectors.getPaymentGridItems);
    const memoizedPaymentGridItems = useCustomDeepMemo(paymentGridItems);

    const isPaymentGridValid = useMemo(() => {
        return notValidPaymentGridRows(memoizedPaymentGridItems);
    }, [memoizedPaymentGridItems]);

    const [shouldDisablePaymentGridToggle, setShouldDisablePaymentGridToggle] = useState<boolean>(false);

    useEffect(() => {
        const hasError = paymentGridErrorState.hasError ?? false;

        if (isPaymentGridValid && isPaymentError && hasError) {
            dispatch(offerReduxActionCreators.updatePaymentGridToggle(false));
            setShouldDisablePaymentGridToggle(true);
        } else {
            setShouldDisablePaymentGridToggle(false);
        }
    }, [dispatch, isPaymentError, isPaymentGridValid, paymentGridErrorState.hasError]);

    return {
        shouldDisablePaymentGridToggle,
        isPaymentGridValid
    };
};
