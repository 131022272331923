import { connect } from 'react-redux';

import { Actions as actionTypes } from '../../../store/actions';
import { getInstantCashOfferProviderUrl, getTradeInValueSource, getTradeInValueIcoStatus } from '../../../store/mmd/tradeIn';

import { default as externalTradeInEvents } from './externalTradeIn';
import IcoValuationUI from './IcoValuationUI';
import { IIcoValuationUIStateProps } from './IcoValuationUI';
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

import Uri from 'urijs';

export const mapStateToProps = (state: any): IIcoValuationUIStateProps => {
    const sdpDomain = window.location.origin;
    const urlProvider = new Uri(getInstantCashOfferProviderUrl(state));
    const instantCashOfferUrlProvider = urlProvider.addQuery('PostTargetOrigin', sdpDomain).toString();
    const tivIcoStatus = getTradeInValueIcoStatus(state);
    const tivSource = getTradeInValueSource(state);
    const extTradeEventObj = externalTradeInEvents(tivSource)[tivIcoStatus];
    let icoAction;
    if (extTradeEventObj) {
        icoAction = extTradeEventObj.tradeInAction;
    }
    return {
        instantCashOfferUrlProvider,
        icoAction
    };
};
export const mapDispatchToProps = (dispatch: any) => ({
    dispatchCallback: (action) => {
        dispatch(action);
    },
    onCompletedIcoTradeIn: () => {
        dispatch({
            type: actionTypes.UPDATE_ICO_TRADE_COMPLETED
        });
    },
    dispatchAnalytics: (type, description) => {
        dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
    },
    dispatchImpressionTag: () => {
        dispatch(tradeInComponentActionCreators.sendIcoPageOpenedAnalytics());
    }
});

const IcoValuation = connect(mapStateToProps, mapDispatchToProps)(IcoValuationUI);

export default IcoValuation;
