import { useEffect } from 'react';

// components
import { NumericInput } from '@interstate/components/NumericInput';
import { EditedTaxFieldInputContainer } from './EditedTaxField.style';

// actions
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { RetailPriceFieldProps } from '../types';
import { useEditableProp } from './helpers';

export default function TaxItemRateField<Props extends RetailPriceFieldProps>({ data, handleBlur }: Props) {
    const [rate, setRate, isEdited] = useEditableProp(data.rate, data.isEditedRate);

    const handleBlurWrapper = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        if (!rate && !data.rate) {
            setRate('0');
            return;
        }
        handleBlur(e, data.key);
    };

    useEffect(() => {
        if (!data.hasError) {
            setRate(data.rate);
        }
    }, [data.hasError, data.rate, setRate]);

    return (
        <EditedTaxFieldInputContainer $isEdited={isEdited}>
            <NumericInput
                data-testid={`detailed-table-cell-input-${data.name}`}
                name={'detail-rate'}
                inputSuffix={'%'}
                sx={{ width: '9rem' }}
                onChange={(e) => setRate(e.target.value)}
                value={rate}
                onBlur={handleBlurWrapper}
                allowDecimal={true}
                decimalMaxLength={5}
                maxValue={99.99999}
                placeholder={'0'}
                autoInsertCommas={true}
                hasError={data.hasError}
            />
        </EditedTaxFieldInputContainer>
    );
}
