/* istanbul ignore file */

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';

// actions
import { clearIncentives, getIncentivesForSelectedProgramLender } from '../actions/programActionCreators';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { updateCurrentCalculationEndpoint, updatePaymentGridToggle } from '../actions/offerRedux';
import { CALC_ENDPOINTS } from '../constants';
import { isCalculationEndpoint } from '../types';
import { offerReduxSelectors } from '../selectors';

export const programsMiddleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    const state = store.getState();

    switch (action.type) {
        case 'APPLIED_PROGRAM': {
            const usePaymentGridMV = featureToggleSelectors.usePaymentGridMV(state);
            const offerType = offerReduxSelectors.getCurrentOfferType(state);
            const useOfferTypeForCalcEndpointChange = featureToggleSelectors.updateCalcEngineOnDealTypeChange(state);

            if (usePaymentGridMV) {
                store.dispatch(updatePaymentGridToggle(false));

                if (isCalculationEndpoint(CALC_ENDPOINTS.programQuotes)) {
                    if (useOfferTypeForCalcEndpointChange) {
                        store.dispatch(updateCurrentCalculationEndpoint(CALC_ENDPOINTS.programQuotes, offerType));
                    } else {
                        store.dispatch(updateCurrentCalculationEndpoint(CALC_ENDPOINTS.programQuotes));
                    }
                }
            }
            break;
        }
    }

    next(action);

    switch (action.type) {
        case 'APPLIED_PROGRAM': {
            store.dispatch(clearIncentives());
            break;
        }
    }

    if (action.meta?.incentives) {
        store.dispatch(getIncentivesForSelectedProgramLender());
    }
};
