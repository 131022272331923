// TODO: Remove this for testing.
// istanbul ignore file

// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';

// consts/enums
import { CONFIRM_PUSH_TO_ROUTEONE, PUSH_TO_FI } from '../actionTypes/pushToRouteOneActionTypes';

// actions
import {
    clearPushToRouteOneCache,
    pushToRouteOneRSAA,
    updatePushToRouteOneFailureStatus
} from '../actions/pushToRouteOneActionCreators';

// interfaces/types
import { type DashMiddleware, type DashStore, type DashNext, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { saveOffer, smartOfferSave } from '../actions/offerActionCreators';
import { BuildPayloadPurpose } from '../types/offerPayloadUtilsEnums';
import {
    LINKS_FAILURE,
    LINKS_SUCCESS,
    OFFER_SAVE_FAILURE,
    OFFER_SAVE_NOCHANGE_SUCCESS,
    OFFER_SAVE_SUCCESS,
    SOFT_SAVE_FAILURE,
    SOFT_SAVE_SUCCESS
} from '../actionTypes/offerActionTypes';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);
    const state = store.getState();

    switch (action.type) {
        case CONFIRM_PUSH_TO_ROUTEONE:
            store.dispatch(clearPushToRouteOneCache());
            if (featureToggleSelectors.enableMultiScenarioPersistence(state)) {
                store.dispatch(saveOffer({ originalAction: CONFIRM_PUSH_TO_ROUTEONE, source: PUSH_TO_FI }));
            } else {
                store.dispatch(smartOfferSave(state, PUSH_TO_FI, BuildPayloadPurpose.OfferSave));
            }
            break;
        case LINKS_SUCCESS:
        case OFFER_SAVE_NOCHANGE_SUCCESS:
        case SOFT_SAVE_SUCCESS:
        case OFFER_SAVE_SUCCESS:
            if (action.meta?.source === PUSH_TO_FI) {
                store.dispatch(pushToRouteOneRSAA(MANAGER_VIEW_APP_PREFIX, state));
            }
            break;
        case LINKS_FAILURE:
        case SOFT_SAVE_FAILURE:
        case OFFER_SAVE_FAILURE:
            if (action.meta?.source === PUSH_TO_FI) {
                store.dispatch(updatePushToRouteOneFailureStatus());
            }
            break;
        default:
            break;
    }
};
