// externals
import { Reducer } from 'redux';
import assignIn_ from 'lodash.assignin';
import isEmpty_ from 'lodash.isempty';
import cloneDeep_ from 'lodash.clonedeep';
import { produce, Draft } from 'immer';

// libraries
import type {
    ILookupsMake,
    ILookupsModel,
    ILookupsTrim,
    ITradeInVehicle,
    IVehicleMake,
    IVehicleModel,
    IVehicleTrim,
    ILookupsYear,
    IVehicleOption
} from '@makemydeal/dr-activities-common';

// consts/enums
import { Actions, devHelperActions } from './actions';
import { TradeInEvaluationStep } from './actionCreators';

// interfaces/types
import type {
    ISubaruEligibilityNextClickAction,
    ISubaruEligibilityNoVinClickAction,
    ISubaruEligibilitySetErrorAction,
    UpdateTradeVehicleColorAction
} from './actionCreators';
import type { ITradeInComponentState } from '../types/tradeInTypes';

// selectors
import * as tradeInSelectors from './mmd/tradeIn';
import * as globalConfigFeatureToggleSelectors from './globalConfigFeatureToggles';

// devHelper
import { mockValidTrade, mockPaymentFailureTrade, mockNegativeEquityTrade } from './mockData/devHelperMockData';

const assignIn = assignIn_;
const isEmpty = isEmpty_;
const cloneDeep = cloneDeep_;

const initialVehicleMake: IVehicleMake = {
    id: '',
    name: ''
};

const initialVehicleModel: IVehicleModel = {
    id: '',
    name: ''
};

const initialVehicleTrim: IVehicleTrim = {
    id: '',
    name: '',
    vehicleId: ''
};

const initialVehicle: ITradeInVehicle = {
    color: '',
    interiorColor: '',
    condition: globalConfigFeatureToggleSelectors.isP202RedesignEnabled() ? '' : 'Good',
    make: initialVehicleMake,
    mileage: null,
    model: initialVehicleModel,
    trim: initialVehicleTrim,
    vin: '',
    year: ''
};

const initialLookupMakes: ILookupsMake = {
    isLoading: false,
    options: []
};

const initialLookupModels: ILookupsModel = {
    isLoading: false,
    options: []
};

const initialLookupTrims: ILookupsTrim = {
    isLoading: false,
    options: []
};

const initialLookupYears: ILookupsYear = {
    isLoading: false,
    options: []
};

export const getInitialState = (): ITradeInComponentState =>
    cloneDeep({
        tivSource: null,
        amountOwed: 0,
        estimate: [],
        tivOfferDate: undefined,
        tivOfferExpirationDate: undefined,
        shouldIncludeTradeIn: true,
        source: '',
        status: '',
        expirationDate: '',
        offerDate: '',
        offerId: '',
        location: {},
        lookups: {
            conditions: ['Excellent', 'Very Good', 'Good', 'Fair'],
            makes: initialLookupMakes,
            models: initialLookupModels,
            trims: initialLookupTrims,
            years: initialLookupYears,
            ownershipTypes: []
        },
        ownership: '', // Purchase or Lease
        saved: {
            amountOwed: 0,
            estimate: [],
            location: {},
            offerDate: '',
            offerExpirationDate: '',
            status: '',
            tivSource: '',
            value: 0,
            valueComments: '',
            valueSource: ' ',
            vehicle: initialVehicle,
            vehicleOptions: {},
            zip: ''
        },
        tivStatus: '', // dr trade in valuation status
        tivIcoStatus: '', // used for ICO status
        isIcoTradeInCompleted: false, // used for DS UI
        isExternalIcoCompleted: false, // used for SP UI ICO Method page
        tivOfferId: '',
        value: 0,
        vehicle: initialVehicle,
        vehicleClass: '',
        vehicleOptions: {},
        zip: null,
        conflicts: [],
        isCompleted: false,
        currentUserLocationRoute: '',
        tradeSaveFailed: false,
        tradeSavePending: false,
        tradePaymentFailed: false,
        hasTradeInChanged: false,
        tradeInSent: false,
        isTradeValuationReset: false,
        searchResultsForVin: [],
        searchResultsForYMMT: [],
        isSearchingForVin: false,
        isSearchingForYMMT: false,
        errorCount: 0,
        isUnavailable: false,
        isICOTradeAlreadySubmitted: false,
        valueSource: '',
        valueComments: '',
        isShopperEnteredTrade: false,
        isOemInfoRequestInProgress: false,
        isOemInfoRequestFailed: false
    } as ITradeInComponentState);

const resetDraftToInitiateState = (draft: Partial<ITradeInComponentState>) => {
    const initialState = getInitialState();
    draft.tivSource = initialState.tivSource;
    draft.amountOwed = initialState.amountOwed;
    draft.estimate = initialState.estimate;
    draft.tivOfferDate = initialState.tivOfferDate;
    draft.tivOfferExpirationDate = initialState.tivOfferExpirationDate;
    draft.shouldIncludeTradeIn = initialState.shouldIncludeTradeIn;
    draft.source = initialState.source;
    draft.status = initialState.status;
    draft.expirationDate = initialState.expirationDate;
    draft.offerDate = initialState.offerDate;
    draft.offerId = initialState.offerId;
    draft.location = initialState.location;
    if (!draft.lookups) {
        draft.lookups = {};
    }
    draft.lookups.conditions = initialState.lookups.conditions;
    draft.lookups.makes = initialState.lookups.makes;
    draft.lookups.models = initialState.lookups.models;
    draft.lookups.trims = initialState.lookups.trims;
    draft.lookups.years = initialState.lookups.years;
    draft.lookups.ownershipTypes = initialState.lookups.ownershipTypes;
    draft.ownership = initialState.ownership;
    if (!draft.saved) {
        draft.saved = {} as any;
    }
    draft.saved.amountOwed = initialState.saved.amountOwed;
    draft.saved.estimate = initialState.saved.estimate;
    draft.saved.location = initialState.saved.location;
    draft.saved.offerDate = initialState.saved.offerDate;
    draft.saved.offerExpirationDate = initialState.saved.offerExpirationDate;
    draft.saved.status = initialState.saved.status;
    draft.saved.tivSource = initialState.saved.tivSource;
    draft.saved.value = initialState.saved.value;
    draft.saved.valueSource = initialState.saved.valueSource;
    draft.saved.valueComments = initialState.saved.valueComments;
    draft.saved.vehicle = initialState.saved.vehicle;
    draft.saved.vehicleOptions = initialState.saved.vehicleOptions;
    draft.saved.zip = initialState.saved.zip;
    draft.tivStatus = initialState.tivStatus; // dr trade in valuation status
    draft.tivIcoStatus = initialState.tivIcoStatus; // used for ICO status
    draft.isIcoTradeInCompleted = initialState.isIcoTradeInCompleted; // used for DS UI
    draft.isExternalIcoCompleted = initialState.isExternalIcoCompleted; // used for SP UI
    draft.tivOfferId = initialState.tivOfferId;
    draft.value = initialState.value;
    draft.valueSource = initialState.valueSource;
    draft.valueComments = initialState.valueComments;
    draft.vehicle = initialState.vehicle;
    draft.vehicleClass = initialState.vehicleClass;
    draft.vehicleOptions = initialState.vehicleOptions;
    draft.zip = initialState.zip;
    draft.conflicts = initialState.conflicts;
    draft.isCompleted = initialState.isCompleted;
    draft.currentUserLocationRoute = initialState.currentUserLocationRoute;
    draft.tradeSaveFailed = initialState.tradeSaveFailed;
    draft.tradeSavePending = initialState.tradeSavePending;
    draft.tradePaymentFailed = initialState.tradePaymentFailed;
    draft.hasTradeInChanged = initialState.hasTradeInChanged;
    draft.tradeInSent = initialState.tradeInSent;
    draft.isTradeValuationReset = initialState.isTradeValuationReset;
    draft.searchResultsForVin = initialState.searchResultsForVin;
    draft.searchResultsForYMMT = initialState.searchResultsForYMMT;
    draft.isSearchingForVin = initialState.isSearchingForVin;
    draft.isSearchingForYMMT = initialState.isSearchingForYMMT;
    draft.errorCount = initialState.errorCount;
    draft.isUnavailable = initialState.isUnavailable;
    draft.isICOTradeAlreadySubmitted = initialState.isICOTradeAlreadySubmitted;
    draft.valueSource = initialState.valueSource;
    draft.valueComments = initialState.valueComments;
    draft.isShopperEnteredTrade = initialState.isShopperEnteredTrade;
    draft.isOemInfoRequestInProgress = initialState.isOemInfoRequestInProgress;
    draft.isOemInfoRequestFailed = initialState.isOemInfoRequestFailed;
};

const clearVehicleMake = (draft: Partial<ITradeInComponentState>, defaultState) => {
    if (!draft.lookups) {
        draft.lookups = {};
    }
    if (!draft.vehicle) {
        draft.vehicle = {} as any;
    }
    draft.lookups.makes = initialLookupMakes;
    draft.vehicle.make = defaultState.vehicle.make;
};

const clearVehicleModel = (draft: Partial<ITradeInComponentState>, defaultState) => {
    if (!draft.lookups) {
        draft.lookups = {};
    }
    if (!draft.vehicle) {
        draft.vehicle = {} as any;
    }
    draft.lookups.models = initialLookupModels;
    draft.vehicle.model = defaultState.vehicle.model;
};

const clearVehicleTrim = (draft: Partial<ITradeInComponentState>, defaultState) => {
    if (!draft.lookups) {
        draft.lookups = {};
    }
    if (!draft.vehicle) {
        draft.vehicle = {} as any;
    }
    draft.lookups.trims = initialLookupTrims;
    draft.vehicle.trim = defaultState.vehicle.trim;
};

const getStepFromEstimationAction = (action: any): TradeInEvaluationStep => {
    const step = action?.meta?.step;
    if (!step) {
        return TradeInEvaluationStep.None;
    }
    return step as TradeInEvaluationStep;
};

export const reducer: Reducer<ITradeInComponentState> = (state: ITradeInComponentState = getInitialState(), action: any) => {
    return produce(state, (draft: Draft<ITradeInComponentState>) => {
        switch (action.type) {
            case Actions.INIT_SUCCESS: {
                const tradeIn = action.payload.offer.tradeIn || {};
                if (isEmpty(tradeIn)) {
                    const initialState = getInitialState();
                    Object.keys(initialState).forEach((key) => {
                        draft[key] = initialState[key];
                    });
                }
                if (action.payload.offer.isTradeCompleted || !isEmpty(tradeIn)) {
                    draft.id = tradeIn.id;
                    draft.amountOwed = tradeIn.amountOwed;
                    draft.expirationDate = tradeIn.expirationDate;
                    draft.shouldIncludeTradeIn = tradeIn.shouldIncludeTradeIn || tradeIn.isAmountAppliedToFinancing;
                    draft.value = tradeIn.value;
                    draft.valueSource = tradeIn.valueSource;
                    draft.valueComments = tradeIn.valueComments;
                    draft.ownership = tradeIn.ownershipType;
                    draft.vehicleOptions = tradeIn.vehicleOptions;
                    // map vehicle data
                    draft.vehicle = {
                        ...draft.vehicle,
                        year: tradeIn.year,
                        make: { id: tradeIn.makeId, name: tradeIn.make },
                        model: { id: tradeIn.modelId, name: tradeIn.model },
                        trim: { id: tradeIn.trimId, name: tradeIn.trim, vehicleId: tradeIn.vehicleId },
                        mileage: tradeIn.mileage,
                        vin: tradeIn.vin,
                        color: tradeIn.color,
                        interiorColor: tradeIn.interiorColor,
                        condition: tradeIn.condition
                    };
                    draft.isCompleted = true;
                    draft.tradeInSent = true;
                    draft.source = tradeIn.source || '';
                }
                draft.skippedTradeIn = action.payload.offer.isTradeSkipped;
                return;
            }

            case Actions.SAVE_COUNTEROFFER: {
                const { netTradeInEquity } = action.payload.payload;
                if (netTradeInEquity != null) {
                    const amountOwed = tradeInSelectors.getTradeInAmountOwed(state);
                    draft.value = netTradeInEquity + amountOwed;
                }
                return;
            }

            case Actions.REMOVE_TRADE_IN: {
                resetDraftToInitiateState(draft);
                return;
            }

            case Actions.SKIP_TRADE_IN: {
                draft.isCompleted = false;
                draft.skippedTradeIn = true;
                return;
            }
            case Actions.CHOOSE_TRADE_IN: {
                draft.isCompleted = false;
                draft.skippedTradeIn = false;
                return;
            }
            case Actions.COMPLETE_TRADE_IN: {
                draft.hasTradeInChanged = false;
                draft.isCompleted = true;
                draft.skippedTradeIn = false;
                return;
            }
            case Actions.RESET_TRADE_IN: {
                resetDraftToInitiateState(draft);
                return;
            }
            case Actions.RESET_FIELDS_NEW_VIN: {
                draft.vehicle.mileage = null;
                draft.ownership = '';
                draft.amountOwed = 0;
                draft.shouldIncludeTradeIn = true;
                return;
            }
            case Actions.TRADE_IN_REMOVE_REQUEST: {
                draft.tradeSavePending = true;
                return;
            }
            case Actions.TRADE_IN_REMOVE_SUCCESS: {
                draft.isCompleted = false;
                draft.tradeSavePending = false;
                return;
            }
            case Actions.TRADE_IN_OPTIONS_SUCCESS: {
                const options = assignIn({}, action.payload);
                draft.vehicleOptions = options;
                draft.conflicts = [];
                return;
            }
            case Actions.TRADE_IN_VALIDATE_OPTIONS_SUCCESS: {
                const options = assignIn({}, action.payload);
                draft.vehicleOptions = options.kbbOptions;
                draft.conflicts = options.conflicts;
                return;
            }
            case Actions.TRADE_IN_YEARS_SUCCESS: {
                draft.lookups.years.options = assignIn([], action.payload);
                draft.lookups.years.isLoading = false;
                return;
            }
            case Actions.TRADE_IN_MAKES_SUCCESS: {
                draft.lookups.makes.options = assignIn([], action.payload);
                draft.lookups.makes.isLoading = false;
                return;
            }
            case Actions.TRADE_IN_MODELS_SUCCESS: {
                draft.lookups.models.options = assignIn([], action.payload);
                draft.lookups.models.isLoading = false;
                return;
            }
            case Actions.TRADE_IN_TRIMS_SUCCESS: {
                draft.lookups.trims.options = assignIn([], action.payload);
                draft.lookups.trims.isLoading = false;
                return;
            }
            case Actions.TRADE_IN_VEHICLES_SUCCESS: {
                const vehicle = assignIn({}, action.payload);
                draft.vehicle.trim.vehicleId = `${vehicle.vehicleId}`;
                return;
            }
            case Actions.TRADE_IN_MAKES_REQUEST: {
                draft.lookups.makes.isLoading = true;
                return;
            }
            case Actions.TRADE_IN_MODELS_REQUEST: {
                draft.lookups.models.isLoading = true;
                return;
            }
            case Actions.TRADE_IN_TRIMS_REQUEST: {
                draft.lookups.trims.isLoading = true;
                return;
            }
            case Actions.TRADE_IN_YEARS_REQUEST: {
                draft.lookups.years.isLoading = true;
                return;
            }

            case Actions.TRADE_IN_CLEAR_VEHICLE_YMMT: {
                const { make, model, trim } = action.payload;
                const initialState = getInitialState();
                if (make) {
                    clearVehicleMake(draft, initialState);
                }
                if (model) {
                    clearVehicleModel(draft, initialState);
                }
                if (trim) {
                    clearVehicleTrim(draft, initialState);
                }
                return;
            }

            case Actions.UPDATE_TRADE_IN_PAYMENT_PREFERENCE: {
                draft.ownership = action.payload.ownership || '';
                return;
            }

            case Actions.UPDATE_TRADE_IN_AMOUNT_FINANCED: {
                draft.shouldIncludeTradeIn = action.payload.shouldIncludeTradeIn;
                return;
            }
            case Actions.UPDATE_TRADE_IN_VALUE: {
                draft.value = action.payload.value || 0;
                return;
            }

            case Actions.UPDATE_TRADE_IN_ZIP: {
                const { zip } = action.payload;
                draft.zip = zip ? `${zip}` : '';
                return;
            }

            case Actions.TRADE_IN_YEARS_FAILURE: {
                return;
            }

            case Actions.UPDATE_TRADE_IN_CONDITION: {
                const { condition } = action.payload;
                draft.vehicle.condition = condition;
                return;
            }

            case Actions.UPDATE_TRADE_IN_BALANCE_OWED: {
                const { amountOwed } = action.payload;
                draft.amountOwed = amountOwed;
                return;
            }

            case Actions.RESET_TRADE_IN_VALUATION: {
                const { amountOwed, shouldIncludeTradeIn, value } = action.payload;
                draft.shouldIncludeTradeIn = shouldIncludeTradeIn;
                draft.amountOwed = amountOwed;
                draft.value = value;
                return;
            }

            case Actions.UPDATE_RESET_VALUATION_FLAG: {
                draft.isTradeValuationReset = action.payload;
                return;
            }

            case Actions.LOOKUP_ICO_TRADE_SUCCESS: {
                const { yearId, makeId, makeName, modelId, modelName } = action.payload.vehicle;
                draft.year = yearId;
                draft.make = makeName;
                draft.makeId = makeId;
                draft.model = modelName;
                draft.modelId = modelId;
                return;
            }
            case Actions.SUBARU_ELIGIBILITY_CHECK_SUCCESS: {
                const { price } = action.payload;
                if (typeof price === 'number') draft.value = price;
                return;
            }
            case Actions.SUBARU_ELIGIBILITY_IMPROVED_WORKFLOW_CHECK_SUCCESS: {
                const { price } = action.payload;
                if (typeof price === 'number') draft.value = price;
                return;
            }
            case Actions.SUBARU_ELIGIBILITY_IMPROVED_WORKFLOW_FALLBACK: {
                draft.value = 0;
                draft.source = '';
                return;
            }
            case Actions.TRADE_IN_DECISION_CLICKED: {
                draft.inTradeInSubaruGtpFlow = false;
                if (action.payload === 'STANDARD' && draft.isICOTradeAlreadySubmitted === true) {
                    draft.isICOTradeAlreadySubmitted = false;
                    draft.tivSource = null;
                }
                return;
            }

            case Actions.ICO_TRADE_IN_SUCCESS:
            case Actions.ICO_TRADE_IN_COMPLETED: {
                if (!action.payload || isEmpty(action.payload)) {
                    return;
                }

                const trade = action.payload;
                draft.status = trade.status;
                draft.tivSource = trade.tivSource;
                draft.tivStatus = trade.status;
                draft.vehicle = {
                    color: '',
                    condition: 'Good',
                    make: {
                        id: trade.makeId || '',
                        name: trade.make
                    },
                    mileage: trade.mileage,
                    model: {
                        id: trade.modelId || '',
                        name: trade.model
                    },
                    trim: {
                        id: '',
                        name: trade.trim,
                        vehicleId: trade.vehicleId || String(trade.KBBVehicleId)
                    },
                    vin: trade.vin,
                    year: trade.year
                };
                draft.zip = trade.zip;
                draft.value = trade.value;
                draft.offerId = trade.offerId;
                draft.tivOfferExpirationDate = trade.offerExpirationDate;
                draft.tivOfferDate = trade.offerExpirationDate;
                draft.offerDate = trade.offerDate;

                if (draft.tivSource === 'ICO' && draft.status !== 'CLEARED') {
                    draft.isCompleted = true;
                    draft.tradeInSent = false;
                    draft.value = 0;
                }

                return;
            }

            case Actions.TRADE_IN_EXTERNAL_ICO_COMPLETED: {
                draft.isExternalIcoCompleted = action.payload;
                return;
            }

            case Actions.TRADE_IN_ESTIMATION_REQUEST: {
                const step = getStepFromEstimationAction(action);
                if (step === TradeInEvaluationStep.EquipmentOptionStep1) {
                    draft.isEquipOptionsEstimate1InProgress = true;
                }
                return;
            }

            case Actions.TRADE_IN_ESTIMATION_SUCCESS: {
                const step = getStepFromEstimationAction(action);
                if (step === TradeInEvaluationStep.EquipmentOptionStep1) {
                    draft.isEquipOptionsEstimate1InProgress = false;
                }

                const estimate = assignIn([], action.payload);
                draft.estimate = estimate;
                /*
                 * TODO: Determine if tivSource is ever passed in the payload.
                 * This seems highly unlikely, as the payload received at runtime is an array of type ITradeInEstimate[].
                 */
                if (action.payload && action.payload.tivSource !== 'ICO') {
                    // if not ico case, define the value as before; ico is defined in ICO_TRADE_INSUCCESS above
                    const value =
                        estimate.find((item: any) => {
                            return item.selected;
                        })?.value || 0;
                    draft.value = value;
                }
                return;
            }

            case Actions.TRADE_IN_ESTIMATION_FAILURE: {
                const step = getStepFromEstimationAction(action);
                if (step === TradeInEvaluationStep.EquipmentOptionStep1) {
                    draft.isEquipOptionsEstimate1InProgress = false;
                }
                return;
            }

            case Actions.UPDATE_TRADE_IN_VEHICLE_INFO: {
                const vehicle = assignIn({}, action.payload.vehicle || action.payload);
                draft.vehicle = vehicle || {};

                return;
            }
            case Actions.UPDATE_TRADE_IN_CURRENT_LOCATION: {
                draft.currentUserLocationRoute = action.payload.nextRoute;
                return;
            }

            case Actions.UPDATE_TRADE_IN: {
                const location = assignIn({}, draft.location);
                const vehicle = assignIn({}, draft.vehicle);
                const vehicleOptions = assignIn({}, draft.vehicleOptions);

                draft.saved.location = location || {};
                draft.saved.vehicle = vehicle || {};
                draft.saved.vehicleOptions = vehicleOptions || {};
                draft.saved.amountOwed = draft.amountOwed || 0;
                draft.saved.estimate = draft.estimate || [];
                draft.saved.shouldIncludeTradeIn = draft.shouldIncludeTradeIn;
                draft.saved.ownership = draft.ownership || '';
                draft.saved.value = draft.value || 0;
                draft.saved.valueSource = draft.valueSource || ' ';
                draft.saved.valueComments = draft.valueComments || '';
                draft.saved.zip = draft.zip || '';
                return;
            }

            case Actions.UPDATE_TRADE_IN_SOURCE: {
                draft.source = action.payload;
                return;
            }
            case Actions.UPDATE_TRADE_IN_EQUIPMENT_OPTION: {
                const { categoryName, optionValueId, isChecked } = action.payload;

                const newVehicleOptions = draft.vehicleOptions;
                const selectedCategory = newVehicleOptions[categoryName];

                if (!selectedCategory) {
                    return;
                }

                const option: any = selectedCategory.options.find((opt) => opt.id === optionValueId);
                // only update if option is previously unselected
                if (option.isSelected !== isChecked) {
                    option.isSelected = isChecked;
                    option.dateTimeModified = Date.now();
                    // if selected category doesn't have multi selection option,
                    // then unselect all other options for this category
                    if (!selectedCategory.allowMultiSelect) {
                        selectedCategory.options.forEach((opt) => {
                            if (opt.id === optionValueId) {
                                return;
                            }
                            opt.isSelected = !isChecked;
                        });
                    }
                }
                return;
            }
            case Actions.TRADE_IN_DECISION_PAGE_DISPLAYED: {
                draft.inTradeInSubaruGtpFlow = false;
                return;
            }
            case Actions.SET_TRADE_IN_IS_SUBARU_GTP_ELIGIBLE: {
                const { isSubaruGtpEligible = false } = action.payload || {};
                draft.isSubaruGtpEligible = isSubaruGtpEligible;
                return;
            }
            case Actions.SET_IN_TRADE_IN_SUBARU_GTP_FLOW: {
                draft.inTradeInSubaruGtpFlow = true;
                return;
            }
            case Actions.SUBARU_ELIGIBILITY_NO_VIN_CLICK: {
                const actionTyped = action as ISubaruEligibilityNoVinClickAction;
                const { mileage, shouldIncludeTradeIn, amountOwed } = actionTyped.payload || {};
                if (draft.vehicle) {
                    draft.amountOwed = shouldIncludeTradeIn ? amountOwed : 0;
                    draft.shouldIncludeTradeIn = shouldIncludeTradeIn;
                    draft.vehicle.mileage = mileage;
                    draft.vehicle.vin = '';
                }
                return;
            }
            case Actions.RESET_IN_TRADE_IN_SUBARU_GTP_FLOW: {
                draft.inTradeInSubaruGtpFlow = false;
                return;
            }
            case Actions.SET_TRADE_IN_SUBARU_GTP_FLOW_ERROR: {
                const actionTyped = action as ISubaruEligibilitySetErrorAction;
                const { tradeInSubaruGtpFlowError } = actionTyped.payload || {};
                draft.tradeInSubaruGtpFlowError = tradeInSubaruGtpFlowError;
                return;
            }
            case Actions.RESET_TRADE_IN_SUBARU_GTP_FLOW_ERROR: {
                draft.tradeInSubaruGtpFlowError = null;
                return;
            }
            case Actions.SUBARU_ELIGIBILITY_NEXT_CLICK: {
                const actionTyped = action as ISubaruEligibilityNextClickAction;
                const { vin, mileage, shouldIncludeTradeIn, amountOwed } = actionTyped.payload || {};
                if (draft.vehicle) {
                    draft.amountOwed = shouldIncludeTradeIn ? amountOwed : 0;
                    draft.shouldIncludeTradeIn = shouldIncludeTradeIn;
                    draft.vehicle.mileage = mileage;
                    draft.vehicle.vin = vin;
                    draft.source = 'SUBARUGTP';
                }
                return;
            }
            case Actions.SUBARU_ELIGIBILITY_IMPROVED_WORKFLOW_CLICK: {
                const actionTyped = action as ISubaruEligibilityNextClickAction;
                const { vin, mileage, shouldIncludeTradeIn, amountOwed } = actionTyped.payload || {};
                if (draft.vehicle) {
                    draft.amountOwed = shouldIncludeTradeIn ? amountOwed : 0;
                    draft.shouldIncludeTradeIn = shouldIncludeTradeIn;
                    draft.vehicle.mileage = mileage;
                    draft.vehicle.vin = vin;
                    draft.source = 'SUBARUGTP';
                }
                return;
            }
            case Actions.TRADE_IN_SEND_SUCCESS: {
                const { id } = action.payload;
                draft.id = id;
                draft.currentUserLocationRoute = '/vehicleInfo';
                draft.tradeSavePending = false;
                if (globalConfigFeatureToggleSelectors.isP202RedesignEnabled() && draft.tivSource === 'ICO') {
                    draft.isCompleted = true;
                    draft.isICOTradeAlreadySubmitted = true;
                }
                return;
            }
            case Actions.TRADE_IN_SEND_REQUEST: {
                draft.tradeSavePending = true;
                draft.tradeSaveFailed = false;
                return;
            }
            case Actions.TRADE_IN_REMOVE_FAILURE:
            case Actions.TRADE_IN_SEND_FAILURE: {
                draft.errorCount += 1;
                draft.isUnavailable = draft.errorCount > 1;
                draft.tradeSavePending = false;
                draft.tradeSaveFailed = true;
                return;
            }

            case Actions.UPDATE_TRADE_IN_PAYMENT_STATUS: {
                draft.tradePaymentFailed = action.payload;
                return;
            }

            case Actions.TRADE_IN_CHANGED: {
                draft.hasTradeInChanged = action.payload;
                return;
            }
            case Actions.OFFER_SUBMISSION_SUCCESS: {
                // tslint:disable-next-line: no-boolean-literal-compare
                if (draft.isCompleted === true) {
                    draft.tradeInSent = true;
                    draft.hasTradeInChanged = false;
                }
                return;
            }

            case Actions.OFFER_SUBMISSION_FAILURE: {
                draft.tradeInSent = false;
                return;
            }

            case Actions.PAYMENT_FAILURE: {
                draft.tradePaymentFailed = true;
                return;
            }
            case Actions.TEST_DRIVE_REQUESTED_FROM_TRADE_IN: {
                draft.testDriveRequestFromTradeIn = true;
                return;
            }

            case Actions.ROUTE_TEST_DRIVE: {
                draft.testDriveRequestFromTradeIn = false;
                return;
            }

            case Actions.UPDATE_TRADE_VALUE_SOURCE: {
                draft.value = action.payload.value;
                draft.valueSource = action.payload.source;
                draft.valueComments = action.payload.comments;
                return;
            }

            case Actions.TRADE_IN_DECISION_TYPE_CHANGED: {
                draft.isShopperEnteredTrade = false;
                const { option, isShopperEnteredTradeEnabled } = action.payload;
                if (option === 'SHOPPER_ENTERED' && isShopperEnteredTradeEnabled) {
                    draft.isShopperEnteredTrade = true;
                }
                return;
            }

            case Actions.SHOPPER_IN_MANUAL_ENTRY: {
                draft.isShopperEnteredTrade = true;
                return;
            }

            case Actions.DR_BACK_TO_DASHBOARD_CLICKED: {
                draft.isShopperEnteredTrade = false;
                return;
            }

            case Actions.UPDATE_TRADE_VEHICLE_COLOR: {
                const actionTyped = action as UpdateTradeVehicleColorAction;
                draft.vehicle = {
                    ...draft.vehicle,
                    color: actionTyped.payload
                };
                return;
            }

            case Actions.OEM_TRADE_IN_SEND_REQUEST: {
                draft.isOemInfoRequestFailed = false;
                draft.isOemInfoRequestInProgress = true;
                return;
            }

            case Actions.OEM_TRADE_IN_SEND_SUCCESS: {
                const { id } = action.payload;
                draft.id = id;
                draft.isOemInfoRequestFailed = false;
                draft.isOemInfoRequestInProgress = false;
                return;
            }

            case Actions.OEM_TRADE_IN_SEND_FAILURE: {
                draft.isOemInfoRequestFailed = true;
                draft.isOemInfoRequestInProgress = false;
                return;
            }

            // devHelper
            case devHelperActions.ADD_MOCK_VALID_TRADE: {
                draft.currentUserLocationRoute = mockValidTrade.currentUserLocationRoute;
                draft.hasTradeInChanged = mockValidTrade.hasTradeInChanged;
                draft.lookups = mockValidTrade.lookups;
                draft.ownership = mockValidTrade.ownership;
                draft.saved.vehicle = mockValidTrade.saved.vehicle;
                draft.vehicle = mockValidTrade.vehicle;
                draft.vehicleOptions = mockValidTrade.vehicleOptions as IVehicleOption;
                draft.zip = mockValidTrade.zip;
                return;
            }
            case devHelperActions.ADD_MOCK_PAYMENT_FAILURE_TRADE: {
                draft.currentUserLocationRoute = mockPaymentFailureTrade.currentUserLocationRoute;
                draft.hasTradeInChanged = mockPaymentFailureTrade.hasTradeInChanged;
                draft.lookups = mockPaymentFailureTrade.lookups;
                draft.ownership = mockPaymentFailureTrade.ownership;
                draft.saved.vehicle = mockPaymentFailureTrade.saved.vehicle;
                draft.vehicle = mockPaymentFailureTrade.vehicle;
                draft.vehicleOptions = mockPaymentFailureTrade.vehicleOptions as IVehicleOption;
                draft.zip = mockPaymentFailureTrade.zip;
                draft.value = mockPaymentFailureTrade.value;
                draft.estimate = mockPaymentFailureTrade.estimate;
                return;
            }
            case devHelperActions.ADD_NEGATIVE_EQUITY_TRADE: {
                draft.currentUserLocationRoute = mockNegativeEquityTrade.currentUserLocationRoute;
                draft.hasTradeInChanged = mockNegativeEquityTrade.hasTradeInChanged;
                draft.lookups = mockNegativeEquityTrade.lookups;
                draft.ownership = mockNegativeEquityTrade.ownership;
                draft.saved.vehicle = mockNegativeEquityTrade.saved.vehicle;
                draft.vehicle = mockNegativeEquityTrade.vehicle;
                draft.vehicleOptions = mockNegativeEquityTrade.vehicleOptions as IVehicleOption;
                draft.zip = mockNegativeEquityTrade.zip;
                draft.amountOwed = mockNegativeEquityTrade.amountOwed;
                draft.value = mockNegativeEquityTrade.value;
                return;
            }
            // End of devHelper section
            case Actions.TRADE_IN_TOAST_CLOSED: {
                draft.toastClosed = true;
                return;
            }
            case Actions.TRADE_IN_DEAL_CHANGE_MESSAGE_BOX_CLOSED: {
                draft.dealChangeMessageHasBeenClosed = true;
                return;
            }
            case Actions.UPDATE_TRADE_IN_ENTRY_POINT: {
                draft.entryPoint = action.entryPoint;
                return;
            }
        }
    });
};
