import styled from 'styled-components';

export const StyledOfficialCreditDecisionToggle = styled.div`
    overflow: hidden;
    padding-bottom: 1px;

    label {
        width: 100%;
        margin-bottom: 0;
    }
`;
