import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useResizeObserver } from '../useResizeObserver';
import { ExpandableAreaContainer, StyledExpandableArea } from './ExpandableArea.style';
import { ExpandableAreaPropTypes } from './types';
import { useExpandableArea } from './useExpandableArea';
import { DEFAULT_EXPANDABLE_ID, DEFAULT_EXPANDABLE_STATUS } from '../../constants';

/**
 * Wraps the contents you want to collapse. Key is optional; however if you need multiple collapsible areas, specify a key to differentiate them. See: activities-programs/RebatesView for an example! Also there is some documentation noted here: https://ghe.coxautoinc.com/DigitalRetailing/dr-dash/pull/1593
 */
export const ExpandableArea: FC<ExpandableAreaPropTypes> = ({
    expandableId,
    children,
    /* istanbul ignore next */
    defaultExpanded = DEFAULT_EXPANDABLE_STATUS,
    duration,
    ...props
}) => {
    const [height, setHeight] = useState(0);
    const [initializing, setInitializing] = useState(true);
    const { hasId, doToggle, isExpanded } = useExpandableArea();

    const refContainer = useRef<HTMLDivElement>(null);

    // jsdom doesn't make this easy to test.
    useResizeObserver(
        refContainer,
        // istanbul ignore next
        (entry) => {
            const totalHeight = entry.borderBoxSize[0].blockSize;
            setHeight(() => totalHeight);
        }
    );

    const isExpandedOrDefault =
        hasId(expandableId) && expandableId !== DEFAULT_EXPANDABLE_ID ? isExpanded(expandableId) : defaultExpanded;

    useEffect(() => {
        doToggle(expandableId, isExpandedOrDefault);
        // We just don't want to animate when we're initalizing the view; So we'll
        // leave the transition off for a fraction of a second to allow the render to finish.
        // jsdom doesn't make this easy to test.
        const id = setTimeout(
            // istanbul ignore next
            () => {
                setInitializing(false);
            },
            200
        );
        return () => {
            clearTimeout(id);
        };
    }, []);

    return (
        // ...props for additional div props
        <ExpandableAreaContainer {...props}>
            <StyledExpandableArea
                ref={refContainer}
                height={height}
                duration={duration as number} // default prop provided
                expanded={isExpandedOrDefault}
                initializing={initializing}
            >
                {children}
            </StyledExpandableArea>
        </ExpandableAreaContainer>
    );
};

ExpandableArea.defaultProps = {
    expandableId: DEFAULT_EXPANDABLE_ID,
    defaultExpanded: DEFAULT_EXPANDABLE_STATUS,
    duration: 200
};
