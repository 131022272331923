export const APPLY_DEALER_PAYMENT_OVERRIDES = 'APPLY_DEALER_PAYMENT_OVERRIDES';

export const OFFER_SAVE_FAILURE = 'OFFER_SAVE_FAILURE';
export const OFFER_SAVE_NOCHANGE_REQUEST = 'OFFER_SAVE_NOCHANGE_REQUEST';
export const OFFER_SAVE_NOCHANGE_SUCCESS = 'OFFER_SAVE_NOCHANGE_SUCCESS';
export const OFFER_SAVE_REQUEST = 'OFFER_SAVE_REQUEST';
export const OFFER_SAVE_SUCCESS = 'OFFER_SAVE_SUCCESS';
export const LINKS_REQUEST = 'LINKS_REQUEST';
export const LINKS_SUCCESS = 'LINKS_SUCCESS';
export const LINKS_FAILURE = 'LINKS_FAILURE';
export const SOFT_SAVE_REQUEST = 'SOFT_SAVE_REQUEST';
export const SOFT_SAVE_SUCCESS = 'SOFT_SAVE_SUCCESS';
export const SOFT_SAVE_FAILURE = 'SOFT_SAVE_FAILURE';
export const OFFER_SUBMISSION_FAILURE = 'OFFER_SUBMISSION_FAILURE';
export const OFFER_SUBMISSION_REQUEST = 'OFFER_SUBMISSION_REQUEST';
export const OFFER_SUBMISSION_SUCCESS = 'OFFER_SUBMISSION_SUCCESS';

export const PROGRAMS_SELECT = 'PROGRAMS_SELECT';
export const PROGRAMS_END_DAYS = 'PROGRAMS_END_DAYS';
export const PROGRAMS_CLEAR_ALL = 'PROGRAMS_CLEAR_ALL';
export const PROGRAMS_CLEAR_SELECTED = 'PROGRAMS_CLEAR_SELECTED';
export const PROGRAMS_CLEAR_INCENTIVES = 'PROGRAMS_CLEAR_INCENTIVES';
export const PROGRAMS_INCENTIVES_REQUEST = 'PROGRAMS_INCENTIVES_REQUEST';
export const PROGRAMS_INCENTIVES_REQUEST_FAILURE = 'PROGRAMS_INCENTIVES_REQUEST_FAILURE';
export const PROGRAMS_INCENTIVES_REQUEST_SUCCESS = 'PROGRAMS_INCENTIVES_REQUEST_SUCCESS';

export const REQUEST_PROGRAMS = 'REQUEST_PROGRAMS';
export const REQUEST_PROGRAMS_FAILURE = 'REQUEST_PROGRAMS_FAILURE';
export const REQUEST_PROGRAMS_SUCCESS = 'REQUEST_PROGRAMS_SUCCESS';

export const SAVE_OFFER = 'SAVE_OFFER';
export const SELECTED_ANNUAL_MILES = 'SELECTED_ANNUAL_MILES';
export const SELECTED_TERM = 'SELECTED_TERM';
export const SUBMIT_OFFER = 'SUBMIT_OFFER';
export const TOGGLE_INCENTIVE_FOR_PROGRAM = 'TOGGLE_INCENTIVE_FOR_PROGRAM';
export const COBUYER_SHOW_INVALID_MODAL = 'COBUYER_SHOW_INVALID_MODAL';
export const MAKE_CURRENT = 'MAKE_CURRENT';
export const MAKE_CURRENT_COMPLETED = 'MAKE_CURRENT_COMPLETED';

export const UPDATE_SELECTED_INCENTIVES = 'UPDATE_SELECTED_INCENTIVES';
export const UPDATED_ACQFEE_OVERRIDE = 'UPDATED_ACQFEE_OVERRIDE';
export const UPDATED_ACQFEE_UPFRONT_OVERRIDE = 'UPDATED_ACQFEE_UPFRONT_OVERRIDE';
export const UPDATED_ADJUSTED_RESIDUAL_DOLLAR_OVERRIDE = 'UPDATED_ADJUSTED_RESIDUAL_DOLLAR_OVERRIDE';
export const UPDATED_ADJUSTED_RESIDUAL_PERCENT_OVERRIDE = 'UPDATED_ADJUSTED_RESIDUAL_PERCENT_OVERRIDE';
export const UPDATED_BASE_RESIDUAL_DOLLAR_OVERRIDE = 'UPDATED_BASE_RESIDUAL_DOLLAR_OVERRIDE';
export const UPDATED_BASE_RESIDUAL_PERCENT_OVERRIDE = 'UPDATED_BASE_RESIDUAL_PERCENT_OVERRIDE';
export const UPDATED_BUY_RATE_OVERRIDE = 'UPDATED_BUY_RATE_OVERRIDE';
export const UPDATED_CREDIT_DECISION_ENABLED = 'UPDATED_CREDIT_DECISION_ENABLED';
export const UPDATED_CREDIT_DECISION_LENDER_NAME = 'UPDATED_CREDIT_DECISION_LENDER_NAME';
export const UPDATED_CREDIT_DECISION_STATUS = 'UPDATED_CREDIT_DECISION_STATUS';
export const UPDATED_CREDIT_DECISION_TOGGLE = 'UPDATED_CREDIT_DECISION_TOGGLE';
export const UPDATED_DOWN_PAYMENT_IMMEDIATE = 'UPDATED_DOWN_PAYMENT_IMMEDIATE';
export const UPDATED_INCENTIVES_TOTAL_OVERRIDE = 'UPDATED_INCENTIVES_TOTAL_OVERRIDE';
export const UPDATED_MANUAL_INCENTIVES = 'UPDATED_MANUAL_INCENTIVES';
export const UPDATED_OFFER_PRICE_IMMEDIATE = 'UPDATED_OFFER_PRICE_IMMEDIATE';
export const UPDATED_RETAIL_PRICE_OVERRIDE = 'UPDATED_RETAIL_PRICE_OVERRIDE';
export const UPDATED_SECURITY_DEPOSIT_OVERRIDE = 'UPDATED_SECURITY_DEPOSIT_OVERRIDE';
export const UPDATED_SELL_RATE_OVERRIDE = 'UPDATED_SELL_RATE_OVERRIDE';
export const UPDATE_DEAL_REF_ID = 'UPDATE_DEAL_REF_ID';

export const UPDATED_TOTAL_TAX_OVERRIDE = 'UPDATED_TOTAL_TAX_OVERRIDE';
export const UPDATED_TOTAL_TAX_UPFRONT_OVERRIDE = 'UPDATED_TOTAL_TAX_UPFRONT_OVERRIDE';
export const UPDATE_TAX_ITEMS = 'UPDATE_TAX_ITEMS';

export const PAYMENT_FEES_TYPES_REQUEST = 'PAYMENT_FEES_TYPES_REQUEST';
export const PAYMENT_FEES_TYPES_FAILURE = 'PAYMENT_FEES_TYPES_FAILURE';
export const PAYMENT_FEES_TYPES_SUCCESS = 'PAYMENT_FEES_TYPES_SUCCESS';
export const UPDATE_PAYMENT_FEES_OVERRIDE = 'UPDATE_PAYMENT_FEES_OVERRIDE';
