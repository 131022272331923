import * as React from 'react';
import FeaturedProduct from '../featuredProduct/FeaturedProductUI';
import { getOptimizelyTests, activateTests } from '../../../store/mmd/optimizelySelectors';

export interface INextStepsStateProps {
    showTestDrive: boolean;
    showMenu: boolean;
    enableNextSteps: boolean;
}

export interface INextStepsDispatchProps {
    onTestDriveClick: { (): void };
    onVehicleProtectionClick: { (): void };
}

const NextStepsUI: React.FC<INextStepsStateProps & INextStepsDispatchProps> = (props) => {
    const testsToActivate = [getOptimizelyTests().tradeInNextStepsTest];

    React.useEffect(() => {
        activateTests(testsToActivate);
    }, []);
    return (
        props.enableNextSteps &&
        (props.showTestDrive || props.showMenu) && (
            <>
                <hr />
                <h3 className="trade-in-next-steps title">What would you like to do next?</h3>
                <div className="trade-in-next-steps featured-products">
                    {props.showTestDrive && (
                        <FeaturedProduct
                            headline="Schedule a Test Drive"
                            description="Make an appointment to get behind the wheel of your new vehicle."
                            buttonText="Schedule Now"
                            onButtonClick={props.onTestDriveClick}
                        />
                    )}
                    {props.showMenu && (
                        <FeaturedProduct
                            headline="Vehicle Protection Plan"
                            description="Learn about warranties, accessories, and other ways to protect your investment."
                            buttonText="Explore Packages"
                            onButtonClick={props.onVehicleProtectionClick}
                        />
                    )}
                </div>
            </>
        )
    );
};
export default NextStepsUI;
