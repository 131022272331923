import { addEvent, removeEvent } from '../../../utils/browserHelper';
import { tradeInEstimateSuccess, updateTradeInCurrentLocation } from '../../../store/actionCreators';
import { Actions as commonTypes } from '../../../store/actions';
import { default as externalTradeInEvents } from './externalTradeIn';

// import URI from 'urijs';
const URI = require('urijs');

const EXTERNAL_TRADE_ADDED = 'EXTERNAL_TRADE_ADDED';
const EXTERNAL_TRADE_COMPLETED = 'EXTERNAL_TRADE_COMPLETED';

const LISTENER_EVENT_NAME = 'message';
const EXCLUDED_EXTERNAL_EVENTS = ['SendIframeIds'];

export const handler = (() => {
    let payload: any = {};
    let status;
    let source;
    let isValidExternalTradeInStatus;

    function createInstance(dispatch, externalTradeInUrlProvider) {
        return (event) => {
            const domain = new URI(event.origin).domain();
            const allowedDomainOrigin = new URI(externalTradeInUrlProvider).domain();
            if (domain !== allowedDomainOrigin) {
                return;
            }

            let eventData;
            // ignoring this events
            // from KBB SD
            if (EXCLUDED_EXTERNAL_EVENTS.indexOf(event.data) >= 0) {
                return;
            }
            // from redux dev tools
            if (event.data && event.data.source && event.data.source.match(/@devtools/)) {
                return;
            }
            try {
                eventData = event.data && JSON.parse(event.data);
            } catch (error) {
                dispatch({
                    type: commonTypes.ICO_PASS_BACK_FAILURE,
                    payload: error
                });
                return;
            }
            payload = eventData.payload || payload;
            status = payload.status;
            source = payload.tivSource;
            isValidExternalTradeInStatus = externalTradeInEvents(source)[status];

            switch (eventData.type) {
                case EXTERNAL_TRADE_ADDED: {
                    if (isValidExternalTradeInStatus) {
                        dispatch({ type: isValidExternalTradeInStatus.tradeInAction, payload });
                    }
                    break;
                }
                case EXTERNAL_TRADE_COMPLETED:
                    dispatch({ type: commonTypes.ICO_TRADE_IN_COMPLETED, payload });
                    dispatch(tradeInEstimateSuccess());
                    dispatch(updateTradeInCurrentLocation('/valuation'));
                    break;
                default:
                    break;
            }
        };
    }

    return {
        getInstance(dispatch?, externalTradeInUrlProvider?) {
            return createInstance(dispatch, externalTradeInUrlProvider);
        }
    };
})();

// Hooks
export const attachExtTradeInListener = (dispatch, externalTradeInUrlProvider) => {
    const listenerHandler = handler.getInstance(dispatch, externalTradeInUrlProvider);
    addEvent(window, LISTENER_EVENT_NAME, listenerHandler);
};

export const detachExtTradeInListener = () => {
    const listenerHandler = handler.getInstance();
    removeEvent(window, LISTENER_EVENT_NAME, listenerHandler);
};
