// libraries
import type { InternalPrevOfferPayloadData, InternalPrevOfferPayloadState, StateTree } from '@makemydeal/dr-dash-types';

export const getInternalPrevOfferPayloadState = (state: StateTree): InternalPrevOfferPayloadState =>
    state.internalPrevOffer || ({} as InternalPrevOfferPayloadState);

export const getInternalPrevOfferPayload = (state: StateTree): InternalPrevOfferPayloadData | undefined => {
    return getInternalPrevOfferPayloadState(state).data;
};

export const getInternalPrevOfferPayloadDealXgId = (state: StateTree): string | undefined => {
    return getInternalPrevOfferPayloadState(state).dealXgId;
};

export const getInternalPrevOfferPayloadDealXgVersion = (state: StateTree): string | undefined => {
    return getInternalPrevOfferPayloadState(state).dealXgVersion;
};
