// libraries
import { Box } from '@interstate/components/Box';
import { SimpleTable, TableCell } from '@interstate/components/SimpleTable';

// components
import TableHead from './TableHead';
import TableBody from './TableBody';

// styles
import { StyledTableCell, StyledSpan } from './TaxCard.style';

// components
import { DATA_DENSITY_LARGE } from '../../common/taxesAndFeesConsts';
import { DefaultFallback } from '../../common/taxesAndFeesComponents';
import { TAXTABLECOLUMNS } from '../constants';

type FallbackData = {
    name: string;
    options: {
        paddingLeft: number;
        align: string;
    };
};
const fallbackData = [
    { name: 'fallback-tax-name', options: { paddingLeft: 1, align: 'left' } },
    { name: 'fallback-tax-rate', options: { paddingLeft: 0, align: 'center' } },
    { name: 'fallback-tax-amount', options: { paddingLeft: 1.5, align: 'right' } }
];
const EmptyCategoryFallback: React.FC = () => {
    const renderRow = (data: FallbackData[]) =>
        data.map(({ name, options: { align, paddingLeft } }, index) => (
            <TableCell key={`fallback-cell-${index}`}>
                <StyledTableCell $textAlign={align} data-testid={`simple-table-cell-${name}`}>
                    <StyledSpan paddingLeft={paddingLeft}>
                        <DefaultFallback />
                    </StyledSpan>
                </StyledTableCell>
            </TableCell>
        ));

    return (
        <Box sx={{ marginBottom: 3, paddingLeft: 2, paddingRight: 2 }}>
            <SimpleTable data-testid="fallback-table" dataDensity={DATA_DENSITY_LARGE} background="white" hover={false}>
                <TableHead columns={TAXTABLECOLUMNS} />
                <TableBody data={[fallbackData]} renderRow={renderRow} />
            </SimpleTable>
        </Box>
    );
};

export default EmptyCategoryFallback;
