// config
import { getGlobalSelector } from './mmd';

const getRoot = () => getGlobalSelector().additionalSelectors.leadForm;

export const onOpenLeadFormModal = (arg1, arg2, arg3, arg4?) => getRoot().onOpenLeadFormModal(arg1, arg2, arg3, arg4);

export const onFormSubmit = () => getRoot().onFormSubmit();

export const renderLeadForm = (props: any) => getRoot().renderLeadForm(props);

export const isLeadFormSubmittedSuccess = (state: any) => getRoot().isLeadFormSubmittedSuccess(state);

export const getLeadForm = () => getRoot();
