import { useState } from 'react';
import { useResizeObserver } from '@makemydeal/dr-dash-components';

import { isEveryElementOnSameRow } from './components';

export const useCheckElementsOnSameRow = (
    containerRef: React.RefObject<HTMLDivElement>,
    childRefs: React.RefObject<HTMLDivElement>[]
) => {
    const [isOnSameRow, setIsOnSameRow] = useState(true);

    useResizeObserver(containerRef, () => {
        const childElements = childRefs.map((ref) => ref?.current);

        setIsOnSameRow(isEveryElementOnSameRow(childElements));
    });

    return isOnSameRow;
};
