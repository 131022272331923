import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { offerActionCreators, smartOfferSaveSelectors } from '@makemydeal/dr-dash-store';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { FooterActionsType } from '@interstate/components/FooterActions';
import { OptionButton } from '@interstate/components/ActionButtons';
import { Typography } from '@interstate/components/Typography';
import { Modal } from '@interstate/components/Modal';
import { Snackbar } from '@interstate/components/Snackbar';

import { UpdateOfferDialogInterstate } from '../UpdateOfferDialog/UpdateOfferDialog.interstate';

const defaultTimeout = 10 * 60 * 1000; // 10 minutes

const BEFORE_UNLOAD_EVENT = 'beforeunload';
const USER_EVENTS = [
    'mousemove',
    'keydown',
    'wheel',
    'DOMMouseScroll',
    'mousewheel',
    'mousedown',
    'touchstart',
    'touchmove',
    'MSPointerDown',
    'MSPointerMove',
    'visibilitychange'
];

export type UnsavedChangesDialogProps = {
    /** Timeout duration in seconds after which the Unsaved Changes Dialog will appear */
    timeout?: number;
};

export const UnsavedChangesManager = ({ timeout = defaultTimeout }: UnsavedChangesDialogProps) => {
    const enableDraftScenarioPersistence = useSelector(featureToggleSelectors.enableDraftScenarioPersistence);
    const dispatch = useDispatch();
    const store = useStore();
    const timerRef = useRef(null as any);

    // Manages the Snackbar visibility
    const [successNotification, setSuccessNotification] = useState(false);
    // istanbul ignore next
    const snackbarOnCloseCallback = useCallback(() => setSuccessNotification(false), [setSuccessNotification]);

    // Manages the Offer Updating dialog visibility
    const [showUpdateOffer, setShowUpdateOffer] = useState(false);
    // istanbul ignore next
    const updateOfferDialogOnHideCallback = useCallback(() => {
        setShowUpdateOffer(false);
    }, []);
    // istanbul ignore next
    const updateOfferDialogOnSuccessCallback = useCallback(() => {
        setSuccessNotification(true);
    }, []);

    // Unsaved Changes dialog
    const [showUnsavedChangesDialog, setShowUnsavedChangesDialog] = useState(false);

    const hideDialog = useCallback(() => {
        setShowUnsavedChangesDialog(false);
    }, []);

    const showDialog = useCallback(() => {
        const state: any = store.getState();
        const hasUnsavedChanges = smartOfferSaveSelectors.currentOfferHasUnsavedChanges(state);
        if (hasUnsavedChanges) {
            setShowUnsavedChangesDialog(true);
        }
    }, [store]);

    const handleSaveDraft = useCallback(() => {
        dispatch(offerActionCreators.saveOffer({ isDraft: true }));
        hideDialog();
        setShowUpdateOffer(true);
    }, [dispatch, hideDialog]);

    const handleUpdate = useCallback(() => {
        dispatch(offerActionCreators.saveOffer());
        hideDialog();
        setShowUpdateOffer(true);
    }, [dispatch, hideDialog]);

    // Timer management
    // For the hardcoded list of DOM events, restart the timer
    useEffect(() => {
        const restartTimer = () => {
            clearTimeout(timerRef.current);
            timerRef.current = setTimeout(showDialog, timeout);
        };
        USER_EVENTS.forEach((event) => {
            addEventListener(event, restartTimer);
        });
        return () => {
            USER_EVENTS.forEach((event) => {
                removeEventListener(event, restartTimer);
            });
            clearTimeout(timerRef.current);
        };
    }, [showDialog, timeout]);

    // Use the state changes of the dialog visibility to manage the timer
    useEffect(() => {
        // If the dialog visibility changes, clear the timer
        clearTimeout(timerRef.current);
        if (!showUnsavedChangesDialog) {
            // If the dialog is dismissed, start the timer
            timerRef.current = setTimeout(showDialog, timeout);
        }
        return () => {
            clearTimeout(timerRef.current);
        };
    }, [showUnsavedChangesDialog, showDialog, timeout]);

    // Manage beforeunload event. Purposely creating the callback once, so add/remove event listeners
    // refer to the same function in memory, so exempting the exhaustive deps rule for the hook and effect
    const beforeUnloadHandler = useCallback((event: BeforeUnloadEvent) => {
        const state: any = store.getState();
        const hasUnsavedChanges = smartOfferSaveSelectors.currentOfferHasUnsavedChanges(state);
        if (hasUnsavedChanges) {
            event.preventDefault();
            showDialog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
        addEventListener(BEFORE_UNLOAD_EVENT, beforeUnloadHandler);
        return () => {
            removeEventListener(BEFORE_UNLOAD_EVENT, beforeUnloadHandler);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const footer: FooterActionsType = {
        options: [
            {
                action: hideDialog,
                label: 'Cancel',
                buttonStyle: 'tertiary'
            }
        ],
        primary: {
            action: handleUpdate,
            label: 'Update'
        }
    };
    if (enableDraftScenarioPersistence) {
        (footer.options as Array<OptionButton>).push({
            action: handleSaveDraft,
            label: 'Save Draft'
        });
    }
    return (
        <>
            <Snackbar
                data-testid="update-offer-dialog-success-2"
                show={successNotification}
                message="We've updated your changes to this offer."
                position="bottom-center"
                onClose={snackbarOnCloseCallback}
                type="success"
            />
            <UpdateOfferDialogInterstate
                show={showUpdateOffer}
                onHide={updateOfferDialogOnHideCallback}
                onSuccess={updateOfferDialogOnSuccessCallback}
            />
            <Modal
                data-testid="unsaved-changes-dialog"
                show={showUnsavedChangesDialog}
                onHide={hideDialog}
                header={
                    <Typography variant="h3" color="sem.color.on-surface.default">
                        Unsaved Changes
                    </Typography>
                }
                footer={footer}
                size="large"
            >
                You made updates to this deal that have not been saved or shared. You can save as a draft or update to publish
                changes.
            </Modal>
        </>
    );
};

export default UnsavedChangesManager;
