import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import * as tradeInRoutes from '../../../utils/routes';

const NoTradeIn = styled.a`
    width: fit-content;
    display: block;
    margin: auto;
    margin-bottom: ${rem(24)};
    margin-top: ${rem(24)};
`;

export interface NoTradeInButtonProps {
    linkText?: string;
    showOnDisclaimer?: boolean;
    onSkipTradeInClick: { () };
    setTradeInEntryPoint: { (entryPoint: string) };
    currentLocation: string;
    entryPoint: string;
    isComplete: boolean;
    isTradeInImprovedWorkflowEnabled: boolean;
    showOnICO?: boolean;
    showOnImprovedWorkflow?: boolean;
}

export const NoTradeInButtonUI = ({
    linkText = 'No Trade-In',
    showOnDisclaimer = false,
    showOnICO = false,
    showOnImprovedWorkflow = false,
    onSkipTradeInClick,
    setTradeInEntryPoint,
    currentLocation,
    entryPoint,
    isComplete,
    isTradeInImprovedWorkflowEnabled
}: NoTradeInButtonProps) => {
    if (entryPoint === '') {
        setTradeInEntryPoint(currentLocation);
    }

    const onDisclaimerValidated = showOnDisclaimer
        ? currentLocation === tradeInRoutes.TRADE_IN_DECISION
        : currentLocation !== tradeInRoutes.TRADE_IN_DECISION;

    const onICOValidated = showOnICO
        ? currentLocation === tradeInRoutes.TRADE_IN_ICO
        : currentLocation !== tradeInRoutes.TRADE_IN_ICO;

    const showOnImprovedWorkflowValidated = showOnImprovedWorkflow
        ? showOnImprovedWorkflow && isTradeInImprovedWorkflowEnabled
        : !showOnImprovedWorkflow && !isTradeInImprovedWorkflowEnabled;

    const renderComponent =
        !isComplete &&
        entryPoint === currentLocation &&
        // Next condition determines if the link is shown as part of the disclaimer only for the decision route
        // otherwise it is displayed as a child of pageUI component
        // if you have doubts about this behavior, please refer to the story US1065501
        onDisclaimerValidated &&
        // Same as previous condition but for ICO
        onICOValidated &&
        showOnImprovedWorkflowValidated;

    return renderComponent ? (
        <NoTradeIn data-id="no-trade-in-link" className="primary-link" onClick={() => onSkipTradeInClick()}>
            {linkText}
        </NoTradeIn>
    ) : null;
};
