// externals
import { Reducer } from 'redux';

// libraries
import type { VehicleBffData } from '@makemydeal/dr-dash-bff-types';
import { actions as offerReduxActionTypes } from '@makemydeal/dr-offer-redux';
import { initActionTypes } from '@makemydeal/dr-shared-store';
import { vehicleUtils } from '@makemydeal/dr-platform-shared';

// consts/enums
import { UPDATE_VEHICLE_INFO, FETCH_CVI_VEHICLE_SUCCESS } from '../actionTypes/vehicleActionTypes';

// utils
import { DealExchangeValidator } from '@makemydeal/dr-shared-ui-utils';
import { REMOVE_VEHICLE_CHANGE_SUCCESS_SNACKBAR, VEHICLE_CHANGE_SUCCESS } from '@makemydeal/dr-activities-change-vehicle';

const { INIT_PENCIL_SUCCESS } = initActionTypes;

// TODO: DASH - move this to reducer (and resolve dr-activities-common type use - it shouldn't be duplicated)
export interface IVehicleState extends VehicleBffData {
    initialRetailPrice?: number;

    // from CVI
    inventoryDate?: string;
    notes?: string;
    location?: {
        isOnLot: boolean;
        gps?: string;
        lot?: string;
    };
    showVehicleChangeSnackBar?: boolean;
}

export type VehicleReducer = Reducer<IVehicleState>;

export const reducer: VehicleReducer = (state: IVehicleState = {}, action: any) => {
    switch (action.type) {
        case INIT_PENCIL_SUCCESS: {
            return {
                ...state,
                ...action.payload.vehicle,
                retailPrice: action.payload.vehicle.retailPrice || action.payload.vehicle.listedPrice,
                initialRetailPrice: action.payload.vehicle.retailPrice
            };
        }
        case UPDATE_VEHICLE_INFO: {
            return {
                ...state,
                ...action.payload
            };
        }

        case VEHICLE_CHANGE_SUCCESS: {
            return {
                ...state,
                showVehicleChangeSnackBar: true
            };
        }

        case REMOVE_VEHICLE_CHANGE_SUCCESS_SNACKBAR: {
            return {
                ...state,
                showVehicleChangeSnackBar: false
            };
        }

        case FETCH_CVI_VEHICLE_SUCCESS: {
            if (!Array.isArray(action.payload) || action.payload.length > 1 || !action.payload[0]) {
                return state;
            }

            const [cviVehicle] = action.payload;

            return {
                ...state,
                destinationCharge: state.destinationCharge ?? cviVehicle.destinationCharge,
                inventoryDate: cviVehicle.inventoryDate,
                invoiceAmount: state.invoiceAmount ?? cviVehicle.invoicePrice,
                fuel: state.fuel ?? cviVehicle.fuel,
                driveLine: state.driveLine ?? DealExchangeValidator.driveLine(cviVehicle.driveLine),
                notes: cviVehicle.sellerComments,
                location: cviVehicle.location,
                chromeStyleId: state.chromeStyleId || vehicleUtils.parseChromeStyleId(cviVehicle.chromeId)
            };
        }

        case offerReduxActionTypes.VEHICLE_RETAIL_PRICE_CHANGED: {
            return {
                ...state,
                retailPrice: action.payload.retailPrice
            };
        }
        case offerReduxActionTypes.UPDATED_OFFER_PRICE_IMMEDIATE: {
            return {
                ...state,
                listedPrice: action.payload
            };
        }
        case offerReduxActionTypes.SWITCH_VEHICLE:
            return { ...action.payload };
        case offerReduxActionTypes.UPDATED_VEHICLE_INFO_DETAILS:
            return action.payload?.vehicle !== undefined ? { ...state, ...action.payload.vehicle } : state;
        default: {
            return state;
        }
    }
};
