// externals
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

// libraries
import { ITradeInState } from '@makemydeal/dr-activities-common';

// components
import ICOSuccessUI from './IcoSuccessUI';

// components
import { ITradeSummaryUIDispatchProps, ITradeSummaryUIStateProps } from './IcoSuccessUI';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as vehicleSelectors from '../../../store/mmd/vehicle';

// actions
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

// consts/enums
import * as tradeInRoutes from '../../../utils/routes';

// utils
import { getNextCardDetails } from '../../../store/mmd/navigationCard';
import { isShareDealEnabled } from '../../../store/featureToggles';

const mapStateToProps = (state: any): ITradeSummaryUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
    const imageUrl = vehicleSelectors.getVehicleImageUrl(state);
    const hasIcoValue = true;
    const cardDetails = getNextCardDetails(state);
    const showShareDeal = isShareDealEnabled(state);

    return {
        imageUrl,
        tradeIn,
        hasIcoValue,
        cardDetails,
        showShareDeal
    };
};

const mapDispatchToProps = (dispatch: any): ITradeSummaryUIDispatchProps => {
    return {
        goToRoute: (route) => {
            dispatch(push(route));
        },
        editTradeIn: () => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN_ICO));
        },
        saveTradeIn: (option: string) => {
            dispatch(tradeInComponentActionCreators.sendTradeInEstimateDisplayedAnalytics());
            dispatch(tradeInComponentActionCreators.applyAmountOwedAndUpdateValue());
            dispatch(tradeInComponentActionCreators.sendIcoBalanceClickedAnalytics(option));
        },
        updateBalanceOwed: (amountOwed) => {
            dispatch((_dispatch, getState) => {
                const state = getState();
                const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
                let hasAmountOwedChanged = false;

                if (amountOwed !== undefined || tradeIn.saved.amountOwed !== undefined) {
                    hasAmountOwedChanged = amountOwed !== tradeIn.saved.amountOwed;
                }

                dispatch(tradeInComponentActionCreators.changedTradeIn(hasAmountOwedChanged));
                dispatch(tradeInComponentActionCreators.updateTradeInBalanceOwed(amountOwed));
            });
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
        },
        dispatchImpressionTag: () => {
            dispatch(tradeInComponentActionCreators.sendIcoBalanceCardDisplayedAnalytics());
        },
        trackActiveLoanChanging: (option: string) => {
            dispatch(tradeInComponentActionCreators.sendIcoActiveLoanAnalytics(option));
        },
        onBlurAmountOwed: () => {
            dispatch(tradeInComponentActionCreators.sendIcoBalanceChangedAnalytics());
        }
    };
};

const ICOSuccess = connect(mapStateToProps, mapDispatchToProps)(ICOSuccessUI);

export default ICOSuccess;
