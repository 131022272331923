import { useMemo, useCallback, useState, useEffect } from 'react';
import { kebabCase } from '@makemydeal/dr-common-utils';
import { formatAprRate, formatMoneyFactor } from '../../utils/formatUtils';
import {
    APR,
    SELL_RATE,
    RATE,
    GENERIC_LAST_FIELD_CHANGED_ERROR,
    MONEY_FACTOR,
    MONEY_FACTOR_TYPE,
    DEBOUNCE_TIMER
} from '../../constants';
import { TextInput } from '@interstate/components/TextInput';
import { compositeSelectors, offerActionTypes, offerReduxActionCreators, offerSelectors } from '@makemydeal/dr-dash-store';
import { useDispatch, useSelector } from 'react-redux';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

const SellRateField = () => {
    const dispatch = useDispatch();

    const value = useSelector(offerSelectors.getSellRateOverrideWithFallback);
    const rateType = useSelector(offerSelectors.getRateTypeOverrideFromOfferTypeOrSelectedTerm);
    const currentTermMonths = useSelector(offerSelectors.getSelectedTermMonths);
    const failed = useSelector(compositeSelectors.getLastFailureActionIfError(offerActionTypes.UPDATED_SELL_RATE_OVERRIDE));
    const usePaymentGridMV = useSelector(featureToggleSelectors.usePaymentGridMV);

    const failedMessage = failed ? GENERIC_LAST_FIELD_CHANGED_ERROR : undefined;
    // determine formatter by rate type
    const formatter = rateType === MONEY_FACTOR_TYPE ? formatMoneyFactor : formatAprRate;
    const rateTypeLabel = rateType === MONEY_FACTOR_TYPE ? MONEY_FACTOR : APR;

    // grab the current, formatted value
    const formattedValue = useMemo(() => formatter(value), [value, formatter, rateType]);

    // setup state for the actual input; default with currently formatted state value
    const [inputText, setInputText] = useState(formattedValue);

    // action creator to also consider selected term months
    const actionCreator = useCallback(
        (newValue: number) =>
            offerReduxActionCreators.updateSellRateOverride(newValue, currentTermMonths, {
                usePaymentGridMV
            }),
        [currentTermMonths]
    );

    const handleInputChanged = (e: any) => {
        if (e.target.value !== inputText) setInputText(e.target.value);
    };
    const handleInputBlur = () => {
        const value = +inputText;
        const formatted = formatter(value);
        if (formatted !== inputText) setInputText(formatted);
    };

    // We're intentionally not using usePaymentCall here and manually implementing a debouncer due to the complexity of action creator memoized by selected term months.
    useEffect(() => {
        // NOTE: only dispatch if the actual parsed value is different from redux state
        if (+inputText === value) return undefined;

        const id = setTimeout(() => {
            dispatch(actionCreator(+inputText));
        }, DEBOUNCE_TIMER);
        return () => {
            clearTimeout(id);
        };
    }, [inputText]);

    // NOTE: upstream change; i.e. term changed in redux causing new override to be set
    // TODO: future integration test here.
    // istanbul ignore next
    useEffect(() => {
        if (inputText === formattedValue) return undefined;
        setInputText(formattedValue);
        return undefined;
    }, [formattedValue]);

    return (
        <TextInput
            label={`${RATE} (${rateTypeLabel})`}
            name={kebabCase(SELL_RATE)}
            onChange={handleInputChanged}
            onBlur={handleInputBlur}
            hasError={failed}
            errorMessage={failedMessage}
            placeholder={formatter(0)}
            value={inputText}
            data-testid={kebabCase(SELL_RATE)}
        />
    );
};

export default SellRateField;
