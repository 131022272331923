import * as React from 'react';
import { Tooltip } from 'react-tooltip'

// tslint:disable:max-line-length

export interface IWarningIconUIState {}

export interface IWarningIconUIProps {
    title: string;
}

class WarningIconUI extends React.Component<IWarningIconUIProps, IWarningIconUIState> {
    render() {
        return (
            <div className="warning-icon-container">
                <a data-tip data-for="message">
                    <svg height="16" version="1.1" viewBox="0 0 16 16" width="16" style={{ display: 'inline-block' }}>
                        <path d="M8.865 1.52c-.18-.31-.51-.5-.87-.5s-.69.19-.87.5L.275 13.5c-.18.31-.18.69 0 1 .19.31.52.5.87.5h13.7c.36 0 .69-.19.86-.5.17-.31.18-.69.01-1L8.865 1.52zM8.995 13h-2v-2h2v2zm0-3h-2V6h2v4z"></path>
                    </svg>
                </a>
                <Tooltip id="message" place="top" variant="dark" float>
                    <span className="toolTipMessage">{this.props.title}</span>
                </Tooltip>
            </div>
        );
    }
}

export default WarningIconUI;
