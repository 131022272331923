import { useState, FC, useCallback } from 'react';

import { Action, ActionProps } from '@interstate/components/Action';
import { CarSolidIcon } from '@interstate/components/Icons';
import { ContentForm } from './components/ContentForm';
import { FormContext } from './formManager/formContext';
import useForm from './formManager/useForm';
import DrawerFooterActions from './components/DrawerFooterActions';
import { StyledVehicleInfoDrawer, VehicleInfoDrawerContentWrapper } from './VehicleInfoDrawer.style';
import { Snackbar } from '@interstate/components/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { REMOVE_VEHICLE_CHANGE_SUCCESS_SNACKBAR } from '@makemydeal/dr-activities-change-vehicle';
import { vehicleSelectors } from '@makemydeal/dr-dash-store';

export type VehicleInfoDrawerProps = {
    actionTitle?: React.ReactNode;
    actionOverrides?: Partial<ActionProps>;
};

export const VehicleInfoDrawer: FC<VehicleInfoDrawerProps> = ({ actionTitle = 'Vehicle', actionOverrides = {} }) => {
    const [showDrawer, setShowDrawer] = useState(false);
    const dispatch = useDispatch();
    const showVehicleSuccessSnackBar = useSelector(vehicleSelectors.showVehicleChangeSnackBar);

    const handleSnackbarClose = () => {
        dispatch({ type: REMOVE_VEHICLE_CHANGE_SUCCESS_SNACKBAR });
    };

    const hideDrawer = useCallback(() => setShowDrawer(false), []);
    const formManager = useForm(hideDrawer);

    return (
        <>
            <Action
                size="sm"
                data-testid="btnVehicleInfo"
                sx={{
                    '.ids-action': {
                        gap: '4px'
                    }
                }}
                actionIcon={{
                    icon: <CarSolidIcon />,
                    start: true
                }}
                onClick={() => setShowDrawer(true)}
                {...actionOverrides}
            >
                {actionTitle}
            </Action>

            <Snackbar
                data-testid="vehicle-change-success-popup"
                show={showVehicleSuccessSnackBar}
                message="Vehicle Successfully Changed!"
                position="bottom-center"
                onClose={() => handleSnackbarClose()}
                type="success"
                autoHide={true}
            />
            <FormContext.Provider value={formManager}>
                <StyledVehicleInfoDrawer
                    data-testid="vehicle-info-drawer"
                    id="vehicle-info-drawer"
                    show={showDrawer}
                    position="right"
                    panelWidth="480px"
                    onHide={hideDrawer}
                    header="Vehicle Details"
                >
                    <VehicleInfoDrawerContentWrapper>
                        <ContentForm />
                        <DrawerFooterActions />
                    </VehicleInfoDrawerContentWrapper>
                </StyledVehicleInfoDrawer>
            </FormContext.Provider>
        </>
    );
};

export default VehicleInfoDrawer;
