import styled, { css } from 'styled-components';

const VehicleInfoDetailsContainer = css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 30px;
`;

const ErrorMessage = css`
    .error-div {
        display: flex;
        align-items: center;
        margin-top: 0.125rem;
    }

    .error-message {
        font-size: 0.75rem;
        margin-left: 0.375rem;
        color: ${({ theme }) => theme.tradeInVehicleInfoErrorMessageColor};
    }

    .error-icon {
        line-height: 0;
    }

    .field-not-filled-in input,
    .field-not-filled-in select {
        border: 1px solid ${({ theme }) => theme.errorInputFieldBorderColor} !important;
    }
`;

const UIBricks = css`
    .ui-bricks {
        & input,
        & select {
            height: 46px;
        }
    }

    select.ui-bricks {
        min-width: unset;
        min-height: unset;
        height: auto;
        font-size: 1rem;
        padding-right: 30px;
    }
`;

const Spinner = css`
    .dr-sp-spinner {
        min-height: unset;
        position: absolute;
        top: 54%;
        transform: translateY(-50%);
        right: 1rem;
    }
`;

const FormGroup = css`
    margin-top: 30px;

    & > div > div {
        padding: 0;
    }

    & > div {
        position: relative;
    }

    label {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: normal;
        margin-bottom: 0px;
        font-weight: ${({ theme }) => theme.tradeInVehicleInfoLabelFontWeight};
        color: ${({ theme }) => theme.tradeInLabelColor};
    }

    .hide {
        display: none;
    }

    select:focus,
    input[type='text']:focus {
        box-shadow: ${({ theme }) => theme.tradeInVehicleInfoInputBoxShadow};
    }

    ${ErrorMessage}

    ${Spinner}

    ${UIBricks}
`;

export const VehicleInfoDetailsVinContainer = styled.div`
    ${VehicleInfoDetailsContainer}

    grid-template-areas:
        "vehicle-info-labels ."
        "trim-input mileage-input"
        "zip-input ownership-input"
        "amountOwed-input .";

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-template-areas:
        "vehicle-info-labels"
        "cardSubaruGTPEnable"
        "trim-input"
        "mileage-input"
        "zip-input"
        "ownership-input"
        "amountOwed-input";
    }
`;

export const VehicleInfoDetailsManualContainer = styled.div`
    ${VehicleInfoDetailsContainer}

    grid-template-areas:
        "year-input make-input"
        "model-input trim-input"
        "mileage-input zip-input"
        "ownership-input amountOwed-input";

    @media (max-width: 480px) {
        grid-template-columns: 1fr;
        grid-template-areas:
            "year-input"
            "make-input"
            "model-input"
            "trim-input"
            "mileage-input"
            "zip-input"
            "ownership-input"
            "amountOwed-input";
    }
`;

export const VehicleInfoDetailsLabelsContainer = styled.div`
    grid-area: vehicle-info-labels;
    margin-top: 32px;

    label {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: normal;
        font-weight: ${({ theme }) => theme.tradeInVehicleInfoLabelFontWeight};
        color: ${({ theme }) => theme.tradeInLabelColor};
    }

    p {
        margin: 0px;
    }
`;

export const CardIsSubaruGTPEligible = styled.div`
    display: flex ;
    margin-top: 54px;
    font-size: 12px;
    width: 302px ;
    .dr-ui-subaru-gtp-logo {
        & svg {
            width: 53px;
            height: 53px;
        }
    }
    & .subaru-gtp-p{
        margin-left: 16px;
    }

    @media (max-width: 480px) {
        margin-top: 16px;
        width: 100% ;
    }
`;

export const YearInputContainer = styled.div`
    ${FormGroup}
    grid-area: year-input;
`;

export const MakeInputContainer = styled.div`
    ${FormGroup}
    grid-area: make-input;
`;

export const ModelInputContainer = styled.div`
    ${FormGroup}
    grid-area: model-input;
`;

export const TrimInputContainer = styled.div`
    ${FormGroup}
    grid-area: trim-input;
`;

export const MileageInputContainer = styled.div`
    ${FormGroup}
    grid-area: mileage-input;
`;

export const ZipInputContainer = styled.div`
    ${FormGroup}
    grid-area: zip-input;
`;

export const OwnershipInputContainer = styled.div`
    ${FormGroup}
    grid-area: ownership-input;
`;

export const AmountOwedInputContainer = styled.div`
    ${FormGroup}
    grid-area: amountOwed-input;
`;
