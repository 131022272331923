// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { offerSessionUtils, queryParamStore } from '@makemydeal/dr-shared-ui-utils';
import {
    featureToggleSelectors,
    initActionTypes,
    selectedOfferSelectors,
    type ReduxNext,
    bridgeUserActionTypes
} from '@makemydeal/dr-shared-store';
import { offerInfoSelectors } from '../selectors';

// consts/enums
import {
    MAKE_CURRENT_COMPLETED,
    OFFER_SAVE_FAILURE,
    OFFER_SAVE_NOCHANGE_REQUEST,
    OFFER_SAVE_SUCCESS,
    SAVE_OFFER
} from '../actionTypes/offerActionTypes';
import { DASH_MENU_LIFECYCLE_INIT_FINISH } from '../actionTypes/vehicleProtectionActionTypes';
import { INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT } from '../actionTypes/internalPrevOfferActionTypes';
import { BuildPayloadPurpose } from '../types/offerPayloadUtilsEnums';

// actions
import {
    buildOfferSavePayloadObject,
    offerSaveNoChangeSuccess,
    saveOfferRSAA,
    smartOfferSave
} from '../actions/offerActionCreators';

// interfaces/types
import { deskingActionTypes } from '../actionTypes';

// utils
import { savedOfferPayloadRegistry } from '../utils/savedOfferPayloadRegistry';
import {
    UpdateInternalPrevOfferAfterInitAction,
    internalPatchPrevOfferPayloadAftermarketItems,
    internalUpdatePrevOfferFullPayload,
    updatePrevOfferAfterInit
} from '../actions/internalPrevOfferActionCreators';
import { SCENARIOS_FETCH_SUCCESS, SCENARIOS_INGEST_COMPLETE } from '../actionTypes/multiScenarioActionTypes';
import * as offerSelectors from '../selectors/offerRedux';
import * as multiScenarioActionCreators from '../actions//multiScenarioActionCreators';

const { BOOTSTRAP_SUCCESS, INIT_PENCIL_SUCCESS, INIT_SUCCESS } = initActionTypes;

// TODO: Revamp the `store` type to more accurately reflect the multi-scenario work
export const middleware = (store: any) => (next: ReduxNext) => (action: AnyFSA) => {
    next(action);

    const state = store.getState();

    switch (action.type) {
        // just for potential backwards compatibility with the other activities from SP
        case INIT_PENCIL_SUCCESS: {
            let resetPerformed = false;
            if (savedOfferPayloadRegistry.isResetAllowed()) {
                savedOfferPayloadRegistry.resetState();
                resetPerformed = true;
            }
            if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                // eslint-disable-next-line no-console
                console.log(
                    `DASH DEBUG: "Smart Offer Save", resetting Deal XG ID and Version in Save Offer Registry ` +
                        `(reset: ${resetPerformed})`
                );
            }
            const selectedOffer = action.payload.offer || {};
            const { commonConsumerId: commonConsumerIdFromOffer, commonOrgId: commonOrgIdFromOffer } = selectedOffer;
            const commonConsumerId = commonConsumerIdFromOffer || offerSessionUtils.getCommonConsumerId();
            const commonOrgId = commonOrgIdFromOffer || offerSessionUtils.getCommonOrgId();
            if (commonConsumerIdFromOffer) {
                offerSessionUtils.setCommonConsumerId(selectedOffer.commonConsumerId);
            }
            if (commonOrgIdFromOffer) {
                offerSessionUtils.setCommonOrgId(selectedOffer.commonOrgId);
            }

            action.payload.offer = { ...action.payload.offer, commonOrgId, commonConsumerId };

            store.dispatch({ type: INIT_SUCCESS, payload: action.payload });

            break;
        }
        case bridgeUserActionTypes.BRIDGE_USER_SUCCESS:
        case SCENARIOS_INGEST_COMPLETE:
        case OFFER_SAVE_SUCCESS:
        case BOOTSTRAP_SUCCESS: {
            const dealXgId = selectedOfferSelectors.getSelectedOfferDealXgId(state);
            if (dealXgId) {
                store.dispatch(updatePrevOfferAfterInit(dealXgId));
            }
            break;
        }
        case INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT: {
            const actionTyped = action as UpdateInternalPrevOfferAfterInitAction;
            const offerSavePayload = buildOfferSavePayloadObject(state);
            const dealXgId = actionTyped.payload?.dealXgId || '';
            const dealXgVersion = offerSavePayload?.offer?.dealExchangeVersionId || '';
            if (queryParamStore.isSmartOfferSavedDebuggingEnabled()) {
                // eslint-disable-next-line no-console
                console.log(
                    `DASH DEBUG: "Smart Offer Save" (INTERNAL_UPDATE_PREV_OFFER_AFTER_INIT): Deal XG ID = ${dealXgId}, Version = ${dealXgVersion}`
                );
            }
            store.dispatch(internalUpdatePrevOfferFullPayload(dealXgId, dealXgVersion, offerSavePayload));
            break;
        }
        case DASH_MENU_LIFECYCLE_INIT_FINISH: {
            const offerSavePayload = buildOfferSavePayloadObject(state, BuildPayloadPurpose.MenuInit);
            const dealXgId = offerSelectors.getDealXgId(state);
            if (dealXgId) {
                store.dispatch(
                    internalPatchPrevOfferPayloadAftermarketItems(
                        offerSavePayload?.menu?.menuProducts,
                        offerSavePayload?.accessories
                    )
                );
            }
            break;
        }

        case SAVE_OFFER: {
            const purpose =
                action.meta?.originalAction === deskingActionTypes.CONTINUE_IN_DESKING
                    ? BuildPayloadPurpose.ContinueInDesking
                    : BuildPayloadPurpose.OfferSave;
            const state = store.getState();

            const hasPaymentGrid =
                featureToggleSelectors.usePaymentGridMV(state) &&
                offerSelectors.getPaymentGridToggle(state, offerSelectors.getCurrentOfferType(state));
            if (featureToggleSelectors.enableMultiScenarioPersistence(state) || hasPaymentGrid) {
                const { isDraft } = action.meta || {};
                const offerSavePayload = buildOfferSavePayloadObject(state);
                const saveOfferAction: any = saveOfferRSAA({ state, source: action.meta?.source, offerSavePayload, isDraft });
                store.dispatch(saveOfferAction);
                break;
            }

            const saveOfferAction = smartOfferSave(state, undefined, purpose);
            store.dispatch(saveOfferAction);
            break;
        }

        case OFFER_SAVE_NOCHANGE_REQUEST: {
            const dealXgId = action.payload.dealXgId;
            const dealXgVersion = action.payload.dealXgVersion;
            const offerSaveSource = action.meta?.source;
            setTimeout(() => {
                store.dispatch(offerSaveNoChangeSuccess(dealXgId, dealXgVersion, offerSaveSource));
            }, 1000);
            break;
        }

        case OFFER_SAVE_FAILURE: {
            const offerSavePayloadIndex = action.meta?.offerSavePayloadIndex;
            savedOfferPayloadRegistry.dequeueOfferPayloadObject(offerSavePayloadIndex);
            break;
        }

        case SCENARIOS_FETCH_SUCCESS: {
            const { scenarios } = action.payload;
            if (offerInfoSelectors.getIsMakingCurrent(state)) {
                store.dispatch({ type: MAKE_CURRENT_COMPLETED });
                store.dispatch(multiScenarioActionCreators.addMakeCurrentAlternateScenariosToState(scenarios));
                break;
            }

            store.dispatch(multiScenarioActionCreators.addAlternateScenariosToState(scenarios));
            break;
        }

        default:
            break;
    }
};
