// externals
import * as React from 'react';
import { Component } from 'react';

// libraries
import { ActionFooterBar } from '@makemydeal/dr-activities-common';
import { ITradeInState } from '@makemydeal/dr-activities-common';

// components
import Header from '../header/Header';
import RadioButtonUI from '../radioButton/RadioButtonUI';
import NextSteps from './NextSteps';
import NextActivityFooterUI from '../nextActivityFooter/NextActivityFooterUI';

export interface IEquityUIStateProps {
    buttonCTAMessage: string;
    condition: string;
    conditionMileageZipSubtitle: string;
    difference: string;
    enableNextSteps: boolean;
    excellentEstimate: string;
    expirationRange: string;
    fairEstimate: string;
    headerSubTitle: string;
    hideRequestTestDrive: boolean;
    shouldIncludeTradeIn: boolean;
    isIcoTradeInFlow: boolean;
    isIcoTradeInNotSiteDriverFlow: boolean;
    isPaymentInvalid: boolean;
    isProcessing?: boolean;
    isTradeInCompleted: boolean;
    isTradeInNegative: boolean;
    mileage: string;
    offerSubmitFailed?: boolean;
    pageTip: string;
    pageTitle: string;
    showTradeInApplyOptions: boolean;
    skipUpdateEstimate: boolean;
    testDriveAtHomeOnTradeInToggle: boolean;
    tradeIn: ITradeInState;
    tradeSaveFailure: boolean;
    tradeSavePending: boolean;
    vehicleName: string;
    zip: string;
}

export interface IEquityUIDispatchProps {
    applyTradeIn: {
        (shouldIncludeTradeIn: boolean, appliedFromViewOfferSummaryLink?: boolean, requestedTestDrive?: boolean): void;
    };
    previous: { () };
    removeTrade: { (): void };
    perfomTradeInValuation: { (tradeInComponent: ITradeInState): void };
    startNewTradeIn: { (): void };
    tradeInEstimateReceived: { () };
    goToNextAvailableRoute: { () };
    onTradeInUpdated: { (applyToAmountFinanced: boolean) };
}

export interface IEquityUIProps extends IEquityUIStateProps, IEquityUIDispatchProps {}

export interface IEquityUIState {
    applyToAmountFinanced: boolean;
}

class EquityUI extends Component<IEquityUIProps, IEquityUIState> {
    constructor(props) {
        super(props);
        this.state = { applyToAmountFinanced: props.shouldIncludeTradeIn };
    }

    componentDidMount() {
        const { applyToAmountFinanced } = this.state;
        const {
            isPaymentInvalid,
            tradeSaveFailure,
            applyTradeIn,
            isTradeInCompleted,
            tradeIn,
            skipUpdateEstimate,
            tradeInEstimateReceived
        } = this.props;
        tradeInEstimateReceived();
        if (isPaymentInvalid || tradeSaveFailure) {
            applyTradeIn(applyToAmountFinanced);
        }

        if (isTradeInCompleted && !skipUpdateEstimate) {
            this.props.perfomTradeInValuation(tradeIn);
        }
    }

    next = (appliedFromViewOfferSummaryLink = false, requestedTestDrive = false): void => {
        const { applyToAmountFinanced } = this.state;
        const { isPaymentInvalid, tradeSaveFailure, tradeSavePending, applyTradeIn } = this.props;
        const hasPageError = isPaymentInvalid || (tradeSaveFailure && !tradeSavePending);

        if (!hasPageError) {
            applyTradeIn(applyToAmountFinanced, appliedFromViewOfferSummaryLink, requestedTestDrive);
        }
    };

    onRadioChangeHandler = (value) => {
        this.setState({
            applyToAmountFinanced: value
        });
        this.props.onTradeInUpdated(value);
    };

    renderTradeInApplyOptions = (showTradeInApplyOptions) => {
        const { fairEstimate, excellentEstimate, shouldIncludeTradeIn, isIcoTradeInFlow, isTradeInNegative } = this.props;

        const negativeWarning = isTradeInNegative ? (
            <p className="danger dr-ui-trade-equity__negative-value-title">
                <b>Your trade-in has a negative equity.</b>
            </p>
        ) : null;

        const valueRanges = isIcoTradeInFlow ? null : (
            <div className="dr-ui-trade-equity__range">
                Value range from Fair
                <span> {fairEstimate} </span>
                to Excellent
                <span> {excellentEstimate}</span>.
            </div>
        );

        return showTradeInApplyOptions ? (
            <section className="row dr-ui-trade-equity__negative-value-opts">
                {negativeWarning}
                <div className="radio-button-container">
                    <RadioButtonUI
                        defaultChecked={shouldIncludeTradeIn}
                        id="finance"
                        name="tradeInEstimate"
                        label="Apply to my amount financed"
                        sublabel={
                            'When you apply a negative equity to your financed amount, the lender may have a limit to the amount ' +
                            'of equity you can finance (called your Loan to Value)'
                        }
                        onChange={() => {
                            this.onRadioChangeHandler(true);
                        }}
                        value={shouldIncludeTradeIn}
                    />
                </div>

                <div className="radio-button-container">
                    <RadioButtonUI
                        defaultChecked={!shouldIncludeTradeIn}
                        id="cash"
                        name="tradeInEstimate"
                        label="Apply to my cash down"
                        onChange={() => {
                            this.onRadioChangeHandler(false);
                        }}
                        value={shouldIncludeTradeIn}
                    />
                </div>
            </section>
        ) : (
            <section className="dr-ui-trade-equity__disclaimer">
                {negativeWarning}
                {valueRanges}
            </section>
        );
    };
    render() {
        const {
            buttonCTAMessage,
            conditionMileageZipSubtitle,
            enableNextSteps,
            expirationRange,
            headerSubTitle,
            hideRequestTestDrive,
            isIcoTradeInFlow,
            isIcoTradeInNotSiteDriverFlow,
            isPaymentInvalid,
            isProcessing,
            isTradeInCompleted,
            offerSubmitFailed,
            pageTip,
            pageTitle,
            previous,
            showTradeInApplyOptions,
            testDriveAtHomeOnTradeInToggle,
            tradeSaveFailure,
            tradeSavePending
        } = this.props;

        const hasPageError = isPaymentInvalid || (tradeSaveFailure && !tradeSavePending);

        const startNewIcoTradeInLink = (
            <a
                className="btn-link link link-trade-equity"
                id="dr_ico_trade_equity_start_new_link"
                onClick={this.props.startNewTradeIn}
            >
                Start New
            </a>
        );

        const removeIcoTradeInLink = (
            <a className="btn-link link link-trade-equity" id="dr_ico_trade_equity_remove_link" onClick={this.props.removeTrade}>
                Remove
            </a>
        );

        const startNewAndRemove =
            isIcoTradeInFlow && isTradeInCompleted ? (
                <p>
                    {startNewIcoTradeInLink} | {removeIcoTradeInLink}
                </p>
            ) : null;

        const icoDisclaimer = (
            // tslint:disable-next-line
            <p>
                Instant Cash Offers ("Offer(s)") are not available in all areas and not all vehicles are eligible. Your Offer is
                valid at Participating Dealers for 3 days (not counting Sundays) as stated on the Offer Certificate. Kelley Blue
                Book will not redeem Offers nor purchase your vehicle from you. Vehicles must pass a mandatory inspection by a
                Participating Dealer. If the inspection report differs from your description or online assessment of your vehicle’s
                condition, the Participating Dealer may adjust the offer amount, which may result in a decrease of the offer. Kelley
                Blue Book and its affiliates disclaim all liability resulting from an adjustment of the offer or refusal to accept
                your vehicle by Participating Dealer(s). Participating Dealers are not owned or operated by, nor are they affiliated
                with or acting on behalf of, Kelley Blue Book. Participating Dealers pay Kelley Blue Book or its affiliate to
                subscribe to the Kelley Blue Book Instant Cash Offer program. The Offer may not reflect the highest sale price or
                trade-in value available for your vehicle, and may be less than the Kelley Blue Book Trade-In Value or the Trade-In
                Range for a similar vehicle. Program Terms and Conditions apply and can be found
                <a href="https://www.kbb.com/company/terms-of-service/#termsAndConditions" target="_blank">
                    here
                </a>
                .
            </p>
        );

        const nonIcoDisclaimer = (
            <p className="disclaimer">
                Kelley Blue Book Co., Inc. All Rights Reserved. {expirationRange}
                Values and pricing are the opinions of Kelley Blue Book, and the actual price of a vehicle may vary. The value and
                pricing information displayed for a particular vehicle is based upon the specification, mileage and/or condition
                information provided by the person generating this report. Kelley Blue Book assumes no responsibility for errors or
                omissions. The amount of any offer for your trade-in will be finalized by us upon actual inspection of your vehicle.
                In addition, if you have provided us with an amount owed on trade in, we have relied on this amount as part of the
                estimate of your net trade in.
            </p>
        );

        return (
            <div id="dr-trade-equity" className="dr-ui-trade-equity">
                <Header pageTitle={pageTitle} pageTip={pageTip} headerSubTitle={headerSubTitle} showTradeErrorMessage={true} />

                <section className="dr-ui-trade-equity__vehicle-desc">
                    <p className="dr-ui-trade-equity__vehicle-condition-desc"> {conditionMileageZipSubtitle} </p>
                    {startNewAndRemove}
                </section>

                {this.renderTradeInApplyOptions(showTradeInApplyOptions)}

                {isIcoTradeInNotSiteDriverFlow ? icoDisclaimer : nonIcoDisclaimer}

                <NextSteps handleTradeIn={this.next} />

                {offerSubmitFailed ? (
                    <div className="form-group text-right">
                        {' '}
                        <span className="message-error"> Unable to Submit Offer </span>
                    </div>
                ) : null}
                {testDriveAtHomeOnTradeInToggle && !hideRequestTestDrive ? (
                    <section className="dr-ui-trade-equity__next-activity">
                        <h2>Request a Test Drive at Home</h2>
                        <NextActivityFooterUI
                            headingText={'Request Test Drive'}
                            bodyText={
                                // eslint-disable-next-line max-len
                                'The dealer will bring the vehicle to you for a no-obligation test drive or pick it up at the dealership'
                            }
                            buttonText={'Request Now'}
                            onButtonClick={this.next}
                            isPending={isProcessing}
                            tradeInComplete={isTradeInCompleted}
                        />
                    </section>
                ) : (
                    <ActionFooterBar
                        disableBackButton={false}
                        onActionButtonClick={this.next}
                        onBackButtonClick={previous}
                        showBackButton={true}
                        buttonText={buttonCTAMessage}
                        disableActionButton={isProcessing}
                        hasSpinner={isProcessing}
                        isPending={isProcessing}
                        useLinkForActionButton={!hasPageError && enableNextSteps}
                    />
                )}
            </div>
        );
    }
}

export default EquityUI;
