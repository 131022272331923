// externals
import { connect } from 'react-redux';

// components
import SearchUI from './SearchUI';

// interfaces/types
import { ISearchUIDispatchProps, ISearchUIStateProps } from './SearchUI';

// selectors
import * as tradeInComponentActionCreators from '../../../store/actionCreators';
import * as featureToggleSelectors from '../../../store/featureToggles';
import { isSearchingForVin, isSearchingForYMMT, getSearchResultsForVin, getSearchResultsForYMMT } from '../../../store/mmd/tradeIn';

const mapStateToProps = (state: any): ISearchUIStateProps => {
    const isDevHelperEnabled = featureToggleSelectors.isDevHelperEnabled(state);
    return {
        isSearchingForVin: isSearchingForVin(state),
        isSearchingForYMMT: isSearchingForYMMT(state),
        getSearchResultsForVin: getSearchResultsForVin(state),
        getSearchResultsForYMMT: getSearchResultsForYMMT(state),
        isDevHelperEnabled
    };
};

const mapDispatchToProps = (dispatch: any): ISearchUIDispatchProps => {
    return {
        next: () => {
            dispatch(tradeInComponentActionCreators.handleTradeInSearchNext());
        },
        tabSelected: (searchBy: string) => {
            dispatch(tradeInComponentActionCreators.trackSearchTypeAnalytics(searchBy));
        },
        setVehicle: (item: any) => {
            dispatch(tradeInComponentActionCreators.handleTradeInSearchSetVehicle(item));
        }
    };
};

const Search = connect(mapStateToProps, mapDispatchToProps)(SearchUI);

export default Search;
