import styled from 'styled-components';

export const Grid = styled.section`
    text-align: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 0.25rem;

    > .card-select-row > .card-select-panel {
        &-header > .statistic {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    @media (min-width: 992px) {
        grid-template-columns: repeat(2, 1fr);

        &[data-hidden='true'] {
            grid-template-columns: repeat(3, 1fr);
        }
    }
`;

export const Modal = styled.section`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000000;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;

    &[data-open='false'] {
        display: none;
    }

    #modal-body {
        > .vcd-modal-body-row {
            @media (max-width: 670px) {
                width: 100%;
            }

            > div > .row-container-segment {
                @media (max-width: 670px) {
                    width: 100%;
                }
            }
        }
    }
`;

export const ModalBG = styled.div`
    position: absolute;
    inset: 0;
    background-color: #000;
    opacity: 0.5;
`;

export const Container = styled.div`
    background-color: #fff;
    border-radius: 1.25rem;
    padding: 1.25rem;
    width: 100%;
    max-width: 1240px;
    position: relative;
    max-height: 100%;
    overflow: auto;
`;

export const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.75rem;

    & > p {
        font-size: 1rem;
        font-weight: 600;
        margin: 0;
    }
`;

export const ModalBody = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
`;

export const ModalFooter = styled.div`
    border-top: 1px solid #c0c8d4;

    & > p {
        text-align: center;
        margin-top: 1.25rem;
    }
`;

export const CloseBtn = styled.span`
    cursor: pointer;
    width: 1rem;
`;

export const Row = styled.div`
    width: calc(50% - 2rem);
    flex-shrink: 0;
    min-height: 28.125rem;
    padding-bottom: 0.625rem;

    &:nth-child(-n + 2) {
        border-bottom: 1px solid #c0c8d4;
    }

    > .title {
        font-size: 1.375rem;
    }

    > .percentage {
        font-size: 1rem;
    }

    > .title,
    > .percentage {
        text-align: left;
        font-weight: 600;
    }

    &[data-hidden='true'] {
        width: 100%;
        min-height: unset;
    }
`;

export const RowContainer = styled.div`
    display: flex;
    flex-wrap: wrap;

    > .row-container-segment {
        width: 50%;
        flex-shrink: 0;

        &[data-hidden='false'] {
            width: 100%;
        }
    }
`;

export const SVGIcon = styled.svg`
    width: 1rem;
    display: inline-block;
    height: 1rem;
    stroke: ${({ theme }) => theme.tradeInEstimateInfoIconColor};
    margin-left: 0.5rem;
    cursor: pointer;
    fill: none;
    stroke-width: 3;
    stroke-linecap: round;
    stroke-linejoin: round;
`;
