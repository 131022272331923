import { InterstateDealSummarySections } from '@makemydeal/dr-dash-components';
import { List } from '@interstate/components/List';
import { useSelector } from 'react-redux';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

const Lease = () => {
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);
    const isTargetOutOfPocketLeaseEnabled = useSelector(featureToggleSelectors.isTargetOutOfPocketLeaseEnabled);
    const isTargetOutOfPocket = useSelector(offerReduxSelectors.getIsTargetOutOfPocket);

    return (
        <List data-testid="lease-deal-summary">
            <InterstateDealSummarySections.InterstateMSRP />
            <InterstateDealSummarySections.InterstateDiscount />
            <InterstateDealSummarySections.InterstateSellingPrice />
            {isTargetOutOfPocketLeaseEnabled && isTargetOutOfPocket && (
                <InterstateDealSummarySections.InterstateInsufficientFunds />
            )}
            {isTargetOutOfPocketLeaseEnabled && isTargetOutOfPocket && (
                <InterstateDealSummarySections.InterstateAdjustedSellingPrice />
            )}
            {!isEnhancedDealDetailsEnabled && <InterstateDealSummarySections.InterstateNetTrade />}
            <InterstateDealSummarySections.InterstateAddsToCapCost />
            <InterstateDealSummarySections.InterstateGrossCapCost />
            {!isEnhancedDealDetailsEnabled && <InterstateDealSummarySections.InterstateLeaseTotalTax />}
            <InterstateDealSummarySections.InterstateCapCostReduction />
            <InterstateDealSummarySections.InterstateAdjCapCost />
            {isEnhancedDealDetailsEnabled ? (
                <InterstateDealSummarySections.InterstateAdjustedResidual />
            ) : (
                <InterstateDealSummarySections.InterstateTotalResidual />
            )}
            {isEnhancedDealDetailsEnabled ? (
                <InterstateDealSummarySections.InterstatePayment />
            ) : (
                <InterstateDealSummarySections.InterstateMonthlyPayment />
            )}
            {!isEnhancedDealDetailsEnabled && <InterstateDealSummarySections.InterstateTermLength />}
            {isEnhancedDealDetailsEnabled ? (
                <InterstateDealSummarySections.InterstateDueAtSigning />
            ) : (
                <InterstateDealSummarySections.InterstateTotalDueAtSigning showLargeValue />
            )}
            {isEnhancedDealDetailsEnabled && <InterstateDealSummarySections.InterstateDueAtSigningPaidBy />}
        </List>
    );
};

export default Lease;
