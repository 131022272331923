import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';

import { NumericInput } from '@interstate/components/NumericInput';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';

import { disabledStyle, roundValue } from '../utils';
import { MAX_DOWNPAYMENT_THRESHOLD } from '../../common/constants';
import { areStringsEqual } from '../../common/utils';
import { IPaymentGridErrorState } from '@makemydeal/dr-dash-types';
import { INPUT_INVALID_ERROR_MESSAGE } from '../../../constants';

export interface DownPaymentInputFieldProps {
    downPaymentValue: number | null;
    rowIndex: number;
    isLoading: boolean;
}

export const DownPaymentInputField = ({ downPaymentValue, rowIndex, isLoading }: DownPaymentInputFieldProps) => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const [downPaymentDisplay, setDownPaymentDisplay] = useState('');
    const [isFocused, setFocus] = useState(false);
    const paymentGridErrorState: IPaymentGridErrorState = useSelector(offerReduxSelectors.getPaymentGridErrorState);

    useEffect(() => {
        if (!isLoading) {
            const roundedStringValue =
                downPaymentValue !== null && Number.isFinite(downPaymentValue) ? `${roundValue(String(downPaymentValue))}` : '';
            setDownPaymentDisplay(roundedStringValue);
        }
    }, [downPaymentValue, isLoading]);

    const handleChange = (e: InterstateOnChangeEvent<TextInputEventValue>) => setDownPaymentDisplay(e.target.value);

    const handleFocus: React.FocusEventHandler<HTMLInputElement> = (e) => {
        setFocus(true);
        setTimeout(() => {
            const len = e.target.value.length;
            e.target.setSelectionRange(len, len);
        }, 100);
    };

    const handleBlur = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        const value = e.target.value;
        const valueNumber = value.toString().length ? Number(value) : null;

        if (downPaymentValue === valueNumber) {
            const roundedValue = roundValue(value);
            setTimeout(() => {
                setDownPaymentDisplay(`${roundedValue ?? ''}`);
                setFocus(false);
            });
            return;
        }

        logNewRelicPageAction('MV:PaymentGrid - Change cash down', {
            mvOfferType: offerType,
            mvFieldEdited: 'mv:payment:grid:cash-down',
            mvDealXgId: dealXgId
        });

        dispatch(offerReduxActionCreators.updatePaymentGridCashdown(offerType, rowIndex, valueNumber));
        setFocus(false);
    };

    return (
        <NumericInput
            autoInsertCommas
            allowNegative={false}
            id={'grid-cashdown-input'}
            data-testid={'grid-cashdown-input'}
            maxValue={MAX_DOWNPAYMENT_THRESHOLD}
            sx={{
                ...(isLoading && disabledStyle)
            }}
            allowDecimal={true}
            decimalMaxLength={2}
            inputPrefix={'$'}
            hasError={
                areStringsEqual(paymentGridErrorState.lastUpdatedInput, `input_cash_${rowIndex}`) && paymentGridErrorState.hasError
            }
            errorMessage={INPUT_INVALID_ERROR_MESSAGE}
            customErrorMessages={{ exceedsMaxValueRule: INPUT_INVALID_ERROR_MESSAGE }}
            name={'cashdown-input'}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={isFocused ? String(downPaymentValue ?? '') : downPaymentDisplay}
        />
    );
};
