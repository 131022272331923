import * as React from 'react';
import { renderLeadForm } from '../../../store/mmd/leadForm';
import { ActionFooterBar } from '@makemydeal/dr-activities-common';
import Header from '../header/Header';
import RadioButtonUI from '../radioButton/RadioButtonUI';
import { getOptimizelyTests } from '../../../store/mmd/optimizelySelectors';
import { activateTests } from '../../../common/optimizelyWrapper';

export interface ITradeInLeadFormStateProps {
    disableActionButton?: boolean;
    offerHasBeenSent: boolean;
    isOfferPending: boolean;
    shopperInfo: any;
    vehicleValuation?: string;
    shouldIncludeTradeIn?: boolean;
    isPaymentInvalid: boolean;
    tradeSaveFailure: boolean;
    tradeSavePending: boolean;
    showTradeInApplyOptions?: boolean;
    isTradeInNegative: boolean;
    isIcoTradeInFlow: boolean;
    excellentEstimate: string;
    fairEstimate: string;
    pageTitle?: string;
    pageTip?: string;
    headerSubTitle?: string;
    conditionMileageZipSubtitle?: string;
    isIcoTradeInNotSiteDriverFlow?: boolean;
    expirationRange?: string;
    isTradeLeadformValEnabled?: boolean;
    tradeInValue: string;
    enableSingleColumnLeadForm: boolean;
}

export interface ITradeInLeadFormDispatchProps {
    previous: { () };
    next: { (isTradeLeadformValEnabled: boolean, applyToAmountFinanced: boolean) };
    updateShopperInfo: { (shopperInfo: any) };
    removeTrade: { (): void };
    trackLeadFormInView?: { (): void };
}

type ITradeInLeadFormProps = ITradeInLeadFormStateProps & ITradeInLeadFormDispatchProps;

class TradeInLeadFormUI extends React.Component<ITradeInLeadFormProps, any> {
    constructor(props) {
        super(props);
        this.state = { applyToAmountFinanced: props.shouldIncludeTradeIn };
    }
    componentDidMount() {
        const { trackLeadFormInView } = this.props;
        const testsToActivate = [getOptimizelyTests().tradeInLeadFormSingleColumn];
        activateTests(testsToActivate);
        trackLeadFormInView();
        const shopperInfo = { ...this.props.shopperInfo };
        if (shopperInfo.phone) {
            shopperInfo.phone = '';
        }
        if (shopperInfo.contactPreferences && (shopperInfo.contactPreferences.sms || shopperInfo.contactPreferences.phone)) {
            shopperInfo.contactPreferences.sms = false;
            shopperInfo.contactPreferences.phone = false;
        }

        this.props.updateShopperInfo(shopperInfo);
    }

    next = () => {
        const { applyToAmountFinanced } = this.state;
        const { next, isTradeLeadformValEnabled } = this.props;
        next(isTradeLeadformValEnabled, applyToAmountFinanced);
    };

    onRadioChangeHandler = (value) => {
        this.setState({
            applyToAmountFinanced: value
        });
    };

    renderTradeInApplyOptions = (showTradeInApplyOptions) => {
        const { shouldIncludeTradeIn, isTradeInNegative } = this.props;

        const negativeWarning = isTradeInNegative ? (
            <p className="danger dr-ui-trade-equity__negative-value-title">
                <b>Your trade-in has a negative equity.</b>
            </p>
        ) : null;

        return showTradeInApplyOptions ? (
            <section className="row dr-ui-trade-equity__negative-value-opts">
                {negativeWarning}
                <div className="radio-button-container">
                    <RadioButtonUI
                        defaultChecked={shouldIncludeTradeIn}
                        id="finance"
                        name="tradeInEstimate"
                        label="Apply to my amount financed"
                        sublabel={
                            'When you apply a negative equity to your financed amount, the lender may have a limit to the amount ' +
                            'of equity you can finance (called your Loan to Value)'
                        }
                        onChange={() => {
                            this.onRadioChangeHandler(true);
                        }}
                        value={shouldIncludeTradeIn}
                    />
                </div>

                <div className="radio-button-container">
                    <RadioButtonUI
                        defaultChecked={!shouldIncludeTradeIn}
                        id="cash"
                        name="tradeInEstimate"
                        label="Apply to my cash down"
                        onChange={() => {
                            this.onRadioChangeHandler(false);
                        }}
                        value={shouldIncludeTradeIn}
                    />
                </div>
            </section>
        ) : (
            <section className="dr-ui-trade-equity__disclaimer">{negativeWarning}</section>
        );
    };

    getCtaText = () => (this.props.isTradeLeadformValEnabled ? 'Apply & Continue' : 'Continue');

    render() {
        const {
            previous,
            disableActionButton,
            offerHasBeenSent,
            isOfferPending,
            isTradeLeadformValEnabled,
            showTradeInApplyOptions,
            isIcoTradeInNotSiteDriverFlow,
            tradeInValue
        } = this.props;

        const barProps = {
            onActionButtonClick: this.next,
            onBackButtonClick: previous,
            showBackButton: true,
            ignoreRouterHistory: true,
            buttonText: this.getCtaText(),
            disableActionButton,
            hasSpinner: !offerHasBeenSent,
            isPending: isOfferPending
        };

        const leadFormProps = {
            isLeadFormShort: true,
            isLeadFormLite: true,
            hideTitle: true,
            hideFooter: true,
            hideNextStepsLink: true,
            hideContinueButton: true,
            hidePhoneNumber: false,
            hideContactPreffered: true,
            hideUserConsent: true,
            hideAdditionalInfo: true,
            isLeadFormSingleCol: this.props.enableSingleColumnLeadForm
        };

        const leadFormHeader = 'Almost There';
        const leadFormSubHeader = 'Your trade-in value is on the next page. Let’s submit your deal status.';
        const correspondence = isTradeLeadformValEnabled ? 'You may receive correspondence from us about this vehicle.' : null;
        const disclaimerValuation = isTradeLeadformValEnabled ? tradeInValue : '';
        const leadFormDisclaimer = (
            <div>
                <p>
                    {correspondence} {disclaimerValuation}
                </p>
            </div>
        );
        const icoDisclaimer = (
            // tslint:disable-next-line
            <p>
                Instant Cash Offers ("Offer(s)") are not available in all areas and not all vehicles are eligible. Your Offer is
                valid at Participating Dealers for 3 days (not counting Sundays) as stated on the Offer Certificate. Kelley Blue
                Book will not redeem Offers nor purchase your vehicle from you. Vehicles must pass a mandatory inspection by a
                Participating Dealer. If the inspection report differs from your description or online assessment of your vehicle’s
                condition, the Participating Dealer may adjust the offer amount, which may result in a decrease of the offer. Kelley
                Blue Book and its affiliates disclaim all liability resulting from an adjustment of the offer or refusal to accept
                your vehicle by Participating Dealer(s). Participating Dealers are not owned or operated by, nor are they affiliated
                with or acting on behalf of, Kelley Blue Book. Participating Dealers pay Kelley Blue Book or its affiliate to
                subscribe to the Kelley Blue Book Instant Cash Offer program. The Offer may not reflect the highest sale price or
                trade-in value available for your vehicle, and may be less than the Kelley Blue Book Trade-In Value or the Trade-In
                Range for a similar vehicle. Program Terms and Conditions apply and can be found
                <a href="https://www.kbb.com/company/terms-of-service/#termsAndConditions">here</a>.
            </p>
        );

        const nonIcoDisclaimer = <p>You may receive correspondence from us about this vehicle.</p>;

        return (
            <div id="dr-trade-lead-form" className="dr-ui-trade-leadform">
                <Header pageTitle={leadFormHeader} pageTip={leadFormSubHeader} showTradeErrorMessage={true} />

                {/* TODO : When the legacy lead form gets ported over into dr-activities-leadform, 
                you should have direct access to the lead form and will no longer have to do this workaround. 
                <LeadForm {...leadFormProps} /> */}
                {renderLeadForm(leadFormProps)}

                {isTradeLeadformValEnabled ? this.renderTradeInApplyOptions(showTradeInApplyOptions) : null}

                <div className="dr-ui-trade-leadform__disclaimerTxt">
                    {isTradeLeadformValEnabled ? (
                        <div>{leadFormDisclaimer}</div>
                    ) : isIcoTradeInNotSiteDriverFlow ? (
                        icoDisclaimer
                    ) : (
                        nonIcoDisclaimer
                    )}
                </div>

                <ActionFooterBar {...barProps} />
            </div>
        );
    }
}

export default TradeInLeadFormUI;
