// externals
import * as React from 'react';
import { CardSelectButtonsUI } from '@makemydeal/dr-activities-common';

// config
import config from '../../../store/mmd/config';

import TradePage from '../../../common/components/TradePage/TradePage';
import DevHelperTradeAutofill from '../../../common/components/devHelperTradeAutofill/DevHelperTradeAutofill';
import { DECISION_TYPES } from '../../../store/actionCreators';
import { analyticsActions } from '../../../store/actions';

export interface ITradeInDecisionUIStateProps {
    pageTitle: string;
    pageSubTitle: string;
    renderDisclaimer?: { () };
    headerImageKBB?: string;
    isDevHelperEnabled: boolean;
    isInTradeInSubaruGtpFlow: boolean;
    isTradeInSubaruGtpFlowError: boolean;
    isShopperEnteredTradeEnabled: boolean;
    showCorrectOptions?: any;
    selectedOption?: string;
}

export interface ITradeInDecisionUIDispatchProps {
    goToTradeInFlow: { (option: string, isSubaruGTPRetry?: boolean) };
    goToICOFlow: { (option: string) };
    goToTradeManualEntryFlow: { (option: string) };
    trackOption: { (option: string, isShopperEnteredTrade: boolean) };
    dispatchImpressionTag: { () };
    dispatchAnalytics: { (type: string, description: string) };
    updateSource: { (source: string) };
}

export interface ITradeInDecisionUIProps extends ITradeInDecisionUIStateProps, ITradeInDecisionUIDispatchProps {}

export interface ITradeInDecisionUIState {
    selectedOptionIndex: number;
    selectedOption: string;
}

export const upperCase = (sentence) => sentence.replace(/(^\w{1})|(\s+\w{1})/g, (letter) => letter.toUpperCase());

export const optionsArray = [
    {
        index: 0,
        id: 'option_0',
        label: upperCase(DECISION_TYPES.ICO),
        selected: false,
        description:
            // eslint-disable-next-line max-len
            'With your License Plate or VIN, ensure a more accurate offer for your vehicle. Find out what you can get for your car today. Offers are valid for 7 days.'
    },
    {
        index: 1,
        id: 'option_1',
        label: upperCase(DECISION_TYPES.TRADE_IN_VALUE),
        selected: false,
        description: 'Get an estimated trade-in value based on similar cars of the same year, make, model and condition'
    },
    {
        index: 2,
        id: 'option_2',
        label: upperCase(DECISION_TYPES.ENTER_OWN_VALUE),
        selected: false,
        description: 'If you already know what your car is worth, simply enter it and calculate your new monthly payment.'
    }
];
class TradeInDecisionUI extends React.Component<ITradeInDecisionUIProps, ITradeInDecisionUIState> {
    constructor(props) {
        super(props);
        const { selectedOption } = this.props;
        const selectedOptionIndex = optionsArray.find((item: any) => item.label === selectedOption).index;
        this.state = { selectedOptionIndex, selectedOption };
    }

    componentDidMount() {
        this.props.dispatchImpressionTag();
    }

    onClickTradeInOption = (event) => {
        const { trackOption, isShopperEnteredTradeEnabled } = this.props;
        const optionId = event.currentTarget.getAttribute('data-id');
        const id = optionId.split('_')[1];
        const option = optionsArray[id].label;
        this.setState(
            {
                selectedOptionIndex: id,
                selectedOption: option
            },
            trackOption(option.toLowerCase(), isShopperEnteredTradeEnabled)
        );
    };

    next = () => {
        const selectedOption = this.state.selectedOption.toLowerCase();
        this.props.updateSource(selectedOption);
        switch (selectedOption) {
            case DECISION_TYPES.TRADE_IN_VALUE:
                this.props.goToTradeInFlow(DECISION_TYPES.TRADE_IN_VALUE);
                break;
            case DECISION_TYPES.ENTER_OWN_VALUE:
                this.props.goToTradeManualEntryFlow(DECISION_TYPES.ENTER_OWN_VALUE);
                break;
            default:
                this.props.goToICOFlow(DECISION_TYPES.ICO);
                break;
        }
    };

    createOptionPanels = (item) => {
        const { selectedOptionIndex } = this.state;
        const activeOptionId = `option_${selectedOptionIndex}`;
        const { id, label, statistic, description } = item;
        const checked = id === activeOptionId;
        const panelId = `panel_${id}`;

        return (
            <CardSelectButtonsUI
                id={id}
                panelId={panelId}
                headingText={label}
                secondaryText={statistic}
                bodyText={description}
                onClick={this.onClickTradeInOption}
                selected={checked}
            ></CardSelectButtonsUI>
        );
    };

    getPageContainer = () => {
        if (this.props.isTradeInSubaruGtpFlowError) {
            return (
                <div className="ico-decision-page-container">
                    <p className="subheader-text">Please contact your retailer or select from another valuation method below</p>
                </div>
            );
        } else {
            const { optionsToShow, message } = this.props.showCorrectOptions;
            return (
                <div className="ico-decision-page-container">
                    {optionsToShow.length === 3 && (
                        <p className="ico-decision-header-text">Choose from three ways to value your vehicle</p>
                    )}
                    {optionsToShow.length === 2 && (
                        <>
                            <p className="ico-decision-header-text">Choose from two ways to value your vehicle</p>
                            <div className="ico-decision-header-container">
                                <img className="kbb-logo" src={this.props.headerImageKBB} />
                                <p className="ico-decision-subheader-text">{message}</p>
                            </div>
                        </>
                    )}
                </div>
            );
        }
    };

    retrySubaruGTP = () => {
        this.props.goToTradeInFlow(DECISION_TYPES.TRADE_IN_VALUE, true);
        this.props.dispatchAnalytics(
            analyticsActions.DR_GTRADE_RETURN_TO_ELIGIBILITY_SCREEN,
            'Return to Subaru Eligibility Screen'
        );
    };

    getPageSubTitle = () =>
        this.props.isTradeInSubaruGtpFlowError ? (
            <>
                {this.props.pageSubTitle}
                <a id="dr-gtp-retry" data-testid="dr-gtp-retry" onClick={this.retrySubaruGTP}>
                    Go Back and Retry
                </a>
            </>
        ) : (
            this.props.pageSubTitle
        );

    render() {
        const { pageTitle, isDevHelperEnabled } = this.props;
        const optionPanels = optionsArray.map(this.createOptionPanels);
        const index = this.state.selectedOptionIndex;

        return (
            <TradePage
                pageClass="ico-decision-page"
                pageTitle={pageTitle}
                pageSubTitle={this.getPageSubTitle()}
                footerProps={{
                    onActionButtonClick: this.next,
                    showBackButton: false,
                    buttonText: 'Next',
                    disableActionButton: index === -1
                }}
                renderDisclaimer={this.props.renderDisclaimer}
                showTradeErrorMessage={false}
                staticAssetsConfig={config.staticImages}
                hideDisclaimerImg={true}
            >
                {this.getPageContainer()}

                <div className="card-select-grid">
                    <div className="ico-decision-card-select-row">
                        {this.props.showCorrectOptions.optionsToShow.map((option) => optionPanels[option])}
                    </div>
                    {isDevHelperEnabled && <DevHelperTradeAutofill />}
                </div>
            </TradePage>
        );
    }
}

export default TradeInDecisionUI;
