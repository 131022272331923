// externals
import { RSAA } from 'redux-api-middleware';

// libraries
import { apiConfigHelper, locHrefUtil } from '@makemydeal/dr-shared-ui-utils';
import { ToggleStatsToggleItem } from '@makemydeal/dr-feature-toggles-client';
import { buildFromConfig, IConfigObj } from '@makemydeal/dr-common-utils';

// consts/enums
import {
    UPDATE_FEATURE_TOGGLE_STATS_REQUEST,
    UPDATE_FEATURE_TOGGLE_STATS_SUCCESS,
    UPDATE_FEATURE_TOGGLE_STATS_FAILURE
} from '../actionTypes/toggleStatsActionTypes';

export const updateFeatureToggleStatsRequest = (appPrefix: string, toggles: ToggleStatsToggleItem[], configObject?: IConfigObj) => {
    let endpoint;
    if (configObject) {
        endpoint = buildFromConfig(configObject, 'toggleStats');
    } else {
        const locHref = locHrefUtil.getLocHref();
        const bffApiUrlResult = apiConfigHelper.buildBffApiUrl(appPrefix, locHref, 'toggleStats');
        endpoint = bffApiUrlResult.url;
    }

    return {
        [RSAA]: {
            endpoint,
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-dr-source': 'CMD BFF' },
            body: JSON.stringify({ toggles }),
            types: [UPDATE_FEATURE_TOGGLE_STATS_REQUEST, UPDATE_FEATURE_TOGGLE_STATS_SUCCESS, UPDATE_FEATURE_TOGGLE_STATS_FAILURE],
            fetch: async (...args: Parameters<typeof fetch>) => {
                return await fetch(...args);
            }
        }
    };
};
