// externals
import { push } from 'connected-react-router';
import * as React from 'react';
import { connect } from 'react-redux';
import * as tradeInComponentActionCreators from '../../../store/actionCreators';
import * as tradeInRoutes from '../../../utils/routes';
import styled from 'styled-components';

export interface IDevHelperTradeAutofillUIProps {
    devHelperValidTrade: { () };
    devHelperPaymentFailureTrade: { () };
    devHelperNegativeEquityTrade: { () };
}

const RowContainer = styled.h3`
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const DevHelperTradeAutofillUI = (props: IDevHelperTradeAutofillUIProps) => {
    const { devHelperValidTrade, devHelperPaymentFailureTrade, devHelperNegativeEquityTrade } = props;

    return (
        <div className="dev-helper-container" style={{ display: 'flex', flexDirection: 'column', padding: '0 2rem' }}>
            <RowContainer>
                <div>
                    <div>Add Valid Trade</div>
                    <h6>1998 | Honda | Accord | DX Sedan 4D | Purchase | 100,000mi | Good</h6>
                </div>
                <div
                    id={'valid-trade-button'}
                    className={`btn btn-secondary card-select-button`}
                    onClick={() => devHelperValidTrade()}
                >
                    Select
                </div>
            </RowContainer>
            <RowContainer>
                <div>
                    <div>Add Payment Failure Trade</div>
                    <h6>2021 | BMW | 3 Series | 330e Sedan 4D | Purchase | 222mi | Excellent</h6>
                </div>
                <div
                    id={'payment-failure-trade-button'}
                    className={`btn btn-secondary card-select-button`}
                    onClick={() => devHelperPaymentFailureTrade()}
                >
                    Select
                </div>
            </RowContainer>
            <RowContainer>
                <div>
                    <div>Add Negative Equity Trade</div>
                    <h6>2005 | Honda | Accord | DX Sedan 4D | Owe: 10,000 | 100,000mi | Fair</h6>
                </div>
                <div
                    id={'negative-equity-trade-button'}
                    className={`btn btn-secondary card-select-button`}
                    onClick={() => devHelperNegativeEquityTrade()}
                >
                    Select
                </div>
            </RowContainer>
        </div>
    );
};

const mapDispatchToProps = (dispatch: any): IDevHelperTradeAutofillUIProps => {
    return {
        devHelperValidTrade: () => {
            dispatch(tradeInComponentActionCreators.addMockValidTrade());
            dispatch(push(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS));
        },
        devHelperPaymentFailureTrade: () => {
            dispatch(tradeInComponentActionCreators.addMockPaymentFailureTrade());
            dispatch(push(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS));
        },
        devHelperNegativeEquityTrade: () => {
            dispatch(tradeInComponentActionCreators.addMockNegativeEquityTrade());
            dispatch(push(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS));
        }
    };
};

const DevHelperTradeAutofill = connect(undefined, mapDispatchToProps)(DevHelperTradeAutofillUI);

export default DevHelperTradeAutofill;
