// externals
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

// actions
import { handleNavigationCardSelected } from '../../../store/actionCreators';

// consts/enums
import * as constants from '../../../utils/constants';

// utils
import * as optimizelyUtils from '../../../store/mmd/optimizelySelectors';

// routing
import { goToMenuProducts } from '../../../store/actionCreators';
import { isVppEnabled } from '../../../store/mmd/dealerSelectors';

// selectors
import * as optimizelySelectors from '../../../store/mmd/optimizelySelectors';
import { menuSelectors } from '../../../store/mmd/menu';
import { isTestDriveServiceUnavailable } from '../../../store/mmd/activitiesStatusSelectors';
// components
import NextStepsUI from './NextStepsUI';

// config

interface INextStepsDispatchProps {
    handleTradeIn: { (appliedFromViewOfferSummaryLink?: boolean): void };
}

const mapStateToProps = (state: any) => {
    const { isVariationActive, getOptimizelyTests } = optimizelySelectors;
    const showTestDrive = !isTestDriveServiceUnavailable(state);
    const showMenu = isVppEnabled(state) && !menuSelectors().isMenuDisabled(state);
    const enableNextSteps = isVariationActive(
        state,
        getOptimizelyTests().tradeInNextStepsTest,
        getOptimizelyTests().tradeInNextStepsTest.showNextSteps
    );

    return {
        showTestDrive,
        showMenu,
        enableNextSteps
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: INextStepsDispatchProps) => {
    const appliedFromViewOfferSummaryLink = true;
    return {
        onVehicleProtectionClick: () => {
            ownProps.handleTradeIn(appliedFromViewOfferSummaryLink);
            optimizelyUtils.track('tradeInNextStepsVehicleProtectionClicked');
            optimizelyUtils.track('tradeInNextStepsFeaturedProductClicked');
            dispatch(goToMenuProducts());
            dispatch(handleNavigationCardSelected(constants.MENU_CARD_ID, 'menuProducts', true));
        },
        onTestDriveClick: () => {
            ownProps.handleTradeIn(appliedFromViewOfferSummaryLink);
            optimizelyUtils.track('tradeInNextStepsTestDriveClicked');
            optimizelyUtils.track('tradeInNextStepsFeaturedProductClicked');
            dispatch(push(constants.RouteValues.TEST_DRIVE));
            dispatch(handleNavigationCardSelected(constants.TEST_DRIVE_CARD_ID, 'testDriveActivity', true));
        }
    };
};

const NextSteps = connect(mapStateToProps, mapDispatchToProps)(NextStepsUI);

export default NextSteps;
