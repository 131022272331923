// external
import { connect } from 'react-redux';
import Uri from 'urijs';

// selectors/actions
import { Actions as actionTypes } from '../../../store/actions';
import {
    getInstantCashOfferProviderUrl,
    getTradeInValueSource,
    getTradeInValueIcoStatus,
    getTradeInVehicleVin,
    getTradeInZip,
    getTradeInVehicleMileage,
    getTradeInVehicleYear,
    getTradeInVehicleMakeName,
    getTradeInVehicleModelName,
    getTradeInVehicleTrimName,
    isExternalIcoCompleted
} from '../../../store/mmd/tradeIn';
import * as tradeInComponentActionCreators from '../../../store/actionCreators';
import { getShopperInfo } from '../../../store/mmd/offerSelectors';

// components
import IcoValuationUI from './IcoValuationUI';
import { IIcoValuationUIStateProps } from './IcoValuationUI';

// utils
import { isShowroomExperience } from '../../../utils/configUtils';
import { processStepperRoutes } from '../../../utils/tradeInDecisionLogic';

// constants/types
import { default as externalTradeInEvents } from './externalTradeIn';
import { IMPROVED_FLOW_STEPPER_ROUTES } from '../../../utils';
import { IRootProps } from '../../../components';

export const mapStateToProps = (state: any, ownProps: IRootProps): IIcoValuationUIStateProps => {
    const sdpDomain = window.location.origin;
    const urlProvider = new Uri(getInstantCashOfferProviderUrl(state));
    urlProvider.addQuery('PostTargetOrigin', sdpDomain);

    // Fill trade-in vehicle
    const vin = getTradeInVehicleVin(state.tradeInComponent);
    urlProvider.addQuery('vin', vin);
    const zip = getTradeInZip(state.tradeInComponent);
    urlProvider.addQuery('zip', zip);
    const mileage = getTradeInVehicleMileage(state.tradeInComponent);
    urlProvider.addQuery('mileage', mileage);
    const year = getTradeInVehicleYear(state.tradeInComponent);
    urlProvider.addQuery('year', year);
    const make = getTradeInVehicleMakeName(state.tradeInComponent);
    urlProvider.addQuery('make', make);
    const model = getTradeInVehicleModelName(state.tradeInComponent);
    urlProvider.addQuery('model', model);
    const trim = getTradeInVehicleTrimName(state.tradeInComponent);
    urlProvider.addQuery('trim', trim);

    // Fill shopper info
    const shopperInfo = getShopperInfo(state);
    if (shopperInfo && shopperInfo.firstName) {
        const fd = btoa(
            JSON.stringify({ F: shopperInfo.firstName, L: shopperInfo.lastName, E: shopperInfo.email, P: shopperInfo.phone })
        );
        urlProvider.addQuery('fd', fd);
    }

    const instantCashOfferUrlProvider = urlProvider.toString();
    const tivIcoStatus = getTradeInValueIcoStatus(state);
    const tivSource = getTradeInValueSource(state);
    const extTradeEventObj = externalTradeInEvents(tivSource)[tivIcoStatus];
    let icoAction;
    if (extTradeEventObj) {
        icoAction = extTradeEventObj.tradeInAction;
    }

    const isSalesView = isShowroomExperience(ownProps.config);
    const stepperRoutes = processStepperRoutes(state, IMPROVED_FLOW_STEPPER_ROUTES.detailsICO, isSalesView);
    const isTradeCompleted = isExternalIcoCompleted(state);

    return {
        instantCashOfferUrlProvider,
        icoAction,
        stepperRoutes,
        isTradeCompleted
    };
};
export const mapDispatchToProps = (dispatch: any) => ({
    dispatchCallback: (action) => {
        dispatch(action);
    },
    onCompletedIcoTradeIn: () => {
        dispatch({
            type: actionTypes.UPDATE_ICO_TRADE_COMPLETED
        });
    },
    dispatchAnalytics: (type, description) => {
        dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
    },
    dispatchImpressionTag: () => {
        dispatch(tradeInComponentActionCreators.sendIcoPageOpenedAnalytics());
    },
    goToRoute: (route: string) => {
        dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(route));
    },
    resetExternalIcoCompleted: () => {
        dispatch(tradeInComponentActionCreators.externalIcoCompleted(false));
    }
});

const IcoValuation = connect(mapStateToProps, mapDispatchToProps)(IcoValuationUI);

export default IcoValuation;
