// externals
import { connect } from 'react-redux';

// libraries
import { ITradeInState, navigateTo } from '@makemydeal/dr-activities-common';
import { onOpenLeadFormModal } from '@makemydeal/dr-activities-lead-form';
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { Resources } from '@makemydeal/dr-activities-common';

// components
import TradeSummaryUI from './TradeSummaryUI';

// interfaces/types
import { ITradeSummaryUIDispatchProps, ITradeSummaryUIStateProps } from './TradeSummaryUI';
import { IRootProps } from '../../../components/RootProps';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';

// actions
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

// consts/enums
import { Actions as types } from '../../../store/actions';

// utils
import { isLeadFormSubmittedSuccess } from '../../../store/mmd/leadForm';
import { getNextCardDetails } from '../../../store/mmd/navigationCard';
import { isShareDealEnabled, isTradeinLeadformValuationEnabled } from '../../../store/featureToggles';

// config
import config from '../../../store/mmd/config';
import { isDealerUser, isDeskingUser } from '../../../utils/configUtils';

import * as tradeInActionCreators from '../../../store/actionCreators';
import * as dealerSelectors from '../../../store/mmd/dealerSelectors';

const mapStateToProps = (state: any, ownProps: IRootProps): ITradeSummaryUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
    const cardDetails = getNextCardDetails(state);
    const isTradeLeadFormEnabled = isTradeinLeadformValuationEnabled(state);
    const imageFPO = urlBuilder.buildFromConfig(config.staticImages, Resources.FPO_IMAGE);
    const isLeadFormSubmitted = isLeadFormSubmittedSuccess(state);
    const isSubaruGtpEligible = tradeInSelectors.isSubaruGtpEligible(state);
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn);
    const tradePaymentFailed = tradeInSelectors.getTradePaymentFailed(state);
    const vehicleId = tradeInSelectors.getTradeInVehicleId(tradeIn);
    const zipCode = tradeInSelectors.getTradeInZip(tradeIn);
    const vehicleMileage = tradeInSelectors.getTradeInVehicleMileage(tradeIn);
    const vehicleCondition = tradeInSelectors.getTradeInVehicleCondition(tradeIn);
    const year = tradeInSelectors.getTradeInVehicleYear(tradeIn);
    const make = tradeInSelectors.getTradeInVehicleMakeName(tradeIn);
    const model = tradeInSelectors.getTradeInVehicleModelName(tradeIn);
    const vin = tradeInSelectors.getTradeInVehicleVin(tradeIn);
    const tradeInValue = tradeInSelectors.getTradeInValue(tradeIn);
    const loanBalance = tradeInSelectors.getTradeInAmountOwed(tradeIn);
    const calculatedTradeInValue = tradeInValue - loanBalance;
    const currentDate = new Date();
    const expirationDate = tradeInSelectors.getExpirationDate(tradeIn);
    const showShareDeal = isShareDealEnabled(state);
    const userIsDealer = isDealerUser(ownProps.config);
    const userIsDesking = isDeskingUser(ownProps.config);
    const dealerPhoneNumber = dealerSelectors.getDealerPhone(state);
    const isLeadFormEnabled = tradeInSelectors.shouldShowLeadForm(state);
    const isManualEntryFlow = tradeInSelectors.isManualEntryFlow(state);
    const valueSource = tradeInSelectors.getValueSource(tradeIn);

    return {
        cardDetails,
        isTradeLeadFormEnabled,
        imageFPO,
        isSubaruGtpEligible,
        isLeadFormSubmitted,
        showShareDeal,
        vehicleOptions,
        tradePaymentFailed,
        vehicleId,
        zipCode,
        vehicleMileage,
        vehicleCondition,
        year,
        make,
        model,
        vin,
        tradeInValue,
        loanBalance,
        calculatedTradeInValue,
        currentDate,
        expirationDate,
        userIsDealer,
        userIsDesking,
        dealerPhoneNumber,
        isLeadFormEnabled,
        isManualEntryFlow,
        valueSource
    };
};

const mapDispatchToProps = (dispatch: any, ownProps: IRootProps): ITradeSummaryUIDispatchProps => {
    return {
        goToRoute: (route) => {
            // Right before we go to the dashboard show the lead form if not already submitted
            if (route === '/dashboard') {
                dispatch((dispatch, getState) => {
                    const state = getState();
                    const isLeadFormSubmitted = isLeadFormSubmittedSuccess(state);
                    const userIsDealer = isDealerUser(ownProps.config);
                    if (!isLeadFormSubmitted && !userIsDealer) {
                        dispatch(
                            onOpenLeadFormModal(
                                "Don't Lose Your Progress",
                                'Send your progress and check availability by contacting us.',
                                true
                            )
                        );
                    }
                });
            }
            dispatch(navigateTo(route));
        },
        editTradeIn: () => {
            dispatch(tradeInComponentActionCreators.trackEditIconOnCompletionClicked());
        },
        termsDetailsClicked: () => {
            dispatch(tradeInComponentActionCreators.trackTermsDetailsOnCompletionClicked());
        },
        loadLeadForm: (skipLink) => {
            dispatch(
                onOpenLeadFormModal(
                    'Almost to Your Trade-In Value',
                    "Your trade-in value is on the next page.  Let's submit your deal status to unlock.",
                    skipLink,
                    types.TRADE_IN_LEAD_SUBMITTED,
                    types.TRADE_IN_PERFORM_UPDATE,
                    types.REMOVE_TRADE_IN_AND_CLOSE_MODAL
                )
            );
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
        },
        tradeInEstimateDisplayed: () => {
            dispatch(tradeInComponentActionCreators.sendTradeInEstimateDisplayedAnalytics());
        },
        removeTrade: () => {
            dispatch(tradeInActionCreators.removeTradeIn());
            dispatch(navigateTo('/dashboard'));
        }
    };
};

const TradeSummary = connect(mapStateToProps, mapDispatchToProps)(TradeSummaryUI);

export default TradeSummary;
