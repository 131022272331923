import styled from 'styled-components';

export const SearchTitle = styled.div`
    display: flex;
    align-items: center;

    label{
        font-size: 16px;
        line-height: 22px;
        margin-right: 8px;
        font-weight: ${({theme}) => theme.tradeInSearchTitleFontWeight};
        color: ${({theme}) => theme.tradeInTitleColor};
    }

    svg > path {
        fill: #636569;
    }
`;

export const TabsContainer = styled.div`
    display: flex;
    z-index: 1;
    position: relative;
    top: 1px;
`;

export const Tab = styled.button`
    flex: 1;
    background-color: ${({theme}) => theme.tradeInSearchVehicleSearchTabsButtonBackgroundColor};
    margin: 0 8px;
    padding: 1rem;
    border: ${({theme}) => theme.tradeInSearchVehicleSearchTabsButtonBorder};
    border: 1px solid ${({theme}) => theme.inactiveTabBorderColor};
    border-bottom: 0;
    border-top-right-radius: ${({theme}) => theme.tradeInSearchVehicleSearchTabsButtonTopRightBorderRadius};
    border-top-left-radius: ${({theme}) => theme.tradeInSearchVehicleSearchTabsButtonTopLeftBorderRadius};
    font-size: 18px;
    font-weight: ${({theme}) => theme.tradeInSearchTabsFontWeight};
    line-height: 26px;
    text-align: center;
    color: ${({theme}) => theme.tradeInSearchVehicleSearchTabsButtonColor};

    &:focus {
        outline: 0;
    }

    &.active {
        background-color: ${({theme}) => theme.tradeInSearchVehicleSearchTabsButtonActiveBackgroundColor};
    }

    &:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }
`;

export const SearchContainer = styled.div`
    position: relative;
    background-color: ${({theme}) => theme.tradeInSearchVehicleSearchBodyBackgroundColor};
    border-bottom-right-radius: ${({theme}) => theme.tradeInSearchVehicleSearchBodyBorderRadiusBottomRight};
    border-bottom-left-radius: ${({theme}) => theme.tradeInSearchVehicleSearchBodyBorderRadiusBottomLeft};
    padding: 40px 15px;
    border: 1px solid ${({theme}) => theme.inactiveTabBorderColor};
    font-size: 1rem;
    color: ${({theme}) => theme.tradeInSearchVehicleSearchBodyColor};

    >p {
        margin-right: 70px;
        min-height: 50px;
    }

    hr {
        margin-top: 30px;
    }

    .more-info-ie11 {
        cursor: pointer;
    }
`;

export const SearchResultsContainer = styled.div`
    position: absolute;
    margin-top: 50px;
    left: 0;
    right: 0;
    top: 100%;
    box-shadow: 0px 4px 4px 0px #00000033;

`;

export const ToolTipContainer = styled.div`
    .tooltip-text-wrapper {
        width: 300px;
        padding: 10px;
        text-align: left;
        
        label, p, ol {
            text-align: left;
            font-size: 12px;
            line-height: 18px;
        }

        ol {
            padding: 0px 10px
        }
    }

    .tooltip-text-wrapper::after {
        left: 35%;
    }

    @media (max-width: 1000px) {
        .tooltip-text-wrapper::after {
            left: 30%;
        }
    }

    @media (max-width: 670px) {
        .tooltip-text-wrapper::after {
            left: 25%;
        }
    }
`;

export const SearchInputContainer = styled.div`
    position: relative;
    z-index: 1;

    label {
        color: ${({theme}) => theme.tradeInLabelColor};
        font-weight: ${({theme}) => theme.tradeInSearchInputLabelFontWeight};
    }

    input {
        height: 46px;
    }

    i.fa {
        position: absolute;
        right: 0.5rem;
        top: 50%;
        color: ${({theme}) => theme.defaultHrBorderColor};
        font-size: 1.5rem;
        transform: translateY(-50%);
        pointer-events: none;
    }

    .list-group-item span {
        color: ${({theme}) => theme.tradeInSearchVehicleSearchResultHighlight};
    }

    .input-container {
        padding: 0;
    }
`;