// externals
import { connect } from 'react-redux';
import isEqual_ from 'lodash.isequal';

// libraries
import { ITradeInState } from '@makemydeal/dr-activities-common';

// utils
import { formatUtils } from '@makemydeal/dr-common-utils';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';

// actions
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

// routes
import * as tradeInRoutes from '../../../utils/routes';

// components
import VehicleConditionUI, { IVehicleConditionUIStateProps, IVehicleConditionUIDispatchProps } from './VehicleConditionUI';

const isEqual = isEqual_;

const mapStateToProps = (state: any): IVehicleConditionUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
    const { vehicle, zip } = tradeIn;
    const vehicleName = `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`;
    const selectedCondition = vehicle.condition || 'Good';
    const mileage = formatUtils.formatNumber(tradeInSelectors.getTradeInVehicleMileage(tradeIn));

    const conditionMileageZipSubtitle = `${selectedCondition} Condition | ${mileage} Miles | ${tradeIn.zip}`;
    const pageTitle = `What condition is your ${vehicleName} in?`;
    const headerSubtitle = 'Step 2 of 5';
    const pageTip = "Please select a condition carefully, as it will affect your car's value.";
    return {
        zip,
        vehicle,
        vehicleName,
        selectedCondition,
        mileage,
        pageTitle,
        headerSubtitle,
        pageTip,
        conditionMileageZipSubtitle
    };
};

const mapDispatchToProps = (dispatch: any): IVehicleConditionUIDispatchProps => {
    return {
        onValueChange: (condition) => {
            dispatch((_dispatch, getState) => {
                const state = getState();
                const savedVehicle = tradeInSelectors.getTradeInSavedVehicle(state.tradeInComponent);
                const hasConditionChanged = !isEqual(condition, savedVehicle.condition);

                if (hasConditionChanged) {
                    dispatch(tradeInComponentActionCreators.changedTradeIn(hasConditionChanged));
                }
            });
            dispatch(tradeInComponentActionCreators.updateTradeInCondition(condition));
        },
        next: () => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS));
            dispatch(tradeInComponentActionCreators.tradeInConditionApplied());
        },
        previous: () => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_INFO));
        }
    };
};

const VehicleCondition = connect(mapStateToProps, mapDispatchToProps)(VehicleConditionUI);

export default VehicleCondition;
