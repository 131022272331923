import type { StateTree } from '@makemydeal/dr-dash-types';
import { OfferReduxConfig, paymentGridMiddleware } from '@makemydeal/dr-offer-redux';
import { initActionTypes, ReduxNext, ReduxStore } from '@makemydeal/dr-shared-store';
import { AnyFSA } from '@makemydeal/dr-platform-shared';

let cachedPaymentGridMiddleware: ((store: ReduxStore<StateTree>) => (next: ReduxNext) => (action: AnyFSA) => void) | undefined;

export const middleware = (store: ReduxStore<StateTree>) => (next: ReduxNext) => (action: AnyFSA) => {
    if (action.type === initActionTypes.INIT_PENCIL_SUCCESS) {
        const usePaymentGridMV = action.payload?.featureToggles.usePaymentGridMV || false;

        if (usePaymentGridMV) {
            const config = OfferReduxConfig.getInstance();
            config.setConfig('isTermIdWithDownPayment', true);

            cachedPaymentGridMiddleware = paymentGridMiddleware();
        }
    }

    if (cachedPaymentGridMiddleware) {
        cachedPaymentGridMiddleware(store)(next)(action);
    } else {
        next(action);
    }
};
