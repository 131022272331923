// externals
import { useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { VehicleInfoDrawer, ProfitInfo } from '@makemydeal/dr-dash-components';
import { Action } from '@interstate/components/Action';
import { ArrowsRightLeftIcon, ArrowTopRightOnSquareIcon, ChartPieIcon } from '@interstate/components/Icons';
import { Typography } from '@interstate/components/Typography';
import { Popover } from '@interstate/components/Popover';
import { useInterstateTheme } from '@interstate/components/InterstateThemeProvider';

// selectors
import { dealerSelectors } from '@makemydeal/dr-shared-store';
import { configSelectors, navigationActionCreators, vehicleSelectors } from '@makemydeal/dr-dash-store';

import {
    StyledVehicleInfoCard,
    StyledVehicleInfoCardImage,
    StyledVehicleInfoCardContainer,
    StyledVehicleInfoCardActions,
    StyledVehicleInfoCardContent,
    StyledVehicleInfoCardStockVin,
    VehicleInfoCardSeparator
} from './VehicleInfo.style';

import { useCheckElementsOnSameRow } from '../../utils/useCheckElementsOnSameRow';
import { VehicleImage } from './VehicleImage';

export const VehicleInfoCard = () => {
    const theme = useInterstateTheme();
    const dispatch = useDispatch();

    const { condition, make, model, stockNumber, trim, vin, year, imageUrl, isCustom } = useSelector(vehicleSelectors.getVehicle);
    const vAutoUrl = useSelector(configSelectors.getVAutoInventoryUrl);
    const isSwitchVehicleEnabled = useSelector(dealerSelectors.isSwitchVehicleEnabled);

    const labelsContainerRef = useRef<HTMLDivElement>(null);
    const stockRef = useRef<HTMLDivElement>(null);
    const vinRef = useRef<HTMLDivElement>(null);
    const customRef = useRef<HTMLDivElement>(null);

    const isFirstDelimiterVisible = useCheckElementsOnSameRow(labelsContainerRef, [stockRef, vin ? vinRef : customRef]);
    const isSecondDelimiterVisible = useCheckElementsOnSameRow(labelsContainerRef, [vin ? vinRef : stockRef, customRef]);

    const vehicleInfoTitle = `${condition} ${year} ${make} ${model} ${trim}`;
    const stockLabel = `Stock #${stockNumber}`;
    const vinLabel = `VIN #${vin}`;
    const customVehicleLabel = 'Custom Vehicle';
    const imgAlt = `Photo of a ${year} ${make} ${model}`;

    return (
        <StyledVehicleInfoCardContainer>
            <StyledVehicleInfoCard theme={theme}>
                <StyledVehicleInfoCardImage>
                    <VehicleImage src={imageUrl} alt={imgAlt} />
                </StyledVehicleInfoCardImage>

                <StyledVehicleInfoCardContent>
                    <VehicleInfoDrawer
                        actionTitle={<span title={vehicleInfoTitle}>{vehicleInfoTitle}</span>}
                        actionOverrides={{
                            size: 'lg',
                            actionIcon: undefined,
                            sx: { textAlign: 'left' }
                        }}
                    />

                    <StyledVehicleInfoCardStockVin
                        className="vehicle-info-card__stock-vin-container"
                        theme={theme}
                        ref={labelsContainerRef}
                    >
                        {stockNumber && (
                            <Typography
                                tag="span"
                                variant="body-sm"
                                data-testid="stock"
                                className="vehicle-info-card__stock-label"
                                ref={stockRef}
                            >
                                <span>{stockLabel}</span>
                                {(vin || isCustom) && (
                                    <VehicleInfoCardSeparator
                                        isVisible={isFirstDelimiterVisible}
                                        theme={theme}
                                        className="vehicle-info-card_separator"
                                        data-testid="vehicle-info-card_separator"
                                        aria-hidden="true"
                                    ></VehicleInfoCardSeparator>
                                )}
                            </Typography>
                        )}

                        {vin && (
                            <Typography
                                tag="span"
                                variant="body-sm"
                                data-testid="vehicle-info-card-vin-label"
                                className="vehicle-info-card__vin-label"
                                ref={vinRef}
                            >
                                {vinLabel}
                                {isCustom && (
                                    <VehicleInfoCardSeparator
                                        isVisible={isSecondDelimiterVisible}
                                        theme={theme}
                                        className="vehicle-info-card_separator"
                                        data-testid="vehicle-info-card_separator"
                                        aria-hidden="true"
                                    ></VehicleInfoCardSeparator>
                                )}
                            </Typography>
                        )}

                        {isCustom && (
                            <Typography
                                tag="span"
                                variant="body-sm"
                                data-testid="vehicle-info-card-custom-vehicle-label"
                                className="vehicle-info-card__custom-vehicle-label"
                                ref={customRef}
                                sx={{ marginRight: theme.tokens.BaseSpace100 }}
                            >
                                <span>{customVehicleLabel}</span>
                            </Typography>
                        )}
                    </StyledVehicleInfoCardStockVin>
                </StyledVehicleInfoCardContent>
            </StyledVehicleInfoCard>

            <StyledVehicleInfoCardActions theme={theme}>
                {isSwitchVehicleEnabled && (
                    <Action
                        data-testid="btnChangeVehicle"
                        size="sm"
                        onClick={() => {
                            dispatch(navigationActionCreators.navigateToChangeVehicle());
                        }}
                        actionIcon={{
                            icon: <ArrowsRightLeftIcon />,
                            start: true
                        }}
                    >
                        Change Vehicle
                    </Action>
                )}

                {vAutoUrl && (
                    <a href={`${vAutoUrl}&vin=${vin}`} target="_blank" data-testid="vauto-link" rel="noreferrer">
                        <Action
                            size="sm"
                            actionIcon={{
                                icon: <ArrowTopRightOnSquareIcon />,
                                start: true
                            }}
                        >
                            vAuto
                        </Action>
                    </a>
                )}
                <Popover
                    padding={true}
                    header="Profit Details"
                    data-testid="profit-details-popover"
                    position="bottom"
                    trigger="outsideClick"
                    popoverContent={<ProfitInfo />}
                >
                    <Action
                        data-testid="profit-details-btn"
                        size="sm"
                        actionIcon={{
                            icon: <ChartPieIcon />,
                            start: true
                        }}
                    >
                        Profit
                    </Action>
                </Popover>
            </StyledVehicleInfoCardActions>
        </StyledVehicleInfoCardContainer>
    );
};
