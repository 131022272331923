// externals
import { connect } from 'react-redux';
import isEqual_ from 'lodash.isequal';

// libraries
import { getInitialDeepLink, ITradeInState, navigateToInitDeepLink } from '@makemydeal/dr-activities-common';
import { formatUtils } from '@makemydeal/dr-common-utils';
import { validator } from '@makemydeal/dr-platform-shared';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as dealerSelectors from '../../../store/mmd/dealerSelectors';
import * as offerSelectors from '../../../store/mmd/offerSelectors';

// actions
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

// consts/enums
import * as tradeInRoutes from '../../../utils/routes';
import { OFFER_DDC_DEALER_SOURCE } from '../../../utils/constants';

// components
import VehicleConditionUI from './VehicleConditionUI';

// interfaces/types
import { IVehicleConditionUIDispatchProps } from './VehicleConditionUI';
import { isNavigateToInitDeepLinkRouteEnabled } from '../../../store/featureToggles';
import { getSponsor } from '../../../store/mmd/widgetSelectors';

const isEqual = isEqual_;

const mapStateToProps = (state: any) => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as ITradeInState;
    const isManualEntryFlow = tradeInSelectors.isManualEntryFlow(state);
    const { vehicle, zip } = tradeIn;
    const vehicleName = `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`;
    const selectedCondition = vehicle.condition;
    const mileage = formatUtils.formatNumber(tradeInSelectors.getTradeInVehicleMileage(tradeIn));
    const isWebsiteExcellentHidden = dealerSelectors.getTradeInHideExcellentConditionWebsite(state);
    const isListingSiteExcellentHidden = dealerSelectors.getTradeInHideExcellentConditionListingSite(state);
    const conditionMileageZipSubtitle = `${selectedCondition} Condition | ${mileage} Miles | ${tradeIn.zip}`;
    const pageTitle = 'Tell Us About Its Condition';
    const pageSubTitle = `Please select your condition carefully, as it will affect the trade-in value of your ${vehicleName}.`;
    const sponsor = getSponsor(state).toString();
    const isListingDealer = validator.isListingSite(sponsor);
    const isWebSiteDealer = !isListingDealer;
    const isExcellentHiddenWebsite = isWebSiteDealer && isWebsiteExcellentHidden;
    const isExcellentHiddenListing = isListingDealer && isListingSiteExcellentHidden;

    return {
        zip,
        vehicle,
        vehicleName,
        selectedCondition,
        mileage,
        pageTitle,
        pageSubTitle,
        conditionMileageZipSubtitle,
        isManualEntryFlow,
        isExcellentHiddenWebsite,
        isExcellentHiddenListing
    };
};

const mapDispatchToProps = (dispatch: any): IVehicleConditionUIDispatchProps => {
    return {
        onValueChange: (condition) => {
            dispatch((_dispatch, getState) => {
                const state = getState();
                const savedVehicle = tradeInSelectors.getTradeInSavedVehicle(state.tradeInComponent);
                const hasConditionChanged = !isEqual(condition, savedVehicle.condition);
                if (hasConditionChanged) {
                    dispatch(tradeInComponentActionCreators.changedTradeIn(hasConditionChanged));
                    dispatch(
                        tradeInComponentActionCreators.trackVehicleConditionAnalytics(condition?.replace(/\s/g, '').toLowerCase())
                    );
                }
            });
            dispatch(tradeInComponentActionCreators.updateTradeInCondition(condition));
        },
        next: () => {
            dispatch((_dispatch, getState) => {
                dispatch(tradeInComponentActionCreators.tradeInConditionApplied());

                const state = getState();
                const initDeepLink = getInitialDeepLink(state);
                const source = offerSelectors.getOfferDealerSource(state);

                if (tradeInSelectors.isManualEntryFlow(state)) {
                    dispatch(tradeInComponentActionCreators.saveOrUpdateTradeIn());

                    if (
                        source?.sponsor &&
                        source.sponsor !== OFFER_DDC_DEALER_SOURCE &&
                        isNavigateToInitDeepLinkRouteEnabled(state) &&
                        initDeepLink !== '' &&
                        initDeepLink !== tradeInRoutes.TRADE_MANUAL_ENTRY
                    ) {
                        dispatch(navigateToInitDeepLink(initDeepLink));
                    }
                } else {
                    dispatch(
                        tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS_STEP_ONE)
                    );
                }
            });
        },
        goToRoute: (route: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(route));
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
        }
    };
};

const VehicleCondition = connect(mapStateToProps, mapDispatchToProps)(VehicleConditionUI);

export default VehicleCondition;
