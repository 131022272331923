export const mockValidTrade = {
    tivSource: null,
    amountOwed: 0,
    estimate: [
        {
            name: 'Fair',
            value: 985,
            selected: false
        },
        {
            name: 'Good',
            value: 1150,
            selected: true
        },
        {
            name: 'Excellent',
            value: 1385,
            selected: false
        },
        {
            name: 'Very Good',
            value: 1297,
            selected: false
        }
    ],
    shouldIncludeTradeIn: false,
    source: '',
    status: '',
    expirationDate: '',
    offerDate: '',
    offerId: '',
    location: {},
    lookups: {
        conditions: ['Excellent', 'Very Good', 'Good', 'Fair'],
        makes: {
            isLoading: false,
            options: [
                {
                    id: 2,
                    name: 'Acura'
                },
                {
                    id: 4,
                    name: 'Audi'
                },
                {
                    id: 5,
                    name: 'BMW'
                },
                {
                    id: 7,
                    name: 'Buick'
                },
                {
                    id: 8,
                    name: 'Cadillac'
                },
                {
                    id: 9,
                    name: 'Chevrolet'
                },
                {
                    id: 10,
                    name: 'Chrysler'
                },
                {
                    id: 13,
                    name: 'Dodge'
                },
                {
                    id: 14,
                    name: 'Eagle'
                },
                {
                    id: 15,
                    name: 'Ford'
                },
                {
                    id: 16,
                    name: 'GMC'
                },
                {
                    id: 18,
                    name: 'Honda'
                },
                {
                    id: 19,
                    name: 'HUMMER'
                },
                {
                    id: 20,
                    name: 'Hyundai'
                },
                {
                    id: 21,
                    name: 'INFINITI'
                },
                {
                    id: 22,
                    name: 'Isuzu'
                },
                {
                    id: 23,
                    name: 'Jaguar'
                },
                {
                    id: 24,
                    name: 'Jeep'
                },
                {
                    id: 25,
                    name: 'Kia'
                },
                {
                    id: 26,
                    name: 'Land Rover'
                },
                {
                    id: 27,
                    name: 'Lexus'
                },
                {
                    id: 28,
                    name: 'Lincoln'
                },
                {
                    id: 30,
                    name: 'MAZDA'
                },
                {
                    id: 31,
                    name: 'Mercedes-Benz'
                },
                {
                    id: 32,
                    name: 'Mercury'
                },
                {
                    id: 34,
                    name: 'Mitsubishi'
                },
                {
                    id: 35,
                    name: 'Nissan'
                },
                {
                    id: 36,
                    name: 'Oldsmobile'
                },
                {
                    id: 39,
                    name: 'Plymouth'
                },
                {
                    id: 40,
                    name: 'Pontiac'
                },
                {
                    id: 41,
                    name: 'Porsche'
                },
                {
                    id: 43,
                    name: 'Saab'
                },
                {
                    id: 44,
                    name: 'Saturn'
                },
                {
                    id: 47,
                    name: 'Subaru'
                },
                {
                    id: 48,
                    name: 'Suzuki'
                },
                {
                    id: 49,
                    name: 'Toyota'
                },
                {
                    id: 50,
                    name: 'Volkswagen'
                },
                {
                    id: 51,
                    name: 'Volvo'
                }
            ]
        },
        models: {
            isLoading: false,
            options: [
                {
                    id: 122,
                    name: 'Accord'
                },
                {
                    id: 123,
                    name: 'Civic'
                },
                {
                    id: 126,
                    name: 'CR-V'
                },
                {
                    id: 128,
                    name: 'Odyssey'
                },
                {
                    id: 386,
                    name: 'Passport'
                },
                {
                    id: 395,
                    name: 'Prelude'
                }
            ]
        },
        trims: {
            isLoading: false,
            options: [
                {
                    id: 264183,
                    name: 'DX Sedan 4D'
                },
                {
                    id: 264185,
                    name: 'EX Coupe 2D'
                },
                {
                    id: 264180,
                    name: 'EX Sedan 4D'
                },
                {
                    id: 264182,
                    name: 'LX Coupe 2D'
                },
                {
                    id: 264186,
                    name: 'LX Sedan 4D'
                }
            ]
        },
        years: {
            isLoading: false,
            options: [
                '2021',
                '2020',
                '2019',
                '2018',
                '2017',
                '2016',
                '2015',
                '2014',
                '2013',
                '2012',
                '2011',
                '2010',
                '2009',
                '2008',
                '2007',
                '2006',
                '2005',
                '2004',
                '2003',
                '2002',
                '2001',
                '2000',
                '1999',
                '1998',
                '1997',
                '1996',
                '1995',
                '1994',
                '1993',
                '1992'
            ]
        },
        ownershipTypes: []
    },
    ownership: 'Purchase',
    saved: {
        amountOwed: 0,
        estimate: [],
        location: {},
        offerDate: '',
        offerExpirationDate: '',
        status: '',
        tivSource: '',
        value: 0,
        vehicle: {
            color: '',
            condition: '',
            make: {
                id: '2',
                name: 'Acura'
            },
            mileage: null,
            model: {
                id: '30207',
                name: 'ILX'
            },
            trim: {
                id: '',
                name: '',
                vehicleId: ''
            },
            vin: '',
            year: '2015'
        },
        vehicleOptions: {},
        zip: ''
    },
    tivStatus: '',
    tivIcoStatus: '',
    isIcoTradeInCompleted: false,
    tivOfferId: '',
    value: 1150,
    vehicle: {
        color: '',
        condition: 'Good',
        make: {
            id: '18',
            name: 'Honda'
        },
        mileage: 100000,
        model: {
            id: '122',
            name: 'Accord'
        },
        trim: {
            id: '264183',
            name: 'DX Sedan 4D',
            vehicleId: '6588'
        },
        vin: '',
        year: '1998'
    },
    vehicleClass: '',
    vehicleOptions: {
        Engine: {
            name: 'Engine',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Engine',
                    displayName: '4-Cyl, 2.3 Liter',
                    isSelected: true,
                    id: 251098
                }
            ]
        },
        Transmission: {
            name: 'Transmission',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Transmission',
                    displayName: 'Manual, 5-Spd',
                    isSelected: false,
                    id: 251099
                },
                {
                    categoryName: 'Transmission',
                    displayName: 'Automatic',
                    isSelected: true,
                    id: 251105
                }
            ]
        },
        Drivetrain: {
            name: 'Drivetrain',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Drivetrain',
                    displayName: 'FWD',
                    isSelected: true,
                    id: 251107
                }
            ]
        },
        'Braking and Traction': {
            name: 'Braking and Traction',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'ABS (4-Wheel)',
                    isSelected: true,
                    id: 251168
                }
            ]
        },
        'Comfort and Convenience': {
            name: 'Comfort and Convenience',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Air Conditioning',
                    isSelected: true,
                    id: 251109
                },
                {
                    displayName: 'Power Windows',
                    isSelected: true,
                    id: 251123
                },
                {
                    displayName: 'Power Door Locks',
                    isSelected: true,
                    id: 251124
                },
                {
                    displayName: 'Cruise Control',
                    isSelected: true,
                    id: 251126
                }
            ]
        },
        Steering: {
            name: 'Steering',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Power Steering',
                    isSelected: true,
                    id: 251119
                },
                {
                    displayName: 'Tilt Wheel',
                    isSelected: true,
                    id: 251125
                }
            ]
        },
        'Entertainment and Instrumentation': {
            name: 'Entertainment and Instrumentation',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'AM/FM Stereo',
                    isSelected: true,
                    id: 251127
                },
                {
                    displayName: 'Cassette',
                    isSelected: true,
                    id: 251131
                },
                {
                    displayName: 'CD/MP3 (Single Disc)',
                    isSelected: false,
                    id: 549966
                },
                {
                    displayName: 'CD/MP3 (Multi Disc)',
                    isSelected: false,
                    id: 549967
                },
                {
                    displayName: 'Premium Sound',
                    isSelected: false,
                    id: 251153
                },
                {
                    displayName: 'Navigation System',
                    isSelected: false,
                    id: 251160
                },
                {
                    displayName: 'DVD System',
                    isSelected: false,
                    id: 251162
                },
                {
                    displayName: 'Integrated Phone',
                    isSelected: false,
                    id: 251158
                }
            ]
        },
        'Safety and Security': {
            name: 'Safety and Security',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Dual Air Bags',
                    isSelected: true,
                    id: 251163
                },
                {
                    displayName: 'Side Air Bags',
                    isSelected: false,
                    id: 251164
                },
                {
                    displayName: 'F&R Side Air Bags',
                    isSelected: false,
                    id: 251167
                }
            ]
        },
        Seats: {
            name: 'Seats',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Power Seat',
                    isSelected: false,
                    id: 251179
                },
                {
                    displayName: 'Dual Power Seats',
                    isSelected: false,
                    id: 251188
                },
                {
                    displayName: 'Leather',
                    isSelected: false,
                    id: 251172
                }
            ]
        },
        'Roof and Glass': {
            name: 'Roof and Glass',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Sun Roof (Flip-Up)',
                    isSelected: false,
                    id: 251193
                },
                {
                    displayName: 'Sun Roof (Sliding)',
                    isSelected: false,
                    id: 251199
                },
                {
                    displayName: 'Moon Roof',
                    isSelected: false,
                    id: 251204
                }
            ]
        },
        Exterior: {
            name: 'Exterior',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Rear Spoiler',
                    isSelected: false,
                    id: 251210
                }
            ]
        },
        'Wheels and Tires': {
            name: 'Wheels and Tires',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Steel Wheels',
                    isSelected: true,
                    id: 4233510
                },
                {
                    displayName: 'Alloy Wheels',
                    isSelected: false,
                    id: 251214
                },
                {
                    displayName: 'Premium Wheels',
                    isSelected: false,
                    id: 251221
                },
                {
                    displayName: 'Premium Wheels 19"+',
                    isSelected: false,
                    id: 549971
                }
            ]
        }
    },
    zip: '30319',
    conflicts: [],
    isCompleted: false,
    currentUserLocationRoute: '/equipmentOptions',
    tradeSaveFailed: false,
    tradeSavePending: false,
    tradePaymentFailed: false,
    hasTradeInChanged: true,
    tradeInSent: false,
    isTradeValuationReset: false,
    searchResultsForVin: [],
    searchResultsForYMMT: [],
    isSearchingForVin: false,
    isSearchingForYMMT: false,
    errorCount: 0,
    isUnavailable: false,
    isICOTradeAlreadySubmitted: false,
    skippedTradeIn: false
};

export const mockPaymentFailureTrade = {
    tivSource: null,
    amountOwed: 0,
    estimate: [
        {
            name: 'Fair',
            value: 32909,
            selected: false
        },
        {
            name: 'Good',
            value: 36892,
            selected: false
        },
        {
            name: 'Excellent',
            value: 37372,
            selected: true
        },
        {
            name: 'Very Good',
            value: 37030,
            selected: false
        }
    ],
    shouldIncludeTradeIn: false,
    source: '',
    status: '',
    expirationDate: '',
    offerDate: '',
    offerId: '',
    location: {},
    lookups: {
        conditions: ['Excellent', 'Very Good', 'Good', 'Fair'],
        makes: {
            isLoading: false,
            options: [
                {
                    id: 2,
                    name: 'Acura'
                },
                {
                    id: 4,
                    name: 'Audi'
                },
                {
                    id: 5,
                    name: 'BMW'
                },
                {
                    id: 9,
                    name: 'Chevrolet'
                },
                {
                    id: 897,
                    name: 'Genesis'
                },
                {
                    id: 20,
                    name: 'Hyundai'
                },
                {
                    id: 24,
                    name: 'Jeep'
                },
                {
                    id: 25,
                    name: 'Kia'
                },
                {
                    id: 27,
                    name: 'Lexus'
                },
                {
                    id: 49,
                    name: 'Toyota'
                }
            ]
        },
        models: {
            isLoading: false,
            options: [
                {
                    id: 12,
                    name: '3 Series'
                }
            ]
        },
        trims: {
            isLoading: false,
            options: [
                {
                    id: 360216,
                    name: '330e Sedan 4D'
                },
                {
                    id: 360238,
                    name: '330e xDrive Sedan 4D'
                },
                {
                    id: 360237,
                    name: '330i Sedan 4D'
                },
                {
                    id: 360197,
                    name: '330i xDrive Sedan 4D'
                },
                {
                    id: 360199,
                    name: 'M340i Sedan 4D'
                },
                {
                    id: 360218,
                    name: 'M340i xDrive Sedan 4D'
                }
            ]
        },
        years: {
            isLoading: false,
            options: [
                '2021',
                '2020',
                '2019',
                '2018',
                '2017',
                '2016',
                '2015',
                '2014',
                '2013',
                '2012',
                '2011',
                '2010',
                '2009',
                '2008',
                '2007',
                '2006',
                '2005',
                '2004',
                '2003',
                '2002',
                '2001',
                '2000',
                '1999',
                '1998',
                '1997',
                '1996',
                '1995',
                '1994',
                '1993',
                '1992'
            ]
        },
        ownershipTypes: []
    },
    ownership: 'Purchase',
    saved: {
        amountOwed: 0,
        estimate: [],
        location: {},
        offerDate: '',
        offerExpirationDate: '',
        status: '',
        tivSource: '',
        value: 0,
        vehicle: {
            color: '',
            condition: '',
            make: {
                id: '5',
                name: 'BMW'
            },
            mileage: null,
            model: {
                id: '12',
                name: '3 Series'
            },
            trim: {
                id: '',
                name: '',
                vehicleId: ''
            },
            vin: '',
            year: '2021'
        },
        vehicleOptions: {},
        zip: ''
    },
    tivStatus: '',
    tivIcoStatus: '',
    isIcoTradeInCompleted: false,
    tivOfferId: '',
    value: 37372,
    vehicle: {
        color: '',
        condition: 'Excellent',
        make: {
            id: '5',
            name: 'BMW'
        },
        mileage: 222,
        model: {
            id: '12',
            name: '3 Series'
        },
        trim: {
            id: '360216',
            name: '330e Sedan 4D',
            vehicleId: '451254'
        },
        vin: '',
        year: '2021'
    },
    vehicleClass: '',
    vehicleOptions: {
        Engine: {
            name: 'Engine',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Engine',
                    displayName: '4-Cyl, Hybrid, Turbo, 2.0 Liter',
                    isSelected: true,
                    id: 9814781
                }
            ]
        },
        Transmission: {
            name: 'Transmission',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Transmission',
                    displayName: 'Automatic, 8-Spd Sport w/Sport & Manual Modes',
                    isSelected: true,
                    id: 9814782
                }
            ]
        },
        Drivetrain: {
            name: 'Drivetrain',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Drivetrain',
                    displayName: 'RWD',
                    isSelected: true,
                    id: 9814780
                }
            ]
        },
        'Accessory Packages': {
            name: 'Accessory Packages',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Driving Assistance Pkg',
                    isSelected: false,
                    id: 9814796
                },
                {
                    displayName: 'Executive Pkg',
                    isSelected: false,
                    id: 9814800
                },
                {
                    displayName: 'Blind-Spot Detection',
                    isSelected: true,
                    id: 9814806
                },
                {
                    displayName: 'Premium Pkg',
                    isSelected: false,
                    id: 9814808
                },
                {
                    displayName: 'Convenience Pkg',
                    isSelected: true,
                    id: 9814810
                },
                {
                    displayName: 'M Sport Pkg',
                    isSelected: false,
                    id: 9814817
                },
                {
                    displayName: 'Shadowline Pkg',
                    isSelected: false,
                    id: 9814827
                },
                {
                    displayName: 'Dynamic Handling Pkg',
                    isSelected: false,
                    id: 9814837
                },
                {
                    displayName: 'Sport Line',
                    isSelected: true,
                    id: 9814840
                }
            ]
        },
        'Braking and Traction': {
            name: 'Braking and Traction',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Dynamic Traction Control',
                    isSelected: true,
                    id: 9814832
                },
                {
                    displayName: 'Dynamic Stability Control',
                    isSelected: true,
                    id: 9814829
                },
                {
                    displayName: 'ABS (4-Wheel)',
                    isSelected: true,
                    id: 9814783
                }
            ]
        },
        'Comfort and Convenience': {
            name: 'Comfort and Convenience',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Alarm System',
                    isSelected: true,
                    id: 9814790
                },
                {
                    displayName: 'Keyless Entry',
                    isSelected: false,
                    id: 9814802
                },
                {
                    displayName: 'Keyless Start',
                    isSelected: false,
                    id: 9814803
                },
                {
                    displayName: 'Air Conditioning',
                    isSelected: true,
                    id: 9814784
                },
                {
                    displayName: 'Air Conditioning, Rear',
                    isSelected: true,
                    id: 9814785
                },
                {
                    displayName: 'Power Windows',
                    isSelected: true,
                    id: 9814825
                },
                {
                    displayName: 'Power Door Locks',
                    isSelected: true,
                    id: 9814822
                },
                {
                    displayName: 'Active Cruise Control',
                    isSelected: false,
                    id: 9814835
                },
                {
                    displayName: 'Dynamic Cruise Control',
                    isSelected: true,
                    id: 9814799
                },
                {
                    displayName: 'Power Liftgate Release',
                    isSelected: false,
                    id: 9814823
                }
            ]
        },
        Steering: {
            name: 'Steering',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Servotronic Steering',
                    isSelected: true,
                    id: 9814824
                },
                {
                    displayName: 'Tilt & Telescoping Wheel',
                    isSelected: true,
                    id: 9814831
                }
            ]
        },
        'Entertainment and Instrumentation': {
            name: 'Entertainment and Instrumentation',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Head-Up Display',
                    isSelected: false,
                    id: 9814801
                },
                {
                    displayName: 'AM/FM/HD Radio',
                    isSelected: true,
                    id: 9814789
                },
                {
                    displayName: 'harman/kardon Surround Sound',
                    isSelected: false,
                    id: 9814815
                },
                {
                    displayName: 'SiriusXM Satellite',
                    isSelected: true,
                    id: 9814826
                },
                {
                    displayName: 'Navigation System',
                    isSelected: true,
                    id: 9814820
                },
                {
                    displayName: 'Bluetooth Wireless',
                    isSelected: true,
                    id: 9814794
                },
                {
                    displayName: 'BMW Assist',
                    isSelected: true,
                    id: 9814830
                }
            ]
        },
        'Safety and Security': {
            name: 'Safety and Security',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Park Assist',
                    isSelected: false,
                    id: 9814811
                },
                {
                    displayName: 'Park Distance Control',
                    isSelected: true,
                    id: 9814807
                },
                {
                    displayName: 'Backup Camera',
                    isSelected: true,
                    id: 9814791
                },
                {
                    displayName: 'Surround View Camera',
                    isSelected: false,
                    id: 9814812
                },
                {
                    displayName: 'Dual Air Bags',
                    isSelected: true,
                    id: 9814797
                },
                {
                    displayName: 'Side Air Bags',
                    isSelected: true,
                    id: 9814828
                },
                {
                    displayName: 'F&R Head Curtain Air Bags',
                    isSelected: true,
                    id: 9814813
                },
                {
                    displayName: 'Knee Air Bags',
                    isSelected: true,
                    id: 9814816
                },
                {
                    displayName: 'Lane Departure Warning System',
                    isSelected: true,
                    id: 9814834
                }
            ]
        },
        Seats: {
            name: 'Seats',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Heated Seats',
                    isSelected: false,
                    id: 9814804
                },
                {
                    displayName: 'Dual Power Seats',
                    isSelected: true,
                    id: 9814798
                },
                {
                    displayName: 'Leather',
                    isSelected: false,
                    id: 9814809
                }
            ]
        },
        'Roof and Glass': {
            name: 'Roof and Glass',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Moon Roof',
                    isSelected: true,
                    id: 9814819
                }
            ]
        },
        Lighting: {
            name: 'Lighting',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Daytime Running Lights',
                    isSelected: true,
                    id: 9814795
                },
                {
                    displayName: 'LED Headlamps',
                    isSelected: false,
                    id: 9814805
                }
            ]
        },
        Exterior: {
            name: 'Exterior',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Fog Lights',
                    isSelected: false,
                    id: 9814818
                },
                {
                    displayName: 'Rear Spoiler',
                    isSelected: false,
                    id: 9814836
                }
            ]
        },
        'Wheels and Tires': {
            name: 'Wheels and Tires',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Alloy Wheels',
                    isSelected: true,
                    id: 9814786
                },
                {
                    displayName: 'Premium Wheels',
                    isSelected: false,
                    id: 9814788
                },
                {
                    displayName: 'Premium Wheels 19"+',
                    isSelected: false,
                    id: 9814787
                }
            ]
        }
    },
    zip: '30319',
    conflicts: [],
    isCompleted: false,
    currentUserLocationRoute: '/equipmentOptions',
    tradeSaveFailed: false,
    tradeSavePending: false,
    tradePaymentFailed: false,
    hasTradeInChanged: true,
    tradeInSent: false,
    isTradeValuationReset: false,
    searchResultsForVin: [],
    searchResultsForYMMT: [],
    isSearchingForVin: false,
    isSearchingForYMMT: false,
    errorCount: 0,
    isUnavailable: false,
    isICOTradeAlreadySubmitted: false,
    skippedTradeIn: false
};

export const mockNegativeEquityTrade = {
    tivSource: null,
    amountOwed: 10000,
    estimate: [
        {
            name: 'Fair',
            value: 2450,
            selected: true
        },
        {
            name: 'Good',
            value: 2797,
            selected: false
        },
        {
            name: 'Excellent',
            value: 3298,
            selected: false
        },
        {
            name: 'Very Good',
            value: 3103,
            selected: false
        }
    ],
    shouldIncludeTradeIn: true,
    source: '',
    status: '',
    expirationDate: '',
    offerDate: '',
    offerId: '',
    location: {},
    lookups: {
        conditions: ['Excellent', 'Very Good', 'Good', 'Fair'],
        makes: {
            isLoading: false,
            options: [
                {
                    id: 2,
                    name: 'Acura'
                },
                {
                    id: 54,
                    name: 'Aston Martin'
                },
                {
                    id: 4,
                    name: 'Audi'
                },
                {
                    id: 57,
                    name: 'Bentley'
                },
                {
                    id: 5,
                    name: 'BMW'
                },
                {
                    id: 7,
                    name: 'Buick'
                },
                {
                    id: 8,
                    name: 'Cadillac'
                },
                {
                    id: 9,
                    name: 'Chevrolet'
                },
                {
                    id: 10,
                    name: 'Chrysler'
                },
                {
                    id: 13,
                    name: 'Dodge'
                },
                {
                    id: 15,
                    name: 'Ford'
                },
                {
                    id: 16,
                    name: 'GMC'
                },
                {
                    id: 18,
                    name: 'Honda'
                },
                {
                    id: 19,
                    name: 'HUMMER'
                },
                {
                    id: 20,
                    name: 'Hyundai'
                },
                {
                    id: 21,
                    name: 'INFINITI'
                },
                {
                    id: 22,
                    name: 'Isuzu'
                },
                {
                    id: 23,
                    name: 'Jaguar'
                },
                {
                    id: 24,
                    name: 'Jeep'
                },
                {
                    id: 25,
                    name: 'Kia'
                },
                {
                    id: 26,
                    name: 'Land Rover'
                },
                {
                    id: 27,
                    name: 'Lexus'
                },
                {
                    id: 28,
                    name: 'Lincoln'
                },
                {
                    id: 55,
                    name: 'Lotus'
                },
                {
                    id: 61,
                    name: 'Maserati'
                },
                {
                    id: 56,
                    name: 'Maybach'
                },
                {
                    id: 30,
                    name: 'MAZDA'
                },
                {
                    id: 31,
                    name: 'Mercedes-Benz'
                },
                {
                    id: 32,
                    name: 'Mercury'
                },
                {
                    id: 29,
                    name: 'MINI'
                },
                {
                    id: 34,
                    name: 'Mitsubishi'
                },
                {
                    id: 35,
                    name: 'Nissan'
                },
                {
                    id: 60,
                    name: 'Panoz'
                },
                {
                    id: 40,
                    name: 'Pontiac'
                },
                {
                    id: 41,
                    name: 'Porsche'
                },
                {
                    id: 58,
                    name: 'Rolls-Royce'
                },
                {
                    id: 43,
                    name: 'Saab'
                },
                {
                    id: 44,
                    name: 'Saturn'
                },
                {
                    id: 45,
                    name: 'Scion'
                },
                {
                    id: 47,
                    name: 'Subaru'
                },
                {
                    id: 48,
                    name: 'Suzuki'
                },
                {
                    id: 49,
                    name: 'Toyota'
                },
                {
                    id: 50,
                    name: 'Volkswagen'
                },
                {
                    id: 51,
                    name: 'Volvo'
                }
            ]
        },
        models: {
            isLoading: false,
            options: [
                {
                    id: 122,
                    name: 'Accord'
                },
                {
                    id: 123,
                    name: 'Civic'
                },
                {
                    id: 126,
                    name: 'CR-V'
                },
                {
                    id: 127,
                    name: 'Element'
                },
                {
                    id: 125,
                    name: 'Insight'
                },
                {
                    id: 128,
                    name: 'Odyssey'
                },
                {
                    id: 129,
                    name: 'Pilot'
                },
                {
                    id: 124,
                    name: 'S2000'
                }
            ]
        },
        trims: {
            isLoading: false,
            options: [
                {
                    id: 266568,
                    name: 'DX Sedan 4D'
                },
                {
                    id: 275981,
                    name: 'EX Coupe 2D'
                },
                {
                    id: 276009,
                    name: 'EX Sedan 4D'
                },
                {
                    id: 266722,
                    name: 'EX-L Coupe 2D'
                },
                {
                    id: 266726,
                    name: 'EX-L Sedan 4D'
                },
                {
                    id: 267152,
                    name: 'Hybrid Sedan 4D'
                },
                {
                    id: 277328,
                    name: 'LX Coupe 2D'
                },
                {
                    id: 267948,
                    name: 'LX Sedan 4D'
                },
                {
                    id: 268004,
                    name: 'LX Special Edition Coupe 2D'
                }
            ]
        },
        years: {
            isLoading: false,
            options: [
                '2021',
                '2020',
                '2019',
                '2018',
                '2017',
                '2016',
                '2015',
                '2014',
                '2013',
                '2012',
                '2011',
                '2010',
                '2009',
                '2008',
                '2007',
                '2006',
                '2005',
                '2004',
                '2003',
                '2002',
                '2001',
                '2000',
                '1999',
                '1998',
                '1997',
                '1996',
                '1995',
                '1994',
                '1993',
                '1992'
            ]
        },
        ownershipTypes: []
    },
    ownership: 'Purchase',
    saved: {
        amountOwed: 0,
        estimate: [],
        location: {},
        offerDate: '',
        offerExpirationDate: '',
        status: '',
        tivSource: '',
        value: 0,
        vehicle: {
            color: '',
            condition: '',
            make: {
                id: '18',
                name: 'Honda'
            },
            mileage: null,
            model: {
                id: '122',
                name: 'Accord'
            },
            trim: {
                id: '',
                name: '',
                vehicleId: ''
            },
            vin: '',
            year: '2005'
        },
        vehicleOptions: {},
        zip: ''
    },
    tivStatus: '',
    tivIcoStatus: '',
    isIcoTradeInCompleted: false,
    tivOfferId: '',
    value: 2450,
    vehicle: {
        color: '',
        condition: 'Fair',
        make: {
            id: '18',
            name: 'Honda'
        },
        mileage: 100000,
        model: {
            id: '122',
            name: 'Accord'
        },
        trim: {
            id: '266568',
            name: 'DX Sedan 4D',
            vehicleId: '846'
        },
        vin: '',
        year: '2005'
    },
    vehicleClass: '',
    vehicleOptions: {
        Engine: {
            name: 'Engine',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Engine',
                    displayName: '4-Cyl, VTEC, 2.4 Liter',
                    isSelected: true,
                    id: 35013
                }
            ]
        },
        Transmission: {
            name: 'Transmission',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Transmission',
                    displayName: 'Manual, 5-Spd',
                    isSelected: false,
                    id: 35014
                },
                {
                    categoryName: 'Transmission',
                    displayName: 'Automatic',
                    isSelected: true,
                    id: 35021
                }
            ]
        },
        Drivetrain: {
            name: 'Drivetrain',
            allowMultiSelect: false,
            options: [
                {
                    categoryName: 'Drivetrain',
                    displayName: 'FWD',
                    isSelected: true,
                    id: 35022
                }
            ]
        },
        'Braking and Traction': {
            name: 'Braking and Traction',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'ABS (4-Wheel)',
                    isSelected: true,
                    id: 35110
                }
            ]
        },
        'Comfort and Convenience': {
            name: 'Comfort and Convenience',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Air Conditioning',
                    isSelected: true,
                    id: 35025
                },
                {
                    displayName: 'Power Windows',
                    isSelected: true,
                    id: 35033
                },
                {
                    displayName: 'Power Door Locks',
                    isSelected: true,
                    id: 35035
                },
                {
                    displayName: 'Cruise Control',
                    isSelected: true,
                    id: 35038
                }
            ]
        },
        Steering: {
            name: 'Steering',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Power Steering',
                    isSelected: true,
                    id: 35032
                },
                {
                    displayName: 'Tilt Wheel',
                    isSelected: true,
                    id: 35036
                }
            ]
        },
        'Entertainment and Instrumentation': {
            name: 'Entertainment and Instrumentation',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'AM/FM Stereo',
                    isSelected: true,
                    id: 35040
                },
                {
                    displayName: 'Cassette',
                    isSelected: true,
                    id: 35054
                },
                {
                    displayName: 'CD/MP3 (Single Disc)',
                    isSelected: true,
                    id: 535366
                },
                {
                    displayName: 'CD/MP3 (Multi Disc)',
                    isSelected: false,
                    id: 535368
                },
                {
                    displayName: 'Premium Sound',
                    isSelected: false,
                    id: 35070
                },
                {
                    displayName: 'Navigation System',
                    isSelected: false,
                    id: 35085
                },
                {
                    displayName: 'DVD System',
                    isSelected: false,
                    id: 35097
                },
                {
                    displayName: 'Integrated Phone',
                    isSelected: false,
                    id: 35078
                }
            ]
        },
        'Safety and Security': {
            name: 'Safety and Security',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Dual Air Bags',
                    isSelected: true,
                    id: 35105
                },
                {
                    displayName: 'Side Air Bags',
                    isSelected: false,
                    id: 35106
                },
                {
                    displayName: 'F&R Side Air Bags',
                    isSelected: true,
                    id: 35109
                }
            ]
        },
        Seats: {
            name: 'Seats',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Power Seat',
                    isSelected: false,
                    id: 35130
                },
                {
                    displayName: 'Dual Power Seats',
                    isSelected: false,
                    id: 35142
                }
            ]
        },
        Exterior: {
            name: 'Exterior',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Rear Spoiler',
                    isSelected: false,
                    id: 35175
                }
            ]
        },
        'Wheels and Tires': {
            name: 'Wheels and Tires',
            allowMultiSelect: true,
            options: [
                {
                    displayName: 'Steel Wheels',
                    isSelected: true,
                    id: 4234513
                },
                {
                    displayName: 'Alloy Wheels',
                    isSelected: false,
                    id: 35184
                },
                {
                    displayName: 'Premium Wheels',
                    isSelected: false,
                    id: 35194
                },
                {
                    displayName: 'Premium Wheels 19"+',
                    isSelected: false,
                    id: 535376
                }
            ]
        }
    },
    zip: '30319',
    conflicts: [],
    isCompleted: false,
    currentUserLocationRoute: '/equipmentOptions',
    tradeSaveFailed: false,
    tradeSavePending: false,
    tradePaymentFailed: false,
    hasTradeInChanged: true,
    tradeInSent: false,
    isTradeValuationReset: false,
    searchResultsForVin: [],
    searchResultsForYMMT: [],
    isSearchingForVin: false,
    isSearchingForYMMT: false,
    errorCount: 0,
    isUnavailable: false,
    isICOTradeAlreadySubmitted: false,
    skippedTradeIn: false
};
