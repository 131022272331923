import { connect } from 'react-redux';
import * as tradeInComponentActionCreators from '../../../store/actionCreators';
import * as tradeInRoutes from '../../../utils/routes';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';

import { ManualEntryUI, IManualEntryUIStateProps, IManualEntryUIDispatchProps, IManualEntryProps } from './ManualEntryUI';

const mapStateToProps = (state: any): IManualEntryUIStateProps => {
    const value = tradeInSelectors.getTradeInValue(state.tradeInComponent);
    const source = tradeInSelectors.getValueSource(state.tradeInComponent);
    const comments = tradeInSelectors.getValueComments(state.tradeInComponent);

    return {
        value,
        source,
        comments
    };
};

const mapDispatchToProps = (dispatch: any): IManualEntryUIDispatchProps => {
    dispatch(tradeInComponentActionCreators.shopperInManualEntry());
    return {
        next: (payload: IManualEntryProps) => {
            dispatch(tradeInComponentActionCreators.updateTradeValueSource(payload));
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_INFO));
        }
    };
};

const ManualEntry = connect(mapStateToProps, mapDispatchToProps)(ManualEntryUI);

export default ManualEntry;
