export const getRoute = (route: string, baseRoute?: string) => {
    baseRoute = baseRoute || '/tradeIn';
    return `${baseRoute}${route}`;
};

export const TRADE_IN = '/tradeIn';
export const TRADE_VEHICLE_INFO = '/vehicleInfo';
export const TRADE_VEHICLE_CONDITION = '/vehicleCondition';
export const TRADE_EQUIPMENT_OPTIONS = '/equipmentOptions';
export const TRADE_EQUITY = '/equity';
export const TRADE_VALUATION = '/valuation';
export const TRADE_SUMMARY = '/tradeSummary';
export const TRADE_IN_LEADFORM = '/tradeLeadForm';
export const TRADE_IN_ICO = '/tradeInIco';
export const TRADE_IN_SEARCH = '/tradeInSearch';
export const TRADE_EQUIPMENT_OPTIONS_STEP_ONE = '/equipmentOptionsOne';
export const TRADE_IN_DECISION = '/tradeInDecision';
export const TRADE_IN_ICO_SUCCESS = '/tradeInIcoSuccess';
export const TRADE_MANUAL_ENTRY = '/tradeInManualEntry';
export const TRADE_SUBARU_ELIGIBILITY = '/tradeSubaruEligibility';
export const TRADE_VEHICLE_INFO_MODAL = '/tradeVehicleInfoModal';
export const TRADE_IN_EXTERNAL = '/tradeInExternal';

export const getAllRoutes = (baseRoute?: string) => {
    return {
        root: getRoute('', baseRoute),
        tradeIn: TRADE_IN,
        vehicleInfo: TRADE_VEHICLE_INFO,
        vehicleCondition: TRADE_VEHICLE_CONDITION,
        equipmentOptions: TRADE_EQUIPMENT_OPTIONS,
        equity: TRADE_EQUITY,
        valuation: TRADE_VALUATION,
        tradeSummary: TRADE_SUMMARY,
        tradeLeadForm: TRADE_IN_LEADFORM,
        tradeInIco: TRADE_IN_ICO,
        tradeInSearch: TRADE_IN_SEARCH,
        equipmentOptionsOne: TRADE_EQUIPMENT_OPTIONS_STEP_ONE,
        tradeInDecision: TRADE_IN_DECISION,
        tradeInIcoSuccess: TRADE_IN_ICO_SUCCESS,
        tradeManualEntry: TRADE_MANUAL_ENTRY,
        tradeSubaruEligibility: TRADE_SUBARU_ELIGIBILITY,
        tradeVehicleInfoModal: TRADE_VEHICLE_INFO_MODAL,
        tradeInExternal: TRADE_IN_EXTERNAL
    };
};
