import { Selectors } from '../selectors';

let globalSelector: Selectors | undefined = undefined;

export function setGlobalSelector(selector: Selectors) {
    globalSelector = selector;
}

export function getGlobalSelector(): Selectors {
    if (!globalSelector) throw new Error('TradeIn::getGlobalSelector::Dev Error::Must specify global selector');
    return globalSelector;
}
