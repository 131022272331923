// externals
import * as React from 'react';

// libraries
import { Input } from '@makemydeal/ui-bricks/dist/cox';
import { InputProps } from '@makemydeal/ui-bricks/dist/cox/Form/Input';

// utils
import { normalizeMileageValues } from '../../../utils/validation';

export interface MileageInputState {
    value: string;
}

export interface MileageInputProps extends InputProps {
    vin?: string;
}

export class MileageInput extends React.Component<MileageInputProps, MileageInputState> {
    constructor(props: MileageInputProps) {
        super(props);
        this.state = {
            value: this.getFormattedMileage(props.value)
        };
    }

    componentDidUpdate(prevProps: Readonly<MileageInputProps>): void {
        const { vin } = this.props;
        if (vin !== prevProps.vin) {
            this.handleResetValue();
        }
    }

    handleResetValue = (): void => {
        this.setState({ value: null });
    };

    getFormattedMileage = (mileage: number | string) => {
        switch (typeof mileage) {
            case 'number': {
                return mileage.toLocaleString('en-US');
            }
            case 'string': {
                mileage = mileage.trim();
                return mileage && Number(mileage.replace(/,/g, '')).toLocaleString('en-US');
            }
            default: {
                return mileage;
            }
        }
    };
    handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        this.props.onBlurFunction && this.props.onBlurFunction(event);
        this.setState((previousState: MileageInputState) => {
            return { ...previousState, value: this.getFormattedMileage(event.target.value) };
        });
    };
    handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChangeFunction && this.props.onChangeFunction(event);
        this.setState((previousState: MileageInputState) => {
            return { ...previousState, value: normalizeMileageValues(event.target.value) };
        });
    };
    handleOnFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        event.persist();
        this.props.onFocusFunction && this.props.onFocusFunction(event);
        this.setState((previousState: MileageInputState) => {
            return { ...previousState, value: normalizeMileageValues(event.target.value) };
        });
    };
    render() {
        return (
            <Input
                {...this.props}
                onBlurFunction={this.handleOnBlur}
                onChangeFunction={this.handleOnChange}
                onFocusFunction={this.handleOnFocus}
                value={this.state.value}
            />
        );
    }
}
