// externals
import { HTMLAttributes, useMemo, useState, type FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { Action } from '@interstate/components/Action';
import { Box } from '@interstate/components/Box';
import { CardHeader } from '@interstate/components/Card';
import { Grid } from '@interstate/components/Grid';
import { Typography } from '@interstate/components/Typography';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import { LoadingSection, LoadingSectionParent, TOTAL_FEES } from '@makemydeal/dr-dash-components';
import { CASH, paymentServicesTypes } from '@makemydeal/dr-platform-types';

// styles
import { FeeCardContainer, FeeDeeDetailsContainer, TypographyNoTextTransform, TypographyRightAligned } from './FeesCard.style';

// selectors
import {
    deskingGlobalActionCreators,
    deskingGlobalSelectors,
    offerActionCreators,
    offerReduxSelectors,
    offerSelectors,
    paymentSelectors
} from '@makemydeal/dr-dash-store';

// components
import { AccordionDetails } from '@interstate/components/AccordionGroup';
import { FeesCategoriesType } from '@makemydeal/dr-shared-types';
import { FEES } from '../../constants';
import TotalSummary from '../common/TotalSummary';
import { StretchHeightCard } from '../deskingActivity/DeskingCard.style';
import { GOV_DEALER_TITLE, LENDER_DEALER_GOV_TITLE } from './constants';
import FeeCategoryItems from './FeeCategoryItems';
import FeesForm from './FeesForm';
import { CustomFeeItem } from './types';
import { getCustomFeeItemsAdapter } from './utils';

export const FeesCard: FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const dispatch = useDispatch();

    const govFees = useSelector(offerSelectors.getItemizedGovernmentFees);
    const totalFees = useSelector(offerReduxSelectors.getTotalFee);
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const lenderFees = useSelector(offerReduxSelectors.getItemizedLenderFees);
    const dealerFees = useSelector(offerSelectors.getItemizedDealerFees);
    const isAccordionOpen = useSelector(deskingGlobalSelectors.getFeesAccordionExpanded);
    const isPaymentCalculating = useSelector(paymentSelectors.isCalculatingPayment);

    const [showEditAction, setShowEditAction] = useState(false);

    const lenderFeeItems: CustomFeeItem[] = useMemo(
        () => getCustomFeeItemsAdapter(lenderFees, FeesCategoriesType.LENDER),
        [lenderFees]
    );

    const govFeeItems: CustomFeeItem[] = useMemo(() => getCustomFeeItemsAdapter(govFees, FeesCategoriesType.GOVERNMENT), [govFees]);

    const dealerFeeItems: CustomFeeItem[] = useMemo(
        () => getCustomFeeItemsAdapter(dealerFees, FeesCategoriesType.DEALER),
        [dealerFees]
    );

    const toggleShowEditAction = () => setShowEditAction(!showEditAction);

    const onSave = (feesOverride: paymentServicesTypes.FeeOverride[]) => {
        dispatch(offerActionCreators.updatedFeesOverride(feesOverride));
    };

    const onReset = () => {
        dispatch(offerActionCreators.updatedFeesOverride([]));
    };

    const onAccordionChange = () => {
        dispatch(deskingGlobalActionCreators.toggleFeesAccordion());
    };

    const totalFeesFormatted = formatDollarsAndCents(totalFees);
    const isCash = offerType === CASH;
    const isNonCash = !isCash;
    const title = isCash ? GOV_DEALER_TITLE : LENDER_DEALER_GOV_TITLE;

    return (
        <LoadingSectionParent {...props}>
            <LoadingSection loading={isPaymentCalculating} />
            <FeeCardContainer>
                <StretchHeightCard
                    data-testid="fees-card"
                    header={
                        <CardHeader
                            title={<Typography variant="h5">Fees</Typography>}
                            action={
                                <Action data-testid="manage-fees-link" onClick={onAccordionChange}>
                                    <Typography variant="body-sm" color="base.color.blue.700">
                                        {isAccordionOpen ? 'Show Less' : 'Show More'}
                                    </Typography>
                                </Action>
                            }
                        />
                    }
                    content={
                        <Box display="flex" height="100%">
                            {!isAccordionOpen && (
                                <Grid display="grid" gridTemplateColumns="repeat(2, minmax(0, auto))" gap=".5rem">
                                    <>
                                        <TypographyRightAligned variant="body-sm">{totalFeesFormatted}</TypographyRightAligned>
                                        <Typography variant="body-sm">{TOTAL_FEES}</Typography>
                                    </>
                                </Grid>
                            )}
                            {isAccordionOpen && (
                                <Box width="100%" height="100%">
                                    <Grid container rowGap={2} spacing={2} height="100%">
                                        <Grid xs={12} height="100%">
                                            <TypographyNoTextTransform variant="h5">
                                                <Box
                                                    data-testid="category_gov_and_dealer_fees"
                                                    display="flex"
                                                    justifyContent="space-between"
                                                    padding="0"
                                                >
                                                    <span>{title}</span>
                                                    {!showEditAction && (
                                                        <Action
                                                            size="sm"
                                                            onClick={toggleShowEditAction}
                                                            data-testid="edit-fees-link"
                                                        >
                                                            Edit
                                                        </Action>
                                                    )}
                                                </Box>
                                            </TypographyNoTextTransform>
                                            <FeeDeeDetailsContainer>
                                                <AccordionDetails>
                                                    {!showEditAction && (
                                                        <>
                                                            {isNonCash && (
                                                                <FeeCategoryItems
                                                                    categoryTitle="Lender Fees"
                                                                    categoryType={FeesCategoriesType.LENDER}
                                                                    dataTestId="category_lender_fees"
                                                                    feeItems={lenderFeeItems}
                                                                    offerType={offerType}
                                                                />
                                                            )}
                                                            <FeeCategoryItems
                                                                categoryTitle="Government Fees"
                                                                categoryType={FeesCategoriesType.GOVERNMENT}
                                                                dataTestId="category_gov_fees"
                                                                feeItems={govFeeItems}
                                                                offerType={offerType}
                                                            />
                                                            <FeeCategoryItems
                                                                categoryTitle="Dealer Fees"
                                                                categoryType={FeesCategoriesType.DEALER}
                                                                dataTestId="category_dealer_fees"
                                                                feeItems={dealerFeeItems}
                                                                offerType={offerType}
                                                            />
                                                        </>
                                                    )}

                                                    {showEditAction && (
                                                        <FeesForm
                                                            key={JSON.stringify({ dealerFeeItems, govFeeItems })}
                                                            toggleShowEditAction={toggleShowEditAction}
                                                            offerType={offerType}
                                                            dealerFeesItems={dealerFeeItems}
                                                            governmentFeesItems={govFeeItems}
                                                            lenderFeesItems={lenderFeeItems}
                                                            onReset={onReset}
                                                            onSave={onSave}
                                                        />
                                                    )}
                                                </AccordionDetails>
                                                <Box display="flex" justifyContent="flex-end">
                                                    <TotalSummary totalType={FEES} totalValue={totalFees} paddingRight={3.7} />
                                                </Box>
                                            </FeeDeeDetailsContainer>
                                        </Grid>
                                    </Grid>
                                </Box>
                            )}
                        </Box>
                    }
                />
            </FeeCardContainer>
        </LoadingSectionParent>
    );
};

export default FeesCard;
