import { memo, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import { Button } from '@interstate/components/Button';
import { ArrowUturnLeftIcon, TrashIcon } from '@interstate/components/Icons';
import { Tooltip } from '@interstate/components/Tooltip';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import { ManualIncentiveEditEntry, transformIncentiveAmount } from '@makemydeal/dr-dash-store';

import { RebatesTableInput } from './RebatesTableInput.interstate';
import { getIncentiveDisplayType } from './utils';
import { ContainerGrid, ContainerGridItem } from '@makemydeal/dr-dash-components';
import { EmptyLabel } from './RebatesTableRow.interstate.style';

export interface RebatesTableRowProps {
    incentive: ManualIncentiveEditEntry;
    term: number | undefined;
    codeCellErrorIndexes: Set<number>;
    incentiveIndex: number;
    haveIncentivesUpdated: boolean;
    isUniqueCode: boolean;
    isEditing: boolean;
    handleIncentiveDeleteOrUndo: (index: number) => void;
    updateHasMessageErrors: (value: boolean) => void;
    handleInputChange: (
        textValue: string | number | undefined,
        fieldName: keyof ManualIncentiveEditEntry,
        incentiveIndex: number
    ) => void;
}

export const RebatesTableRow = memo(function RebatesTableRow({
    incentive,
    term,
    codeCellErrorIndexes,
    incentiveIndex,
    haveIncentivesUpdated,
    isUniqueCode,
    isEditing,
    handleIncentiveDeleteOrUndo,
    updateHasMessageErrors,
    handleInputChange
}: RebatesTableRowProps) {
    const columnMappings = useMemo(
        () =>
            ({
                'Program #': {
                    fieldName: 'program',
                    fieldValue: incentive.program,
                    required: false,
                    mobileFieldSize: 4,
                    desktopFieldSize: 2
                },
                'Incentive Name': {
                    fieldName: 'name',
                    fieldValue: incentive.name,
                    required: true,
                    mobileFieldSize: 8,
                    desktopFieldSize: 3
                },
                Code: {
                    fieldName: 'code',
                    fieldValue: incentive.code,
                    required: false,
                    mobileFieldSize: 4,
                    desktopFieldSize: 2
                },
                Type: {
                    fieldName: 'type',
                    fieldValue: incentive.type,
                    required: true,
                    mobileFieldSize: 4,
                    desktopFieldSize: 3
                },
                Amount: {
                    fieldName: 'amount',
                    fieldValue: transformIncentiveAmount(term, incentive),
                    required: true,
                    mobileFieldSize: 4,
                    desktopFieldSize: 2
                }
            } as const),
        [incentive, term]
    );
    const displayType = useMemo(() => getIncentiveDisplayType(incentive), [incentive]);

    const [hasDuplicateCode, setHasDuplicateCode] = useState(false);

    useEffect(() => {
        if (!incentive.deleted && (isEditing || codeCellErrorIndexes.has(incentiveIndex))) {
            if (!isUniqueCode) {
                codeCellErrorIndexes.add(incentiveIndex);
                updateHasMessageErrors(true);
                setHasDuplicateCode(true);
            } else {
                codeCellErrorIndexes.delete(incentiveIndex);
                setHasDuplicateCode(false);
            }
        }
    }, [incentive, codeCellErrorIndexes, isEditing, isUniqueCode, incentiveIndex, updateHasMessageErrors]);

    // This is the only way we can identify incentives that came from program-incentives
    const isFromProgramIncentives = !!incentive.description && incentive.description !== incentive.name;

    const deleteRevertButton = useMemo(() => {
        const firstRow = incentiveIndex === 0;
        return incentive.deleted ? (
            <>
                {firstRow ? (
                    <EmptyLabel data-testid={`undo-${incentiveIndex}`}>&nbsp;</EmptyLabel>
                ) : (
                    <ContainerGridItem xs="contents" md="hidden" style={{ display: 'contents' }}>
                        <EmptyLabel data-testid={`undo-${incentiveIndex}`}>&nbsp;</EmptyLabel>
                    </ContainerGridItem>
                )}
                <Button
                    buttonStyle="secondary"
                    icon={
                        <Tooltip
                            position="bottom"
                            toolTipContent="Item was deleted. You can still undo this before updating the Offer."
                            size="large"
                        >
                            <ArrowUturnLeftIcon color="#005BA8" />
                        </Tooltip>
                    }
                    size="medium"
                />
            </>
        ) : (
            <>
                {firstRow ? (
                    <EmptyLabel data-testid={`delete-${incentiveIndex}`}>&nbsp;</EmptyLabel>
                ) : (
                    <ContainerGridItem xs="contents" md="hidden">
                        <EmptyLabel data-testid={`delete-${incentiveIndex}`}>&nbsp;</EmptyLabel>
                    </ContainerGridItem>
                )}
                <Button
                    buttonStyle="secondary"
                    icon={<TrashIcon data-testid={`manual-rebate-delete-${incentiveIndex}`} color="#005BA8" />}
                    size="medium"
                />
            </>
        );
    }, [incentive.deleted, incentiveIndex]);

    return (
        <>
            <ContainerGrid $mdBreakpoint={900} data-testid={`incentive-row-${incentiveIndex}`}>
                <ContainerGridItem xs={12} md="hidden">
                    {incentiveIndex > 0 && <hr />}
                </ContainerGridItem>
                <ContainerGridItem
                    justifyContent="start"
                    xs="min-content"
                    data-testid={`manual-rebate-trash-icon-${incentiveIndex}`}
                    onClick={() => handleIncentiveDeleteOrUndo(incentiveIndex)}
                >
                    {deleteRevertButton}
                </ContainerGridItem>

                <ContainerGridItem xs="full">
                    <ContainerGrid $mdBreakpoint={900}>
                        {Object.entries(columnMappings).map(([incentiveLabel, inputProps], columnIndex) => (
                            <ContainerGridItem
                                xs={inputProps.mobileFieldSize}
                                md={inputProps.desktopFieldSize}
                                key={`${incentiveIndex}-${columnIndex}`}
                            >
                                <RebatesTableInput
                                    hasCodeDuplicate={hasDuplicateCode}
                                    deleted={incentive.deleted}
                                    manual={incentive.manual}
                                    type={incentive.type}
                                    isFromProgramIncentives={isFromProgramIncentives}
                                    displayType={displayType}
                                    incentiveLabel={incentiveLabel}
                                    fieldValue={inputProps.fieldValue}
                                    fieldName={inputProps.fieldName}
                                    required={inputProps.required}
                                    haveIncentivesUpdated={haveIncentivesUpdated}
                                    incentiveIndex={incentiveIndex}
                                    handleInputChange={handleInputChange}
                                />
                            </ContainerGridItem>
                        ))}
                    </ContainerGrid>
                </ContainerGridItem>
            </ContainerGrid>
        </>
    );
});
