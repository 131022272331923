import { useSelector } from 'react-redux';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { TermMonthItem } from '@makemydeal/dr-platform-types';
import { GridItem, StyledTypography } from './styled.components';
import TermMonthInput from './TermMonthInput';
import { dealerSelectors, featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { generateCashDownLabel } from '../common/utils';

interface ITermProps {
    paymentGridTermList: TermMonthItem[];
    isLoading?: boolean;
}

const TermsMonth = ({ paymentGridTermList, isLoading = false }: ITermProps) => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const deductDueAtSigning = useSelector(dealerSelectors.getIsDueAtSigninAmountDeduct);
    const isTargetOutOfPocket = useSelector(offerReduxSelectors.getIsTargetOutOfPocket);
    const isEnhancedDealDetailsEnabled = useSelector(featureToggleSelectors.getEnhancedDealDetails);
    const isTargetOutOfPocketLeaseEnabled = useSelector(featureToggleSelectors.isTargetOutOfPocketLeaseEnabled);

    return (
        <>
            <GridItem
                xs={3}
                className="payment-grid-item"
                sx={{
                    background: '#f4f4f4'
                }}
            >
                <StyledTypography className="large" data-testid="cash-down-header" style={{ color: 'black' }}>
                    {generateCashDownLabel(
                        offerType,
                        deductDueAtSigning,
                        isTargetOutOfPocket,
                        isEnhancedDealDetailsEnabled,
                        isTargetOutOfPocketLeaseEnabled
                    )}
                </StyledTypography>
            </GridItem>
            {paymentGridTermList.map((term) => (
                <TermMonthInput key={`${term.key}-${term.val}`} term={term} isLoading={isLoading} />
            ))}
        </>
    );
};

export default TermsMonth;
