// externals
import URI from 'urijs';

// actions
import {
    externalIcoCompleted,
    lookupIcoTradeUsingIds,
    sendAddICOAnalytics,
    sendIcoExitClickedAnalytics,
    tradeInEstimateSuccess,
    updateTradeInCurrentLocation,
    updateTradeInValuation
} from '../../../store/actionCreators';

// consts/enums
import { Actions as commonTypes } from '../../../store/actions';
import { TRADE_VEHICLE_INFO } from '../../../utils';

// utils
import { default as externalTradeInEvents } from './externalTradeIn';
import { addEvent, removeEvent } from '../../../utils/browserHelper';

export const EXTERNAL_TRADE_ADDED = 'EXTERNAL_TRADE_ADDED';
export const EXTERNAL_TRADE_COMPLETED = 'EXTERNAL_TRADE_COMPLETED';
export const EXTERNAL_TRADE_ERROR = 'External_Trade_Error';

const LISTENER_EVENT_NAME = 'message';
const EXCLUDED_EXTERNAL_EVENTS = ['SendIframeIds'];

export interface ITradeInIcoBaseEventData<P = any> {
    payload: P;
    type: string; // e.g. "External_Trade_Error"
}

export interface ITradeInIcoFailureEventData extends ITradeInIcoBaseEventData {
    description: string; // e.g. "Vehicle is not eligible because offer was deemed not eligible."
    make: number; // e.g. 27
    model: number; // e.g. 27731
    trim: number; // e.g. 438378
    vin: string; // e.g. ""  (seems like this doesn't return a value)
    year: number; // e.g. 2019
}

export interface ITradeInIcoSuccessEventDataPayload {
    email: string; // e.g. 'jim.bob@somewhere.test'
    firstName: string; // e.g. 'Jim'
    KBBVehicleId: number; // e.g. 1928
    lastName: string; // e.g. 'Bob'
    make: string; // e.g. 'Acura'
    mileage: number; // e.g. 100000
    model: string; // e.g. 'MDX'
    offerDate: string; // .e.g. '2020-11-12T01:09:50.852Z'
    offerExpirationDate: string; // e.g. '2020-11-18T20:09:37.39'
    offerId: string; // e.g. '0bacf84e-1f63-474d-83c2-d6db8b6fccf4'
    phone: string; // e.g. '+1 672 222 2299'
    status: string; // e.g. 'CLEARED'
    tivSource: string; // e.g. 'ICO'
    trim: string; // e.g. 'Sport Utility 4D'
    value: number; // e.g. 2040
    vin: string; // e.g. ''
    year: string; // e.g. '2004'
    zip: string; // e.g. '30319'
}

export type ITradeInIcoSuccessEventData = ITradeInIcoBaseEventData<ITradeInIcoSuccessEventDataPayload>;

export const handler = (() => {
    let payload: any = {};
    let status;
    let source;
    let isValidExternalTradeInStatus;

    function createInstance(dispatch, externalTradeInUrlProvider) {
        return (event) => {
            const domain = new URI(event.origin).domain();
            const allowedDomainOrigin = new URI(externalTradeInUrlProvider).domain();
            if (domain !== allowedDomainOrigin) {
                return;
            }

            let eventData: ITradeInIcoFailureEventData | ITradeInIcoSuccessEventData;
            // ignoring this events
            // from KBB SD
            if (EXCLUDED_EXTERNAL_EVENTS.indexOf(event.data) >= 0) {
                return;
            }
            // from redux dev tools
            if (event.data && event.data.source && event.data.source.match(/@devtools/)) {
                return;
            }
            try {
                eventData = event.data && JSON.parse(event.data);
            } catch (error) {
                dispatch({
                    type: commonTypes.ICO_PASS_BACK_FAILURE,
                    payload: error
                });
                return;
            }
            payload = eventData.payload || payload;
            status = payload.status;
            source = payload.tivSource;
            isValidExternalTradeInStatus = externalTradeInEvents(source)[status];

            const eventDataTypeToUse: string = eventData?.type || '';
            switch (eventDataTypeToUse.toLowerCase()) {
                case EXTERNAL_TRADE_ADDED.toLowerCase(): {
                    if (isValidExternalTradeInStatus) {
                        const payloadTyped = payload as ITradeInIcoSuccessEventDataPayload;
                        dispatch({ type: isValidExternalTradeInStatus.tradeInAction, payload: payloadTyped });
                        dispatch({ type: commonTypes.ICO_TRADE_IN_COMPLETED, payload: payloadTyped });
                    }
                    break;
                }
                case EXTERNAL_TRADE_COMPLETED.toLowerCase(): {
                    const payloadTyped = payload as ITradeInIcoSuccessEventDataPayload;
                    dispatch({ type: commonTypes.ICO_TRADE_IN_COMPLETED, payload: payloadTyped });
                    dispatch(tradeInEstimateSuccess());
                    dispatch(externalIcoCompleted(true));
                    dispatch(updateTradeInValuation());
                    dispatch(sendAddICOAnalytics());
                    break;
                }
                case EXTERNAL_TRADE_ERROR.toLowerCase(): {
                    const eventDataTyped = eventData as ITradeInIcoFailureEventData;
                    const { description } = eventDataTyped;
                    if (description === 'Vehicle is not eligible because offer was deemed not eligible.') {
                        // trim returns a VRS 1.0 value, vin always returns an empty string
                        const { year, make, model } = eventDataTyped;
                        dispatch(lookupIcoTradeUsingIds(year, make, model, null));
                    } else {
                        dispatch(sendIcoExitClickedAnalytics());
                        dispatch(updateTradeInCurrentLocation(TRADE_VEHICLE_INFO));
                    }
                    break;
                }
                default:
                    break;
            }
        };
    }

    return {
        getInstance(dispatch?, externalTradeInUrlProvider?) {
            return createInstance(dispatch, externalTradeInUrlProvider);
        }
    };
})();

// Hooks
export const attachExtTradeInListener = (dispatch, externalTradeInUrlProvider) => {
    const listenerHandler = handler.getInstance(dispatch, externalTradeInUrlProvider);
    addEvent(window, LISTENER_EVENT_NAME, listenerHandler);
};

export const detachExtTradeInListener = () => {
    const listenerHandler = handler.getInstance();
    removeEvent(window, LISTENER_EVENT_NAME, listenerHandler);
};
