import * as React from 'react';
import { attachExtTradeInListener, detachExtTradeInListener } from './externalTradeInListener';
import TradePage from '../../../common/components/TradePage/TradePage';
import config from '../../../store/mmd/config';
import GTPFlowPageSubtitle from '../GTPFlowPageSubtitle/GTPFlowPageSubtitle';

// types
import { ImprovedStepperRoute } from '../../../types';
import { SpinnerUI } from '@makemydeal/dr-activities-common';

// TODO: import/create listeners
export interface IIcoValuationUIStateProps {
    icoAction?: string;
    instantCashOfferUrlProvider: string;
    stepperRoutes: ImprovedStepperRoute[];
    isTradeCompleted: boolean;
}

export interface IIcoValuationUIDispatchProps {
    dispatchAnalytics?: { (type: string, description: string) };
    dispatchCallback: { () };
    dispatchImpressionTag: { () };
    goToRoute: { (route: string): void };
    onCompletedIcoTradeIn: { () };
    onIcoConfirmMessage: { () };
    resetExternalIcoCompleted: { () };
}

export interface IIcoValuationUIProps extends IIcoValuationUIStateProps, IIcoValuationUIDispatchProps {}

export interface IIcoValuationUIState {
    newURL: string;
}

class IcoValuationUI extends React.Component<IIcoValuationUIProps, IIcoValuationUIState> {
    static defaultProps = {
        icoAction: ''
    };

    iframeEl: HTMLElement;

    constructor(props) {
        super(props);
        this.state = { newURL: '' };
    }

    componentDidMount() {
        const { resetExternalIcoCompleted } = this.props;
        resetExternalIcoCompleted();
        document.body.classList.add('dr-page-iframe');
        attachExtTradeInListener(this.props.dispatchCallback, this.props.instantCashOfferUrlProvider);
        this.iframeEl.addEventListener('load', this.props.dispatchImpressionTag);
        this.setState({ newURL: this.props.instantCashOfferUrlProvider });
    }

    componentDidUpdate() {
        const { icoAction } = this.props;
        if (icoAction) {
            this.props.onIcoConfirmMessage();
        }
    }

    componentWillUnmount() {
        document.body.classList.remove('dr-page-iframe');
        const { onCompletedIcoTradeIn } = this.props;
        onCompletedIcoTradeIn();
        detachExtTradeInListener();
        this.iframeEl.removeEventListener('load', this.props.dispatchImpressionTag);
    }

    setRef = (el: HTMLElement) => (this.iframeEl = el);

    pageSubTitle() {
        return (
            <GTPFlowPageSubtitle
                pageSubtitle={
                    <>
                        Complete the Kelley Blue Book<sup>®</sup> Instant Cash Offer form.
                    </>
                }
                suffix={
                    <>
                        You can still get a value for your vehicle using our Kelley Blue Book<sup>®</sup> Instant Cash Offer tool.
                    </>
                }
            />
        );
    }

    render() {
        const { dispatchAnalytics, goToRoute, stepperRoutes, isTradeCompleted } = this.props;

        return (
            <TradePage
                pageClass="dr-trade-ico-valuation"
                pageTitle="Get your Kelley Blue Book Instant Cash Offer"
                pageSubTitle={this.pageSubTitle()}
                stepperRoutes={stepperRoutes}
                staticAssetsConfig={config.staticImages}
                dispatchAnalytics={dispatchAnalytics}
                stepLabelVisible={true}
                isStepperClickable={true}
                goToRoute={goToRoute}
            >
                <div id="dr-trade-ico">
                    {isTradeCompleted && (
                        <div id="dr-trade-ico-spinner">
                            <SpinnerUI size="60"></SpinnerUI>
                        </div>
                    )}
                    <iframe
                        id="dr-trade-ico-iframe"
                        ref={this.setRef}
                        width="100%"
                        height="600px"
                        src={this.state.newURL}
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </TradePage>
        );
    }
}

export default IcoValuationUI;
