// externals
import * as React from 'react';
import { Component } from 'react';

// libraries
import { Select, Input } from '@makemydeal/ui-bricks/dist/cox';

// interfaces
import { IVehicleInfoDetailsUIProps, IVehicleInfoDetailsUIState, IGetInputFieldInput } from './VehicleInfoDetailsInterfaces';

// components
import { MileageInput } from '../../../common/components/VehicleInfo/MileageInput';
import { SpinnerUI, IconFactory, Modal, PageUI } from '@makemydeal/dr-activities-common';
import {
    VehicleInfoDetailsVinContainer,
    VehicleInfoDetailsManualContainer,
    VehicleInfoDetailsLabelsContainer,
    YearInputContainer,
    MakeInputContainer,
    ModelInputContainer,
    TrimInputContainer,
    MileageInputContainer,
    ZipInputContainer,
    OwnershipInputContainer,
    AmountOwedInputContainer,
    CardIsSubaruGTPEligible
} from './VehicleInfoDetailsComponents';

import config from '../../../store/mmd/config';

// utils
import { normalizeZip, validateForMileageValues, validateZip } from '../../../utils/validation';
import { formatUtils } from '@makemydeal/dr-common-utils';
import RulesUI from '../../../common/components/subaruEligibility/RulesUI';

export const ownershipStatusOptions = [
    { name: 'Own outright', hasLoan: 'false', ownership: 'Purchase' },
    { name: 'Active Loan', hasLoan: 'true', ownership: 'Purchase' },
    { name: 'Active Lease', hasLoan: 'false', ownership: 'Lease' }
];

class VehicleInfoDetailsUI extends Component<IVehicleInfoDetailsUIProps, IVehicleInfoDetailsUIState> {
    setTextInputRef: (elem, name) => void;

    constructor(props) {
        super(props);
        this.state = {
            validationErrorMessaqe: '',
            showModal: false,
            fieldsChanged: {
                mileage: false,
                zip: false,
                amountowed: false
            },
            ownershipStatus:
                Object.values(ownershipStatusOptions).find(
                    (item) =>
                        item.ownership == this.props.ownership && item.hasLoan == this.props.isApplyToAmountFinanced?.toString()
                )?.name || ''
        };

        this.setTextInputRef = (element, name) => {
            this[name] = element;
        };
    }

    componentDidMount() {
        const { vehicle, years, makes, models, trims, zip } = this.props;
        this.props.onLoad(vehicle, years, makes, models, trims, zip);

        let focusFieldName = 'year';
        if (vehicle.year) focusFieldName = 'make';
        if (vehicle.make?.id) focusFieldName = 'model';
        if (vehicle.model?.id) focusFieldName = 'trim';
        if (vehicle.trim?.id) focusFieldName = 'ownership';

        this.focusElementByRefName(focusFieldName);
    }

    componentDidUpdate(prevProps: IVehicleInfoDetailsUIProps): void {
        const { vehicle } = this.props;
        if (vehicle.vin !== prevProps.vehicle.vin) {
            this.handleResetOwnershipStatus();
        }
    }

    handleResetOwnershipStatus(): void {
        const { setApplyToAmountFinanced, dispatchResetFormValues } = this.props;
        this.setState(() => ({ ownershipStatus: '' }));
        setApplyToAmountFinanced(false);
        dispatchResetFormValues();
    }

    focusElementByRefName(refName: string): void {
        const element = this[refName];
        if (element && !element.disabled) {
            element.focus();
        } else if (element && element.disabled) {
            setTimeout(() => this.focusElementByRefName(refName), 100);
        }
    }

    validationFunction = (fieldValue, validatorFunc, required) => {
        const isFilledIn = this.validateRequiredFields(fieldValue);
        const isInValid = !validatorFunc(fieldValue) && this.props.continueWasClicked;

        if ((isFilledIn || !required) && !isInValid) return true;
        else return false;
    };

    errorMessageFunction = (fieldValue, validatorFunc, name) => {
        const isFilledIn = this.validateRequiredFields(fieldValue);
        const isInValid = !validatorFunc(fieldValue) && this.props.continueWasClicked;

        if (!isFilledIn) return 'This is a required field';
        else if (isInValid) return `${name} is invalid`;
        else return '';
    };

    validateRequiredFields = (fieldValue) => {
        return (fieldValue === null || fieldValue === '') && this.props.continueWasClicked ? false : true;
    };

    handleSelectOnChange: React.ChangeEventHandler<HTMLSelectElement> = (event): void => {
        if (this.props.nextDisabled) this.props.nextDisabled(false);
        const { currentTarget } = event;
        this.onChangeHandler(currentTarget.getAttribute('name'), currentTarget.options[currentTarget.selectedIndex]);
    };

    updateFieldChanged = (fieldName: string, isChanged?: boolean) => {
        const fieldsChanged = { ...this.state.fieldsChanged };
        fieldsChanged[fieldName] = isChanged;
        this.setState({ fieldsChanged });
    };

    handleInputOnChange: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
        if (this.props.nextDisabled) this.props.nextDisabled(false);
        const { currentTarget } = event;
        const fieldName = currentTarget.getAttribute('name').toLowerCase();
        this.updateFieldChanged(fieldName, true);
        this.props.onInputValueChange(fieldName, currentTarget, { ...this.props.vehicle });
    };

    handleInputOnBlur: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
        const { currentTarget } = event;
        const { fieldsChanged } = this.state || {};
        const fieldName = currentTarget.getAttribute('name').toString().toLowerCase();
        if (fieldsChanged[fieldName]) {
            this.updateFieldChanged(fieldName, false);
            this.props?.onBlurHandler && this.props?.onBlurHandler(fieldName, currentTarget);
        }
    };

    getSelectField(name, options, optionsId, label, selectedValue, enabled = false, additionalClass = '', placeholder = 'Select') {
        const optionsItems = options.map((option, index) => {
            const optionId = optionsId !== '' ? option[optionsId] : option;
            const optionName = option.hasOwnProperty('name') ? option.name : option;
            return (
                <option key={optionId} value={optionId}>
                    {optionName}
                </option>
            );
        });

        const isValid = this.validateRequiredFields(selectedValue);
        const showError = isValid ? 'hide' : '';
        const showRedOutline = isValid ? '' : 'field-not-filled-in';
        return (
            <>
                <Select
                    selectId={name}
                    name={name}
                    ref={(element) => this.setTextInputRef(element, name)}
                    disabled={!enabled}
                    value={selectedValue || ' '}
                    label={label}
                    placeholder={placeholder}
                    defaultValue={' '}
                    className={`select-field ui-bricks ${showRedOutline}`}
                    onChangeFunction={this.handleSelectOnChange}
                >
                    <option value=" " disabled>
                        {placeholder}
                    </option>
                    {optionsItems}
                </Select>
                <div className={`error-div ${showError}`}>
                    <IconFactory icon="errorIcon" className="error-icon" staticImages={config.staticImages} />
                    <span className="error-message">This is a required field</span>
                </div>
            </>
        );
    }

    getInputField(
        name,
        label,
        value,
        _enabled = false,
        additionalClass = '',
        input: IGetInputFieldInput = { type: 'text' },
        required = false,
        validatorFunc = (val) => true,
        placeholder = '',
        blurCb: any = () => null,
        vin = ''
    ) {
        const normalizer = {
            zipCode: normalizeZip,
            amountOwed: formatUtils.formatCurrencyAmount
        };
        const isValid = this.validationFunction(value, validatorFunc, required);
        const showError = isValid ? 'hide' : '';
        const showRedOutline = isValid ? '' : 'field-not-filled-in';
        const errorMessage = this.errorMessageFunction(value, validatorFunc, name);
        const InputClass = input.inputType === 'mileage' ? MileageInput : Input;
        return (
            <>
                <InputClass
                    {...input}
                    type="text"
                    inputId={name}
                    name={name}
                    ref={(element) => this.setTextInputRef(element, name)}
                    label={label}
                    disabled={!_enabled}
                    value={value}
                    placeholder={placeholder}
                    className={`${additionalClass} ui-bricks ${showRedOutline}`}
                    onChangeFunction={this.handleInputOnChange}
                    onBlurFunction={blurCb}
                    formatValueFunction={normalizer[input.inputType]}
                    {...(input.inputType === 'mileage' && { vin })}
                />
                <div className={`error-div ${showError}`}>
                    <IconFactory icon="errorIcon" className="error-icon" staticImages={config.staticImages} />
                    <span className="error-message">{errorMessage}</span>
                </div>
            </>
        );
    }

    handleMileageValidation(mileage: number | string) {
        switch (typeof mileage) {
            case 'number': {
                return validateForMileageValues(mileage);
            }
            case 'string': {
                const value = Number.parseInt(mileage?.trim().replace(/,/g, ''), 10);
                return isNaN(value) ? false : validateForMileageValues(value);
            }
            default: {
                return false;
            }
        }
    }

    onChangeHandler(propName, domElement) {
        this.props.onValueChange(propName, domElement, { ...this.props.vehicle }, undefined);

        switch (propName) {
            case 'year': {
                this.focusElementByRefName('make');
                break;
            }
            case 'make': {
                this.focusElementByRefName('model');
                break;
            }
            case 'model': {
                this.focusElementByRefName('trim');
                break;
            }
            case 'trim': {
                this.focusElementByRefName('mileage');
                break;
            }
            case 'ownershipStatus':
                this.setState({
                    ownershipStatus: domElement.value
                });

                if (domElement.value === 'Active Loan') {
                    this.props.setApplyToAmountFinanced(true);
                } else {
                    this.props.setApplyToAmountFinanced(false);
                    this.props.onInputValueChange('amountowed', { ...domElement, ...{ value: '0' } }, { ...this.props.vehicle });
                }
                this.focusElementByRefName('amountOwed');
                break;
        }
    }

    render() {
        const {
            vehicle,
            zip,
            isZipCodeEnabled,
            years,
            isYearEnabled,
            isYearLoading,
            makes,
            isMakeEnabled,
            isMakeLoading,
            models,
            isModelEnabled,
            isModelLoading,
            trims,
            isTrimEnabled,
            isTrimLoading,
            isMileageEnabled,
            amountOwed,
            isVinActive,
            isApplyToAmountFinanced,
            subaruGtpLogoAlt,
            checkIsSubaruGtpEligible,
            dispatchAnalytics,
            isSubaruGTPImprovedWorkflowEnabled,
            isSubaruGtpEnabled,
            isSDPRedesignEnabled
        } = this.props;

        const subaruGtpLogo = subaruGtpLogoAlt || (
            <IconFactory className="dr-ui-subaru-gtp-logo" icon="subaruGtpLogo" staticImages={config.staticImages} />
        );

        const elementToAttachModalTo = isSDPRedesignEnabled ? '.checkout-wizard-layout__wrapper' : '.dr-sp-checkout-main';
        const elementToModalBlur = isSDPRedesignEnabled ? '.checkout-wizard-layout__container' : '#checkout-main';

        const VehicleInfoDetailsContainer = isVinActive ? VehicleInfoDetailsVinContainer : VehicleInfoDetailsManualContainer;
        return (
            <VehicleInfoDetailsContainer>
                <YearInputContainer className={`${isVinActive ? 'hide' : ''}`} id="year-input">
                    <div>
                        {this.getSelectField(
                            'year',
                            years,
                            '',
                            'Year',
                            vehicle.year,
                            !isVinActive && isYearEnabled,
                            '',
                            'Select year'
                        )}
                        {isYearLoading && <SpinnerUI size="30" />}
                    </div>
                </YearInputContainer>

                <MakeInputContainer className={`${isVinActive ? 'hide' : ''}`} id="make-input">
                    <div>
                        {this.getSelectField(
                            'make',
                            makes,
                            'id',
                            'Make',
                            vehicle.make?.id,
                            isVinActive ? !!vehicle.vin : isMakeEnabled,
                            '',
                            'Select make'
                        )}
                        {isMakeLoading && <SpinnerUI size="30" />}
                    </div>
                </MakeInputContainer>

                <ModelInputContainer className={`${isVinActive ? 'hide' : ''}`} id="model-input">
                    <div>
                        {this.getSelectField(
                            'model',
                            models,
                            'id',
                            'Model',
                            vehicle.model?.id,
                            isVinActive ? !!vehicle.vin : isModelEnabled,
                            '',
                            'Select model'
                        )}
                        {isModelLoading && <SpinnerUI size="30" />}
                    </div>
                </ModelInputContainer>

                <VehicleInfoDetailsLabelsContainer
                    className={`${isVinActive && vehicle.vin ? '' : 'hide'}`}
                    id="vehicle-info-labels"
                >
                    <label>Your Vehicle</label>
                    <p>
                        {vehicle.year} {vehicle.make?.name} {vehicle.model?.name}
                    </p>
                    <p>VIN: {vehicle.vin}</p>
                </VehicleInfoDetailsLabelsContainer>
                {isSubaruGtpEnabled &&
                isSubaruGTPImprovedWorkflowEnabled &&
                isVinActive &&
                vehicle.vin &&
                checkIsSubaruGtpEligible ? (
                    <CardIsSubaruGTPEligible data-testid="isSubaru-Card">
                        {subaruGtpLogo}
                        <div data-testid="rules-gtp">
                            <p className="subaru-gtp-p">
                                <span style={{ fontWeight: 700 }}>
                                    Your {vehicle.year} {vehicle.make?.name} {vehicle.model?.name}{' '}
                                </span>{' '}
                                may be eligible for the Subaru Guaranteed Trade-In Program.&nbsp;
                                <a
                                    className="btn-link primary-link"
                                    data-testid="clickToShowRules"
                                    onClick={() => this.setState({ showModal: true })}
                                >
                                    Rules and Eligibility
                                </a>
                            </p>
                        </div>
                    </CardIsSubaruGTPEligible>
                ) : null}

                <TrimInputContainer id="trim-input">
                    <div>
                        {this.getSelectField(
                            'trim',
                            trims,
                            'id',
                            'Trim',
                            vehicle.trim?.id,
                            isVinActive ? !!vehicle.vin : isTrimEnabled,
                            '',
                            'Select trim'
                        )}
                        {isTrimLoading && <SpinnerUI size="30" />}
                    </div>
                </TrimInputContainer>

                <MileageInputContainer id="mileage-input">
                    <div>
                        {this.getInputField(
                            'Mileage',
                            'Mileage',
                            vehicle.mileage,
                            isVinActive ? !!vehicle.vin : isMileageEnabled,
                            'mileage-field',
                            {
                                type: 'number',
                                maxlength: 6,
                                inputType: 'mileage',
                                pattern: '[0-9]*',
                                inputmode: 'numeric'
                            },
                            true,
                            this.handleMileageValidation,
                            'e.g. 105,000',
                            this.handleInputOnBlur,
                            vehicle.vin
                        )}
                    </div>
                </MileageInputContainer>

                <ZipInputContainer id="zip-input">
                    <div>
                        {this.getInputField(
                            'Zip',
                            'Zip Code',
                            zip,
                            isVinActive ? !!vehicle.vin : isZipCodeEnabled,
                            'zip-field',
                            {
                                type: 'number',
                                maxlength: 5,
                                inputType: 'zipCode',
                                pattern: '[0-9]*',
                                inputmode: 'numeric'
                            },
                            true,
                            validateZip,
                            'e.g. 90210',
                            this.handleInputOnBlur
                        )}
                    </div>
                </ZipInputContainer>

                <OwnershipInputContainer id="ownership-input">
                    <div>
                        {this.getSelectField(
                            'ownershipStatus',
                            ['Active Loan', 'Active Lease', 'Own outright'],
                            'value',
                            'Ownership Status',
                            this.state.ownershipStatus || '',
                            isVinActive ? !!vehicle.vin : true,
                            '',
                            'Select status'
                        )}
                    </div>
                </OwnershipInputContainer>

                {!!isApplyToAmountFinanced && (
                    <AmountOwedInputContainer id="amountOwed-input">
                        <div>
                            {this.getInputField(
                                'AmountOwed',
                                'How Much Do You Owe?',
                                amountOwed,
                                true,
                                'amount-owed-field',
                                {
                                    type: 'number',
                                    maxlength: 12,
                                    inputType: 'amountOwed',
                                    pattern: '[0-9]*',
                                    inputmode: 'numeric'
                                },
                                true,
                                formatUtils.isValidNumber,
                                'e.g. $1000',
                                this.handleInputOnBlur
                            )}
                        </div>
                    </AmountOwedInputContainer>
                )}
                <Modal
                    show={(this.state as any).showModal}
                    elementToAttachModalTo={elementToAttachModalTo}
                    elementToBlur={elementToModalBlur}
                    cls="dr_trade_subaru_rules_modal"
                >
                    <PageUI
                        pageClass="dr_trade_subaru_rules_page"
                        pageTitle="Rules and Eligibility"
                        footerProps={{
                            onActionButtonClick: () => this.setState({ showModal: false }),
                            showBackButton: false,
                            buttonText: 'Close',
                            disableActionButton: false
                        }}
                        dispatchAnalytics={dispatchAnalytics}
                    >
                        <RulesUI />
                    </PageUI>
                </Modal>
            </VehicleInfoDetailsContainer>
        );
    }
}

export default VehicleInfoDetailsUI;
