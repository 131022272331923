import * as React from 'react';

export interface NextActivityFooterUIProps {
    headingText?: string;
    bodyText?: string;
    buttonText?: string;
    onButtonClick: { (appliedFromViewOfferSummaryLink?: boolean, requestedTestDrive?: boolean): void };
    isPending: boolean;
    tradeInComplete: boolean;
}

class NextActivityFooterUI extends React.Component<NextActivityFooterUIProps> {
    render() {
        const { headingText, bodyText, buttonText, onButtonClick, isPending, tradeInComplete } = this.props;

        return (
            <>
                <div className="card-next-activity-row">
                    <div className="card-next-activity-panel" data-id="next-activity-footer">
                        <div className="card-next-activity-panel-header">
                            <div className="header">{headingText}</div>
                        </div>
                        <div className="card-next-activity-panel-description">{bodyText}</div>
                        <div className="condition-select-button">
                            <button
                                className="card-next-activity-button"
                                onClick={(e) => {
                                    e.preventDefault();
                                    onButtonClick(false, true);
                                }}
                            >
                                {isPending ? 'Sending...' : buttonText}
                            </button>
                        </div>
                    </div>
                </div>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        onButtonClick(false, false);
                    }}
                    className="active"
                >
                    {tradeInComplete ? 'No thanks, continue to next step' : 'No thanks, apply and continue to next step'}.
                </a>
            </>
        );
    }
}
export default NextActivityFooterUI;
