/* eslint-disable max-len */

export interface IRule {
    name: string;
    rule: string;
}

export const Rules: IRule[] = [
    {
        name: '1. Vehicle Mileage Criteria:',
        rule: `
<p>Beginning April 2, 2024, GTP value is based on the following maximum allowable total vehicle mileage at the time of actual sale:</p>

<p>Model Year 2016 100,000 miles­</p>
<p>Model Year 2017 100,000 miles­</p>
<p>Model Year 2018 90,000 miles­</p>
<p>Model Year 2019 75,000 miles­</p>
<p>Model Year 2020 60,000 miles­</p>
<p>Model Year 2021 45,000 miles­</p>
<p>Model Year 2022 30,000 miles­</p>
<p>Model Year 2023 22,500 miles­</p>
<p>Model Year 2024 15,000 miles­</p>


<p><p>For vehicles exceeding the above maximum mileage limits and&nbsp;<strong>not</strong>&nbsp;disallowed by the Vehicle Exclusions or the Vehicle Condition Criteria outlined in the Program Guidelines, the GTP Value offered to the customer&nbsp;<strong>must be reduced&nbsp;</strong>by $0.20 for each mile over the maximum mileage allowance.&nbsp;<u>This is the only allowed reduction of the GTP Value.</u></p>`
    },
    {
        name: '2. Adjusting Trade-In Value:',
        // eslint-disable-next-line quotes
        rule: `<p>If your vehicle exceeds the maximum mileage limits stated above and is not disallowed by the Vehicle Exclusions or the Vehicle Condition Criteria, your participating Subaru retailer will calculate a value based on your vehicle's actual mileage. Please visit your participating Subaru retailer to receive a complimentary and complete vehicle evaluation to determine the actual market value of your Subaru vehicle.</p>`
    },
    {
        name: '3. Vehicle Exclusions:',
        rule: '<p>Active leased vehicles are excluded from this offer. Active commercial fleet vehicles are excluded from this offer. Current Subaru vehicle cannot be titled in a business, retailer, bank or finance company name. Vehicles originally allocated outside the 49 United States of America (Hawaii excluded) are excluded from this offer. The owner(s) of the current Subaru vehicle must reside in one of the 49 United States of America (Hawaii excluded). Vehicles originally sold in a foreign country are excluded from this offer even if a value is provided. Vehicles owned by a customer who did not purchase a new Subaru vehicle under this program are not eligible. Vehicles that do not meet the minimum conditional criteria are not eligible. Vehicles that receive "Trade Assistance" from Subaru of America Customer Advocacy Department due to reoccurring mechanical issues are ineligible. Vehicle brands other than Subaru are excluded from this offer. Vehicles that have accident and/or damage information listed on vehicle history report, e.g., CARFAX, AutoCheck, etc. are <strong><u>excluded</u></strong>  from the GTP repurchase.</p>'
    },
    {
        name: '4. Vehicle Condition Criteria:',
        rule: `
<p>The Guaranteed Trade-In Program Value is based on the vehicle being in safe operating condition and free of <strong>existing and/or previously repaired collision damage</strong> which required panel and/or part(s) replacement. The vehicle must also conform to the standards for normal wear and use.</p>

<p>Meeting any one of the following criteria will exclude the vehicle from the Guaranteed Trade-In Program:</p>

<div class="dr_trade_subaru_rules--medium-margin">
<ul>
    <li>Vehicles that do not have a current registration, inspection or emission sticker, that cannot be legally registered as a passenger vehicle or that will not pass applicable state or federal safety or emissions inspection.­</li>
    <li>Vehicles previously used for racing, emergency vehicle applications or other extreme uses for which the vehicle was not designed.­</li>
    <li>Vehicles where the odometer/speedometer has been replaced, tampered with and/or is currently inoperative, or vehicles with an undocumented odometer change, falsified mileage accumulation, interrupted or limited odometer occurrence, or any vehicle whose true mileage cannot be determined.</li>
    <li>Vehicles with any condition that would require disclosure at time of auction sale and/or on certificate of title, including but not limited to, frame damage, flood damage, or salvage vehicle.</li>
    <li> Vehicles used for a purpose that requires a legal disclosure to sell, e.g. vehicles having been in rental service.</li>
    <li>Vehicles with branded titles for "totaled", "salvage", "rebuilt", "Lemon Law", "flood", and/or "water damage" regardless of their repaired or service status or vehicles sold with wholesale or retail disclosures.</li>
    <li>Vehicles that have been modified in such a way as to have either voided the original Subaru Warranty or to have taken the vehicle out of compliance with any applicable state or federal safety or emissions requirements.</li>
    <li>Vehicles that have been altered from their original design specification in any manner. These alterations include, but are not limited to exhaust system, turbo or engine components, tire, wheel, suspension, powertrain performance, and aftermarket accessory installations, etc.</li>
    <li>Vehicles that have been abused or neglected resulting in premature wear or damage or vehicles where it is evident that the recommended scheduled maintenance has not been performed as determined by an Authorized Subaru Retailer.</li>
    <li>Vehicles that do not have four (4) undamaged matching tires with each one having at least 1/8-inch (4/32) tread at its lowest point.</li>
    <li>Vehicles with engine drivetrain, or other mechanical, safety, or electrical parts that do not operate.</li>
    <li>Vehicles with windows, windshield, lenses, or lights that are cracked or broken and need replacement.</li>
    <li>Vehicles that have sustained structural or unibody damage as defined by industry standards whether repaired or un-repaired.</li>
    <li>Vehicles with environmental or applied chemical damage to the finish such as hail, acid rain, etc.</li>
    <li>Vehicles with existing, un-repaired collision damage.</li>
    <li>Vehicles with previously repaired collision damage which required panel or part(s) replacement, including but not limited to, hood, fender, door, trunk lid, and/or quarter panel.</li>
    <li>Vehicles with previously repaired paint damage which does not conform to OEM specifications, including but not limited to, non-matched paint, paint swirls, paint bubbles, paint flaking and/or paint contamination.</li>
    <li>Vehicles that receive "Trade Assistance" from Subaru of America Customer Advocacy Department due to reoccurring mechanical issues.</li>
    <li>Vehicles that have accident and/or damage information listed on vehicle history report, e.g., CARFAX, AutoCheck, etc. are <strong><u>excluded</u></strong> from the GTP repurchase.</li>
    <li>Vehicles with Subaru Eyesight Technology equipped with non-Subaru OEM windshields.</li>
</ul>
    
</div>
`
    }
];
