import * as React from 'react';

export interface IConditionPanelUIStateProps {}

export interface IConditionPanelUIDispatchProps {}

export interface IConditionPanelUIProps extends IConditionPanelUIStateProps, IConditionPanelUIDispatchProps {
    label: string;
    id: string;
    statistic: string;
    description: string;
    onClickConditionPanel: { (id) };
    checked: boolean;
    selectedCondition?: string;
}

export interface IConditionPanelUIState {}

class ConditionPanelUI extends React.Component<IConditionPanelUIProps, IConditionPanelUIState> {
    render() {
        const { id, label, checked, statistic, description, onClickConditionPanel } = this.props;

        const className = checked ? 'vehicle-condition-panel selected' : 'vehicle-condition-panel';
        const panelId = `panel_${id}`;

        return (
            <button className={className} onClick={() => onClickConditionPanel(id)} id={panelId}>
                <div className="vehicle-condition-panel-header">
                    <div className="checkbox-ui-container">
                        <input type="checkbox" name={label} id={id} checked={checked} value={label} disabled={true} />
                        <div className="check"></div>
                        <label className="checkbox" htmlFor={id}>
                            {label}
                        </label>
                    </div>
                    <div className="statistic">{statistic}</div>
                </div>
                <div className="vehicle-condition-panel-description">{description}</div>
            </button>
        );
    }
}

export default ConditionPanelUI;
