// externals
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// libraries
import { ReadyToSendDialogStatus } from '@makemydeal/dr-dash-types';
import {
    deskingActionCreators,
    deskingActionTypes,
    offerActionCreators,
    offerInfoSelectors,
    offerReduxSelectors
} from '@makemydeal/dr-dash-store';

// components
import { Modal } from '@interstate/components/Modal';
import { Typography } from '@interstate/components/Typography';
import { Button } from '@interstate/components/Button';
import { Footer } from './Footer.styles';
import { Grid } from '@interstate/components/Grid';
import ActionButtonInterstate from '../common/ActionButton.interstate';
import { ErrorModalDialogContent } from '../common/ErrorModalDialogContent';
import { Action } from '../constants';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';

export interface IReadyToSendDialogProps {
    show: boolean;
    onNo(): void;
    onHide(): void;
    onSaved(): void;

    dialogHeader: string;
    dialogPrompt?: string;
    dialogPromptHeader?: string;
    action?: number;
    promptSx?: object;
}

const ReadyToSendDialogInterstate = (props: IReadyToSendDialogProps) => {
    const {
        show,
        onNo,
        onSaved,
        onHide,
        dialogHeader,
        dialogPrompt,
        action,
        dialogPromptHeader,
        promptSx = { padding: '0 24px' }
    } = props;

    const [saveOfferStatus, setSaveOfferStatus] = React.useState(ReadyToSendDialogStatus.Ready);
    const [saveOfferHasBeenTriggered, setSaveOfferHasBeenTriggered] = React.useState(false);

    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);
    const isCDL3InMVEnabled = useSelector(featureToggleSelectors.isCDL3EnabledInMV);

    const dispatch = useDispatch();

    const prefix = 'ready-to-send-dialog';

    const handleShowChange = () => {
        if (show) {
            setSaveOfferStatus(ReadyToSendDialogStatus.Ready);
        }
    };

    const handleSaveOfferStatusChange = () => {
        if (!(saveOfferHasBeenTriggered && show)) return;

        if (offerIsBeingSaved === true) {
            return setSaveOfferStatus(ReadyToSendDialogStatus.Sending);
        }

        setSaveOfferHasBeenTriggered(false);

        if (offerHasBeenSaved === true) {
            setSaveOfferStatus(ReadyToSendDialogStatus.Success);
            setTimeout(onSaved, 500);
            return;
        }

        return setSaveOfferStatus(ReadyToSendDialogStatus.Failure);
    };

    const handleYesClick = () => {
        if (isCDL3InMVEnabled) dispatch(deskingActionCreators.continueInDeskingConfirmClicked());

        const originalAction = Action.CONTINUE_IN_DESKING === action ? deskingActionTypes.CONTINUE_IN_DESKING : undefined;
        setSaveOfferHasBeenTriggered(true);
        dispatch(offerActionCreators.saveOffer({ originalAction }));
    };

    React.useEffect(handleShowChange, [show]);
    React.useEffect(handleSaveOfferStatusChange, [offerIsBeingSaved, offerHasBeenSaved, onSaved, show, saveOfferHasBeenTriggered]);

    const footer = (
        <Footer data-testid={`${prefix}-footer`}>
            {saveOfferStatus !== ReadyToSendDialogStatus.Failure && (
                <Grid display="flex" justifyContent="end">
                    <Button
                        data-testid={`${prefix}-no-button`}
                        buttonStyle="tertiary"
                        onClick={onNo}
                        disabled={saveOfferStatus === 'sending' || saveOfferStatus === 'success'}
                        sx={{ marginRight: '10px' }}
                    >
                        No
                    </Button>
                    <ActionButtonInterstate
                        data-testid={'ready-to-send-dialog-yes-button'}
                        status={saveOfferStatus}
                        prefix={prefix}
                        successAction="offer-saved"
                        readyPrefix="yes-button"
                        onAction={handleYesClick}
                        actionText="Yes"
                        loadingText="Updating..."
                    />
                </Grid>
            )}
            {saveOfferStatus === ReadyToSendDialogStatus.Failure && (
                <Button data-testid={`${prefix}-close`} buttonStyle="secondary" onClick={onNo}>
                    Close
                </Button>
            )}
        </Footer>
    );

    return (
        <Modal
            data-testid={prefix}
            show={show}
            header={
                <Typography data-testid={`${prefix}-header`} variant="h4" color="base.color.blue.900">
                    {dialogHeader}
                </Typography>
            }
            footer={{
                footerComponent: footer
            }}
            onHide={onHide}
            size="small"
        >
            {saveOfferStatus === ReadyToSendDialogStatus.Failure && <ErrorModalDialogContent />}
            {saveOfferStatus !== ReadyToSendDialogStatus.Failure && (
                <Typography
                    data-testid={`${prefix}-prompt`}
                    variant="body-md"
                    color="sem.color.on-surface.default"
                    sx={promptSx}
                    tag="div"
                >
                    {dialogPromptHeader && <b>{dialogPromptHeader}</b>}
                    {dialogPrompt}
                </Typography>
            )}
        </Modal>
    );
};

export default ReadyToSendDialogInterstate;
