// externals
import { connect } from 'react-redux';
import isEqual_ from 'lodash.isequal';

// libraries
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';

// components
import VehicleInfoUI, { IVehicleInfoUIStateProps, IVehicleInfoUIDispatchProps } from './VehicleInfoUI';

// selectors
import * as offerSelectors from '../../../store/mmd/offerSelectors';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import * as dealerSelectors from '../../../store/mmd/dealerSelectors';

// actions
import * as tradeInActionCreators from '../../../store/actionCreators';

// consts/enums
import * as tradeInRoutes from '../../../utils/routes';
import { CALLSITE_VEHICLEINFO_V4_1, CALLSITE_VEHICLEINFO_V4_2 } from '../../../utils/constants';

const isEqual = isEqual_;

const mapStateToProps = (state: any): IVehicleInfoUIStateProps => {
    const tradeIn = state.tradeInComponent;
    const years = tradeInSelectors.getTradeInLookupYears(tradeIn);
    const makes = tradeInSelectors.getTradeInLookupMakes(tradeIn);
    const models = tradeInSelectors.getTradeInLookupModels(tradeIn);
    const trims = tradeInSelectors.getTradeInLookupTrims(tradeIn);

    const isTradeInValid = tradeInSelectors.getTradeInIsValid(tradeIn, state);

    const isMakeEnabled = tradeInSelectors.getTradeInIsMakeEnabled(tradeIn);
    const isMakeLoading = tradeInSelectors.getTradeInIsMakeLoading(tradeIn);

    const isModelEnabled = tradeInSelectors.getTradeInIsModelEnabled(tradeIn);
    const isModelLoading = tradeInSelectors.getTradeInIsModelLoading(tradeIn);

    const isTrimEnabled = tradeInSelectors.getTradeInIsTrimEnabled(tradeIn);
    const isTrimLoading = tradeInSelectors.getTradeInIsTrimLoading(tradeIn);
    const isTrimSelected = tradeInSelectors.getTradeInIsTrimSelected(tradeIn);

    const isYearEnabled = tradeInSelectors.getTradeInIsYearEnabled(tradeIn);
    const isYearLoading = tradeInSelectors.getTradeInIsYearLoading(tradeIn);

    const isMileageEnabled = tradeInSelectors.getTradeInIsMileageEnabledFromState(state);
    const isZipCodeEnabled = tradeInSelectors.getTradeInIsZipCodeEnabled(tradeIn);

    const shouldIncludeTradeIn = tradeInSelectors.getAmountAppliedToFinancing(tradeIn);
    const amountOwed = tradeInSelectors.getTradeInAmountOwed(tradeIn);
    const vehicle = tradeInSelectors.getTradeInVehicle(tradeIn);
    const savedVehicle = tradeInSelectors.getTradeInSavedVehicle(state.tradeInComponent);
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn);
    const tradeInZipValue = tradeInSelectors.getTradeInZip(tradeIn);
    const zip = tradeInZipValue !== null ? tradeInZipValue : offerSelectors.getShopperZip(state);
    const isManualEntryFlow = tradeInSelectors.isManualEntryFlow(state);
    let ownership;
    // TODO: remove showOwnership when ownership dropdown is no more hidden for iDeal credit provider
    let showOwnership;
    // TODO: remove if block and only keep else condition when ownership dropdown is no more hidden for iDeal credit provider
    if (dealerSelectors.getCreditProvider(state) === 'ideal' && dealerSelectors.hasNoTradeOwnership(state)) {
        (ownership = 'Purchase'), (showOwnership = false);
    } else {
        (ownership = tradeInSelectors.getTradeInOwnership(tradeIn)), (showOwnership = true);
    }
    return {
        isMakeEnabled,
        isMakeLoading,
        isModelEnabled,
        isModelLoading,
        isTradeInValid,
        isTrimEnabled,
        isTrimLoading,
        isYearEnabled,
        isYearLoading,
        isMileageEnabled,
        isZipCodeEnabled,
        isTrimSelected,
        ownership,
        showOwnership,
        years,
        makes,
        models,
        trims,
        vehicle,
        zip,
        shouldIncludeTradeIn,
        amountOwed,
        vehicleOptions,
        savedVehicle,
        isManualEntryFlow
    };
};

const updateVehicleProp = (vehicle: ITradeInVehicle, prop, elem, subProps): ITradeInVehicle => {
    let { value, text } = elem;
    switch (prop) {
        case 'make':
            vehicle.model = {
                id: '',
                name: ''
            };
            vehicle.trim = {
                id: '',
                name: '',
                vehicleId: ''
            };
            break;
        case 'mileage': {
            value = value?.trim().replace(/,|\D/g, '');
            value = parseInt(value); // eslint-disable-line radix
            break;
        }
        case 'model':
            vehicle.trim = {
                id: '',
                name: '',
                vehicleId: ''
            };
            break;

        default:
            break;
    }
    if (subProps) {
        return {
            ...vehicle,
            [prop]: {
                ...vehicle[prop],
                id: value,
                name: text
            }
        };
    } else {
        return {
            ...vehicle,
            [prop]: value
        };
    }
};

const mapDispatchToProps = (dispatch: any): IVehicleInfoUIDispatchProps => {
    return {
        onLoad: (vehicle, years, makes, models, trims, zip) => {
            const loadCb = () => {
                dispatch(tradeInActionCreators.tradeInStarted());
            };
            setTimeout(loadCb, 500);
            if (years.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInYears(undefined));
            }
            if (vehicle.year && makes.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInMakes(vehicle.year, undefined));
            }
            if (vehicle.year && vehicle.make.id && models.length === 0) {
                dispatch(
                    tradeInActionCreators.fetchTradeInModels(vehicle.year, vehicle.make.id, undefined, CALLSITE_VEHICLEINFO_V4_1)
                );
            }
            if (vehicle.year && vehicle.make.id && vehicle.model.id && trims.length === 0) {
                dispatch(tradeInActionCreators.fetchTradeInTrims(vehicle.year, vehicle.make.id, vehicle.model.id, undefined));
            }
            if (vehicle.year && vehicle.make.id && vehicle.model.id && vehicle.trim.id) {
                dispatch(tradeInActionCreators.fetchTradeInVehicleId(vehicle));
            }
            if (zip) {
                dispatch(tradeInActionCreators.updateZip(zip));
            }
        },
        onBlurHandler: (propName, domElement) => {
            dispatch((_dispatch, getState) => {
                const state = getState();
                const isTradeInUpdated = (tradeInValue) => {
                    const hasFieldChanged = (domElement.value || '').toString() !== (tradeInValue || '').toString();
                    dispatch(tradeInActionCreators.changedTradeIn(hasFieldChanged));
                    return hasFieldChanged;
                };
                switch (propName) {
                    case 'mileage': {
                        const savedMileage = tradeInSelectors.getTradeInSavedMileage(state.tradeInComponent);
                        if (isTradeInUpdated(savedMileage)) {
                            dispatch(tradeInActionCreators.sendVehicleInfoAnalytics('mileage'));
                        }
                        break;
                    }
                    case 'zip': {
                        const savedZip = tradeInSelectors.getTradeInSavedZip(state.tradeInComponent);
                        if (isTradeInUpdated(savedZip)) {
                            dispatch(tradeInActionCreators.sendVehicleInfoAnalytics('zipcode'));
                        }
                        break;
                    }
                    case 'amountowed': {
                        const savedAmountOwed = tradeInSelectors.getTradeInSavedAmountOwed(state.tradeInComponent);
                        if (isTradeInUpdated(savedAmountOwed)) {
                            dispatch(tradeInActionCreators.sendVehicleAmountOwedAnalytics());
                        }
                        break;
                    }
                    default:
                        break;
                }
            });
        },
        onInputValueChange: (propName, domElement, vehicle) => {
            switch (propName) {
                case 'mileage': {
                    const vehicleWithUpdatedFields = updateVehicleProp(vehicle, propName, domElement, false);
                    dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedFields));
                    break;
                }
                case 'zip': {
                    dispatch(tradeInActionCreators.updateZip(domElement.value));
                    dispatch(tradeInActionCreators.fetchTradeInEquipmentOptions(vehicle, domElement.value));
                    break;
                }
                case 'amountowed': {
                    // eslint-disable-next-line radix
                    const amountOwed = parseInt((domElement.value || '').replace(/[^0-9]+/g, ''));
                    dispatch(tradeInActionCreators.updateTradeInBalanceOwed(amountOwed));
                    break;
                }
                default:
                    break;
            }
        },
        onValueChange: (propName, domElement, vehicle, savedVehicle) => {
            const hasVehicleChanged = !isEqual(vehicle, savedVehicle);
            dispatch(tradeInActionCreators.changedTradeIn(hasVehicleChanged));

            switch (propName) {
                case 'year': {
                    if (domElement) {
                        const vehicleWithUpdatedYear = updateVehicleProp(vehicle, propName, domElement, false);
                        dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedYear));
                        dispatch(tradeInActionCreators.fetchTradeInMakes(vehicleWithUpdatedYear.year, undefined));
                        dispatch(tradeInActionCreators.clearYMMT({ make: true, model: true, trim: true }));
                        dispatch(tradeInActionCreators.sendVehicleInfoAnalytics('year'));
                    } else {
                        console.warn(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;
                }

                case 'make': {
                    if (domElement) {
                        const vehicleWithUpdatedMake = updateVehicleProp(vehicle, propName, domElement, true);
                        dispatch(
                            tradeInActionCreators.fetchTradeInModels(
                                vehicleWithUpdatedMake.year,
                                vehicleWithUpdatedMake.make.id,
                                undefined,
                                CALLSITE_VEHICLEINFO_V4_2
                            )
                        );
                        dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedMake));
                        dispatch(tradeInActionCreators.clearYMMT({ model: true, trim: true }));
                        dispatch(tradeInActionCreators.sendVehicleInfoAnalytics('make'));
                    } else {
                        console.warn(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }

                    break;
                }

                case 'model': {
                    if (domElement) {
                        const vehicleWithUpdatedModel = updateVehicleProp(vehicle, propName, domElement, true);
                        dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedModel));
                        dispatch(
                            tradeInActionCreators.fetchTradeInTrims(
                                vehicleWithUpdatedModel.year,
                                vehicleWithUpdatedModel.make.id,
                                vehicleWithUpdatedModel.model.id,
                                undefined
                            )
                        );
                        dispatch(tradeInActionCreators.clearYMMT({ trim: true }));
                        dispatch(tradeInActionCreators.sendVehicleInfoAnalytics('model'));
                    } else {
                        console.warn(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;
                }

                case 'trim':
                    if (domElement) {
                        const safeElement = { value: domElement?.value, text: domElement?.text };
                        dispatch((_dispatch, getState) => {
                            const vehicleUpdatedTrim = updateVehicleProp(vehicle, propName, safeElement, true);
                            vehicleUpdatedTrim.trim.vehicleId = vehicleUpdatedTrim.trim ? vehicleUpdatedTrim.trim.id : '';
                            dispatch(tradeInActionCreators.fetchTradeInVehicleId(vehicleUpdatedTrim));
                            dispatch(tradeInActionCreators.updateVehicle(vehicleUpdatedTrim));
                            dispatch(tradeInActionCreators.sendVehicleInfoAnalytics('trim'));
                        });
                    } else {
                        throw new Error(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;

                case 'ownership':
                case 'paymentPreference':
                    if (domElement) {
                        dispatch((_dispatch, getState) => {
                            const state = getState();
                            const tradeIn = state.tradeInComponent;
                            const zip = tradeInSelectors.getTradeInZip(tradeIn) || offerSelectors.getShopperZip(state);
                            if (zip) {
                                dispatch(tradeInActionCreators.fetchTradeInEquipmentOptions(vehicle, zip));
                            }
                            dispatch(tradeInActionCreators.updatePurchasePreference(domElement.value));
                            dispatch(tradeInActionCreators.sendVehicleInfoAnalytics('ownership'));
                        });
                    } else {
                        throw new Error(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;

                case 'color':
                case 'condition':
                case 'vin': {
                    if (domElement) {
                        const vehicleWithUpdatedFields = updateVehicleProp(vehicle, propName, domElement, false);
                        dispatch(tradeInActionCreators.updateVehicle(vehicleWithUpdatedFields));
                    } else {
                        console.warn(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;
                }

                case 'amountFinanced':
                    if (domElement) {
                        dispatch(tradeInActionCreators.trackFinanceTypeAnalytics(JSON.parse(domElement.value || false)));
                    } else {
                        console.warn(
                            `Unexpected condition: propname is ${propName} and domElement is undefined (turbo/VehicleInfo)`
                        );
                    }
                    break;

                default:
                    break;
            }
        },
        applyTradeIn: (shouldIncludeTradeIn: boolean) => {
            dispatch(tradeInActionCreators.applyToAmountFinanced(shouldIncludeTradeIn));
            dispatch(tradeInActionCreators.applyTradeInSelections());
        },
        next: () => {
            dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_VEHICLE_CONDITION));
        },
        goToRoute: (route: string) => {
            dispatch(tradeInActionCreators.updateTradeInCurrentLocation(route));
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInActionCreators.dispatchAnalytics(type, description));
        }
    };
};

const VehicleInfoContainer = connect(mapStateToProps, mapDispatchToProps)(VehicleInfoUI);

export default VehicleInfoContainer;
