// externals
import * as React from 'react';
import { Component } from 'react';

// libraries
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { Resources } from '@makemydeal/dr-activities-common';
import { Tooltip } from '@makemydeal/ui-bricks/dist/cox';

// utils
import DevHelperTradeAutofill from '../../../common/components/devHelperTradeAutofill/DevHelperTradeAutofill';
import { normalizeVin } from '../../../utils/validation';
import { buildApiUrl } from '../../../common/utils/searchUiUtils';

// components
import SearchInput from './SearchInput';
import TradePage from '../../../common/components/TradePage/TradePage';

// consts/enums
import { STEPPER_ROUTES } from '../../../utils/constants';

// config
import config from '../../../store/mmd/config';
import { ITradeInSearchItem } from '../../../store';

export interface ISearchUIStateProps {
    renderDisclaimer?: { () };
    isSearchingForVin?: any;
    isSearchingForYMMT?: any;
    getSearchResultsForVin?: any;
    getSearchResultsForYMMT?: any;
    isDevHelperEnabled: boolean;
}

export interface ISearchUIDispatchProps {
    next: { () };
    tabSelected: { (searchBy: string) };
    setVehicle: { (item: any) };
}

export interface ISearchUIProps extends ISearchUIStateProps, ISearchUIDispatchProps {}

export interface ISearchUIState {
    isVinActive: boolean;
    isYMMTActive: boolean;
}

class SearchUI extends Component<ISearchUIProps, ISearchUIState> {
    apiUrl: string;
    kbbLogoUrl: string;

    constructor(props) {
        super(props);

        const {
            services: {
                bff: { protocol, host, port, base }
            }
        } = config;

        this.kbbLogoUrl = urlBuilder.buildFromConfig(config.staticImages, Resources.KBB_LOGO_HD);
        this.apiUrl = buildApiUrl(protocol, host, port, base);
        this.state = {
            isVinActive: false,
            isYMMTActive: true
        };
    }

    fetchVin = (value: string) => fetch(`${this.apiUrl}?vin=${value}`);

    formatVin = (item: any, value: string) => `${item.yearId} ${item.makeName} ${item.modelName}`;

    handleVinSelect = (trade: ITradeInSearchItem, vin: string | false): void => {
        if (vin) {
            trade.vin = vin;
        } else {
            trade.vin = '';
        }
        this.props.setVehicle(trade);
    };

    renderVinSearch(): JSX.Element {
        if (!this.state.isVinActive) return <></>;
        const toolTip = "You can find your VIN on your car's Drivers side dashboard or door jamb";

        return (
            <>
                <p>
                    Use your VIN to look up your car and get a more accurate Kelley Blue Book
                    <sup>&reg;</sup> trade-in value.
                </p>
                <div className="search-title">
                    VIN&nbsp;
                    <Tooltip>{toolTip}</Tooltip>
                </div>
                <SearchInput
                    inputId="trade-search-vin"
                    name="vin"
                    placeholder="e.g. RTG567908THG"
                    fetchCallback={this.fetchVin}
                    normalizeCallback={normalizeVin}
                    formatResult={this.formatVin}
                    selectCallback={(item, vin) => this.handleVinSelect(item, vin)}
                />
            </>
        );
    }

    fetchYMMT = (value: string) => fetch(`${this.apiUrl}?ymm=${value}`);

    formatYMMT = (item: any, value: string) => {
        let highlight = item.displayName;
        const tokens = value
            .split(' ')
            .map((item) => item.trim())
            .filter((item) => item.length > 0);

        const escapeRegExp = (str) => str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const mapArray = [];
        const buildStringMap = (str) => {
            mapArray.push(str);
            return `{${mapArray.length - 1}}`;
        };
        tokens.forEach((token) => (highlight = highlight.replace(new RegExp(token, 'gi'), buildStringMap)));
        mapArray.forEach((token, index) => {
            highlight = highlight.replace(new RegExp(escapeRegExp(`{${index}}`), 'g'), `<span>${token}</span>`);
        });
        return highlight;
    };

    handleYMMTSelect = (item: any) => this.props.setVehicle(item);

    renderYMMTSearch(): JSX.Element | null {
        if (!this.state.isYMMTActive) return null;
        return (
            <>
                <p>
                    Enter your car's year, make, and model to get an estimated Kelley Blue Book
                    <sup>&reg;</sup> trade-in value.
                </p>
                <SearchInput
                    inputId="trade-search-ymmt"
                    name="ymmt"
                    label="Year, Make &amp; Model "
                    placeholder="e.g. 2015 Honda Pilot"
                    fetchCallback={this.fetchYMMT}
                    formatResult={this.formatYMMT}
                    selectCallback={this.handleYMMTSelect}
                />
            </>
        );
    }

    setVinActive = (): void => {
        this.props.tabSelected('vin');
        this.setState({
            isVinActive: true,
            isYMMTActive: false
        });
    };

    setYMMTActive = (): void => {
        this.props.tabSelected('ymm');
        this.setState({
            isVinActive: false,
            isYMMTActive: true
        });
    };

    render(): JSX.Element {
        const { next, isDevHelperEnabled } = this.props;

        return (
            <TradePage
                pageClass="search-vehicle-page"
                pageTitle="Value Your Trade-In"
                pageSubTitle="Four simple steps to discover your car's Kelley Blue Book value."
                footerProps={{
                    onActionButtonClick: next,
                    showBackButton: false,
                    buttonText: 'Next'
                }}
                stepperRoutes={STEPPER_ROUTES}
                renderDisclaimer={this.props.renderDisclaimer}
                showTradeErrorMessage={false}
                staticAssetsConfig={config.staticImages}
            >
                <div className="search-tabs-container">
                    <div className="search-tabs">
                        <button
                            className={`tab-ymmt common-header ${this.state.isYMMTActive ? 'active' : ''}`}
                            onClick={this.setYMMTActive}
                        >
                            Value By Make &amp; Model
                        </button>
                        <button
                            className={`tab-vin common-header ${this.state.isVinActive ? 'active' : ''}`}
                            onClick={this.setVinActive}
                        >
                            Value My Car
                        </button>
                    </div>
                    <div className="search-body">
                        <img className="kbb-logo" src={this.kbbLogoUrl} />
                        {this.renderYMMTSearch()}
                        {this.renderVinSearch()}
                    </div>
                </div>
                {isDevHelperEnabled && <DevHelperTradeAutofill />}
            </TradePage>
        );
    }
}

export default SearchUI;
