import { rem } from 'polished';
import styled from 'styled-components';

export const SubaruEligibilityDiv = styled.div`
    .gtp-logo {
        svg {
            width: ${rem(100)};
            height: ${rem(100)};
        }
    }

    .eligibility-text {
        text-align: left;
    }

    .justify-content-center {
        text-align: center;
    }

    &.mileage-field {
        min-width: unset;
    }

    input[type='text'] {
        &#mileage {
            min-width: unset;
            min-height: unset;
            height: auto;
            font-size: ${rem(16)};
            transition: all ease-in 0.25s;

            & ~ label {
                text-transform: lowercase;
                color: ${({ theme }) => theme.tradeInLabelColor || '#222222'};
            }
        }

        @include placeholder() {
            font-size: ${rem(16)};
        }
    }

    #amountOwed {
        width: 100%;
        height: ${rem(50)};
        font-size: ${rem(16)};
        border-radius: ${rem(4)};
    }

    select.ui-bricks {
        min-width: unset;
        min-height: unset;
        height: auto;
        font-size: ${rem(16)};
        padding-right: ${rem(30)};
    }

    .form-group {
        > div {
            padding: 0;
        }
        padding: 0;
        margin-bottom: ${rem(43)};
        label {
            font-weight: $trade-in-vehicle-info-label-font-weight;
            color: ${({ theme }) => theme.tradeInLabelColor || '#222222'};
        }

        & > label,
        & > div > label {
            font-weight: 600;
            color: ${({ theme }) => theme.tradeInLabelColor || '#222222'};
        }
    }

    .field-not-filled-in input,
    .field-not-filled-in select {
        border: ${({ theme }) => `${rem(1)} solid ${theme.errorFieldBorderColor || 'red'}`};
    }

    select.field-not-filled-in {
        border: ${({ theme }) => `${rem(1)} solid ${theme.errorFieldBorderColor || 'red'}`};
    }

    select:focus,
    input[type='text']:focus {
        box-shadow: ${({ theme }) => theme.defaultInputFieldBorderBoxShadow || '0.05rem 0.05rem 0.1rem black'};
    }

    .error-div {
        display: flex;
        align-items: left;
        margin-top: 0.125rem;
    }

    .error-message {
        font-size: 0.75rem;
        margin-left: 0.375rem;
        color: ${({ theme }) => theme.errorFieldBorderColor || 'red'};
    }

    .error-icon {
        line-height: 0;
    }

    .hide {
        display: none;
    }

    .amtOwedInputWrap {
        margin: 0;
    }

    .select-container,
    .input-container {
        padding-bottom: 0.5rem;
    }

    .kbb-footer {
        display: none;
    }
`;
