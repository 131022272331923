// tslint:disable:import-name
import * as React from 'react';
import { Route, Redirect } from 'react-router';
import * as tradeInRoutes from '../../utils/routes';
import VehicleCondition from './vehicleCondition/VehicleCondition';
import EquipmentOptions from './equipmentOptions/EquipmentOptions';
import Valuation from './valuation/Valuation';
import Equity from './Equity/Equity';
import TradeInLeadForm from './leadForm/LeadForm';
import IcoValuation from './icoValuation/IcoValuation';
import VehicleInfoAdapter from './VehicleInfo/VehicleInfoAdapter';
import { IRootProps } from '../../components/RootProps';

// Import GTP views
// import GTP from 'dr-subaru-gtp';

const TradeInRouting: React.FC<IRootProps> = (props) => {
    return (
        <div className="dr-ui-trade-in">
            <Route path={tradeInRoutes.TRADE_IN} render={() => <Redirect to={props.currentRoute} />} />
            {/* Following section is for temporary mount for GTP Component for Holden Utes dev until Catalyst sets up
                    modularize approach to loading separate components. */}
            <Route path={tradeInRoutes.TRADE_IN_LEADFORM} component={TradeInLeadForm} exact />
            <Route path={tradeInRoutes.TRADE_VEHICLE_INFO} component={VehicleInfoAdapter} />
            <Route path={tradeInRoutes.TRADE_VEHICLE_CONDITION} component={VehicleCondition} />
            <Route path={tradeInRoutes.TRADE_EQUIPMENT_OPTIONS} component={EquipmentOptions} />
            <Route path={tradeInRoutes.TRADE_VALUATION} component={Valuation} />
            <Route path={tradeInRoutes.TRADE_EQUITY} component={Equity} />
            <Route path={tradeInRoutes.TRADE_IN_ICO} component={IcoValuation} />
        </div>
    );
};

export default TradeInRouting;
