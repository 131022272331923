import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { SUMMARY_CARD_TEXTS, TRADEIN_SOURCE_TYPES } from './constants';

export interface ITradeInSummaryUIProps {
    amountOwed: number;
    formatTradeIn: string;
    hasACV?: boolean;
    isTradeInImprovedWorkflowEnabled?: boolean;
    source?: string;
    tradeInValue: number;
    rawTradeInValue: number;
}

type ITradeInContainerUIProps = RouteComponentProps & ITradeInSummaryUIProps;

const TradeInSummaryUI: React.FC<ITradeInContainerUIProps> = (props) => {
    const isManualEntry = props.source === TRADEIN_SOURCE_TYPES.manual;
    const { isTradeInImprovedWorkflowEnabled } = props;
    if (isTradeInImprovedWorkflowEnabled) {
        if (isManualEntry) {
            return (
                <div className="trade-estimated-value">
                    <span>{props.hasACV ? SUMMARY_CARD_TEXTS.appliedValue : SUMMARY_CARD_TEXTS.estValue}: </span>
                    <span className="card-drawer-list-values">
                        {props.tradeInValue > 0 ? props.formatTradeIn : SUMMARY_CARD_TEXTS.valuePendingAppraisal}
                    </span>
                </div>
            );
        } else {
            if (props.rawTradeInValue === 0) {
                return <div className="trade-estimated-value">{SUMMARY_CARD_TEXTS.seeDealer}</div>;
            }
            return (
                <div className="trade-estimated-value">
                    {SUMMARY_CARD_TEXTS.estValue}: <span className="card-drawer-list-values">{props.formatTradeIn}</span>
                </div>
            );
        }
    }
    if (!props.tradeInValue) {
        return <div className="trade-estimated-value">{SUMMARY_CARD_TEXTS.seeDealer}</div>;
    } else {
        return (
            <div className="trade-estimated-value">
                {SUMMARY_CARD_TEXTS.estValue}: <span className="card-drawer-list-values">{props.formatTradeIn}</span>
            </div>
        );
    }
};

export default TradeInSummaryUI;
