import { constants as offerReduxConstants } from '@makemydeal/dr-offer-redux';

export const DEALER_ROLE = 'dealer';
export const SHOPPER_ROLE = 'shopper';

// Manual Incentives Types
export const DEALER_CASH = 'Dealer Cash';
export const INCLUDED = 'Included';
export const LENDER = 'Lender';
export const MANUFACTURER = 'Manufacturer';

export const VIN_SOURCE = 'VIN';

export const SHOPPER_QUOTE_DASHBOARD = 'shopper-quote-dashboard';
export const REGULAR = 'regular'; // For any feature with regular dashboard
export const SHARE_URL_OPTION_LABEL = 'Share URL';
export const SHARE_EMAIL_OPTION_LABEL = 'Share via Email';
export const SHARE_URL_INPUT_LABEL = 'Share URL';
export const COPY_URL_BUTTON_TEXT = 'Copy';
export const SHARE_MESSAGE_PLACEHOLDER = 'Include a message about this offer';
export const SHOPPER_EMAIL_INPUT_LABEL = 'Shopper Email Address';

export const ICO = 'ICO';
export const SD = 'SD';
export const USER = 'USER';

export const ICO_MESSAGE = 'KBB ICO';
export const SD_MESSAGE = 'Standard KBB';
export const USER_MESSAGE = 'Shopper Submitted Value';
export const DEFAULT_MESSAGE = 'Dealer';

// analytics related
export const DATA_LAYER_NAME = 'dataLayer';

export const ACQUISITION_FEE_ID = 1050;

//push to darwin
export const PUSH_TO_DARWIN_FLOW = 'PUSH_TO_DARWIN_FLOW';
export const OPEN_IN_DARWIN_FLOW = 'OPEN_IN_DARWIN_FLOW';
export const enum DARWIN_ERROR_MESSAGES {
    PUSH_TO_DARWIN_ERROR = 'Darwin is unavailable at this time.',
    OPEN_IN_DARWIN_ERROR = 'Please check that you are signed into Darwin and the system is available then try again.',
    STOCK_NUMBER_ERROR = 'Please provide a valid stock number and try again.'
}

// push to dms
export const PUSH_TO_DMS_FLOW = 'PUSH_TO_DMS_FLOW';
export const CDMS = 'CDMS';
export const DMS_PLUS = 'DMS+';
export const COX_DMS_PLUS = 'CoxDMS+';

export const PERSONA_WHITE_LIST = ['shopper', 'dealer', 'system'];

export const APPLICATION_NAME = 'Manager View';

export const PERSONA_APPNAME_RELATION = {
    dealmaker: APPLICATION_NAME,
    dealer: 'Showroom',
    shopper: 'Accelerate My Deal',
    system: 'System'
} as Record<string, string>;

export const VERSION_CREATED_BY_TO_APP_DISPLAY_NAME = {
    DarwinFI: 'Darwin'
} as Record<string, string>;

export const DEFAULT_DAYS_TO_FIRST_PAYMENT = 30;

export const { CALC_ENDPOINTS } = offerReduxConstants;
