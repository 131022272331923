// externals
import * as React from 'react';
import { Component } from 'react';

// libraries
import { Select, Input } from '@makemydeal/ui-bricks/dist/cox';
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';

// interfaces/types
import { MANUAL_STEPPER_ROUTES, STEPPER_ROUTES } from '../../../utils/constants';

// components
import { MileageInput } from './MileageInput';
import { SpinnerUI, IconFactory } from '@makemydeal/dr-activities-common';
import TradePage from '../../../common/components/TradePage/TradePage';
import config from '../../../store/mmd/config';

// utils
import { normalizeZip, validateForMileageValues, validateZip } from '../../../utils/validation';
import { formatUtils } from '@makemydeal/dr-common-utils';

export interface IVehicleInfoUIStateProps {
    vehicle: ITradeInVehicle;
    years: any[];
    makes: any[];
    models: any[];
    trims: any[];
    vehicleOptions: object;
    isYearEnabled: boolean;
    isYearLoading: boolean;
    isMakeEnabled: boolean;
    isMakeLoading: boolean;
    isModelEnabled: boolean;
    isModelLoading: boolean;
    isTrimEnabled: boolean;
    isTrimLoading: boolean;
    isTrimSelected: boolean;
    isMileageEnabled: boolean;
    isZipCodeEnabled: boolean;
    ownership: any;
    showOwnership: boolean;
    zip: string;
    isTradeInValid: boolean;
    savedVehicle?: ITradeInVehicle;
    shouldIncludeTradeIn: boolean;
    amountOwed: number;
    renderDisclaimer?: { () };
    isManualEntryFlow?: boolean;
}

export interface IVehicleInfoUIDispatchProps {
    onLoad: { (vehicle, years, makes, models, trims, zip): void };
    onValueChange: {
        (propName, domElement, vehicle: ITradeInVehicle, savedVehicle?: ITradeInVehicle): void;
    };
    onInputValueChange: { (propName, domElement, vehicle: ITradeInVehicle): void };
    onBlurHandler: { (propName, domElement): void };
    applyTradeIn: { (shouldIncludeTradeIn: boolean) };
    next: { () };
    goToRoute: { (route: string): void };
    dispatchAnalytics: { (type: string, description: string) };
}

export interface IVehicleInfoUIProps extends IVehicleInfoUIStateProps, IVehicleInfoUIDispatchProps {}

interface FieldsChanged {
    mileage?: boolean;
    zip?: boolean;
    amountowed?: boolean;
}

export interface IVehicleInfoUIState {
    continueWasClicked?: boolean;
    validationErrorMessaqe?: string;
    applyToAmountFinanced?: boolean;
    fieldsChanged?: FieldsChanged;
}

export interface IGetInputFieldInput {
    type: string;
    maxlength?: number;
    minlength?: number;
    inputType?: string;
    inputmode?: 'none' | 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url';
    pattern?: string;
}

class VehicleInfoUI extends Component<IVehicleInfoUIProps, IVehicleInfoUIState> {
    setTextInputRef: (elem, name) => void;

    constructor(props) {
        super(props);
        this.state = {
            continueWasClicked: false,
            validationErrorMessaqe: '',
            applyToAmountFinanced: props.shouldIncludeTradeIn,
            fieldsChanged: {
                mileage: false,
                zip: false,
                amountowed: false
            }
        };

        this.setTextInputRef = (element, name) => {
            this[name] = element;
        };
    }

    componentDidMount() {
        const { vehicle, years, makes, models, trims, zip } = this.props;
        this.props.onLoad(vehicle, years, makes, models, trims, zip);

        let focusFieldName = 'year';
        if (vehicle.year) focusFieldName = 'make';
        if (vehicle.make?.id) focusFieldName = 'model';
        if (vehicle.model?.id) focusFieldName = 'trim';
        if (vehicle.trim?.id) focusFieldName = 'ownership';

        this.focusElementByRefName(focusFieldName);
    }

    focusElementByRefName(refName: string): void {
        const element = this[refName];
        if (element && !element.disabled) {
            element.focus();
        } else if (element && element.disabled) {
            setTimeout(() => this.focusElementByRefName(refName), 100);
        }
    }

    validationFunction = (fieldValue, validatorFunc, required) => {
        const isFilledIn = this.validateRequiredFields(fieldValue);
        const isInValid = !validatorFunc(fieldValue) && this.state.continueWasClicked;

        if ((isFilledIn || !required) && !isInValid) return true;
        else return false;
    };

    errorMessageFunction = (fieldValue, validatorFunc, name) => {
        const isFilledIn = this.validateRequiredFields(fieldValue);
        const isInValid = !validatorFunc(fieldValue) && this.state.continueWasClicked;

        if (!isFilledIn) return 'This is a required field';
        else if (isInValid) return `${name} is invalid`;
        else return '';
    };

    validateRequiredFields = (fieldValue) => {
        return (fieldValue === null || fieldValue === '') && this.state.continueWasClicked ? false : true;
    };

    handleSelectOnChange: React.ChangeEventHandler<HTMLSelectElement> = (event): void => {
        const { currentTarget } = event;
        this.onChangeHandler(currentTarget.getAttribute('name'), currentTarget.options[currentTarget.selectedIndex]);
    };

    updateFieldChanged = (fieldName: string, isChanged?: boolean) => {
        const fieldsChanged = { ...this.state.fieldsChanged };
        fieldsChanged[fieldName] = isChanged;
        this.setState({ fieldsChanged });
    };

    handleInputOnChange: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
        const { currentTarget } = event;
        const fieldName = currentTarget.getAttribute('name').toLowerCase();
        this.updateFieldChanged(fieldName, true);
        this.props.onInputValueChange(fieldName, currentTarget, { ...this.props.vehicle });
    };

    handleInputOnBlur: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
        const { currentTarget } = event;
        const { fieldsChanged } = this.state || {};
        const fieldName = currentTarget.getAttribute('name').toString().toLowerCase();
        if (fieldsChanged[fieldName]) {
            this.updateFieldChanged(fieldName, false);
            this.props?.onBlurHandler && this.props?.onBlurHandler(fieldName, currentTarget);
        }
    };

    getSelectField(name, options, optionsId, label, selectedValue, enabled = false, additionalClass = '', placeholder = 'Select') {
        const optionsItems = options.map((option, index) => {
            const optionId = optionsId !== '' ? option[optionsId] : option;
            const optionName = option.hasOwnProperty('name') ? option.name : option;
            return (
                <option key={optionId} value={optionId}>
                    {optionName}
                </option>
            );
        });

        const isValid = this.validateRequiredFields(selectedValue);
        const showError = isValid ? 'hide' : '';
        const showRedOutline = isValid ? '' : 'field-not-filled-in';
        return (
            <>
                <Select
                    selectId={name}
                    name={name}
                    ref={(element) => this.setTextInputRef(element, name)}
                    disabled={!enabled}
                    value={selectedValue || ' '}
                    label={label}
                    placeholder={placeholder}
                    defaultValue={' '}
                    className={`select-field ui-bricks ${showRedOutline}`}
                    onChangeFunction={this.handleSelectOnChange}
                >
                    <option value=" " disabled>
                        {placeholder}
                    </option>
                    {optionsItems}
                </Select>
                <div className={`error-div ${showError}`}>
                    <IconFactory icon="errorIcon" className="error-icon" staticImages={config.staticImages} />
                    <span className="error-message">This is a required field</span>
                </div>
            </>
        );
    }

    getInputField(
        name,
        label,
        value,
        _enabled = false,
        additionalClass = '',
        input: IGetInputFieldInput = { type: 'text' },
        required = false,
        validatorFunc = (val) => true,
        placeholder = '',
        blurCb: any = () => null
    ) {
        const normalizer = {
            zipCode: normalizeZip,
            amountOwed: formatUtils.formatCurrencyAmount
        };
        const isValid = this.validationFunction(value, validatorFunc, required);
        const showError = isValid ? 'hide' : '';
        const showRedOutline = isValid ? '' : 'field-not-filled-in';
        const errorMessage = this.errorMessageFunction(value, validatorFunc, name);
        const InputClass = input.inputType === 'mileage' ? MileageInput : Input;
        return (
            <>
                <InputClass
                    {...input}
                    type="text"
                    inputId={name}
                    name={name}
                    ref={(element) => this.setTextInputRef(element, name)}
                    label={label}
                    disabled={false}
                    value={value}
                    placeholder={placeholder}
                    className={`${additionalClass} ui-bricks ${showRedOutline}`}
                    onChangeFunction={this.handleInputOnChange}
                    onBlurFunction={blurCb}
                    formatValueFunction={normalizer[input.inputType]}
                />
                <div className={`error-div ${showError}`}>
                    <IconFactory icon="errorIcon" className="error-icon" staticImages={config.staticImages} />
                    <span className="error-message">{errorMessage}</span>
                </div>
            </>
        );
    }

    handleMileageValidation(mileage: number | string) {
        switch (typeof mileage) {
            case 'number': {
                return validateForMileageValues(mileage);
            }
            case 'string': {
                const value = Number.parseInt(mileage?.trim().replace(/,/g, ''), 10);
                return isNaN(value) ? false : validateForMileageValues(value);
            }
            default: {
                return false;
            }
        }
    }

    onChangeHandler(propName, domElement) {
        this.props.onValueChange(propName, domElement, { ...this.props.vehicle }, undefined);

        switch (propName) {
            case 'year': {
                this.focusElementByRefName('make');
                break;
            }
            case 'make': {
                this.focusElementByRefName('model');
                break;
            }
            case 'model': {
                this.focusElementByRefName('trim');
                break;
            }
            case 'trim': {
                this.focusElementByRefName('ownership');
                break;
            }
            case 'ownership': {
                this.focusElementByRefName('mileage');
                break;
            }
            case 'amountFinanced': {
                if (domElement.value === 'true') {
                    this.setState({
                        applyToAmountFinanced: true
                    });
                } else {
                    this.setState({
                        applyToAmountFinanced: false
                    });
                    this.props.onInputValueChange('amountowed', { ...domElement, ...{ value: '0' } }, { ...this.props.vehicle });
                }
                this.focusElementByRefName('amountOwed');
                break;
            }
        }
    }

    next = () => {
        if (!this.props.isTradeInValid) {
            this.setState({ continueWasClicked: true });
            return;
        }

        const { applyToAmountFinanced } = this.state;
        this.props.applyTradeIn(applyToAmountFinanced);
        this.props.next();
    };

    render() {
        const {
            vehicle,
            zip,
            isZipCodeEnabled,
            years,
            isYearEnabled,
            isYearLoading,
            makes,
            isMakeEnabled,
            isMakeLoading,
            models,
            isModelEnabled,
            isModelLoading,
            trims,
            isTrimEnabled,
            isTrimLoading,
            isMileageEnabled,
            ownership,
            showOwnership,
            isTrimSelected,
            isManualEntryFlow,
            goToRoute,
            dispatchAnalytics,
            amountOwed
        } = this.props;

        const { applyToAmountFinanced } = this.state;
        const isHidden = showOwnership ? '' : 'hide';

        return (
            <TradePage
                pageClass="vehicle-info"
                stepperRoutes={isManualEntryFlow ? MANUAL_STEPPER_ROUTES : STEPPER_ROUTES}
                goToRoute={goToRoute}
                pageTitle="Tell Us About Your Vehicle"
                pageSubTitle="Enter the details of your vehicle below"
                dispatchAnalytics={dispatchAnalytics}
                footerProps={{
                    onActionButtonClick: this.next,
                    showBackButton: false,
                    buttonText: 'Next',
                    backButtonText: "I'm not interested in trading in a vehicle",
                    hideBackIcon: true
                }}
                renderDisclaimer={this.props.renderDisclaimer}
                showTradeErrorMessage={false}
                staticAssetsConfig={config.staticImages}
            >
                <div className="vehicle-info-container container-fluid">
                    <div className="row">
                        <div className="col-xs-12 col-md-4 col-lg-4">
                            <div className="form-row form-group">
                                <div>
                                    {this.getSelectField('year', years, '', 'Year', vehicle.year, isYearEnabled)}
                                    {isYearLoading && <SpinnerUI size="30" />}
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-4 col-lg-4">
                            <div className="form-row form-group">
                                <div>
                                    {this.getSelectField('make', makes, 'id', 'Make', vehicle.make.id, isMakeEnabled)}
                                    {isMakeLoading && <SpinnerUI size="30" />}
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-12 col-md-4 col-lg-4">
                            <div className="form-row form-group">
                                <div>
                                    {this.getSelectField('model', models, 'id', 'Model', vehicle.model.id, isModelEnabled)}
                                    {isModelLoading && <SpinnerUI size="30" />}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 col-sm-8">
                            <div className="form-row form-group">
                                <div>
                                    {this.getSelectField('trim', trims, 'id', 'Trim', vehicle.trim.id, isTrimEnabled)}
                                    {isTrimLoading && <SpinnerUI size="30" />}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-4">
                            {/* eslint-disable-next-line max-len*/}
                            {/* TODO: remove isHidden from the className when ownership type option 'Lease' works as expected for iDeal and it's safe to display ownership dropdown*/}
                            <div className={`form-row form-group ${isHidden}`} data-testid="ownership-dropdown">
                                <div>
                                    {this.getSelectField(
                                        'ownership',
                                        ['Purchase', 'Lease'],
                                        '',
                                        'Ownership',
                                        ownership,
                                        isTrimSelected || ownership
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 col-md-6 col-lg-6">
                            <div className="form-row form-group">
                                <div>
                                    {this.getInputField(
                                        'Mileage',
                                        'Mileage',
                                        vehicle.mileage,
                                        isMileageEnabled,
                                        'mileage-field',
                                        {
                                            type: 'number',
                                            maxlength: 6,
                                            inputType: 'mileage',
                                            pattern: '[0-9]*',
                                            inputmode: 'numeric'
                                        },
                                        true,
                                        this.handleMileageValidation,
                                        'e.g. 105,000',
                                        this.handleInputOnBlur
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-6 col-lg-6">
                            <div className="form-row form-group">
                                <div>
                                    {this.getInputField(
                                        'Zip',
                                        'Zip Code',
                                        zip,
                                        isZipCodeEnabled,
                                        'zip-field',
                                        {
                                            type: 'number',
                                            maxlength: 5,
                                            inputType: 'zipCode',
                                            pattern: '[0-9]*',
                                            inputmode: 'numeric'
                                        },
                                        true,
                                        validateZip,
                                        'e.g. 90210',
                                        this.handleInputOnBlur
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xs-12 col-md-6 col-lg-6">
                            <div className="form-row form-group">
                                <div>
                                    {this.getSelectField(
                                        'amountFinanced',
                                        [
                                            { name: 'Yes', value: 'true' },
                                            { name: 'No', value: 'false' }
                                        ],
                                        'value',
                                        'Is There An Active Loan On This Vehicle?',
                                        applyToAmountFinanced || 'false',
                                        true
                                    )}
                                </div>
                            </div>
                        </div>

                        {!!applyToAmountFinanced && (
                            <div className="col-xs-12 col-md-6 col-lg-6">
                                <div className="form-row form-group amtOwedInputWrap">
                                    <div>
                                        {this.getInputField(
                                            'AmountOwed',
                                            'How Much Do You Owe?',
                                            amountOwed,
                                            true,
                                            'amount-owed-field',
                                            {
                                                type: 'number',
                                                maxlength: 12,
                                                inputType: 'amountOwed',
                                                pattern: '[0-9]*',
                                                inputmode: 'numeric'
                                            },
                                            true,
                                            formatUtils.isValidNumber,
                                            'e.g. $1000',
                                            this.handleInputOnBlur
                                        )}
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </TradePage>
        );
    }
}

export default VehicleInfoUI;
