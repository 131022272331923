import { Grid } from '@interstate/components/Grid';
import { Alert } from '@interstate/components/Alert';
import { Typography } from '@interstate/components/Typography';
import React from 'react';

const AlertError = ({ message, testId = 'payment-error-grid' }: { message: string; testId?: string }) => (
    <Grid xs={12} padding="0" marginBottom="1rem">
        <Alert role="banner" type="error" data-testid={testId}>
            <Typography variant="label-md">{message}</Typography>
        </Alert>
    </Grid>
);

export default AlertError;
