// externals
import * as React from 'react';

// libraries
import { Select, Input } from '@makemydeal/ui-bricks/dist/cox';
import { IconFactory, ITradeInVehicle } from '@makemydeal/dr-activities-common';

// config
import config from '../../../store/mmd/config';

// components
import TradePage from '../../../common/components/TradePage/TradePage';

// consts/enums
import { STEPPER_ROUTES } from '../../../utils/constants';
import * as tradeInRoutes from '../../../utils/routes';

export interface IEquipmentOptionsStepOneUIStateProps {
    vehicle: ITradeInVehicle;
    vehicleOptions: any;
    pageTitle: string;
    vehicleColor: string;
    pageSubTitle: string;
    conflict?: any;
    vehicleOptionsFiltered?: any;
    years?: any;
    transmissionOptions?: any;
    driveTrainOptions?: any;
    engineOptions?: any;
    isSDPRedesignEnabled: boolean;
}

export interface IEquipmentOptionsStepOneUIDispatchProps {
    onValueChange: { (categoryName: string, id: number, checked: boolean) };
    onColorChange: { (propName, vehicle: ITradeInVehicle): void };
    next: { () };
    goToRoute: { (route: string): void };
    dispatchAnalytics?: { (type: string, description: string) };
    removeTrade: { () };
}

export interface IEquipmentOptionsStepOneUIProps
    extends IEquipmentOptionsStepOneUIStateProps,
        IEquipmentOptionsStepOneUIDispatchProps {}

export interface IEquipmentOptionsStepOneUIState {
    id?: number;
    Engine?: string;
    Transmission?: string;
    DriveTrain?: string;
    isSelected?: boolean;
    selectedColorOption?: string;
}
const colors = ['beige', 'gray', 'white', 'blue', 'black', 'red'];

export class EquipmentOptionsStepOneUI extends React.Component<IEquipmentOptionsStepOneUIProps, IEquipmentOptionsStepOneUIState> {
    setTextInputRef: (elem, name) => void;

    constructor(props) {
        super(props);

        this.setTextInputRef = (element, name) => {
            this[name] = element;
        };

        const { engineOptions, transmissionOptions, driveTrainOptions } = this.props;

        const currentEngineOption = engineOptions ? engineOptions.options.find((option) => option.isSelected) : '';
        const currentTransmissionOption = transmissionOptions
            ? transmissionOptions.options.find((option) => option.isSelected)
            : '';
        const currentdriveTrainOption = driveTrainOptions ? driveTrainOptions.options.find((option) => option.isSelected) : '';

        this.state = {
            Engine: currentEngineOption ? currentEngineOption.id : '',
            Transmission: currentTransmissionOption ? currentTransmissionOption.id : '',
            DriveTrain: currentdriveTrainOption ? currentdriveTrainOption.id : '',
            selectedColorOption: this.props.vehicleColor
        };
    }

    onClickNext = () => this.props.next();

    onVehicleColorChange = (event) => {
        const selectedColorOption = event.currentTarget.getAttribute('data-name');
        this.setState({
            selectedColorOption
        });
        this.props.onColorChange(selectedColorOption, this.props.vehicle);
    };

    valueChange = (event) => {
        const id = event.currentTarget.getAttribute('data-id');
        const name = event.currentTarget.name;

        this.setState({
            id,
            [name]: event.currentTarget.value
        });
        const optionId = Number(event.currentTarget.value);
        this.props.onValueChange(name, optionId, true);
    };

    getOptions = (vehicleOptions) => {
        return vehicleOptions.options.map(({ id, displayName }) => (
            <option key={id} value={id}>
                {displayName}
            </option>
        ));
    };

    getSelectField = (name, vehicleOptionsName, placeholder = '') => {
        const vehicleOptions = this.props[vehicleOptionsName];
        if (!vehicleOptions) return null;
        const options = this.getOptions(vehicleOptions);

        if (options.length === 1) {
            const currentOption = vehicleOptions.options.find((item) => item.id === this.state[name]);
            return (
                <>
                    <label className="field-label">{name}</label>
                    <Input
                        inputId={`id-${name}`}
                        name={name}
                        value={currentOption ? currentOption.displayName : ''}
                        disabled={true}
                        label={placeholder}
                    />
                </>
            );
        }

        return (
            <>
                <label className="field-label">{name}</label>
                <Select
                    selectId={`id-${name}`}
                    name={name}
                    data-id={name}
                    ref={(element) => this.setTextInputRef(element, name)}
                    data-vehicle-options-name={vehicleOptionsName}
                    value={this.state[name]}
                    label={placeholder}
                    defaultValue="Value"
                    className={'select-field ui-bricks'}
                    onChangeFunction={this.valueChange}
                >
                    {options}
                </Select>
            </>
        );
    };

    getColors() {
        const { selectedColorOption } = this.state;

        return colors.map((name) => {
            const isSelected = selectedColorOption === name;
            return (
                <div
                    className={`color-item ${name} ${isSelected ? 'selected' : ''}`}
                    data-name={name}
                    onClick={this.onVehicleColorChange}
                    key={name}
                >
                    <div className="selected-color-container">
                        {isSelected ? (
                            <IconFactory icon="checkmark" className="checkmark" staticImages={config.staticImages} />
                        ) : null}
                    </div>
                    <p className="color-label">{name}</p>
                </div>
            );
        });
    }

    render() {
        const { pageTitle, pageSubTitle, dispatchAnalytics, goToRoute, isSDPRedesignEnabled, removeTrade } = this.props;

        return (
            <TradePage
                pageClass="equipment-options-step-2"
                pageTitle={pageTitle}
                pageSubTitle={pageSubTitle}
                stepperRoutes={STEPPER_ROUTES}
                goToRoute={goToRoute}
                dispatchAnalytics={dispatchAnalytics}
                footerProps={{
                    onActionButtonClick: this.onClickNext,
                    showBackButton: isSDPRedesignEnabled,
                    buttonText: isSDPRedesignEnabled ? 'Continue to Details' : 'Next',
                    fullWidthLayout: isSDPRedesignEnabled,
                    showSkipButton: isSDPRedesignEnabled,
                    skipButtonText: 'Cancel Trade-In',
                    onSkipButtonClick: removeTrade,
                    onBackButtonClick: () => goToRoute(tradeInRoutes.TRADE_VEHICLE_CONDITION)
                }}
                staticAssetsConfig={config.staticImages}
            >
                <div className="container-fluid">
                    {this.getSelectField('Engine', 'engineOptions')}
                    {this.getSelectField('Transmission', 'transmissionOptions')}
                    {this.getSelectField('DriveTrain', 'driveTrainOptions')}
                    <label className="field-label">Color</label>
                    <div className="color-container">{this.getColors()}</div>
                </div>
            </TradePage>
        );
    }
}

export default EquipmentOptionsStepOneUI;
