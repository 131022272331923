import { connect } from 'react-redux';
import { GTPFlowPageSubtitleUI } from './GTPFlowPageSubtitleUI';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import { TradeInSubaruGtpFlowErrorType } from '../../../types';

const mapStateToProps = (state: any) => {
    const tradeInSubaruGtpFlowError = tradeInSelectors.getTradeInSubaruGtpFlowError(state);

    const tradeIn = tradeInSelectors.getTradeInComponent(state);
    const { year, model } = tradeInSelectors.getTradeInVehicle(tradeIn);
    const { name } = model || {};

    return {
        GTPErrorPageSubTitle: getGTPErrorPageSubTitle(
            tradeInSubaruGtpFlowError?.errorType,
            tradeInSubaruGtpFlowError?.errorMessage,
            year,
            name
        )
    };
};

const getGTPErrorPageSubTitle = (
    errorType: TradeInSubaruGtpFlowErrorType,
    errorMessage: string,
    year: string,
    modelName: string
) => {
    const displayErrorMessage = errorMessage ? ` ${errorMessage}` : '';
    switch (errorType) {
        case TradeInSubaruGtpFlowErrorType.MILEAGE_ERROR: {
            return `Your ${year} ${modelName} is not eligible for the Subaru Guaranteed Trade-In Program. The mileage entered may not qualify for GTP at this time, please contact your retailer.`;
        }
        case TradeInSubaruGtpFlowErrorType.API_ERROR: {
            return `Your ${year} ${modelName} is not eligible for the Subaru Guaranteed Trade-In Program.${displayErrorMessage}`;
        }
        case TradeInSubaruGtpFlowErrorType.LEASE_ERROR: {
            return `Your ${year} ${modelName} is not eligible for the Subaru Guaranteed Trade-In Program. Active leased vehicles do not qualify for the program.`;
        }
    }
};

const GTPFlowPageSubtitle = connect(mapStateToProps)(GTPFlowPageSubtitleUI);

export default GTPFlowPageSubtitle;
