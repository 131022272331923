import { KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TermMonthItem } from '@makemydeal/dr-platform-types';
import { offerReduxActionCreators, offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { NumericInput } from '@interstate/components/NumericInput';
import { GridItem } from './styled.components';
import { ENTER_KEY_CODE, getNewTarget, areStringsEqual } from '../common/utils';
import { IPaymentGridErrorState } from '@makemydeal/dr-dash-types';
import { INPUT_INVALID_ERROR_MESSAGE } from '../../constants';
import { disabledStyle } from './utils';

interface ITermInputProps {
    term: TermMonthItem;
    isLoading: boolean;
}

const TermMonthInput = ({ term, isLoading }: ITermInputProps) => {
    const dispatch = useDispatch();
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const [termValue, setTermValue] = useState('');
    const paymentGridErrorState: IPaymentGridErrorState = useSelector(offerReduxSelectors.getPaymentGridErrorState);

    useEffect(() => {
        setTermValue(`${term.val ?? ''}`);
    }, [term]);

    const handleChange = (e: InterstateOnChangeEvent<TextInputEventValue>) => setTermValue(e.target.value);

    const handleBlur = (item: TermMonthItem, e: InterstateOnChangeEvent<TextInputEventValue>) => {
        const value = e.target.value === '' ? null : parseInt(e.target.value);
        if (item.val === value) return;

        logNewRelicPageAction('MV:PaymentGrid - Change term value', {
            mvOfferType: offerType,
            mvFieldEdited: 'mv:payment:grid:term',
            mvDealXgId: dealXgId
        });

        dispatch(offerReduxActionCreators.updatePaymentGridTerm(offerType, item.key, value));
    };

    const handleKeyDownEvent = (item: TermMonthItem) => (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.code === ENTER_KEY_CODE) {
            const newTarget = getNewTarget(event);
            handleBlur(item, newTarget);
        }
    };

    return (
        <GridItem xs={3} className="payment-grid-item">
            <NumericInput
                sx={{
                    width: '100%',
                    ...(isLoading && disabledStyle)
                }}
                data-testid={`term-month-input-${term.val}`}
                name={'term-month'}
                label="Term (mo)"
                value={termValue}
                allowDecimal={false}
                autoInsertCommas={true}
                hasError={
                    areStringsEqual(paymentGridErrorState.lastUpdatedInput, `input_term_${term.key}`) &&
                    paymentGridErrorState.hasError
                }
                errorMessage={INPUT_INVALID_ERROR_MESSAGE}
                customErrorMessages={{ exceedsMaxValueRule: INPUT_INVALID_ERROR_MESSAGE }}
                minValue={1}
                maxValue={1000}
                onChange={handleChange}
                onBlur={(e) => handleBlur(term, e)}
                onKeyDown={handleKeyDownEvent(term)}
            />
        </GridItem>
    );
};

export default TermMonthInput;
