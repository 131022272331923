import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { PropsRoute } from '@makemydeal/dr-activities-common';
import * as tradeInRoutes from '../utils/routes';

import VehicleInfo from '../common/components/VehicleInfo/VehicleInfo';
import VehicleCondition from '../redesignP202/components/vehicleCondition/VehicleCondition';
import EquipmentOptions from '../redesignP202/components/equipmentOptions/EquipmentOptions';
import EquipmentOptionsStepOne from '../redesignP202/components/equipmentOptions/EquipmentOptionsStepOne';
import Search from '../redesignP202/components/search/Search';
import TradeSummary from '../redesignP202/components/summary/TradeSummary';
import { IRootProps } from './RootProps';
import ManualEntry from '../redesignP202/components/manualEntry/ManualEntry';
import TradeInDecision from '../redesignP202/components/tradeInDecision/TradeInDecision';
import ICOSuccess from '../redesignP202/components/icoSuccess/IcoSuccess';
import TradeInIco from '../redesignP202/components/icoValuation/IcoValuation';
import Eligibility from '../common/components/subaruEligibility/SubaruEligibility';
import TradeInVehicleInfoModal from '../redesignP202/components/oemVehicleInfo/TradeInVehicleInfoModal';

const Root: React.FC<IRootProps> = (props: IRootProps) => {
    const getStartingComponent = () => {
        return tradeInRoutes.TRADE_IN_SEARCH;
    };

    return (
        <div className="dr-p202-trade-in">
            <Route {...props} path={tradeInRoutes.TRADE_IN} render={() => <Redirect to={getStartingComponent()} />} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_IN_SEARCH} component={Search} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_VEHICLE_INFO} component={VehicleInfo} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_VEHICLE_CONDITION} component={VehicleCondition} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_EQUIPMENT_OPTIONS} component={EquipmentOptions} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_EQUIPMENT_OPTIONS_STEP_ONE} component={EquipmentOptionsStepOne} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_SUMMARY} component={TradeSummary} />
            <Route path={tradeInRoutes.TRADE_IN_ICO} component={TradeInIco} />
            <Route path={tradeInRoutes.TRADE_IN_ICO_SUCCESS} component={ICOSuccess} />
            <Route path={tradeInRoutes.TRADE_MANUAL_ENTRY} component={ManualEntry} />
            <PropsRoute {...props} path={tradeInRoutes.TRADE_IN_DECISION} component={TradeInDecision} />
            <Route path={tradeInRoutes.TRADE_SUBARU_ELIGIBILITY} component={Eligibility} />
            <Route path={tradeInRoutes.TRADE_VEHICLE_INFO_MODAL} component={TradeInVehicleInfoModal} />
        </div>
    );
};

export default Root;
