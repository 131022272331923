// externals
import { connect } from 'react-redux';

// libraries
import { Resources } from '@makemydeal/dr-activities-common';
import { urlBuilder } from '@makemydeal/dr-common-utils';

// utils
import * as tradeInRoutes from '../../../utils/routes';
import * as tradeInDecisionLogic from '../../../utils/tradeInDecisionLogic';

// components
import TradeInDecisionUI, { ITradeInDecisionUIStateProps, ITradeInDecisionUIDispatchProps } from './TradeInDecisionUI';

// actions
import * as tradeInComponentActionCreators from '../../../store/actionCreators';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import featureToggles from '../../../store/featureToggles';

// consts/enums
import { TradeInSubaruGtpFlowErrorType } from '../../../types/tradeInEnums';

// config
import config from '../../../store/mmd/config';

const mapStateToProps = (state: any): ITradeInDecisionUIStateProps => {
    const headerImageKBB = urlBuilder.buildFromConfig(config.staticImages, Resources.KBB_LOGO_HD);
    const isDevHelperEnabled = featureToggles.isDevHelperEnabled(state);
    const isInTradeInSubaruGtpFlow = tradeInSelectors.inTradeInSubaruGtpFlow(state);
    const tradeInSubaruGtpFlowError = tradeInSelectors.getTradeInSubaruGtpFlowError(state);
    const isTradeInSubaruGtpFlowError = !!tradeInSubaruGtpFlowError;
    let pageTitle = 'Value Your Vehicle';
    let pageSubTitle = 'If you have a vehicle, we’ll apply the trade-in value or instant cash offer to your order.';
    switch (tradeInSubaruGtpFlowError?.errorType) {
        case TradeInSubaruGtpFlowErrorType.MILEAGE_ERROR: {
            pageTitle = 'Sorry, Your Vehicle May Not Be Eligible';
            pageSubTitle = 'The mileage entered may not qualify for GTP at this time, please contact your retailer.';
            break;
        }
        case TradeInSubaruGtpFlowErrorType.API_ERROR: {
            pageTitle = 'Sorry, Your Vehicle May Not Be Eligible';
            pageSubTitle =
                tradeInSubaruGtpFlowError?.errorMessage ||
                // eslint-disable-next-line max-len
                'The VIN entered is not a valid VIN or could not be found in Subaru systems. Please check for spaces or special characters in the entry field.';
            break;
        }
    }
    const isShopperEnteredTradeEnabled = featureToggles.isShopperEnteredTradeEnabled(state);
    const showCorrectOptions = tradeInDecisionLogic.showCorrectOptions(state);
    const optionsToShow = showCorrectOptions?.optionsToShow;
    const selectedOption = tradeInDecisionLogic.getSelectedOption(optionsToShow[0]);

    return {
        pageTitle,
        pageSubTitle,
        headerImageKBB,
        isDevHelperEnabled,
        isInTradeInSubaruGtpFlow,
        isTradeInSubaruGtpFlowError,
        isShopperEnteredTradeEnabled,
        showCorrectOptions,
        selectedOption
    };
};

const mapDispatchToProps = (dispatch: any): ITradeInDecisionUIDispatchProps => {
    return {
        goToTradeInFlow: (option: string, isSubaruGTPRetry = false) => {
            dispatch(tradeInComponentActionCreators.goToTradeInFlow(option, isSubaruGTPRetry));
        },
        goToICOFlow: (option: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN_ICO));
            dispatch(tradeInComponentActionCreators.sendTradeDecisionClickedAnalytics(option));
        },
        goToTradeManualEntryFlow: (option: string) => {
            dispatch(tradeInComponentActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_MANUAL_ENTRY));
            dispatch(tradeInComponentActionCreators.sendTradeDecisionClickedAnalytics(option));
        },
        trackOption: (option: string, isShopperEnteredTradeEnabled: boolean) => {
            dispatch(tradeInComponentActionCreators.sendTradeDecisionChangedAnalytics(option, isShopperEnteredTradeEnabled));
        },
        dispatchImpressionTag: () => {
            dispatch(tradeInComponentActionCreators.sendTradeDecisionDisplayedAnalytics());
        },
        dispatchAnalytics: (type, description) => {
            dispatch(tradeInComponentActionCreators.dispatchAnalytics(type, description));
        },
        updateSource: (source) => {
            dispatch(tradeInComponentActionCreators.updateTradeInSource(source));
        }
    };
};

const TradeInDecision = connect(mapStateToProps, mapDispatchToProps)(TradeInDecisionUI);

export default TradeInDecision;
