import { RetailPriceFieldProps } from '../types';
import { EditedTaxFieldInputContainer } from './EditedTaxField.style';
import { NumericInput } from '@interstate/components/NumericInput';
import { useEditableProp } from './helpers';
import { InterstateOnChangeEvent } from '@interstate/components/InterstateEvents';
import { TextInputEventValue } from '@interstate/components/TextInput';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

export default function TaxItemAmountField<Props extends RetailPriceFieldProps>({ data, handleBlur }: Props) {
    const formattedAmount = formatDollarsAndCents(data.amount);
    const [amount, setAmount, isEdited] = useEditableProp(formattedAmount, data.isEditedAmount);

    const handleBlurWrapper = (e: InterstateOnChangeEvent<TextInputEventValue>) => {
        if (!amount || formattedAmount === '$--' || !formattedAmount) {
            setAmount('0.00');
        }
        handleBlur(e, data.amountKey);
    };

    return (
        <EditedTaxFieldInputContainer $isEdited={isEdited}>
            <NumericInput
                data-testid={`detailed-table-cell-amount-input-${data.name}`}
                name={'detail-amount'}
                inputPrefix={'$'}
                onChange={(e) => setAmount(e.target.value)}
                value={amount}
                sx={{ width: '9rem' }}
                onBlur={handleBlurWrapper}
                allowDecimal={true}
                decimalMaxLength={2}
                maxValue={999999.99}
                placeholder="0.00"
                autoInsertCommas={true}
                textAlign="right"
            />
        </EditedTaxFieldInputContainer>
    );
}
