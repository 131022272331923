import { useDispatch, useSelector } from 'react-redux';
import { offerReduxSelectors, offerReduxActionCreators } from '@makemydeal/dr-dash-store';
import { logNewRelicPageAction } from '@makemydeal/dr-activities-common';
import { Spinner as LoadingIndicator } from '@interstate/components/Spinner';
import { PaymentGridRow, PaymentGridCellItem, FINANCE } from '@makemydeal/dr-platform-types';
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

import { LoadingIndicatorWrapper, StyledGridItem, StyledTypography } from './styled.components';
import { DownPaymentInputField } from './components/DownPaymentInputField';
import { hasTermAndDownpayment } from './utils';

interface IGridRowProps {
    data: PaymentGridRow;
    isLoading?: boolean;
}

const GridRow = ({ data, isLoading = false }: IGridRowProps) => {
    const offerType = useSelector(offerReduxSelectors.getCurrentOfferType);
    const dealXgId = useSelector(offerReduxSelectors.getDealXgId);
    const dispatch = useDispatch();

    const handleSelect = (item: PaymentGridCellItem) => () => {
        if (item.isEmpty) return;

        logNewRelicPageAction('MV:PaymentGrid - Change selected term', {
            mvOfferType: offerType,
            mvFieldEdited: 'mv:payment:grid:selected-term',
            mvDealXgId: dealXgId
        });

        dispatch(offerReduxActionCreators.updateDownPaymentImmediate(data.val));
        dispatch(offerReduxActionCreators.selectedTerm(item.id));
    };

    return (
        <>
            <StyledGridItem xs={3} className={'payment-grid-item'} maxHeight="70px">
                <DownPaymentInputField downPaymentValue={data.val} rowIndex={data.key} isLoading={isLoading} />
            </StyledGridItem>
            {data &&
                data.items.map((item: PaymentGridCellItem, index) => (
                    <StyledGridItem
                        xs={3}
                        className={'payment-grid-item'}
                        key={index}
                        maxHeight="70px"
                        alignItems={item.isLoading ? 'center' : 'start'}
                        paddingLeft="1%"
                        data-testid={`grid-item-row-${item.id}`}
                        onClick={handleSelect(item)}
                        isSelected={item.isSelected && !item.isLoading}
                        canSelect={!item.isEmpty && !item.isLoading && !item.isSelected}
                    >
                        {item.isLoading && (
                            <LoadingIndicatorWrapper>
                                <LoadingIndicator data-testid="loading-spinner-terms-col" />
                            </LoadingIndicatorWrapper>
                        )}
                        {!item.isLoading && !item.isEmpty && (
                            <>
                                {offerType !== FINANCE && (
                                    <StyledTypography data-testid="monthly-payment-lender-code">{item.lenderCode}</StyledTypography>
                                )}
                                <StyledTypography className="large" data-testid="monthly-payment-title">
                                    {formatDollarsAndCents(item.monthlyPayment)}
                                </StyledTypography>
                            </>
                        )}

                        {!item.isLoading && item.isEmpty && item.id && hasTermAndDownpayment(item.id) && (
                            <StyledTypography data-testid="no-payment-available" className="large">
                                No Payment Available
                            </StyledTypography>
                        )}
                    </StyledGridItem>
                ))}
        </>
    );
};

export default GridRow;
