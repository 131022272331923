// externals
import * as React from 'react';
import * as ReactDOM from 'react-dom';

// libraries
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';
import { SpinnerUI, ActionFooterBar } from '@makemydeal/dr-activities-common';
import { Select, Input } from '@makemydeal/ui-bricks';

// components
import Header from '../header/Header';

// utils
import { validateZip, normalizeZip, validateForMileageValues, normalizeMileageValues } from '../../../utils/validation';
import * as optimizelyUtils from '../../../common/optimizelyWrapper';

export interface IChangeHandlerMetaData {
    eventTargetSelectedIndex?: string | null;
    eventTargetOptionsCount?: string | null;
}

export interface IVehicleInfoUIStateProps {
    vehicle: ITradeInVehicle;
    years: any[];
    makes: any[];
    models: any[];
    trims: any[];
    vehicleOptions: object;
    isYearEnabled: boolean;
    isYearLoading: boolean;
    isMakeEnabled: boolean;
    isMakeLoading: boolean;
    isModelEnabled: boolean;
    isModelLoading: boolean;
    isTrimEnabled: boolean;
    isTrimLoading: boolean;
    isTrimSelected: boolean;
    ownership: any;
    zip: string;
    isTradeInValid: boolean;
    pageTitle: string;
    headerSubtitle: string;
    savedVehicle?: ITradeInVehicle;
    isKbbFieldValidationEnabled?: boolean;
}

export interface IVehicleInfoUIDispatchProps {
    onLoad: { (vehicle, years, makes, models, trims, zip): void };
    onValueChange: {
        (propName, domElement, vehicle: ITradeInVehicle, savedVehicle?: ITradeInVehicle, meta?: IChangeHandlerMetaData): void;
    };
    applyTradeIn: { () };
    cancelTradeIn: { (): void };
    fetchEquipmentOptions: {
        (vehicleOptions: object, zip: string, vehicle: ITradeInVehicle): void;
    };
    next: { () };
    onSkip?: { (): void };
}

export interface IVehicleInfoUIProps extends IVehicleInfoUIStateProps, IVehicleInfoUIDispatchProps {}

export interface IVehicleInfoUIState {}

export interface IGetInputFieldInput {
    type: string;
    maxlength?: number;
    inputType?: string;
}

const buildMeta = (selectedIndex: number | undefined | null, options: any[]): IChangeHandlerMetaData => {
    let eventTargetSelectedIndex: string | undefined | null;
    let eventTargetOptionsCount: string | undefined | null;
    if (selectedIndex || selectedIndex === 0) {
        eventTargetSelectedIndex = `${selectedIndex}`;
    } else {
        eventTargetSelectedIndex = selectedIndex as undefined | null;
    }
    if (!options) {
        eventTargetOptionsCount = 'no options';
    } else if (options.length === 0) {
        eventTargetOptionsCount = '0';
    } else if (!options.length) {
        eventTargetOptionsCount = 'undefined/null';
    } else {
        eventTargetOptionsCount = `${options.length}`;
    }
    return { eventTargetSelectedIndex, eventTargetOptionsCount };
};

class VehicleInfoUI extends React.Component<IVehicleInfoUIProps, IVehicleInfoUIState> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const { vehicle, years, makes, models, trims, zip, isKbbFieldValidationEnabled } = this.props;
        this.props.onLoad(vehicle, years, makes, models, trims, zip);

        if (isKbbFieldValidationEnabled) {
            this.focusElementByRefName('year');
        }
    }

    getElementByRefName(refName: string) {
        return ReactDOM.findDOMNode(this.refs[refName]) as HTMLInputElement | HTMLButtonElement;
    }

    lookupFieldsAreBusy = (): boolean => {
        const { isYearLoading, isMakeLoading, isModelLoading, isTrimLoading } = this.props;
        return isYearLoading || isMakeLoading || isModelLoading || isTrimLoading;
    };

    focusElementByRefName(refName: string): void {
        const element = this.getElementByRefName(refName);
        if (element && !element.disabled) {
            element.focus();
        } else if (element && element.disabled) {
            setTimeout(() => this.focusElementByRefName(refName), 10);
        }
    }

    getSelectField(name, options, optionsId, placeholder, selectedValue, enabled = false) {
        const optionsItems = options.map((option, index) => {
            const optionId = optionsId !== '' ? option[optionsId] : option;
            const optionName = option.hasOwnProperty('name') ? option.name : option;

            return (
                <option key={optionId} value={optionId}>
                    {optionName}
                </option>
            );
        });

        return (
            <Select
                selectId={name}
                name={name}
                ref={name}
                disabled={this.lookupFieldsAreBusy() || !enabled}
                value={selectedValue}
                label={placeholder}
                defaultValue=""
                className="select-field ui-bricks"
                onChangeFunction={(event) => {
                    this.onChangeHandler(
                        name,
                        event.target.options[event.target.selectedIndex],
                        buildMeta(event.target.selectedIndex, event.target.options)
                    );
                }}
            >
                <option value="" disabled></option>
                {optionsItems}
            </Select>
        );
    }

    getInputField(name, placeholder, value, enabled = false, additionalClass = '', input: IGetInputFieldInput = { type: 'text' }) {
        const validator = {
            zipCode: validateZip,
            mileage: validateForMileageValues
        };

        const normalizer = {
            zipCode: normalizeZip,
            mileage: normalizeMileageValues
        };

        const errorMessages = {
            zipCode: 'Invalid zip code',
            mileage: 'Invalid mileage'
        };

        return (
            <Input
                {...input}
                type="text"
                inputId={name}
                name={name}
                ref={name}
                label={placeholder}
                disabled={!enabled}
                value={value}
                className={`${additionalClass} ui-bricks`}
                onChangeFunction={(event) => {
                    this.onChangeHandler(name, event.target, {});
                }}
                validationFunction={validator[input.inputType]}
                formatValueFunction={normalizer[input.inputType]}
                errorMessage={errorMessages[input.inputType]}
            />
        );
    }

    onChangeHandler(propName, domElement, meta: IChangeHandlerMetaData) {
        this.props.onValueChange(propName, domElement, this.props.vehicle, undefined, meta);
        const { isKbbFieldValidationEnabled } = this.props;
        if (isKbbFieldValidationEnabled) {
            switch (propName) {
                case 'year': {
                    this.focusElementByRefName('make');
                    break;
                }
                case 'make': {
                    this.focusElementByRefName('model');
                    break;
                }
                case 'model': {
                    this.focusElementByRefName('trim');
                    break;
                }
                case 'trim': {
                    this.focusElementByRefName('ownership');
                    break;
                }
                case 'ownership': {
                    this.focusElementByRefName('mileage');
                    break;
                }
                default:
                    break;
            }
        }
    }

    next = () => {
        optimizelyUtils.track('tradeStepOneContinued');
        const { vehicle, vehicleOptions, zip } = this.props;
        this.props.fetchEquipmentOptions(vehicleOptions, zip, vehicle);
        this.props.applyTradeIn();
        this.props.next();
    };

    skip = () => {
        const { onSkip } = this.props;
        onSkip();
    };

    previous = () => {
        this.props.cancelTradeIn();
    };

    render() {
        const {
            vehicle,
            zip,
            years,
            isYearEnabled,
            isYearLoading,
            makes,
            isMakeEnabled,
            isMakeLoading,
            models,
            isModelEnabled,
            isModelLoading,
            trims,
            isTrimEnabled,
            isTrimLoading,
            isTrimSelected,
            ownership,
            isTradeInValid,
            pageTitle,
            headerSubtitle
        } = this.props;

        const barProps = {
            onActionButtonClick: this.next,
            onBackButtonClick: this.skip,
            showBackButton: true,
            ignoreRouterHistory: true,
            buttonText: 'Continue',
            backButtonText: "I'm not interested in trading in a vehicle",
            hideBackIcon: true,
            disableActionButton: !isTradeInValid
        };

        return (
            <div id="dr-trade-vehicle-info" className="dr-ui-trade-vehicle-info">
                <div className="dr-ui-trade-vehicle-info__wrap">
                    <Header pageTitle={pageTitle} headerSubTitle={headerSubtitle} showTradeErrorMessage={false} />

                    <section>
                        <article className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-row form-group">
                                {this.getSelectField('year', years, '', 'Year', vehicle.year, isYearEnabled)}
                                {isYearLoading && <SpinnerUI size="25" />}
                            </div>
                            <div className="form-row form-group">
                                {this.getSelectField('make', makes, 'id', 'Make', vehicle.make?.id, isMakeEnabled)}
                                {isMakeLoading && <SpinnerUI size="25" />}
                            </div>
                            <div className="form-row form-group">
                                {this.getSelectField('model', models, 'id', 'Model', vehicle.model?.id, isModelEnabled)}
                                {isModelLoading && <SpinnerUI size="25" />}
                            </div>

                            <div className="form-row form-group">
                                {this.getSelectField('trim', trims, 'id', 'Trim', vehicle.trim?.id, isTrimEnabled)}
                                {isTrimLoading && <SpinnerUI size="25" />}
                            </div>
                        </article>
                        <article className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="form-row form-group">
                                {this.getSelectField(
                                    'ownership',
                                    ['Purchase', 'Lease'],
                                    '',
                                    'Ownership',
                                    ownership,
                                    isTrimSelected || ownership
                                )}
                            </div>

                            <div className="row dr-ui-trade-vehicle-info__row">
                                <article className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-row form-group">
                                        {this.getInputField(
                                            'mileage',
                                            'Mileage',
                                            vehicle.mileage,
                                            isTrimSelected || vehicle.mileage !== null,
                                            'mileage-field',
                                            { type: 'number', maxlength: 6, inputType: 'mileage' }
                                        )}
                                    </div>
                                </article>
                                <article className="col-lg-6 col-md-6 col-sm-6 col-xs-6">
                                    <div className="form-row form-group">
                                        {this.getInputField('zip', 'Zip', zip, isTrimSelected || zip !== '', 'zip-field', {
                                            type: 'number',
                                            maxlength: 5,
                                            inputType: 'zipCode'
                                        })}
                                    </div>
                                </article>
                            </div>

                            <div className="form-row form-group">
                                {this.getInputField(
                                    'vin',
                                    'VIN (Optional)',
                                    vehicle.vin,
                                    isTrimSelected || vehicle.vin !== '',
                                    'vin-field',
                                    { type: 'text', maxlength: 17 }
                                )}
                            </div>

                            <div className="form-row form-group">
                                {this.getInputField(
                                    'color',
                                    'Color (Optional)',
                                    vehicle.color,
                                    isTrimSelected || vehicle.color !== '',
                                    'color-field'
                                )}
                            </div>
                        </article>
                    </section>
                </div>
                <ActionFooterBar {...barProps} />
            </div>
        );
    }
}

export default VehicleInfoUI;
