import { TypographyVariant } from '@interstate/components/Typography';

export const ACCESSORIES = 'Accessories';
export const ACQUISITION_FEE_ABBR = 'Acq Fee';
export const ACQUISITION_FEE = 'Acquisition Fee';
export const ACQUISITION_FEE_OPTION_LABEL = 'Upfront?';
export const ACQUISITION_FEE_OPTION_LABEL_INTERSTATE = 'Upfront';
export const ADJUSTED_CAP_COST = 'Adjusted Cap Cost';
export const ADD_ONS = 'Add-ons';
export const ADJUSTED_RESIDUAL_ID = 'adj-residual-id';
export const ADJUSTED_RESIDUAL_LABEL = 'Adj Residual';
export const ADJUSTED_RESIDUAL_VALUE = 'Adj Residual Value';
export const ADJUSTED_RESIDUAL_GROUPID = 'base-residual-group';
export const ADJUSTED_RESIDUAL_VALUE_TESTID = 'adj-residual-value';
export const ADJUSTED_RESIDUAL_GROUP_TESTID = 'adj-residual-group';
export const ADJUSTED_RESIDUAL_DOLLAR_TESTID = 'adj-residual-dollar';
export const ADJUSTED_RESIDUAL_PERCENT_TESTID = 'adj-residual-percent';
export const ADDS_TO_CAP_COST = 'Adds to Cap Cost';
export const ADVANCE_FLAT_TAX = 'Advanced Flat Tax';
export const AMOUNT_FINANCED = 'Amount Financed';
export const APR = 'APR';
export const ALLOWANCE = 'Allowance';
export const BASE_RESIDUAL_ID = 'base-residual-id';
export const BASE_RESIDUAL_LABEL = 'Base Residual';
export const BASE_RESIDUAL_PERCENT = 'Base Residual %';
export const BASE_RESIDUAL_DOLLAR = 'Base Residual $';
export const BASE_RESIDUAL_VALUE = 'Base Residual Value';
export const BASE_RESIDUAL_GROUPID = 'base-residual-group';
export const BASE_RESIDUAL_VALUE_TESTID = 'base-residual-value';
export const BASE_RESIDUAL_GROUP_TESTID = 'base-residual-group';
export const BASE_RESIDUAL_DOLLAR_TESTID = 'base-residual-dollar';
export const BASE_RESIDUAL_PERCENT_TESTID = 'base-residual-percent';
export const BASE_MO_PAYMENT = 'Base Mo. Payment';
export const BUY_RATE = 'Buy Rate';
export const CAP_COST_REDUCTION = 'Cap Cost Reduction';
export const CAP_COST_REDUCTION_TAX = 'Cap Reduction Tax';
export const CAP_COST = 'Cap Cost';
export const CAPITALIZED_TAXES = 'Capitalized Taxes';
export const CASH = 'Cash';
export const CASH_DOWN = 'Cash Down';
export const CASH_FROM_CUSTOMER = 'Cash from Customer';
export const DUE_AT_SIGNING = 'Due at Signing';
export const DEAL_REVIEW_AND_SUBMISSION_DIALOG_HEADER = 'Go To Deal Review & Submission Screen?';
export const DEAL_REVIEW_AND_SUBMISSION_DIALOG_PROMPT =
    'Clicking "Yes" will send you to the Deal Review & Submission Screen. Are you sure you want to continue?';
export const CASH_PURCHASE_PRICE = 'Cash Purchase Price';
export const CASH_TERMS = 'Cash Terms';
export const CUSTOMER_DETAILS = 'Customer Details';
export const CUSTOM_MILEAGE_LABEL = 'Miles Per Year';
export const CUSTOM_MILEAGE_TESTID = 'Custom Miles Per Year';
export const CUSTOM_MILEAGE_ERROR = 'Enter a value in range 1-';
export const CUSTOM_MILEAGE_MAX_LENGTH = 1e5 - 1;
export const CREDIT_DECISION_LENDER_NAME = 'Lender';
export const CREDIT_DECISION_LENDER_NAME_FIELD = 'CreditDecisionLenderNameField';
export const CREDIT_DECISION_STATUS = 'Lender Decision Status';
export const CREDIT_DECISION_TOGGLE = 'Official Credit Decision';
export const CREDIT_SCORE = 'Credit Score';
export const DEALER = 'Dealer';
export const DEALER_CASH = 'Dealer Cash';
export const DEALER_FEES = 'Dealer Fees';
export const DEBOUNCE_TIMER = 2000;
export const DEPRECIATION = 'Total Depreciation';
export const DISCOUNT = 'Discount';
export const DMV = 'DMV';
export const DOC_FEE = 'Doc Fee';
export const DOC_FEES = 'Doc Fees';
export const DRAWER_DURATION = 400;
export const OUT_OF_POCKET = 'Out of Pocket';
export const EASY_CARE_PRO_5_100 = 'Easy Care Pro 5/100';
export const EFFECTIVE_RESIDUAL_PERCENT = 'Effective Residual %';
export const EST_PAYMENT = 'Est. Payment';
export const EXCESS_MILEAGE_CHARGE = '$/Mi';
export const EXCESS_MILEAGE_PLACEHOLDER = '20';
export const EXISTING_MILEAGE = 'Existing Mileage';
export const EXISTING_MILEAGE_PERCENT = 'Existing Mileage %';
export const EXPECTED_ANNUAL_MILES = 'Exp Miles';
export const EXPECTED_ANNUAL_MILES_PLACEHOLDER = '20,000';
export const FEES = 'Fees';
export const FINANCE_REDUCTIONS = 'Finance Reductions';
export const FINANCE_TERM = 'Finance Term';
export const FINANCING_TERMS = 'Financing Terms';
export const FIRST_PAYMENT_ABBR = '1st Payment';
export const GAP_INSURANCE = 'Gap Insurance';
export const GENERIC_LAST_FIELD_CHANGED_ERROR = 'Adjustment did not return a valid payment.';
export const GOV_FEES = 'Gov. Fees';
export const GOVERNMENT_FEES = 'Government Fees';
export const GROSS_CAP_COST = 'Gross Cap Cost';
export const HEADER_HEIGHT = 96;
export const INCENTIVES_TOTAL_OVERRIDE = 'Total Incentives';
export const KS_PLATES = 'KS Plates';
export const KS_TRANSFER = 'KS Transfer';
export const LEASE = 'Lease';
export const LEASE_TERM = 'Lease Term';
export const LEASING_TERMS = 'Leasing Terms';
export const LENDER_FEES = 'Lender Fees';
export const LENGTH_OF_LOAN = 'Length of Loan';
export const LX_PACKAGE_CASH_REBATE = 'LX Package Cash Rebate';
export const MAX_RESIDUALIZED_MSRP = 'MRM';
export const MAX_RESIDUALIZED_MSRP_APPLIED = 'MRM Applied?';
export const MAX_RESIDUALIZED_MSRP_APPLIED_LABEL = 'Included?';
export const MAX_RESIDUALIZED_MSRP_APPLIED_LABEL_INTERSTATE = 'Included';
export const MEMORIAL_DAY_BONUS_CASH = 'Memorial Day Bonus Cash';
export const NO_VALID_PAYMENT_EXIST = 'No valid payments available. Adjust terms or try again later.';
export const MILES_LABEL = 'Miles';
export const MILES_PER_YEAR = 'Miles Per Year';
export const MILES_PER_YEAR_PERCENT = 'Miles Per Year %';
export const MILITARY_REBATE = 'Military Rebate';
export const MONEY_FACTOR = 'MF';
export const MONEY_FACTOR_TYPE = 'MoneyFactor';
export const MONTHLY_PAYMENT = 'Monthly Payment';
export const MO_PAYMENT = 'Mo. Payment';
export const MO_USE_TAX = 'Mo. Use Tax';
export const MO_USE_TAX_NAME = 'mut';
export const MO_USE_TAXES = 'Mo. Use Taxes';
export const MSRP = 'MSRP';
export const NEGATIVE_TRADE_EQUITY = 'Negative Trade Equity';
export const NET_SELLING_PRICE = 'Net Selling Price';
export const NET_TRADE = 'Net Trade';
export const NOT_AVAILABLE = 'N/A';
export const NOT_AVAILABLE_DASH = '--';
export const PAYOFF = 'Payoff';
export const PROFIT_DETAILS = 'Profit Details';
export const PROTECTION = 'Protection';
export const PROTECTION_PRODUCTS = 'Protection Products';
export const PAYMENT_FREQUENCY = 'Payment Frequency';
export const PAYMENT_GRID = 'Payment Grid';
export const PRICE = 'Price';
export const RADIO_BUTTON_LIST = 'radio-button-list';
export const RADIO_BUTTON_LIST_LABEL = 'Type';
export const RATE = 'Sell Rate';
export const RATE_TYPE = 'Rate Type';
export const RATING_DISPLAY_NAME = 'Rating';
export const RATING_INPUT = 'rating-input';
export const REBATES = 'Rebates';
export const REDUCTIONS = 'Reductions';
export const RENT_CHARGED = 'Mo. Rent Charged';
export const RETAIL_PRICE = 'Retail Price';
export const RUNNING_BOARDS = 'Running Boards';
export const SAFEGUARD_THEFT_PROTECTION = 'Safeguard Theft Protection';
export const SECURITY_DEPOSIT = 'Security Deposit';
export const SELF_REPORTED_CREDIT_RATING = 'Self Reported Credit Rating';
export const SELF_REPORTED_CREDIT_RATING_FIELD = 'SelfReportedCreditRatingField';
export const TIER = 'Tier';
export const RATING = 'Rating';
export const SELL_RATE = 'Sell Rate';
export const SELLING_PRICE = 'Selling Price';
export const TARGET_OUT_OF_POCKET = 'Target Out of Pocket';
export const TAXES = 'Taxes';
export const TERM = 'Term';
export const TAX_CAP_COST_RED = 'Tax on Cap Cost Red.';
export const TEMP_TAG = 'Temp Tag';
export const TOTAL_ADD_ONS = 'Total Add-ons';
export const TOTAL_AMOUNT_DUE = 'Tot. Amt. Due';
export const TOTAL_AMOUNT_DUE_FINANCE = 'Amt. Financed';
export const TOTAL_BASE_MO_PAYMENTS = 'Tot. Mo. Payments';
export const TOTAL_CAP_REDUCTION = 'Total Cap Reduction';
export const TOTAL_CONDITIONAL_INCENTIVES = 'Total Conditional Incentives';
export const TOTAL_DUE_AT_SIGNING = 'Tot. Due at Signing';
export const TOTAL_FEES = 'Total Fees';
export const TOTAL_FINANCED = 'Total Financed';
export const TOTAL_INTEREST = 'Total Interest';
export const TOTAL_MANUFACTURER_INCENTIVES = 'Total Manufacturer Incentives';
export const TOTAL_PROTECTION = 'Total Protection';
export const TOTAL_RESIDUAL = 'Total Residual';
export const TOTAL_TAX = 'Total Tax';
export const TOTAL_TAXES = 'Total Taxes';
export const TRADE_ALLOWANCE = 'Trade Allowance';
export const TRADE_ACV = 'Trade ACV';
export const TRADE_IN = 'Trade-in';
export const TRADE_IN_MAKE = 'Make';
export const TRADE_IN_MODEL = 'Model';
export const TRADE_IN_TRIM = 'Trim';
export const TRADE_IN_EXTERIOR_COLOR = 'Exterior Color';
export const TRADE_IN_CONDITION = 'Condition';
export const TRADE_IN_YEAR = 'Year';
export const TRADE_OFF = 'Trade Payoff';
export const UPFRONT_ADDONS = 'Upfront Add-ons';
export const UPFRONTS_CAPITALIZED = 'Upfronts Capitalized';
export const UPFRONT_FEES = 'Upfront Fees';
export const UPFRONT_TAXES = 'Upfront Taxes';
export const WINDOW_TINT = 'Window Tint';
export const ZERO_DOLLARS = '$0.00';
export const PRIMARY_TAXES = 'Primary Taxes';
export const ADDL_TAXES = 'Additional Taxes';
export const SHARE_URL_OPTION_LABEL = 'Share URL';
export const SHARE_URL_INPUT_LABEL = 'Share URL';
export const COPY_URL_BUTTON_TEXT = 'Copy';
export const COPY_AND_CONTINUE_BUTTON_TEXT = 'Copy and Continue';
export const SHARE_MESSAGE_PLACEHOLDER = 'Include a message about this offer';
export const SHOPPER_EMAIL_INPUT_LABEL = 'Shopper Email Address';
export const DEALER_SUPPROT_REQUEST_FORM = 'https://www.dealer.com/support/requestform/';
export const SALE = 'Sale';
export const APPROVED = 'Approved';
export const SOLD = 'Sold';
export const CONTRACT_SIGNED = 'Contract Signed';
export const DELIVERED = 'Delivered';
export const MARKUP = 'Markup';
export const STOCK_NUMBER_USED_ERROR_MSG = 'Stock# already in use. Please enter another.';
export const STOCK_NUMBER_DEFAULT_ERROR_MSG = 'Something went wrong. Please try again later.';
export const PAYMENT = 'Payment';
export const TOTAL_BASE_PAYMENT = 'Total Base Payment';
export const PAYMENT_DEPRECIATION = 'Depreciation';
export const PAYMENT_RENT_CHARGE = 'Rent Charge';
export const TOTAL_ADJUSTED_RESIDUAL = 'Adjusted Residual';
export const ADJUSTED_RESIDUAL_PERCENT = 'Adjusted Residual %';
export const ADJUSTED_RESIDUAL_DOLLAR = 'Adjusted Residual $';
export const RESIDUALIZED_ACCESSORIES = 'Residualized Accessories';
export const FIRST_ST_MONTHLY_PAYMENT = '1st Monthly Payment';
export const REGISTRATION_FEE = 'Registration Fee';
export const DUE_AT_SIGNING_PAID_BY = 'Due at Signing Paid By';
export const NET_TRADE_IN_ALLOWANCE = 'Net Trade-in Allowance';
export const REBATES_AND_NONCASH_CREDITS = 'Rebates and Noncash Credits';
export const AMOUNT_PAID_BY_CUSTOMER = 'Amount Paid by Customer';
export const ADDITIONAL_CAPITALIZED_COST = 'Additional Capitalized Cost';
export const AMOUNT_PAID_BY_DEALER = 'Amount Paid by Dealer';
export const INSUFFICIENT_FUNDS = 'Insufficient Funds (INSF)';
export const ADJUSTED_SELLING_PRICE = 'Adjusted Selling Price';
export const CRT_TAX = 'crt';
export const MUT_TAX = 'mut';

export const SKEW_DELIMETER = '-';
export const SUMMARY_ACCORDION_ID = 'deal-summary-item-accordion';
export const DEFAULT_EXPANDABLE_ID = 'main';
export const DEFAULT_EXPANDABLE_STATUS = false;

export enum Action {
    CONTINUE_IN_DESKING,
    SEND_TO_ECOMMERCE
}

export const UPDATED_DEAL_BANNER_LABELS = {
    creditApplication: 'Credit App',
    documentUpload: 'Document Upload',
    incentives: 'Incentives',
    testDrive: 'Test Drive',
    tradeIn: 'Trade',
    vehicleProtectionProducts: 'Vehicle Protection',
    vehicleReservation: 'Reservations'
} as Record<string, string>;

// Viewport modes
export const MOBILE = 'mobile';
export const TABLET = 'tablet';
export const DESKTOP = 'desktop';

export const MAX_ACTIVITY_CONTAINER_WIDTH_OLD = '1360px';
export const MAX_ACTIVITY_CONTAINER_WIDTH_NEW = '1900px';
export const SHARE_TO_SALES_VIEW_HEADER = 'Share to Sales View';
export const CUSTOMER_RANGE_TITLE = 'Customer Range';
export const TRADE_IN_RANGE_TITLE = 'Trade-In Range';

export const WHOLESALE_BOOK_SOURCE_OPTIONS = [
    { label: 'ALG', value: 'ALG' },
    { label: 'ALG Enhanced', value: 'ALGE' },
    { label: 'Black Book', value: 'BB' },
    { label: 'CBB', value: 'CBB' },
    { label: 'Chrome Canada', value: 'CHRCA' },
    { label: 'Chrome USA', value: 'CHRUS' },
    { label: 'Galves', value: 'GAL' },
    { label: 'KBB', value: 'KBB' },
    { label: 'MMR', value: 'MMR' },
    { label: 'NAAA', value: 'NAAA' },
    { label: 'JD Power', value: 'NADA' },
    { label: 'Polk', value: 'POLK' },
    { label: 'Galves market', value: 'GALVES' },
    { label: 'Other', value: 'OTHER' }
];

export const FIRST_PAYMENT_DAY = 'First Payment Date';
export const DAYS_TO_FIRST_PAYMENT = 'Days to First Payment';

export const VERSION_CREATED_BY_TO_APP_DISPLAY_NAME = {
    DarwinFI: 'Darwin'
} as Record<string, string>;

export const INPUT_INVALID_ERROR_MESSAGE = 'Invalid';
