export enum TRADEIN_SOURCE_TYPES {
    ico = 'ICO',
    kbb = 'KBB',
    manual = 'Manual Entry',
    subarugtp = 'SUBARUGTP'
}

export enum SUMMARY_CARD_TEXTS {
    appliedValue = 'Applied Value',
    estValue = 'Est. Value',
    valuePendingAppraisal = 'Pending Appraisal',
    seeDealer = 'See Dealer'
}

export enum VEHICLE_DETAILS_LABELS {
    condition = 'Condition'
}
