// externals
import * as React from 'react';

// libraries
import { Select, Input } from '@makemydeal/ui-bricks/dist/legacy';
import { ITradeInVehicle } from '@makemydeal/dr-activities-common';
import { SpinnerUI, ActionFooterBar } from '@makemydeal/dr-activities-common';
import { IconFactory } from '@makemydeal/dr-activities-common';

// components
import Header from '../header/Header';

// config
import config from '../../../store/mmd/config';

// utils
import { normalizeZip, normalizeMileageValues, validateForMileageValues, validateZip } from '../../../utils/validation';
import * as optimizelyUtils from '../../../common/optimizelyWrapper';

export interface IVehicleInfoUIStateProps {
    vehicle: ITradeInVehicle;
    years: any[];
    makes: any[];
    models: any[];
    trims: any[];
    vehicleOptions: object;
    isYearEnabled: boolean;
    isYearLoading: boolean;
    isMakeEnabled: boolean;
    isMakeLoading: boolean;
    isModelEnabled: boolean;
    isModelLoading: boolean;
    isTrimEnabled: boolean;
    isTrimLoading: boolean;
    isTrimSelected: boolean;
    isOwnershipEnabled: boolean;
    isMileageEnabled: boolean;
    isZipCodeEnabled: boolean;
    areOptionalFieldsEnabled: boolean;
    ownership: any;
    zip: string;
    isTradeInValid: boolean;
    pageTitle: string;
    headerSubtitle: string;
    savedVehicle?: ITradeInVehicle;
    isKbbFieldValidationEnabled?: boolean;
    formFieldVariation?: string;
}

export interface IVehicleInfoUIDispatchProps {
    onLoad: { (vehicle, years, makes, models, trims, zip): void };
    onValueChange: { (propName, domElement, vehicle: ITradeInVehicle, savedVehicle?: ITradeInVehicle): void };
    applyTradeIn: { () };
    cancelTradeIn: { (): void };
    fetchEquipmentOptions: {
        (vehicleOptions: object, vehicle: ITradeInVehicle, zip: string): void;
    };
    next: { () };
    onSkip?: { (): void };
}

export interface IVehicleInfoUIProps extends IVehicleInfoUIStateProps, IVehicleInfoUIDispatchProps {}

export interface IVehicleInfoUIState {
    continueWasClicked: boolean;
    validationErrorMessaqe: string;
}

export interface IGetInputFieldInput {
    type: string;
    maxlength?: number;
    inputType?: string;
}

class VehicleInfoUI extends React.Component<IVehicleInfoUIProps, IVehicleInfoUIState> {
    setTextInputRef: (elem, name) => void;

    constructor(props) {
        super(props);
        this.state = {
            continueWasClicked: false,
            validationErrorMessaqe: ''
        };

        this.setTextInputRef = (element, name) => {
            this[name] = element;
        };
    }

    componentDidMount() {
        const { vehicle, years, makes, models, trims, zip, isKbbFieldValidationEnabled } = this.props;
        this.props.onLoad(vehicle, years, makes, models, trims, zip);

        if (isKbbFieldValidationEnabled) {
            this.focusElementByRefName('year');
        }
    }

    focusElementByRefName(refName: string): void {
        const element = this[refName];
        if (element && !element.disabled) {
            element.focus();
        } else if (element && element.disabled) {
            setTimeout(() => this.focusElementByRefName(refName), 100);
        }
    }

    validationFunction = (fieldValue, validatorFunc, required) => {
        const isFilledIn = this.validateRequiredFields(fieldValue);
        const isInValid = !validatorFunc(fieldValue) && this.state.continueWasClicked;

        if ((isFilledIn || !required) && !isInValid) return true;
        else return false;
    };

    errorMessageFunction = (fieldValue, validatorFunc, name) => {
        const isFilledIn = this.validateRequiredFields(fieldValue);
        const isInValid = !validatorFunc(fieldValue) && this.state.continueWasClicked;

        if (!isFilledIn) return 'This is a required field';
        else if (isInValid) return `${name} is invalid`;
        else return '';
    };

    validateRequiredFields = (fieldValue) => {
        return (fieldValue === null || fieldValue === '') && this.state.continueWasClicked ? false : true;
    };

    handleSelectOnChange: React.ChangeEventHandler<HTMLSelectElement> = (event): void => {
        this.onChangeHandler(event.currentTarget.getAttribute('name'), event.target.options[event.target.selectedIndex]);
    };

    handleInputOnChange: React.ChangeEventHandler<HTMLInputElement> = (event): void => {
        this.onChangeHandler(event.currentTarget.getAttribute('name'), event.target);
    };

    getSelectField(name, options, optionsId, placeholder, selectedValue, enabled = false, _additionalClass = '') {
        const optionsItems = options.map((option, _index) => {
            const optionId = optionsId !== '' ? option[optionsId] : option;
            const optionName = option.hasOwnProperty('name') ? option.name : option;

            return (
                <option key={optionId} value={optionId}>
                    {optionName}
                </option>
            );
        });

        const isValid = this.validateRequiredFields(selectedValue);
        const showError = isValid ? 'hide' : '';
        const showRedOutline = isValid ? '' : 'field-not-filled-in';

        return (
            <>
                <Select
                    selectId={name}
                    name={name}
                    ref={(element) => this.setTextInputRef(element, name)}
                    disabled={!enabled}
                    value={selectedValue}
                    label={placeholder}
                    defaultValue=""
                    className={`select-field ui-bricks ${showRedOutline}`}
                    onChangeFunction={this.handleSelectOnChange}
                >
                    <option value="" disabled></option>
                    {optionsItems}
                </Select>
                <div className={`error-div ${showError}`}>
                    <IconFactory staticImages={config.staticImages} icon="errorIcon" className="error-icon" />
                    <span className="error-message">This is a required field</span>
                </div>
            </>
        );
    }

    getInputField(
        name,
        placeholder,
        value,
        enabled = false,
        additionalClass = '',
        input: IGetInputFieldInput = { type: 'text' },
        required = false,
        validatorFunc = (val) => true
    ) {
        const normalizer = {
            zipCode: normalizeZip,
            mileage: normalizeMileageValues
        };

        const isValid = this.validationFunction(value, validatorFunc, required);
        const showError = isValid ? 'hide' : '';
        const showRedOutline = isValid ? '' : 'field-not-filled-in';
        const errorMessage = this.errorMessageFunction(value, validatorFunc, name);

        return (
            <>
                <Input
                    {...input}
                    type="text"
                    inputId={name}
                    name={name}
                    ref={(element) => this.setTextInputRef(element, name)}
                    label={placeholder}
                    disabled={!enabled}
                    value={value}
                    className={`${additionalClass} ui-bricks ${showRedOutline}`}
                    onChangeFunction={this.handleInputOnChange}
                    formatValueFunction={normalizer[input.inputType]}
                />
                <div className={`error-div ${showError}`}>
                    <IconFactory staticImages={config.staticImages} icon="errorIcon" className="error-icon" />
                    <span className="error-message">{errorMessage}</span>
                </div>
            </>
        );
    }

    onChangeHandler(propName, domElement) {
        this.props.onValueChange(propName, domElement, this.props.vehicle);
        const { isKbbFieldValidationEnabled } = this.props;

        if (isKbbFieldValidationEnabled) {
            switch (propName) {
                case 'year': {
                    this.focusElementByRefName('make');
                    break;
                }
                case 'make': {
                    this.focusElementByRefName('model');
                    break;
                }
                case 'model': {
                    this.focusElementByRefName('trim');
                    break;
                }
                case 'trim': {
                    this.focusElementByRefName('ownership');
                    break;
                }
                case 'ownership': {
                    this.focusElementByRefName('mileage');
                    break;
                }
                default:
                    break;
            }
        }
    }

    next = () => {
        optimizelyUtils.track('tradeStepOneContinued');
        if (!this.props.isTradeInValid) {
            this.setState({ continueWasClicked: true });
            return;
        }
        const { vehicle, vehicleOptions, zip } = this.props;
        this.props.fetchEquipmentOptions(vehicleOptions, vehicle, zip);
        this.props.applyTradeIn();
        this.props.next();
    };

    skip = () => {
        const { onSkip } = this.props;
        onSkip();
    };

    previous = () => {
        this.props.cancelTradeIn();
    };

    render() {
        const {
            vehicle,
            zip,
            years,
            isYearEnabled,
            isYearLoading,
            makes,
            isMakeEnabled,
            isMakeLoading,
            models,
            isModelEnabled,
            isModelLoading,
            trims,
            isTrimEnabled,
            isTrimLoading,
            isOwnershipEnabled,
            isMileageEnabled,
            isZipCodeEnabled,
            areOptionalFieldsEnabled,
            ownership,
            isTradeInValid,
            pageTitle,
            headerSubtitle
        } = this.props;

        const barProps = {
            onActionButtonClick: this.next,
            onBackButtonClick: this.skip,
            showBackButton: true,
            ignoreRouterHistory: true,
            buttonText: 'Continue',
            backButtonText: "I'm not interested in trading in a vehicle",
            hideBackIcon: true,
            disableActionButton: !isTradeInValid
        };

        return (
            <div id="dr-trade-vehicle-info" className="dr-ui-trade-vehicle-info-single-column">
                <div className="dr-ui-trade-vehicle-info__wrap">
                    <Header pageTitle={pageTitle} headerSubTitle={headerSubtitle} showTradeErrorMessage={false} />

                    <section>
                        <article className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-row form-group">
                                {this.getSelectField('year', years, '', 'Year', vehicle.year, isYearEnabled)}
                                {isYearLoading && <SpinnerUI size="25" />}
                            </div>
                            <div className="form-row form-group">
                                {this.getSelectField('make', makes, 'id', 'Make', vehicle.make.id, isMakeEnabled)}
                                {isMakeLoading && <SpinnerUI size="25" />}
                            </div>
                            <div className="form-row form-group">
                                {this.getSelectField('model', models, 'id', 'Model', vehicle.model.id, isModelEnabled)}
                                {isModelLoading && <SpinnerUI size="25" />}
                            </div>

                            <div className="form-row form-group">
                                {this.getSelectField('trim', trims, 'id', 'Trim', vehicle.trim.id, isTrimEnabled)}
                                {isTrimLoading && <SpinnerUI size="25" />}
                            </div>
                        </article>

                        <article className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <div className="form-row form-group">
                                {this.getSelectField(
                                    'ownership',
                                    ['Purchase', 'Lease'],
                                    '',
                                    'Ownership',
                                    ownership,
                                    isOwnershipEnabled
                                )}
                            </div>

                            <div className="form-row form-group">
                                {this.getInputField(
                                    'mileage',
                                    'Mileage',
                                    vehicle.mileage,
                                    isMileageEnabled,
                                    'mileage-field',
                                    { type: 'number', maxlength: 6, inputType: 'mileage' },
                                    true,
                                    validateForMileageValues
                                )}
                            </div>

                            <div className="form-row form-group">
                                {this.getInputField(
                                    'zip',
                                    'Zip',
                                    zip,
                                    isZipCodeEnabled,
                                    'zip-field',
                                    { type: 'number', maxlength: 5, inputType: 'zipCode' },
                                    true,
                                    validateZip
                                )}
                            </div>

                            <div className="optionalData">
                                <span className="additionalInfo">Do you want to include this additional info?</span>

                                <div className="form-row form-group">
                                    {this.getInputField(
                                        'vin',
                                        'VIN (Optional)',
                                        vehicle.vin,
                                        areOptionalFieldsEnabled,
                                        'vin-field',
                                        { type: 'text', maxlength: 17 }
                                    )}
                                </div>

                                <div className="form-row form-group">
                                    {this.getInputField(
                                        'color',
                                        'Color (Optional)',
                                        vehicle.color,
                                        areOptionalFieldsEnabled,
                                        'color-field'
                                    )}
                                </div>
                            </div>
                        </article>
                    </section>
                </div>
                <ActionFooterBar {...barProps} />
            </div>
        );
    }
}

export default VehicleInfoUI;
