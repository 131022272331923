import { Alert } from '@interstate/components/Alert';
import { Box } from '@interstate/components/Box';
import { deskingSelectors, offerActionTypes } from '@makemydeal/dr-dash-store';
import { useSelector } from 'react-redux';

export const TaxCardAlerts = () => {
    const shouldShowTotalTaxRateSupersedeAlert = useSelector(deskingSelectors.shouldShowTotalTaxRateSupersedeAlert);
    const failedTotalTaxUpdate = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATED_TOTAL_TAX_OVERRIDE));
    const failedItemizedTaxesUpdate = useSelector(deskingSelectors.wasTheLastFailure(offerActionTypes.UPDATE_TAX_ITEMS));

    return (
        <Box marginBottom={2}>
            {shouldShowTotalTaxRateSupersedeAlert && (
                <Alert data-testid={'total-tax-rate-supersede-alert'} role="total-tax-rate-supersede-alert" type={'error'}>
                    <span data-testid="total-tax-rate-supersede-alert-message">Rates can not supersede 90%.</span>
                </Alert>
            )}
            {(failedTotalTaxUpdate || failedItemizedTaxesUpdate) && (
                <Alert data-testid={'total-tax-rate-payment-alert'} role="total-tax-rate-payment-alert" type={'error'}>
                    <span data-testid="total-tax-rate-payment-alert-message">Service Unavailable. Please try again.</span>
                </Alert>
            )}
        </Box>
    );
};
