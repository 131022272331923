import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { offerReduxSelectors } from '@makemydeal/dr-dash-store';
import { CASH, FINANCE, LEASE } from '@makemydeal/dr-platform-types';

import { DealSummaryContainer } from './DealSummary.interstate.style';
import { ExpandableAreaProvider } from '@makemydeal/dr-dash-components';

import Cash from './CashDealSummary.interstate';
import Lease from './LeaseDealSummary.interstate';
import Finance from './FinanceDealSummary.interstate';

const DealSummary = () => {
    const currentOfferType = useSelector(offerReduxSelectors.getCurrentOfferType);

    const offerComponent = useMemo(() => {
        switch (currentOfferType) {
            case CASH:
                return <Cash />;
            case LEASE:
                return <Lease />;
            case FINANCE:
                return <Finance />;
        }
    }, [currentOfferType]);

    return (
        <ExpandableAreaProvider>
            <DealSummaryContainer data-testid="deal-summary-container" xs={3.9} sx={{ padding: 0 }} width="100%">
                {offerComponent}
            </DealSummaryContainer>
        </ExpandableAreaProvider>
    );
};

export default DealSummary;
