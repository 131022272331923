import styled from 'styled-components';

import { Typography } from '@interstate/components/Typography';
import { AccordionDetails } from '@interstate/components/AccordionGroup';
import { TableCell } from '@interstate/components/SimpleTable';

export const StyledTableCell = styled.div<{ $textAlign?: string; $minWidth?: string; $marginTop?: string }>`
    min-width: ${(props) => props.$minWidth || '3.125rem'};
    text-align: ${(props) => props.$textAlign || 'left'};
    margin-top: ${(props) => props.$marginTop || '0.3rem'};
`;

export const StyledTableCellWithoutBorder = styled(TableCell)<{ $width?: string }>`
    align-content: start;
    border-bottom-style: unset !important;
    padding: 0 0.5rem !important;
    width: ${(props) => props.$width || 'auto'};
`;

export const StyledSpan = styled.span<{ paddingLeft: number }>`
    padding-left: ${(props) => props.paddingLeft}rem;
`;

export const TypoRightAligned = styled(Typography)`
    text-align: right;
`;

export const TaxDetailsContainer = styled(AccordionDetails)`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

export const TaxOverrideContainer = styled.div`
    margin-top: auto;
`;
