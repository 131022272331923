import { optimizelyUtils } from '@makemydeal/dr-browser-utils';
import { getFeatureToggleState } from '../store/featureToggles';
import { getDealerState } from '../store/mmd/dealerSelectors';

const spEnv = process.env.ENVIRONMENT || 'local';
const isProdEnv = ['prod', 'production'].indexOf(spEnv) !== -1;
export const load = (store, isProd: boolean = isProdEnv) => {
    const state = store.getState();
    const toggles = getFeatureToggleState(state);
    const logLevel = toggles.enableOptimizelyLogs ? optimizelyUtils.LOG_LEVEL.DEBUG : undefined;
    return optimizelyUtils.load(getDealerState(state), logLevel, isProd);
};

export const track = optimizelyUtils.track;

const optimizelyTestMap = {};
optimizelyUtils.getTestSuites().forEach((test) => {
    const testVariations = test.testVariations.map((item) => {
        return item.name;
    });

    optimizelyTestMap[test.testName.toLowerCase()] = testVariations;
});

export const getTestValue = (test, state) => {
    const toggles = getFeatureToggleState(state);
    if (toggles.disableOptimizelyTests || toggles.disableoptimizelytests) return '';

    const testVariation = optimizelyUtils.getTestValue(test);

    const lowerCaseTest = test.toLowerCase();
    if (optimizelyTestMap[lowerCaseTest]) {
        const overrideVariation = optimizelyTestMap[lowerCaseTest].find((variation) => {
            return toggles[`optimizely${test}${variation}`.toLowerCase()] || toggles[`optimizely${test}${variation}`];
        });
        if (overrideVariation) return overrideVariation;
    }

    return testVariation;
};

type TestType = { experiments: string[] };
export const activateTests = (tests: TestType[]) => {
    const allExperiments = tests.reduce((acc, test) => [...acc, ...test.experiments], []);
    optimizelyUtils.activateExperiments(allExperiments);
};
