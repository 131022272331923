// externals
import * as React from 'react';

// libraries
import { IVehicleOption } from '@makemydeal/dr-activities-common';

export interface IKBBTradeInAdvisorProps {
    apiKey: string;
    vehicleId: string;
    vehicleOptions: Record<string, IVehicleOption>;
    zipCode: string;
    vehicleMileage: number;
    vehicleCondition: string;
    showExactValue?: boolean;
}

const createOptionIds = (vehicleOptions: Record<string, IVehicleOption>): string => {
    const ids = [];
    Object.keys(vehicleOptions).forEach((optionName) => {
        vehicleOptions[optionName].options.forEach((option) => {
            if (option.isSelected) {
                ids.push(option.id);
            }
        });
    });

    return ids.join(',');
};

const removeBlankSpaces = (condition: any): string => {
    return condition.replace(/ /g, '');
};

export const KBBTradeInAdvisor = (props: IKBBTradeInAdvisorProps) => {
    const { apiKey, vehicleId, vehicleOptions, vehicleMileage, vehicleCondition, zipCode, showExactValue = false } = props;

    const src =
        '//pauc.syndication.kbb.com/priceadvisorusedcar/tradein' +
        `?APIKey=${apiKey}` +
        `&VehicleId=${vehicleId}` +
        `&OptionIds=${createOptionIds(vehicleOptions)}` +
        `&ZipCode=${zipCode}` +
        `&Mileage=${vehicleMileage}` +
        `&Condition=${removeBlankSpaces(vehicleCondition)}` +
        `&DisplayTI=${showExactValue}`;

    return <iframe className="kbb-trade-in-advisor" frameBorder="0" scrolling="no" src={src}></iframe>;
};
