import { Spinner } from '@interstate/components/Spinner';
import { Typography } from '@interstate/components/Typography';
import { offerInfoSelectors } from '@makemydeal/dr-dash-store';
import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { OfferSaveBusyIndicatorContainer } from './OfferSaveBusyIndicator.style';

export type OfferSaveBusyIndicatorProps = PropsWithChildren<{
    /**
     * The delay in milliseconds before showing the loading indicator. (Default: 1000)
     */
    delayIndicatorMs?: number;
}>;

export const OfferSaveBusyIndicator: FC<OfferSaveBusyIndicatorProps> = ({ children, delayIndicatorMs = 1000 }) => {
    const [status, setStatus] = useState<'ready' | 'loading' | 'failed'>('loading'); // init with loading
    const [showIndicator, setShowIndicator] = useState(false);
    const offerIsBeingSaved = useSelector(offerInfoSelectors.getIsOfferBeingSaved);
    const offerHasBeenSaved = useSelector(offerInfoSelectors.getHasBeenSaved);

    // Set mode when offer save status changes
    useEffect(() => {
        if (offerIsBeingSaved === true) return setStatus((_old) => 'loading');
        if (offerIsBeingSaved === false && offerHasBeenSaved === true) return setStatus((_old) => 'ready');
        if (offerIsBeingSaved === false && offerHasBeenSaved === false) return setStatus((_old) => 'failed');
    }, [offerIsBeingSaved, offerHasBeenSaved]);

    useEffect(() => {
        if (status !== 'loading') {
            setShowIndicator((_old) => false);
            return;
        }

        const id = setTimeout(() => {
            setShowIndicator((_old) => true);
        }, delayIndicatorMs);

        return () => {
            clearTimeout(id);
        };
    }, [delayIndicatorMs, status]);

    // NOTE: renders nothing while loading < 1 second
    if (status === 'loading') {
        return (
            <>
                {showIndicator && (
                    <OfferSaveBusyIndicatorContainer>
                        <Spinner />
                        <Typography variant="h4">Saving offer and getting links...</Typography>
                    </OfferSaveBusyIndicatorContainer>
                )}
            </>
        );
    }

    // if (status === 'failed') return <></> // NOTE: if we decide to handle errors here. For now, we'll show the customer card to allow messaging regardless.

    return <>{children}</>;
};
