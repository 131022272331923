import { Box } from '@interstate/components/Box';
import { BreakPoint } from '@makemydeal/dr-activities-common';
import styled from 'styled-components';

export const StyledPriceInputContainer = styled(Box)`
    @media (min-width: ${BreakPoint.PHONE}) {
        margin-right: 0.5rem;
    }
`;

export const StyledTaxesContainer = styled(Box)`
    border-top: ${({ theme }) => theme.tokens.BaseSizeBorderPx} solid ${({ theme }) => theme.tokens.SemColorBorderMuted};
    padding: 0.5rem 0.3rem 0.3rem 0.3rem;
`;
