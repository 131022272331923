import * as React from 'react';
import { attachExtTradeInListener, detachExtTradeInListener } from './externalTradeInListener';
// TODO: import/create listeners
export interface IIcoValuationUIStateProps {
    instantCashOfferUrlProvider: string;
    icoAction?: string;
}

export interface IIcoValuationUIDispatchProps {
    onCompletedIcoTradeIn: { () };
    onIcoConfirmMessage: { () };
    dispatchCallback: { () };
}

export interface IIcoValuationUIProps extends IIcoValuationUIStateProps, IIcoValuationUIDispatchProps {}

export interface IIcoValuationUIState {}

class IcoValuationUI extends React.Component<IIcoValuationUIProps, IIcoValuationUIState> {
    static defaultProps = {
        icoAction: ''
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        attachExtTradeInListener(this.props.dispatchCallback, this.props.instantCashOfferUrlProvider);
    }

    shouldComponentUpdate(nextProps) {
        return this.props.icoAction !== nextProps.icoAction;
    }

    componentDidUpdate() {
        const { icoAction } = this.props;
        if (icoAction) {
            this.props.onIcoConfirmMessage();
        }
    }

    componentWillUnmount() {
        const { onCompletedIcoTradeIn } = this.props;
        onCompletedIcoTradeIn();
        detachExtTradeInListener();
    }

    render() {
        const { instantCashOfferUrlProvider } = this.props;
        return (
            <div id="dr-trade-ico-valuation">
                <iframe
                    id="dr-trade-ico-iframe"
                    width="100%"
                    height="1250"
                    src={instantCashOfferUrlProvider}
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        );
    }
}

export default IcoValuationUI;
