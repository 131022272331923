import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';
import type { DealState } from '@makemydeal/dr-dash-types';
import { offerReduxSelectors } from '@makemydeal/dr-dash-store';

import { TOTAL_AMOUNT_DUE } from '../../constants';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';
import DealSummaryItem from '../common/DealSummaryItem';

export type TotalAmountDueProps = DealState & {
    showLargeValue?: boolean;
    hasDelta?: boolean;
};

const TotalAmountDue = ({ deal, showLargeValue, hasDelta = false }: TotalAmountDueProps) => {
    const useDealRootSelector = createDealRootSelector(deal);
    const totalAmountDueAfterCredits = useDealRootSelector(offerReduxSelectors.getCashTotalAmountDueAfterCredits);

    return (
        <DealSummaryItem
            label={TOTAL_AMOUNT_DUE}
            value={formatDollarsAndCents(totalAmountDueAfterCredits)}
            showLargeValue={showLargeValue}
            hasDelta={hasDelta}
        />
    );
};

export default TotalAmountDue;
