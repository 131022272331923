// external
import { Fragment, SyntheticEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// components
import { Button } from '@interstate/components/Button';
import { featureToggleSelectors } from '@makemydeal/dr-shared-store';
import { ChangeVehicleConfirmModal } from './ChangeVehicleConfirmModal';

// selectors
import { changeVehicleCreators, changeVehicleSelectors, middlewareMgr } from '../../store';

// actions
import { Grid } from '@interstate/components/Grid';

import { useValidateVehicle } from '../../utils';

export type ChangeVehicleFooterInterstateProps = {
    applyMessage?: string;
    enableConfirmModal?: boolean;
};

export const ChangeVehicleFooterInterstate: React.FC<ChangeVehicleFooterInterstateProps> = ({
    enableConfirmModal = true,
    ...otherProps
}) => {
    const isSellAnyVehicleEnabled = useSelector(featureToggleSelectors.isSellAnyVehicleEnabled);

    const defaults = { applyMessage: 'Change' };

    const { applyMessage = defaults.applyMessage } = otherProps;

    const dispatch = useDispatch();

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const selectedVehicle = useSelector(changeVehicleSelectors.getSelectedVehicleWithCustomData);
    const isChangeProcessing = useSelector(changeVehicleSelectors.isChangeProcessing);

    const handleCancelClick = () => {
        dispatch(changeVehicleCreators.vehicleChangeCancel());
        dispatch(middlewareMgr.hostActions.navigateToDashboard());
    };

    const handleChangeClick = (e: SyntheticEvent) => {
        e.stopPropagation();
        if (enableConfirmModal && isSellAnyVehicleEnabled) {
            setShowConfirmModal(true);
        } else {
            changeVehicle();
        }
    };

    const changeVehicle = useCallback(() => {
        // NOTE: prepare state before switching the vehicle
        dispatch(changeVehicleCreators.changeVehicleClicked(selectedVehicle));
        // NOTE: switch the vehicle, make payment request
        dispatch(middlewareMgr.hostActions.switchVehicle(selectedVehicle));
    }, [dispatch, selectedVehicle]);

    const handleChangeProceed = useCallback(() => {
        setShowConfirmModal(false);
        changeVehicle();
    }, [changeVehicle]);

    const handleChangeCancel = useCallback(() => {
        setShowConfirmModal(false);
    }, []);

    const isVehicleValid = useValidateVehicle(selectedVehicle);

    const shouldDisableChangeBtn = !isVehicleValid;

    return (
        <Fragment>
            <div className="change-vehicle-footer" data-testid="change-vehicle-footer">
                <div className="change-vehicle-footer-button-container">
                    <Grid
                        container
                        justifyContent="flex-end"
                        gap={1}
                        paddingX={4}
                        paddingY={2}
                        bgcolor="#FFF"
                        borderTop="1px solid #B2B2B2"
                        className="change-vehicle-footer-grid"
                    >
                        <Button
                            data-testid="btn-cancel-change-vehicle"
                            buttonStyle="tertiary"
                            onClick={handleCancelClick}
                            sx={{ marginRight: '16px' }}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            buttonStyle="primary"
                            data-testid="btn-change-vehicle"
                            onClick={handleChangeClick}
                            disabled={shouldDisableChangeBtn}
                            isLoading={isChangeProcessing}
                        >
                            {applyMessage}
                        </Button>
                    </Grid>
                </div>
            </div>
            <ChangeVehicleConfirmModal show={showConfirmModal} onProceed={handleChangeProceed} onCancel={handleChangeCancel} />
        </Fragment>
    );
};
