import config from '../../../store/mmd/config';
import { connect } from 'react-redux';
import { skipTradeIn, updateTradeInEntryPoint } from '../../../store/actionCreators';
import { getGlobalSelector } from '../../../store/mmd/mmd';
import { NoTradeInButtonUI } from './NoTradeInButtonUI';

export const mapDispatchToProps = (dispatch: any) => {
    return {
        onSkipTradeInClick: () => {
            dispatch(skipTradeIn());
        },
        setTradeInEntryPoint: (entryPoint: string) => {
            dispatch(updateTradeInEntryPoint(entryPoint));
        }
    };
};

const mapStateToProps = (state: any) => {
    const currentLocation = config.utils.getCurrentLocation();
    const entryPoint = getGlobalSelector().getTradeInEntryPoint(state);
    const isComplete = getGlobalSelector().isTradeInCompleted(state);
    const isTradeInImprovedWorkflowEnabled =
        getGlobalSelector().additionalSelectors.configSelectors.isTradeInImprovedWorkflowEnabled(state);

    return { currentLocation, entryPoint, isComplete, isTradeInImprovedWorkflowEnabled };
};

export default connect(mapStateToProps, mapDispatchToProps)(NoTradeInButtonUI);
