import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import config from '../../../store/mmd/config';
import { urlBuilder } from '@makemydeal/dr-common-utils';
import { Resources, getInitialDeepLink } from '@makemydeal/dr-activities-common';
import * as tradeInSelectors from '../../../store/mmd/tradeIn';
import { getGlobalSelector } from '../../../store/mmd/mmd';
import { closeTradeInToastMessage } from '../../../store/actionCreators';
import { RouteValues } from '../../../utils/constants';
import { Actions } from '../../../store/actions';
import { MessageBox } from '@makemydeal/dr-platform-types';
import NoTradeInButton from '../NoTradeInButton/NoTradeInButton';
import { TradePageUI } from './TradePageUI';
import { ITradeInSubaruGtpFlowError } from '../../../types';

export interface ITradeInPageUIStateProps {
    paymentErrorMsg?: string;
    showTradeErrorMessage?: boolean;
    isPaymentInvalid?: boolean;
    tradeValuationReset?: boolean;
    paymentError?: { () };
    renderDisclaimer?: { () };
    retryApplyTrade?: { () };
    dispatchAnalytics?: { (type: string, description: string) };
    urlPath?: string;
    hideDisclaimerImg?: boolean;
    toastMessage?: {
        message: string;
        onCloseActionCreator: { () };
    };
    messageBox?: MessageBox;
    isSDPRedesignEnabled?: boolean;
    pageTitle: ReactNode;
    pageSubTitle?: ReactNode;
    showCustomTitle?: boolean | string;
    isSubaruGtpEligible?: boolean;
    tradeInSubaruGtpFlowError?: ITradeInSubaruGtpFlowError;
}

const convertRouteToTitleCase = (route) => {
    const routeWithoutSlash = route.slice(1);
    const routeWithSpacesInserted = routeWithoutSlash.replace(/([A-Z])/g, ' $1');
    return routeWithSpacesInserted.charAt(0).toUpperCase() + routeWithSpacesInserted.slice(1);
};

const toastMessage = (state) => {
    const isUserRedirectedToManualEntry =
        getGlobalSelector().additionalSelectors.routeSelectors.isUserRedirectedToManualEntry(state);
    const initialDeepLink = getInitialDeepLink(state) || '';
    const shouldShowToastMessage =
        !state.tradeInComponent.toastClosed && isUserRedirectedToManualEntry && initialDeepLink !== RouteValues.DASHBOARD;
    const route = convertRouteToTitleCase(initialDeepLink);
    const message = `Before navigating to the ${route} page, please provide additional information about your trade-in vehicle. 
    After completion, you can navigate to the ${route} page from the dashboard.`;

    const toastMessage = {
        message,
        onCloseActionCreator: closeTradeInToastMessage
    };

    return shouldShowToastMessage ? toastMessage : null;
};

export const mapStateToProps = (state, ownProps: ITradeInPageUIStateProps): ITradeInPageUIStateProps => {
    const urlPath = config.utils.getCurrentLocation();
    const isTradeInImprovedWorkflowEnabled =
        getGlobalSelector().additionalSelectors.configSelectors.isTradeInImprovedWorkflowEnabled(state);
    const isSDPRedesignEnabled = getGlobalSelector().additionalSelectors.configSelectors.isSDPRedesignEnabled(state);

    const paymentError = () => {
        const { isPaymentInvalid, showTradeErrorMessage, paymentErrorMsg, tradeValuationReset } = ownProps;

        if (!((isPaymentInvalid && showTradeErrorMessage) || tradeValuationReset)) return null;

        return (
            <div className="form-group text-center">
                {paymentErrorMsg}
                <a className="error-retry-tradein" onClick={ownProps.retryApplyTrade}>
                    retry
                </a>
            </div>
        );
    };

    const renderDisclaimer = () => {
        const kbbLogoUrl = urlBuilder.buildFromConfig(config.staticImages, Resources.KBB_LOGO_HD);

        if (isTradeInImprovedWorkflowEnabled && urlPath !== RouteValues.TRADE_IN_DECISION) {
            return null;
        }
        if (tradeInSelectors.hideKBBDisclaimer(state)) {
            return <NoTradeInButton showOnDisclaimer />;
        }

        return (
            <>
                <NoTradeInButton showOnDisclaimer />
                <div className="kbb-footer">
                    {!ownProps.hideDisclaimerImg && <img className="kbb-logo" src={kbbLogoUrl} />}
                    <div className="kbb-body-area">
                        <div className="kbb-title common-header">
                            About Kelley Blue Book<sup>&reg;</sup> Values
                        </div>
                        <p className="kbb-body">
                            For over 90 years, Kelley Blue Book has been The Trusted Resource for used car values, helping car
                            owners understand what their used car is worth. Kelley Blue Book Values are the results of massive
                            amounts of data, including actual sales transactions and auction prices, which are then analyzed and
                            adjusted to account for seasonality and market trends. Kelley Blue Book Values reflect local conditions
                            in over 100 different geographic regions and are updated weekly to give consumers the most up-to-date
                            used car pricing information.
                        </p>
                    </div>
                </div>
            </>
        );
    };

    const messageBox = getGlobalSelector().additionalSelectors.editPaymentActivitiesSelectors.messageBox(
        Actions.TRADE_IN_DEAL_CHANGE_MESSAGE_BOX_CLOSED,
        state,
        tradeInSelectors.hasDealChangeMessageBoxBeenClosed
    );

    // Modify the pageTitle here before passing it to TradePageUI
    const { showCustomTitle, isSubaruGtpEligible, tradeInSubaruGtpFlowError } = ownProps;
    const isSubaruGtpError = tradeInSubaruGtpFlowError && tradeInSubaruGtpFlowError.errorType;
    const useCustomTitle = showCustomTitle && !isSubaruGtpEligible && !isSubaruGtpError;
    const pageTitle = useCustomTitle ? 'Trade-In Vehicle' : ownProps.pageTitle;
    const pageSubTitle = useCustomTitle
        ? 'Determine how much your current vehicle is worth, and apply that value to your new vehicle.'
        : ownProps.pageSubTitle;

    return {
        paymentError,
        renderDisclaimer,
        urlPath,
        toastMessage: toastMessage(state),
        messageBox,
        isSDPRedesignEnabled,
        pageTitle,
        pageSubTitle
    };
};

export const mapDispatchToProps = (dispatch: any) => {
    return {
        onCloseToastMessage: (actionCreator) => {
            dispatch(actionCreator());
        },
        onCloseMessageBox: (actionCreator) => {
            dispatch(actionCreator());
        }
    };
};

const TradePage = connect(mapStateToProps, mapDispatchToProps)(TradePageUI);

export default TradePage;
