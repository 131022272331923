export const getDefaultPort = (protocol: string): number | null => {
    if (!protocol) {
        return null;
    }
    switch (protocol.toLowerCase()) {
        case 'http':
        case 'http://': {
            return 80;
        }
        case 'https':
        case 'https://': {
            return 443;
        }
        default: {
            return null;
        }
    }
};

/**
 * If the protocol matches the port then it considers that port an "default port" - meaning that no port number will need to be
 * used.
 *
 * NOTE: If the port number is not provided it is considered an default port because it is, by default, never applied when
 * building the url.
 */
export const isDefaultPort = (protocol: string, port: number | undefined): boolean => {
    const identityPort = getDefaultPort(protocol);
    if (identityPort === null) {
        return false;
    }
    return `${identityPort}` === `${port}`;
};

export const trimSlashes = (val: string): string => {
    if (!val) {
        return '';
    }
    const starts = val.startsWith('/');
    const ends = val.endsWith('/');
    if (starts && ends) {
        return val.substr(1, val.length - 2);
    } else if (starts) {
        return val.substr(1);
    } else if (ends) {
        return val.substr(0, val.length - 1);
    }
    return val;
};

export const buildApiUrl = (protocol: string, host: string, port: number | undefined, base: string): string => {
    let portString;
    if (port) {
        portString = !isDefaultPort(protocol, port) ? `:${port}` : '';
    } else {
        portString = '';
    }
    const protocolToUse = protocol?.endsWith('://') ? protocol : `${protocol}://`;
    const trimmedHost = trimSlashes(host);
    const trimmedBase = trimSlashes(base);
    const baseToUse = trimmedBase ? `/${trimmedBase}` : '';
    return `${protocolToUse}${trimmedHost}${portString}${baseToUse}/trade/search`;
};
