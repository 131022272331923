import { getGlobalSelector } from './mmd';

const getRoot = () => getGlobalSelector().additionalSelectors.optimizelySelectors;

export const isVariationActive = (state, test: any, variation: string) => getRoot().isVariationActive(state, test, variation);
export const getOptimizelyTests = () => getRoot().getOptimizelyTests();
export const activateTests = (tests: any) => getRoot().activateTests(tests);
export const getTestValue = (test: any, state: any) => getRoot().getTestValue(test, state);
export const getTestNames = () => getRoot().getTestNames();
export const track = (test: any) => getRoot().track(test);
