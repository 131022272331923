// types
import { DealHistory } from '@makemydeal/dr-dash-types';
import { FC } from 'react';

// selectors
import { offerReduxSelectors, offerSelectors, tradeInSelectors } from '@makemydeal/dr-dash-store';
import { createDealRootSelector } from '../../offerCompareColumn/hooks/createDealRootSelector';

// components
import DealSummaryItem from '../common/DealSummaryItem';
import LineItemWithChildren from '../common/LineItemWithChildren';

// constants
import * as constants from '../../constants';

// utils
import { formatDollarsAndCents } from '@makemydeal/dr-common-utils';

// styles
import { labelStyles, valueStyles } from '../utils/styles';

export type AdjustedResidualProps = {
    deal?: DealHistory;
};

const DueAtSigningPaidBy: FC<AdjustedResidualProps> = ({ deal }) => {
    const useDealRootSelector = createDealRootSelector(deal);

    const netTradeValue = useDealRootSelector(tradeInSelectors.getNetTradeInAllowanceValue);
    const totalRebates = useDealRootSelector(offerReduxSelectors.getAppliedIncentivesTotalForNonDealerCash);
    const getCashDueFromCustomer = useDealRootSelector(offerReduxSelectors.getCashDueFromCustomer);
    const cashOffsetAmountAddedToPrice = useDealRootSelector(offerReduxSelectors.getCashOffsetPriorLoanBalance);
    const totalAmountDue = useDealRootSelector(offerReduxSelectors.getDueAtSigningFromDueInAdvance);

    const formattedTotalAmountDue = formatDollarsAndCents(totalAmountDue);

    return (
        <DealSummaryItem
            labelStyles={labelStyles}
            valueStyles={valueStyles}
            label={constants.DUE_AT_SIGNING_PAID_BY}
            value={formattedTotalAmountDue}
        >
            <LineItemWithChildren label={constants.NET_TRADE_IN_ALLOWANCE} value={formatDollarsAndCents(netTradeValue)} />
            <LineItemWithChildren label={constants.REBATES_AND_NONCASH_CREDITS} value={formatDollarsAndCents(totalRebates)} />
            <LineItemWithChildren label={constants.AMOUNT_PAID_BY_CUSTOMER} value={formatDollarsAndCents(getCashDueFromCustomer)} />
            <LineItemWithChildren
                label={constants.ADDITIONAL_CAPITALIZED_COST}
                value={formatDollarsAndCents(cashOffsetAmountAddedToPrice)}
            />
            <LineItemWithChildren label={constants.AMOUNT_PAID_BY_DEALER} value={formatDollarsAndCents(0)} />
        </DealSummaryItem>
    );
};

export default DueAtSigningPaidBy;
