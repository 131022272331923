// externals
import { connect } from 'react-redux';
import isEqual from 'lodash.isequal';

// selectors
import * as tradeInSelectors from '../../../store/mmd/tradeIn';

// actions
import * as tradeInActionCreators from '../../../store/actionCreators';

// utils
import * as tradeInRoutes from '../../../utils/routes';
import { isShowroomExperience } from '../../../utils/configUtils';
import { processStepperRoutes } from '../../../utils/tradeInDecisionLogic';

// constants
import { IMPROVED_FLOW_STEPPER_ROUTES } from '../../../utils';

// components
import { IEquipmentOptionsStepOneUIStateProps, IEquipmentOptionsStepOneUIDispatchProps } from './EquipmentOptionsStepOneUI';
import EquipmentOptionsStepOneUI from './EquipmentOptionsStepOneUI';

// types
import { IRootProps } from '../../../components';

const mapStateToProps = (state: any, ownProps: IRootProps): IEquipmentOptionsStepOneUIStateProps => {
    const tradeIn = tradeInSelectors.getTradeInComponent(state) as any;
    const vehicle = tradeInSelectors.getTradeInVehicle(tradeIn);
    const vehicleName = `${vehicle.year} ${vehicle.make.name} ${vehicle.model.name}`;
    const vehicleOptions = tradeInSelectors.getTradeInVehicleOptions(tradeIn);
    const vehicleColor = tradeInSelectors.getTradeInVehicleColor(tradeIn);
    const pageSubTitle = `Please answer thoroughly, these options will affect your ${vehicleName}'s value.`;
    const pageTitle = 'Tell Us More About Your Car';
    const vehicleOptionsStepOne = Object.keys(vehicleOptions).map((key) => {
        const value = vehicleOptions[key];
        return value;
    });
    const engineOptions = vehicleOptionsStepOne.find((items) => items.name === 'Engine');
    const transmissionOptions = vehicleOptionsStepOne.find((items) => items.name === 'Transmission');
    const driveTrainOptions = vehicleOptionsStepOne.find((items) => items.name === 'DriveTrain');
    const isSalesView = isShowroomExperience(ownProps.config);
    const stepperRoutes = processStepperRoutes(state, IMPROVED_FLOW_STEPPER_ROUTES.detailsKbbOptions, isSalesView);
    const isSDPRedesignEnabled = ownProps.selector.additionalSelectors.configSelectors.isSDPRedesignEnabled(state);

    return {
        vehicle,
        pageTitle,
        pageSubTitle,
        vehicleOptions,
        vehicleColor,
        transmissionOptions,
        driveTrainOptions,
        engineOptions,
        stepperRoutes,
        isSDPRedesignEnabled
    };
};

const mapDispatchToProps = (dispatch: any): IEquipmentOptionsStepOneUIDispatchProps => ({
    onValueChange: (categoryName, optionValueId, isChecked) => {
        dispatch(tradeInActionCreators.changeEquipmentOption(categoryName, optionValueId, isChecked));
    },
    onColorChange: (colorValue, vehicle) => {
        dispatch(tradeInActionCreators.updateVehicleColor(colorValue));
    },

    next: () => {
        dispatch((_dispatch, getState) => {
            const state = getState();
            const vehicleOptions = tradeInSelectors.getTradeInSavedVehicleOpts(state.tradeInComponent);
            const tradeinOptions = tradeInSelectors.getTradeInVehicleOptions(state.tradeInComponent);
            const haveVehicleOptionsChanged = !isEqual(tradeinOptions, vehicleOptions);

            if (haveVehicleOptionsChanged) {
                dispatch(tradeInActionCreators.changedTradeIn(haveVehicleOptionsChanged));
            }
        });
        dispatch(tradeInActionCreators.applyTradeInEquipmentOptions('PAGEONE'));
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_EQUIPMENT_OPTIONS));
    },

    goToRoute: (route: string) => {
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(route));
    },
    dispatchAnalytics: (type, description) => {
        dispatch(tradeInActionCreators.dispatchAnalytics(type, description));
    },
    removeTrade: () => {
        dispatch(tradeInActionCreators.removeTradeIn());
        dispatch(tradeInActionCreators.updateTradeInCurrentLocation(tradeInRoutes.TRADE_IN));
    }
});

const EquipmentOptionsStepOne = connect(mapStateToProps, mapDispatchToProps)(EquipmentOptionsStepOneUI);

export default EquipmentOptionsStepOne;
