import { useState, useEffect, useMemo } from 'react';

// components
import { SelectInput } from '@interstate/components/SelectInput';
import { Alert } from '@interstate/components/Alert';
import ActionButton from '../common/ActionButton';
import { Button } from '@interstate/components/Button';
import { SlideOut } from '@interstate/components/SlideOut';
import { Grid } from '@interstate/components/Grid';
import { DatePicker } from '@interstate/components/DatePicker';

// libraries
import { DealLifecycle } from '@makemydeal/dr-dash-types';
import { dealLifeCycleActionCreators, configSelectors } from '@makemydeal/dr-dash-store';
import { Typography } from '@interstate/components/Typography';
import { MANAGER_VIEW_APP_PREFIX } from '@makemydeal/dr-shared-ui-utils';
import { dateUtils } from '@makemydeal/dr-common-utils';
import { isAfterDate, isBeforeDate } from '@interstate/components/Validation';

// hooks
import { useDispatch, useSelector } from 'react-redux';

// consts/enums
import { SALE, DELIVERED, CONTRACT_SIGNED, APPROVED } from '../constants';

// styles
import { DealLifeCycleFooterContainer } from './Style';
import { interstateThemeRegistry } from '@interstate/components/InterstateTheming/themes/interstateThemeRegistry';
import { InterstateThemeProvider } from '@interstate/components/InterstateThemeProvider';

type ErrorType = {
    attempts: number;
    titleMessage: string;
    message: (() => JSX.Element) | null;
};

export type DealLifecycleModalProps = {
    show: boolean;
    setShowModal: any;
    dealLifecycle: DealLifecycle;
};

const initialErrorState = {
    attempts: 0,
    titleMessage: '',
    message: null
};

const oneYearAgoFromNow = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
const oneYearAgoFromNowToValidate = dateUtils.addDaysToDate(oneYearAgoFromNow, -1);

export const DealLifecycleModal = (props: DealLifecycleModalProps) => {
    const { show, setShowModal, dealLifecycle } = props;
    const { updateDealLifecycleStatus } = dealLifecycle;
    const { updateDealLifeCycleData, updateDealLifeCycleReady } = dealLifeCycleActionCreators;
    const dispatch = useDispatch();
    const [dealStatusValue, setDealStatusValue] = useState(dealLifecycle.status || 'InProgress');
    const [saleFieldValue, setSaleFieldDate] = useState(dealLifecycle.soldDate || '');
    const [approvedFieldValue, setApprovedFieldDate] = useState(dealLifecycle.approvedDate || '');
    const [contractSignedFieldValue, setContractSignedFieldDate] = useState(dealLifecycle.contractSignedDate || '');
    const [deliveredFieldValue, setDeliveredFieldDate] = useState(dealLifecycle.deliveredDate || '');
    const [error, setError] = useState<ErrorType>(initialErrorState);
    const maxAllowedFutureMonthsInDateField = useSelector(configSelectors.getMaxAllowedFutureMonthsInDateField);
    const maxAllowedDate = new Date(new Date().setMonth(new Date().getMonth() + maxAllowedFutureMonthsInDateField));
    const maxAllowedDateToValidate = dateUtils.addDaysToDate(maxAllowedDate, 1);
    const isDealStatusSold = dealStatusValue === 'Sold';

    const isValidDates = useMemo(() => {
        return (
            (!saleFieldValue ||
                (isBeforeDate(new Date(saleFieldValue), maxAllowedDateToValidate) &&
                    isAfterDate(new Date(saleFieldValue), oneYearAgoFromNowToValidate))) &&
            (!contractSignedFieldValue ||
                (isBeforeDate(new Date(contractSignedFieldValue), maxAllowedDateToValidate) &&
                    isAfterDate(new Date(contractSignedFieldValue), oneYearAgoFromNowToValidate))) &&
            (!deliveredFieldValue ||
                (isBeforeDate(new Date(deliveredFieldValue), maxAllowedDateToValidate) &&
                    isAfterDate(new Date(deliveredFieldValue), oneYearAgoFromNowToValidate))) &&
            (!approvedFieldValue ||
                (isBeforeDate(new Date(approvedFieldValue), maxAllowedDateToValidate) &&
                    isAfterDate(new Date(approvedFieldValue), oneYearAgoFromNowToValidate)))
        );
    }, [saleFieldValue, contractSignedFieldValue, deliveredFieldValue, maxAllowedDateToValidate, approvedFieldValue]);

    const isSaleDateRequired = isDealStatusSold && saleFieldValue === '';
    const isUpdateButtonDisabled = (isDealStatusSold && saleFieldValue === '') || !isValidDates;

    const isFieldEmpty = (fieldValue: string): boolean => {
        return fieldValue === '';
    };

    const getDateFieldDisabledState = (dateFieldValue: string | undefined, status = dealStatusValue) =>
        (status === 'InProgress' || status === 'Lost') && isFieldEmpty(dateFieldValue || '');

    const isApprovedDateDisabled = getDateFieldDisabledState(dealLifecycle.approvedDate);
    const isSoldDateDisabled = getDateFieldDisabledState(dealLifecycle.soldDate);
    const isContractSignedDateDisabled = getDateFieldDisabledState(dealLifecycle.contractSignedDate);
    const isDeliveredDateDisabled = getDateFieldDisabledState(dealLifecycle.deliveredDate);

    const setDate = (event: any, dateField: string) => {
        const userEnteredDate = event.target.value;
        const checkUserEnteredDate = userEnteredDate.dateValue === undefined ? '' : userEnteredDate.dateValue;
        if (dateField === SALE) {
            setSaleFieldDate(checkUserEnteredDate);
        } else if (dateField === APPROVED) {
            setApprovedFieldDate(checkUserEnteredDate);
        } else if (dateField === CONTRACT_SIGNED) {
            setContractSignedFieldDate(checkUserEnteredDate);
        } else if (dateField === DELIVERED) {
            setDeliveredFieldDate(checkUserEnteredDate);
        }
    };

    /* istanbul ignore next */
    const updateDealLifeCycleDataHandler = () => {
        dispatch(
            updateDealLifeCycleData(MANAGER_VIEW_APP_PREFIX, {
                status: dealStatusValue,
                soldDate: new Date(saleFieldValue),
                contractSignedDate: new Date(contractSignedFieldValue),
                deliveredDate: new Date(deliveredFieldValue),
                approvedDate: new Date(approvedFieldValue)
            })
        );
    };

    const updateDealStatusValue = (event: any) => {
        const newStatus = event.target.value;
        if (newStatus === 'Sold' && !saleFieldValue) {
            setSaleFieldDate(dateUtils.formatAsMMDDYYYY(new Date()));
        } else if (newStatus === 'Lost' || newStatus === 'InProgress') {
            if (approvedFieldValue && getDateFieldDisabledState(dealLifecycle.approvedDate, newStatus)) {
                setApprovedFieldDate('');
            }
            if (contractSignedFieldValue && getDateFieldDisabledState(dealLifecycle.contractSignedDate, newStatus)) {
                setContractSignedFieldDate('');
            }
            if (deliveredFieldValue && getDateFieldDisabledState(dealLifecycle.deliveredDate, newStatus)) {
                setDeliveredFieldDate('');
            }
            if (saleFieldValue && getDateFieldDisabledState(dealLifecycle.soldDate, newStatus)) {
                setSaleFieldDate('');
            }
        }
        setDealStatusValue(event.target.value);
    };

    useEffect(() => {
        if (updateDealLifecycleStatus === 'success') {
            setTimeout(() => {
                setShowModal(false);
            }, 500);

            setError(initialErrorState);
        }

        if (updateDealLifecycleStatus === 'failure') {
            if (error.attempts < 2) {
                setError((prev) => ({
                    attempts: prev.attempts + 1,
                    titleMessage: 'An Error Has Ocurred',
                    message: () => <p>There's a problem updating. Please try again.</p>
                }));
            } else {
                setError((prev) => ({
                    attempts: prev.attempts + 1,
                    titleMessage: 'The System Is Currently Unavailable',
                    message: () => (
                        <p data-testid="third-attempt-error-message">
                            Sorry, the system you're attempting to update is currently unavailable. Please contact Support at{' '}
                            <a href="tel:888-740-2165">888-740-2165</a>
                        </p>
                    )
                }));
            }
        }
    }, [updateDealLifecycleStatus]);

    useEffect(() => {
        if (show) {
            setDealStatusValue(dealLifecycle.status || 'InProgress');
            setSaleFieldDate(dealLifecycle.soldDate || '');
            setApprovedFieldDate(dealLifecycle.approvedDate || '');
            setContractSignedFieldDate(dealLifecycle.contractSignedDate || '');
            setDeliveredFieldDate(dealLifecycle.deliveredDate || '');
            setError(initialErrorState);
        }
    }, [show]);

    useEffect(() => {
        setTimeout(() => {
            dispatch(updateDealLifeCycleReady());
        }, 500);
    }, [error]);

    useEffect(() => {
        if (show) {
            setError(initialErrorState);
            dispatch(updateDealLifeCycleReady());
        }
    }, [show]);

    const selectInput = (
        <SelectInput
            options={[
                {
                    label: 'In Progress',
                    value: 'InProgress'
                },
                {
                    label: 'Lost',
                    value: 'Lost'
                },
                {
                    label: 'Sold',
                    value: 'Sold',
                    disabled: false
                }
            ]}
            onChange={updateDealStatusValue}
            name="change-deal-status"
            data-testid="change-deal-status"
            label="Change Deal Status"
            value={dealStatusValue}
            displayDeselectOption={false}
        />
    );

    const saleDateField = (
        <>
            <DatePicker
                id="sale-date-picker"
                label="Sold"
                dateFormat="MM/dd/yyyy"
                value={saleFieldValue ? new Date(saleFieldValue) : null}
                onChange={(e) => {
                    setDate(e, SALE);
                }}
                disabled={isSoldDateDisabled}
                required={isSaleDateRequired}
                hasError={isSaleDateRequired}
                locale="en-US"
                maxDate={maxAllowedDate}
                minDate={oneYearAgoFromNow}
                errorMessage={isSaleDateRequired ? 'Sold date is required' : undefined}
            />
        </>
    );

    const approvedDateField = (
        <>
            <DatePicker
                id="approved-date-picker"
                label={APPROVED}
                dateFormat="MM/dd/yyyy"
                value={approvedFieldValue ? new Date(approvedFieldValue) : null}
                onChange={(e) => {
                    setDate(e, APPROVED);
                }}
                disabled={isApprovedDateDisabled}
                locale="en-US"
                maxDate={maxAllowedDate}
                minDate={oneYearAgoFromNow}
            />
        </>
    );

    const contractSignedDateField = (
        <>
            <DatePicker
                id="contract-signed-date-picker"
                label={CONTRACT_SIGNED}
                dateFormat="MM/dd/yyyy"
                value={contractSignedFieldValue ? new Date(contractSignedFieldValue) : null}
                onChange={(e) => {
                    setDate(e, CONTRACT_SIGNED);
                }}
                disabled={isContractSignedDateDisabled}
                required={false}
                locale="en-US"
                maxDate={maxAllowedDate}
                minDate={oneYearAgoFromNow}
                hasError={false}
            />
        </>
    );

    const deliveryDateField = (
        <>
            <DatePicker
                id="delivered-date-picker"
                label={DELIVERED}
                dateFormat="MM/dd/yyyy"
                value={deliveredFieldValue ? new Date(deliveredFieldValue) : null}
                onChange={(e) => {
                    setDate(e, DELIVERED);
                }}
                disabled={isDeliveredDateDisabled}
                required={false}
                locale="en-US"
                maxDate={maxAllowedDate}
                minDate={oneYearAgoFromNow}
                hasError={false}
            />
        </>
    );

    const slideOutHeader = (
        <Typography sx={{ fontSize: '20px', lineHeight: '24px' }} tag="div" variant="strong-sm">
            Manage This Deal’s Progress
        </Typography>
    );

    const attempMessage = error.attempts >= 1 && (
        <div data-testid="update-dealLifecycle-error">
            <Alert role="alert" id="update-dealLifecycle-error" type="caution">
                <strong>{error.titleMessage}</strong>
                <br />
                {error.message && error.message()}
            </Alert>
        </div>
    );

    const slideOutFooter = (
        <DealLifeCycleFooterContainer gap={2}>
            <Button
                size="small"
                buttonStyle="tertiary"
                onClick={() => setShowModal(false)}
                sx={{
                    height: '40px',
                    padding: '12px'
                }}
                data-testid="slideout-footer-cancel-button"
            >
                Cancel
            </Button>
            <ActionButton
                status={updateDealLifecycleStatus}
                prefix="update-btn"
                readyPrefix="ready-button"
                successAction="email-sent"
                onAction={updateDealLifeCycleDataHandler}
                actionText="Update"
                loadingText="Updating..."
                disabled={isUpdateButtonDisabled || error.attempts > 2}
                sx={{ height: '40px' }}
            />
        </DealLifeCycleFooterContainer>
    );

    return (
        <div data-testid="dr-dash-deal-status-modal">
            <div data-testid="deal-status-modal">
                <InterstateThemeProvider
                    themeName="Interstate"
                    applicationName="Manager View"
                    scopeName="Manager View"
                    themeRegistries={[interstateThemeRegistry]}
                >
                    {
                        <SlideOut
                            header={slideOutHeader}
                            panelWidth={'486px'}
                            show={show}
                            onHide={() => setShowModal(false)}
                            position="right"
                            footer={{
                                footerComponent: slideOutFooter,
                                layout: 'rightAligned'
                            }}
                            sx={{
                                '.ids-SlideOut-content': {
                                    padding: '0px'
                                }
                            }}
                        >
                            <Grid data-testid="deal-status-slideout-panel-grid" sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
                                {attempMessage}
                                {selectInput}
                            </Grid>
                            <Typography
                                sx={{
                                    margin: '24px 0 24px 0',
                                    fontSize: '18px',
                                    lineHeight: '22px',
                                    paddingLeft: '24px',
                                    paddingRight: '24px'
                                }}
                                tag="div"
                                variant="strong-sm"
                            >
                                Add Milestone Date
                            </Typography>
                            <Grid container spacing={2} sx={{ paddingLeft: '24px', paddingRight: '24px' }}>
                                <Grid xs={6}>{saleDateField}</Grid>
                                <Grid xs={6}>{approvedDateField}</Grid>
                                <Grid xs={6}>{contractSignedDateField}</Grid>
                                <Grid xs={6}>{deliveryDateField}</Grid>
                            </Grid>
                        </SlideOut>
                    }
                </InterstateThemeProvider>
            </div>
        </div>
    );
};
