// libraries
import type { AnyFSA } from '@makemydeal/dr-platform-shared';
import { featureToggleSelectors, scopedStateSelectors } from '@makemydeal/dr-shared-store';
import type { DashMiddleware, DashStore, DashNext } from '@makemydeal/dr-shared-store';
import { actions as offerActions } from '@makemydeal/dr-offer-redux';
import { PaymentProduct } from '@makemydeal/dr-platform-types';

import { calculationDetailsSelectors, configSelectors, paymentTermSelectors, vehicleProtectionSelectors } from '../selectors';
import { getShopperZip } from '../selectors/offerRedux';

import {
    completeVehicleProtection,
    fetchVehicleProtectionProducts,
    fetchVehicleProtectionRates,
    repeatFetchVehicleProtectionRates,
    resetVppProductsMonthlyPayment,
    retryFetchVehicleProtectionRates,
    startVehicleProtection,
    updatePayment,
    updateVppProductMonthlyPayment
} from '../actions/vehicleProtectionActionCreators';

import {
    ALL_PRODUCTS_SELECTED,
    ALL_PRODUCTS_UNSELECTED,
    FETCH_PRODUCTS_RETRY,
    FETCH_PRODUCTS_SUCCESS,
    FETCH_RATES_RETRY,
    FETCH_RATES_SUCCESS,
    PRODUCT_SELECTED,
    PRODUCT_UNSELECTED,
    PRODUCT_UPDATED,
    PRODUCTS_REQUEST_OVERRIDDEN
} from '../actionTypes/vehicleProtectionActionTypes';
import { PAYMENT_RECEIVED, PAYMENT_REQUESTED } from '../actionTypes/deskingActionTypes';
import { MAX_FETCH_RATES_POLLING_COUNT, RATE_REQUEST_STATUSES, RERATE_PRODUCTS_ACTION_LIST } from '../types/vehicleProtectionTypes';
import { DASH_START_VEHICLE_PROTECTION } from '../actionTypes/navigationActionTypes';

export const middleware: DashMiddleware = (store: DashStore) => (next: DashNext) => (action: AnyFSA) => {
    next(action);

    const state = store.getState();

    if (
        !featureToggleSelectors.areFeatureTogglesAvailable(state) ||
        !calculationDetailsSelectors.isFieCalculationEngineService(state)
    ) {
        return;
    }

    switch (action.type) {
        // NOTE: shoudn't have actions from `RERATE_PRODUCTS_ACTION_LIST` list here, covered after payment success
        case offerActions.INIT_SUCCESS:
        case offerActions.MAKE_CURRENT_OFFER:
        case PRODUCTS_REQUEST_OVERRIDDEN:
        case FETCH_PRODUCTS_RETRY:
        case offerActions.PAYMENT_GRID_FETCH_VPP_PRODUCTS: {
            store.dispatch(fetchVehicleProtectionProducts(state));

            break;
        }
        case PAYMENT_REQUESTED: {
            const usePaymentProductsMonthlyPayment = featureToggleSelectors.usePaymentProductsMonthlyPayment(state);

            if (usePaymentProductsMonthlyPayment) {
                store.dispatch(resetVppProductsMonthlyPayment());
            }

            break;
        }
        case PAYMENT_RECEIVED: {
            const { originalAction } = action.meta;

            // we don't need multiple calls to get vpp products if the payment call is just for 9-box
            if (originalAction?.meta?.paymentGrid?.isMainPayment === false) {
                return;
            }

            if (RERATE_PRODUCTS_ACTION_LIST.includes(originalAction?.type)) {
                store.dispatch(fetchVehicleProtectionProducts(state));
            }

            // NOTE: updating productMonthlyPayment of selected products
            const usePaymentProductsMonthlyPayment = featureToggleSelectors.usePaymentProductsMonthlyPayment(state);

            if (usePaymentProductsMonthlyPayment) {
                const paymentQuote = paymentTermSelectors.getCurrentPaymentTermData(state);

                if (!paymentQuote?.products?.length) {
                    return;
                }

                vehicleProtectionSelectors.getLastSuccessfulPaymentProducts(state).forEach((product, productIndex) => {
                    // NOTE: PO response may contain accessories and vpp together in products[], so we have to filter it
                    const paymentProduct = paymentQuote.products
                        .filter((product) => product.categoryCode !== 'ACC')
                        .find(
                            (paymentDetails: PaymentProduct, paymentProductIndex) =>
                                paymentDetails.categoryCode === product.productCategoryCode && paymentProductIndex === productIndex
                        );

                    if (product.productCode && paymentProduct?.monthlyPrice) {
                        store.dispatch(updateVppProductMonthlyPayment(product.productCode, paymentProduct.monthlyPrice));
                    }
                });
            }

            break;
        }
        case FETCH_PRODUCTS_SUCCESS:
        case FETCH_RATES_RETRY: {
            const { rateToken } = action.payload;
            const bffConfig = configSelectors.getServicesBff(state);
            const showRecommendedOnVppRates = featureToggleSelectors.showRecommendedOnVppRates(state);
            const isPlanSelectionForVppEnabled = featureToggleSelectors.isPlanSelectionForVppEnabled(state);
            const scopeId = scopedStateSelectors.getCurrentScopeId(state as any);

            store.dispatch(
                fetchVehicleProtectionRates(rateToken, bffConfig, scopeId, {
                    showRecommendedOnVppRates,
                    isPlanSelectionForVppEnabled
                })
            );

            break;
        }
        case FETCH_RATES_SUCCESS: {
            const { status } = action.payload;

            if (status === RATE_REQUEST_STATUSES.PENDING) {
                const { retryNumber = 0 } = action.meta;

                if (retryNumber <= MAX_FETCH_RATES_POLLING_COUNT) {
                    const bffConfig = configSelectors.getServicesBff(state);
                    const scopeId = scopedStateSelectors.getCurrentScopeId(state as any);

                    store.dispatch(repeatFetchVehicleProtectionRates(action, bffConfig, scopeId));
                }
            }

            break;
        }
        case DASH_START_VEHICLE_PROTECTION: {
            const isRatingTimeout = vehicleProtectionSelectors.isVppRatingTimeout(state);

            if (isRatingTimeout) {
                store.dispatch(retryFetchVehicleProtectionRates());
            }

            store.dispatch(startVehicleProtection());

            break;
        }
        case PRODUCT_SELECTED:
        case PRODUCT_UNSELECTED:
        case ALL_PRODUCTS_UNSELECTED:
        case ALL_PRODUCTS_SELECTED:
        case PRODUCT_UPDATED: {
            store.dispatch(completeVehicleProtection());

            const { skipPayment } = action.meta || {};

            if (skipPayment) {
                return;
            }

            const includeTaxes = getShopperZip(state) !== '';
            const paymentProductCodes = vehicleProtectionSelectors.getVppSelectedProductCodes(state);

            store.dispatch(updatePayment(includeTaxes, paymentProductCodes));

            break;
        }
        default:
            break;
    }
};
