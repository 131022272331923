// interfaces/types
import { FeatureTogglesAndUrlOnly } from './featureToggleTypes';

export const getFeatureToggleState = (state: any): FeatureTogglesAndUrlOnly => {
    return state.featureToggles || ({} as FeatureTogglesAndUrlOnly);
};

const getDealerState = (state: any): any => {
    return state.dealer || {};
};

export const useFnI2 = (state: any): boolean => {
    const dealer = getDealerState(state);
    return dealer.useFnI2 || false;
};

export const isTradeInAppEnabled = (state: any): boolean => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enableTradeInOnSDP || false;
};

export const creditOverwriteBlockEnabled = (state: any): boolean => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return (featureToggles.creditOverwriteBlock && useFnI2(state)) || false; // TODO: Kevin - && usFni2 should not be here
};

export const isTradeInIcoEnabledOnSP = (state: any): boolean => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enableIcoTradeInOnSP || false;
};

export const isTradeInIcoEnabledForAmsi = (state: any): boolean => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.isAmsiTradeInEnabledOnSP || false;
};

export const fakeDealerIcoOff = (state: any): boolean => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.fakeDealerIcoOff || false;
};

export const isTradeInLeadFormSDPEnabled = (state: any): boolean => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enableTradeInLeadFormSDP || false;
};

export const isTradeinLeadformValuationEnabled = (state: any): boolean => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enableTradeinLeadFormValuation || false;
};

export const isTradeServiceEnabled = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.isTradeServiceEnabled || false;
};

export const enableDebugging = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enableDebugging || false;
};

export const isKbbFieldValidationEnabled = (state: any): boolean => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.kbbFieldValidation || false;
};

export const isShareDealEnabled = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.showShareDealComp || false;
};

export const isDevHelperEnabled = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enableDevHelper || false;
};

export const isShopperEnteredTradeEnabled = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enableShopperEnteredTrade || false;
};

export const isTradeCdkCrudEnabled = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.tradeInCdkCrud || false;
};

export const isPaymentBuildingCardsEnabled = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enablePaymentBuildingCards || false;
};

export const isNavigateToInitDeepLinkRouteEnabled = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.enableNavigateToInitDeepLinkRoute || false;
};

export const isAmdInitDevoEnabled = (state: any) => {
    const featureToggles: FeatureTogglesAndUrlOnly = getFeatureToggleState(state);
    return featureToggles.isAmdInitDevoEnabled || false;
};

const featureToggles = {
    isShareDealEnabled,
    isTradeInAppEnabled,
    creditOverwriteBlockEnabled,
    isTradeInIcoEnabledOnSP,
    isTradeInIcoEnabledForAmsi,
    fakeDealerIcoOff,
    isTradeInLeadFormSDPEnabled,
    isTradeinLeadformValuationEnabled,
    isTradeServiceEnabled,
    enableDebugging,
    isKbbFieldValidationEnabled,
    getFeatureToggleState,
    isDevHelperEnabled,
    isShopperEnteredTradeEnabled,
    isTradeCdkCrudEnabled
};

export default featureToggles;
