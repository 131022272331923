import { FC, PropsWithChildren, useState } from 'react';
import { DEFAULT_EXPANDABLE_ID, DEFAULT_EXPANDABLE_STATUS } from '../../constants';
import { ExpandableAreaContext } from './Context';

/**
 * Establish a collapsible area provider track collapsible state remotely. This should exits at the closest DOM level possible to the CollapsibleArea and CollapsibleExpandableAreaSection. If you are using multiple collapsibles in the same area, use a single provider and specify differentiating collapsibleId's to tie them together.
 */
export const ExpandableAreaProvider: FC<PropsWithChildren> = ({ children }) => {
    const [toggleByKey, setToggleByKey] = useState<Record<string, boolean>>({ [DEFAULT_EXPANDABLE_ID]: DEFAULT_EXPANDABLE_STATUS });

    const hasId = (expandableId?: string): boolean => {
        return Boolean(Object.keys(toggleByKey).find((id) => id === expandableId));
    };

    const isExpanded = (expandableId: string = DEFAULT_EXPANDABLE_ID): boolean => {
        return Boolean(toggleByKey[expandableId]);
    };

    const doToggle = (expandableId: string = DEFAULT_EXPANDABLE_ID, value?: boolean): void => {
        setToggleByKey((old) => {
            const updated = { ...old };

            if (value != null) {
                updated[expandableId] = value;
            } else {
                updated[expandableId] = !updated[expandableId]; // flip logic; also assumes value might be undefined.
            }

            return updated;
        });
    };

    return (
        <ExpandableAreaContext.Provider
            value={{
                hasId,
                doToggle,
                isExpanded
            }}
        >
            {children}
        </ExpandableAreaContext.Provider>
    );
};
